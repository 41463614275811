define("ember-svg-jar/inlined/pui_mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.7 0H4.3c-.6 0-1 .5-1 1v14c0 .6.5 1 1 1h7.4c.6 0 1-.5 1-1V1c0-.6-.4-1-1-1zM6.9.7h2.3c.1 0 .1.1.1.1 0 .1 0 .1-.1.1H6.9c-.1 0-.1-.1-.1-.1 0-.1 0-.1.1-.1zm1.2 14.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5zM12 14H4V1.7h8V14z\"/><path d=\"M4.8 2.6h6.5V13H4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});