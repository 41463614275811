define("ember-svg-jar/inlined/tokenStruct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.667 4.667h2.666v2.666H4.667V4.667zm0 4h2.666v2.666H4.667V8.667zm4-4h2.666v2.666H8.667V4.667zm0 4h2.666v2.666H8.667V8.667zM3.333 3.333v9.334h9.334V3.333H3.333zm0-1.333h9.334C13.403 2 14 2.597 14 3.333v9.334c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 012 12.667V3.333C2 2.597 2.597 2 3.333 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});