define("ember-svg-jar/inlined/apm_trace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm0 1a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1H2zm.5 2h10a.5.5 0 110 1h-10a.5.5 0 010-1zm1 3h6a.5.5 0 010 1h-6a.5.5 0 010-1zm2 3h4a.5.5 0 010 1h-4a.5.5 0 010-1zm3 3h5a.5.5 0 110 1h-5a.5.5 0 110-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});