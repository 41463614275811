define("ember-svg-jar/inlined/pui_gauge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.157 3.5L7.309 7.118A1.096 1.096 0 006.843 8a1.114 1.114 0 002.228 0c0-.169-.044-.326-.112-.47l.198-4.03z\"/><path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zm1.859 8a1.86 1.86 0 01-3.718 0c0-.053.011-.103.016-.154H2.008C2.091 4.609 4.744 2 8 2s5.909 2.609 5.992 5.846H9.843c.004.051.016.101.016.154z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});