define("ember-parachute/utils/try-invoke", ["exports", "ember-parachute/utils/can-invoke"], function (_exports, _canInvoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tryInvoke = tryInvoke;
  function tryInvoke(obj, methodName, args) {
    if ((0, _canInvoke.canInvoke)(obj, methodName)) {
      let method = obj[methodName];
      return method.apply(obj, args);
    }
  }
});