define("ember-svg-jar/inlined/l-binoculars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 10h4m5-3V4a1 1 0 00-1-1h-2a1 1 0 00-1 1v3m5 14a2 2 0 002-2v-3.851c0-1.39-2-2.962-2-4.829V8a1 1 0 00-1-1h-4a1 1 0 00-1 1v11a2 2 0 002 2zm2-5H2m2 5a2 2 0 01-2-2v-3.851c0-1.39 2-2.962 2-4.829V8a1 1 0 011-1h4a1 1 0 011 1v11a2 2 0 01-2 2zM9 7V4a1 1 0 00-1-1H6a1 1 0 00-1 1v3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-binoculars",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});