define("ember-svg-jar/inlined/l-align-horizontal-space-around", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"6\" height=\"10\" x=\"9\" y=\"7\" rx=\"2\"/><path d=\"M4 22V2m16 20V2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-align-horizontal-space-around",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});