define("ember-svg-jar/inlined/l-candy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.5 7.5l-2 2a4.95 4.95 0 107 7l2-2a4.95 4.95 0 10-7-7m4.5-1v10m-4-9v10\"/><path d=\"M16 7l1-5 1.37.68A3 3 0 0019.7 3H21v1.3c0 .46.1.92.32 1.33L22 7l-5 1m-9 9l-1 5-1.37-.68A3 3 0 004.3 21H3v-1.3a3 3 0 00-.32-1.33L2 17l5-1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-candy",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});