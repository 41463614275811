define("ember-svg-jar/inlined/tokenMetricGauge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 4h-.5a.5.5 0 010-1h1a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-1a.5.5 0 010-1H5v-2h-.5a.5.5 0 010-1H5V7h-.5a.5.5 0 010-1H5V4zm3 1a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1V6a1 1 0 00-1-1H8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});