define("ember-svg-jar/inlined/l-sandwich", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 11v3a1 1 0 001 1h16a1 1 0 001-1v-3m-9 8H4a1 1 0 01-1-1v-2a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-1 1h-3.83M3 11l7.77-6.04a2 2 0 012.46 0L21 11z\"/><path d=\"M12.97 19.77L7 15h12.5l-3.75 4.5a2 2 0 01-2.78.27\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-sandwich",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});