define("ember-svg-jar/inlined/l-a-large-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 14h-5m0 2v-3.5a2.5 2.5 0 015 0V16M4.5 13h6M3 16l4.5-9 4.5 9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-a-large-small",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});