define("ember-svg-jar/inlined/pui_category_manufacturing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.995 8.678L8.328 1.253a2.11 2.11 0 00-2.003 0l-4.04 2.134c-.364.203-.669.5-.883.861a2.408 2.408 0 00-.032 2.4c.205.367.501.672.86.884l13.523 7.785a2.188 2.188 0 002.147 0l4.194-2.516c.352-.218.64-.526.837-.893a2.41 2.41 0 00-.057-2.38 2.36 2.36 0 00-.88-.85v0z\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.274 8.678l13.667-7.425a2.11 2.11 0 012.004 0l4.039 2.134c.367.198.676.49.895.85a2.408 2.408 0 01.064 2.399c-.2.37-.492.68-.849.896L8.517 15.317a2.188 2.188 0 01-2.147 0l-4.194-2.516a2.366 2.366 0 01-.837-.893 2.41 2.41 0 01.057-2.38c.215-.357.518-.65.88-.85v0zM12.135 23.46V13.352\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M20.988 13.913v3.785c0 .424-.114.84-.33 1.203a2.294 2.294 0 01-.899.853l-6.64 3.46a2.11 2.11 0 01-1.97 0l-6.64-3.46a2.295 2.295 0 01-.897-.853 2.34 2.34 0 01-.33-1.203v-3.785\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});