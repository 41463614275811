define("ember-svg-jar/inlined/checkInCircleFilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8 16A8 8 0 118 0a8 8 0 010 16zm3.65-10.857L6.91 9.8 4.35 7.286a.5.5 0 00-.7.714l2.909 2.857a.5.5 0 00.7 0l5.091-5a.5.5 0 10-.7-.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});