define("ember-svg-jar/inlined/l-tree-pine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 14l3 3.3a1 1 0 01-.7 1.7H4.7a1 1 0 01-.7-1.7L7 14h-.3a1 1 0 01-.7-1.7L9 9h-.2A1 1 0 018 7.3L12 3l4 4.3a1 1 0 01-.8 1.7H15l3 3.3a1 1 0 01-.7 1.7zm-5 8v-3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-tree-pine",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});