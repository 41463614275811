define("ember-svg-jar/inlined/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M92 173.456v-49.055l-10.121 10.316v49.054L92 173.456z\" fill=\"#494404\"/><path d=\"M81.879 134.716l-13.652-4.355v49.513L81.879 184v-49.284z\" fill=\"#635112\"/><path d=\"M68.227 130.361l-3.06-15.129 10.592-10.774 12.828 4.126 3.295 16.046-10.121 10.316-13.534-4.585z\" fill=\"#B9D542\"/><path d=\"M158.125 130.908V80.081L98.481 90.769v50.827l59.644-10.688z\" fill=\"#71A619\"/><path d=\"M98.481 90.768l-80.449-4.513v51.303l80.45 4.275V90.768z\" fill=\"#94C021\"/><path d=\"M18.032 86.256L0 70.58v50.909l18.032 16.069V86.256z\" fill=\"#6EA523\"/><path d=\"M18.032 86.255L0 70.58l62.418-11.163 75.595 4.275 19.418 16.626-59.643 10.688-79.756-4.75z\" fill=\"#B9D542\"/><path d=\"M132.25 77.265V33.234l-41.208 9.259v44.03l41.208-9.258z\" fill=\"#71A619\"/><path d=\"M91.042 42.493l-55.584-3.91v44.443l55.584 3.703V42.493z\" fill=\"#94C021\"/><path d=\"M35.458 38.583L23 25.003v44.102l12.458 13.92V38.584z\" fill=\"#6EA523\"/><path d=\"M35.458 38.583L23 25.003l43.125-9.67 52.229 3.704 13.417 14.403-41.208 9.258-55.105-4.115z\" fill=\"#B9D542\"/><path d=\"M115 29.252V8.455l-28.195 4.373v20.797L115 29.252z\" fill=\"#71A619\"/><path d=\"M86.805 12.828l-38.031-1.846v20.991l38.03 1.75V12.827z\" fill=\"#94C021\"/><path d=\"M48.774 10.982L40.25 4.568v20.83l8.524 6.575V10.982z\" fill=\"#6EA523\"/><path d=\"M48.774 10.982L40.25 4.568 69.757 0l35.735 1.75 9.18 6.802-28.195 4.373-37.703-1.943z\" fill=\"#B9D542\"/>",
    "attrs": {
      "width": "159",
      "height": "184",
      "viewBox": "0 0 159 184",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});