define("ember-svg-jar/inlined/precaucion_materiales_oxidantes_y_comburentes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_MATERIALES_OXIDANTES_Y_COMBURENTES</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212zm23.726-12.768c.025-.088.15-.086.173.003.163.63.409 2.122-.382 3.595-.276.514-.591.84-.907 1.04a6.372 6.372 0 11-11.582-3.734 4.458 4.458 0 01-.06-.371c-.253-2.074 1.092-2.993 1.653-3.283.076-.039.159.04.123.117-.162.356-.402 1.053-.027 1.446l.003.003c.218.229.6.138.696-.163.81-2.55 3.543-3.101 4.383-4.536.753-1.286-.39-2.283-.777-2.569-.03-.023-.01-.071.028-.066.507.076 2.196.458 2.953 2.224.897 2.092-1.195 3.773-.896 4.931.287 1.113 2.27-.51 2.424-2.634a.09.09 0 01.164-.044c.367.546 1.243 2.051 1.035 3.687-.062.49-.267.956-.542 1.383-.247.38.196.83.58.588.523-.33.826-1.162.958-1.617zm-7.396 9.884a5.2 5.2 0 100-10.399 5.2 5.2 0 000 10.399z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});