define("ember-svg-jar/inlined/l-leafy-green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 22c1.25-.987 2.27-1.975 3.9-2.2a5.56 5.56 0 013.8 1.5 4 4 0 006.187-2.353 3.5 3.5 0 003.69-5.116A3.5 3.5 0 0020.95 8 3.5 3.5 0 1016 3.05a3.5 3.5 0 00-5.831 1.373 3.5 3.5 0 00-5.116 3.69 4 4 0 00-2.348 6.155C3.499 15.42 4.409 16.712 4.2 18.1 3.926 19.743 3.014 20.732 2 22m0 0L17 7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-leafy-green",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});