define("ember-svg-jar/inlined/l-anvil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 10H6a4 4 0 01-4-4 1 1 0 011-1h4m0 0a1 1 0 011-1h13a1 1 0 011 1 7 7 0 01-7 7H8a1 1 0 01-1-1zm2 7v5m6-5v5M5 20a3 3 0 013-3h8a3 3 0 013 3 1 1 0 01-1 1H6a1 1 0 01-1-1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-anvil",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});