define("ember-keyboard/decorators/key-responder", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = keyResponder;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function populateKeyboardHandlers(responder) {
    responder.keyboardHandlers = responder.keyboardHandlers || {};
    if (!responder.keyboardHandlerNames) {
      responder.keyboardHandlerNames = {};
      for (let propertyName in responder) {
        let propertyValue = responder[propertyName];
        if (typeof propertyValue === 'function' && propertyValue._emberKeyboardOnKeyDecoratorData) {
          for (let listenerName of propertyValue._emberKeyboardOnKeyDecoratorData.listenerNames || []) {
            responder.keyboardHandlerNames[listenerName] = propertyName;
          }
        }
      }
    }
    for (let [listenerName, methodName] of Object.entries(responder.keyboardHandlerNames || {})) {
      responder.keyboardHandlers[listenerName] = responder[methodName].bind(responder);
    }
  }
  function keyResponder(opts = {}) {
    const createClass = function (DecoratedClass) {
      var _class, _descriptor, _class2;
      if (opts.priority === undefined) {
        opts.priority = 0;
      }
      if (opts.activated === undefined) {
        opts.activated = true;
      }
      return _class = (_class2 = class ClassAsKeyResponder extends DecoratedClass {
        get keyboardPriority() {
          if (super.keyboardPriority === undefined) {
            return opts.priority;
          }
          return super.keyboardPriority;
        }
        set keyboardPriority(val) {
          super.keyboardPriority = val;
        }
        get keyboardActivated() {
          if (super.keyboardActivated === undefined) {
            return opts.activated;
          }
          return super.keyboardActivated;
        }
        set keyboardActivated(val) {
          super.keyboardActivated = val;
        }
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "keyboard", _descriptor, this);
          populateKeyboardHandlers(this);
          this.keyboard.register(this);
        }
        willDestroy() {
          this.keyboard.unregister(this);
          super.willDestroy(...arguments);
        }
      }, _defineProperty(_class2, "name", `${DecoratedClass.name}WithKeyResponder`), _class2), _descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _class;
    };
    if (typeof opts === 'function') {
      return createClass(opts);
    } else {
      return function (DecoratedClass) {
        return createClass(DecoratedClass);
      };
    }
  }
});