define("ember-svg-jar/inlined/l-feather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.67 19a2 2 0 001.416-.588l6.154-6.172a6 6 0 00-8.49-8.49L5.586 9.914A2 2 0 005 11.328V18a1 1 0 001 1zM16 8L2 22m15.5-7H9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-feather",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});