define("ember-svg-jar/inlined/map_builder_redo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Rehacer</title><path d=\"M21.38 23.91a6.17 6.17 0 012.48-2.65 5.48 5.48 0 011.58-.55l-.17-.55a.69.69 0 01-.11-.41.81.81 0 01.56-.79h.43a.9.9 0 01.45.24l.39.34 1.3 1.11a.93.93 0 01.16 1.25c-.49.71-1 1.42-1.46 2.14a.86.86 0 01-1.12.29.8.8 0 01-.37-.42.37.37 0 01-.05-.11.32.32 0 010-.14.27.27 0 010-.13.32.32 0 010-.17l.16-1a3.9 3.9 0 00-3.1 3.38 3.82 3.82 0 001.49 3.5l.22.16a.5.5 0 01.2.3.54.54 0 01-.07.33l-.42.61a.51.51 0 01-.37.21.46.46 0 01-.23-.09 5.53 5.53 0 01-1.79-2.08 5 5 0 01-.43-1.68 6.88 6.88 0 01.27-3.09z\" fill=\"#b6b6b7\" opacity=\".4\"/><path d=\"M21.69 23.68A6.2 6.2 0 0124.17 21a5.49 5.49 0 011.58-.56l-.17-.54a.71.71 0 01-.11-.41.83.83 0 01.56-.8h.43a1.1 1.1 0 01.45.24l.39.34 1.3 1.12a.93.93 0 01.16 1.25c-.49.7-1 1.42-1.46 2.14a.88.88 0 01-1.12.29.84.84 0 01-.37-.42.61.61 0 010-.12.32.32 0 010-.14.53.53 0 010-.12.37.37 0 010-.18l.16-1a3.67 3.67 0 00-1.84.93 3.71 3.71 0 00-1.26 2.45A3.8 3.8 0 0024.34 29l.22.17a.48.48 0 01.2.29.58.58 0 01-.07.34l-.42.6a.51.51 0 01-.37.21.39.39 0 01-.23-.09 5.53 5.53 0 01-1.79-2.08 5 5 0 01-.46-1.68 6.88 6.88 0 01.27-3.08z\" fill=\"#437287\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});