define("ember-svg-jar/inlined/l-pentagon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.83 2.38a2 2 0 012.34 0l8 5.74a2 2 0 01.73 2.25l-3.04 9.26a2 2 0 01-1.9 1.37H7.04a2 2 0 01-1.9-1.37L2.1 10.37a2 2 0 01.73-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pentagon",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});