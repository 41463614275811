define("ember-svg-jar/inlined/l-hard-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 18a1 1 0 001 1h18a1 1 0 001-1v-2a1 1 0 00-1-1H3a1 1 0 00-1 1zm8-8V5a1 1 0 011-1h2a1 1 0 011 1v5M4 15v-3a6 6 0 016-6m4 0a6 6 0 016 6v3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-hard-hat",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});