define("ember-svg-jar/inlined/pui_maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voltage</title><path d=\"M15.498 6.309l-2.176-.52 1.228-1.803a.65.65 0 00-.077-.827l-1.566-1.563a.649.649 0 00-.801-.094L10.2 2.672 9.794.53a.651.651 0 00-.64-.53H6.941a.65.65 0 00-.633.5l-.52 2.176-1.802-1.227a.65.65 0 00-.827.077L1.595 3.091a.65.65 0 00-.094.802l1.171 1.906-2.142.406a.65.65 0 00-.53.64v2.213c0 .3.206.564.5.633l2.178.52-1.228 1.803a.65.65 0 00.077.827l1.564 1.564a.65.65 0 00.801.094l1.906-1.171.406 2.142a.653.653 0 00.64.528h2.213a.649.649 0 00.633-.5l.52-2.178 1.803 1.228a.65.65 0 00.827-.077l1.564-1.564a.65.65 0 00.094-.801L13.327 10.2l2.142-.406A.65.65 0 0016 9.155V6.942a.652.652 0 00-.502-.633zm-7.499 4.987a3.296 3.296 0 110-6.593 3.296 3.296 0 010 6.593z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});