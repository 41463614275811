define("ember-svg-jar/inlined/pui_shower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 .15c.001-.137-.037-.15-.156-.15-2.364.004-4.727.003-7.091.003-.27.055-.544.089-.805.179C6.179.794 5.125 2.033 4.79 3.898c-.022.124-.067.154-.179.152a4.113 4.113 0 00-.576.025C1.599 4.084.605 7.089.751 9.293a.25.25 0 00.016.078H.546c-.344 0-.543.202-.544.549-.002.402-.002.804-.001 1.205.001.334.207.549.532.549h11.439c.332 0 .536-.207.538-.543.002-.375-.016-.751.005-1.124.023-.397-.227-.645-.624-.642l-.142.001a.241.241 0 00.011-.084c-.004-.269.008-1.636-.027-1.905-.243-1.881-1.619-3.312-3.481-3.328-.187-.002-.192-.008-.099-.18.306-.568.587-.812 1.207-.812 2.013-.002 4.026-.003 6.04.002.283.001.483-.097.596-.363 0-.849-.004-1.697.004-2.546z\"/><ellipse cx=\"9.253\" cy=\"13.042\" rx=\".641\" ry=\".65\"/><ellipse cx=\"6.255\" cy=\"13.042\" rx=\".641\" ry=\".65\"/><ellipse cx=\"3.256\" cy=\"13.042\" rx=\".641\" ry=\".65\"/><ellipse cx=\"7.755\" cy=\"15.35\" rx=\".641\" ry=\".65\"/><ellipse cx=\"11\" cy=\"15.35\" rx=\".641\" ry=\".65\"/><ellipse cx=\"4.756\" cy=\"15.35\" rx=\".641\" ry=\".65\"/><ellipse cx=\"1.758\" cy=\"15.35\" rx=\".641\" ry=\".65\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});