define("ember-svg-jar/inlined/l-rotate-cw-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 5H6a2 2 0 00-2 2v3\"/><path d=\"M9 8l3-3-3-3M4 14v4a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-rotate-cw-square",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});