define("ember-svg-jar/inlined/l-unfold-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 12h6M8 12H2M12 2v2m0 4v2m0 4v2m0 4v2m7-7l3-3-3-3M5 9l-3 3 3 3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-unfold-horizontal",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});