define("ember-svg-jar/inlined/l-mouse-pointer-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 4.1L12 6M5.1 8l-2.9-.8M6 12l-1.9 2M7.2 2.2L8 5.1m1.037 4.59a.498.498 0 01.653-.653l11 4.5a.5.5 0 01-.074.949l-4.349 1.041a1 1 0 00-.74.739l-1.04 4.35a.5.5 0 01-.95.074z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-mouse-pointer-click",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});