define("ember-svg-jar/inlined/l-refresh-ccw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 12a9 9 0 00-9-9 9.75 9.75 0 00-6.74 2.74L3 8\"/><path d=\"M3 3v5h5m-5 4a9 9 0 009 9 9.75 9.75 0 006.74-2.74L21 16\"/><path d=\"M16 16h5v5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-refresh-ccw",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});