define("ember-svg-jar/inlined/pui_leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.954.243c-.11-.11-.22-.22-.33-.22C1.54.023 0 5.854 0 8.385c0 .88.22 1.76.55 2.531.11.11.22.22.33.22.11 0 .22-.11.33-.22.99-1.65 3.741-5.722 8.142-7.812.22-.11.44 0 .55.11.11.22 0 .44-.22.44-.33.22-2.421 1.65-2.421 1.65C2.861 8.605.11 13.887.11 15.647c0 .22.11.33.33.33s.33-.11.33-.33c0-.55.44-1.76 1.1-3.191 1.1.88 2.531 1.32 4.291 1.32 4.621 0 5.612-4.401 6.162-6.822.66-3.191 2.311-5.061 3.521-6.382.11.001.22-.109.11-.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});