define("ember-svg-jar/inlined/pui_collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rockFall</title><path d=\"M7.929 4.428l1.207.402h.201a.77.77 0 00.503-.201l.805-1.006a.486.486 0 000-.704l-.804-1.007c-.101-.201-.403-.302-.604-.201l-1.308.302c-.201.1-.403.301-.403.603v1.308c0 .202.202.403.403.504z\"/><path d=\"M15.778 12.377l-1.509-.503.906.101h.101c.101 0 .201-.101.302-.201l.403-.805c.101-.201 0-.403-.101-.503l-.704-.604c-.201-.101-.403-.101-.503 0l-.704.403c-.201.101-.201.302-.201.403l.201.805c.101.201-1.912-.503-1.912-.503l.101-1.711a.77.77 0 00-.201-.503l-1.211-.806h.302l.604-.403c.101-.101.201-.201.201-.403l-.201-.704c-.101-.101-.201-.201-.302-.201h-.704c-.101 0-.302.101-.302.201l-.503.705c0 .101 0 .302.101.302l-.906-.604a.64.64 0 00-.604 0l-.503.402-1.711-1.509-1.409-3.522c0-.201-.201-.302-.403-.302l-.603-.101L3.3.302A.554.554 0 002.797 0H.482C.281 0-.021.201-.021.403v14.994c0 .402.201.603.503.603h14.994c.302 0 .503-.201.503-.503v-2.616c.101-.302 0-.403-.201-.504z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});