define("ember-svg-jar/inlined/precaucion_sustancias_corrosivas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_SUSTANCIAS_CORROSIVAS</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M23.094 33.605a.813.813 0 01.416-.261l-1.283-.397a.809.809 0 01-.376-1.3l-.733-.22.733.22a.813.813 0 01.412-.254l-.737-.228a.809.809 0 11.478-1.544l.601.186a.806.806 0 01.848-1.303l5.382 1.666-.833-1.21a.807.807 0 01.208-1.123.81.81 0 011.126.209l2.138 3.104c.632.938.888 1.35.64 2.153l-.573 1.85a2.145 2.145 0 01-2.682 1.411l-5.385-1.666a.809.809 0 01-.38-1.293l-1.425-.246 1.425.246zM4 42.357L25.222 4l21.223 38.357H4zm4.075-2.673h34.314L25.341 8.499 8.075 39.684zm18.043-10.465a.751.751 0 01-.5-.938c.121-.396 1.173-1.263 1.173-1.263s.387 1.306.265 1.703a.751.751 0 01-.938.498zm.207-4.307l.427-.653-4.496-2.932a1.79 1.79 0 111.958-2.998l4.501 2.935.429-.653 1.195.781-2.82 4.301-1.194-.781zm-7.107 19.89L23.425 46l-4.207-1.199z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});