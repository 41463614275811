define("ember-svg-jar/inlined/pui_train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.8 12.05c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3zM11.7 12.05c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z\"/><path d=\"M15 10.95v-5.6h-1.3V3.957l.002-.014.581-2.516a.063.063 0 00-.061-.077h-3.143c-.04 0-.07.037-.061.077l.581 2.516.001.014v1.43a.063.063 0 01-.063.063H8.663a.063.063 0 01-.063-.063V2.45h1.1v-1H0v1h1v8.5H0v1.937c0 .035.028.063.063.063h2.485c.03 0 .055-.023.058-.053.126-1.076 1.113-1.947 2.294-1.947 1.182 0 2.074.874 2.291 1.952a.058.058 0 00.058.048h2.199c.03 0 .055-.023.058-.053.127-1.076 1.114-1.947 2.295-1.947 1.182 0 2.074.874 2.291 1.952a.059.059 0 00.058.048h1.789a.063.063 0 00.063-.063V10.95H15zm-9.4-2.6v-2.2c0-.4-.3-.8-.8-.8-.4 0-.8.4-.8.8v2.2H3V6.28c0-.925.636-1.795 1.554-1.914A1.795 1.795 0 016.6 6.15v2.2h-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});