define("ember-svg-jar/inlined/pui_hydrant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.8 14.1H4.2c-.5 0-.9.4-1 .9v.7c0 .2.1.3.2.3h9c.2 0 .3-.1.3-.2V15c0-.5-.4-.9-.9-.9zM4.3 4.1h7.6c.2 0 .4-.2.4-.4s-.2-.4-.4-.4h-.5c-.4-1-1.3-1.7-2.4-1.9V.9c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.5c-1.1.1-2 .8-2.4 1.9h-.5c-.2 0-.4.2-.4.4s.2.4.4.4zM12.7 6.2h-.8V5.1H4.1v1.1h-.9c-.3 0-.426.201-.4.5v2.7c0 .3.276.405.5.4h.8v3.3h7.8V9.9h.8c.3 0 .5-.2.5-.4V6.7c0-.2-.2-.5-.5-.5zM7.95 9.932a1.843 1.843 0 110-3.686 1.843 1.843 0 010 3.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});