define("ember-svg-jar/inlined/l-citrus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.66 17.67a1.08 1.08 0 01-.04 1.6A12 12 0 014.73 2.38a1.1 1.1 0 011.61-.04z\"/><path d=\"M19.65 15.66A8 8 0 018.35 4.34M14 10l-5.5 5.5\"/><path d=\"M14 17.85V10H6.15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-citrus",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});