define("ember-svg-jar/inlined/punto_de_reunion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PUNTO_DE_REUNION</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><g fill=\"#FFF\"><path d=\"M24.801 4v8.174L21.403 8.61 19 11.13 26.5 19l7.5-7.87-2.403-2.521-3.398 3.565V4zM8.609 29.597L11.13 32 19 24.5 11.13 17 8.61 19.403l3.565 3.398H4V26.2h8.174zM19 39.87l2.403 2.52 3.398-3.565V47H28.2v-8.175l3.398 3.565L34 39.87 26.5 32zM42.391 19.403L39.87 17 32 24.5l7.87 7.5 2.521-2.403-3.565-3.398H47v-3.398h-8.174zM24 24h3v3h-3z\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});