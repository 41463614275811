define("ember-svg-jar/inlined/pui_category_cleaning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.185 7.243C6.132 10.368 4.08 10.889 1 11.41l8.212 10.42c2.053-1.042 6.159-5.21 6.159-7.294M17.804 1.647l-4.486 4.554-1.632-1.657a2.038 2.038 0 00-1.448-.606c-.542 0-1.062.218-1.447.606L7.16 6.201l9.238 9.377 1.633-1.656a2.1 2.1 0 00.596-1.47 2.1 2.1 0 00-.597-1.469l-1.632-1.657 4.486-4.553c.409-.414.638-.976.638-1.563 0-.586-.23-1.148-.638-1.563A2.161 2.161 0 0019.343 1c-.577 0-1.131.233-1.54.647v0z\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});