define("ember-svg-jar/inlined/pui_mesh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rollo acero</title><path d=\"M3.1 2.5c1.4 0 2.5-.7 2.5-1.3C5.6.6 4.4.1 3.1.1S.6.6.6 1.2c0 .6 1.1 1.3 2.5 1.3z\"/><path d=\"M14.9 11.2l.6-1.5L15 8l.4-1.4-.5-1.8.5-1.5-.6-1.7.6-1.6H3.8c.6.1 1.1.2 1.6.5H7v1.1H6c-.3.7-1.5 1.2-2.9 1.2S.2 2.2.2 1.4v13.2c0 .8 1.3 1.4 2.9 1.4 1.6 0 2.9-.6 2.9-1.4v-1.3h9.8l-.9-2.1zM7 12.8H6v-1.1h1v1.1zm0-1.6H6v-1.1h1v1.1zm0-1.6H6V8.5h1v1.1zM7 8H6V6.9h1V8zm0-1.6H6V5.3h1v1.1zm0-1.6H6V3.7h1v1.1zm0-1.6H6V2.1h1v1.1zm2.3 9.6H7.6v-1.1h1.7v1.1zm0-1.6H7.6v-1.1h1.7v1.1zm0-1.6H7.6V8.5h1.7v1.1zm0-1.6H7.6V6.9h1.7V8zm0-1.6H7.6V5.3h1.7v1.1zm0-1.6H7.6V3.7h1.7v1.1zm0-1.6H7.6V2.1h1.7v1.1zm0-1.6H7.6V.5h1.7v1.1zm2.5 11.2h-1.7v-1.1h1.7v1.1zm0-1.6h-1.7v-1.1h1.7v1.1zm0-1.6h-1.7V8.5h1.7v1.1zm0-1.6h-1.7V6.9h1.7V8zm0-1.6h-1.7V5.3h1.7v1.1zm0-1.6h-1.7V3.7h1.7v1.1zm0-1.6h-1.7V2.1h1.7v1.1zm0-1.6h-1.7V.5h1.7v1.1zm2.4 11.2h-1.7v-1.1h1.7v1.1zm0-1.6h-1.7v-1.1h1.7v1.1zm0-1.6h-1.7V8.5h1.7v1.1zm0-1.6h-1.7V6.9h1.7V8zm0-1.6h-1.7V5.3h1.7v1.1zm0-1.6h-1.7V3.7h1.7v1.1zm0-1.6h-1.7V2.1h1.7v1.1zm0-1.6h-1.7V.5h1.7v1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});