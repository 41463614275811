define("ember-svg-jar/inlined/pui_category_professional_services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.964 5.966H3.163c-1.091 0-1.975.897-1.975 2.005v10.024c0 1.107.884 2.005 1.975 2.005h15.801c1.091 0 1.975-.898 1.975-2.005V7.971c0-1.108-.884-2.005-1.975-2.005z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15.014 20V3.96a2.02 2.02 0 00-.579-1.417 1.96 1.96 0 00-1.396-.587h-3.95a1.96 1.96 0 00-1.397.587 2.02 2.02 0 00-.579 1.418V20\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "21",
      "viewBox": "0 0 22 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});