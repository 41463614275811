define("ember-svg-jar/inlined/swatch_input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"2\" y=\"2\" width=\"12\" height=\"12\" rx=\"3\"/><rect class=\"euiSwatchInput__stroke\" x=\"2.5\" y=\"2.5\" width=\"11\" height=\"11\" rx=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});