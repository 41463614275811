define("ember-svg-jar/inlined/pui_eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.962 5.35c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.6-2.6c.001-1.4-1.2-2.6-2.6-2.6z\"/><path d=\"M15.762 7.45c-.9-1.1-4-4.6-7.8-4.6s-6.9 3.5-7.8 4.6c-.2.3-.2.8 0 1.1.9 1.1 4 4.6 7.8 4.6s6.9-3.5 7.8-4.6c.301-.4.301-.8 0-1.1zm-7.8 4.2c-2.1 0-3.7-1.7-3.7-3.7s1.6-3.7 3.7-3.7 3.7 1.7 3.7 3.7c0 2.1-1.599 3.7-3.7 3.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});