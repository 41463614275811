define("ember-svg-jar/inlined/l-square-dashed-mouse-pointer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.034 12.681a.498.498 0 01.647-.647l9 3.5a.5.5 0 01-.033.943l-3.444 1.068a1 1 0 00-.66.66l-1.067 3.443a.5.5 0 01-.943.033zM5 3a2 2 0 00-2 2m16-2a2 2 0 012 2M5 21a2 2 0 01-2-2M9 3h1M9 21h2m3-18h1M3 9v1m18-1v2M3 14v1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-square-dashed-mouse-pointer",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});