define("ember-svg-jar/inlined/riesgo_de_caida_a_desnivel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_DE_CAIDA_A_DESNIVEL</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M2 45L24.882 5l22.883 40H2zm4.393-2.788h37L25.01 9.692 6.393 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M23.889 39.63l1.685.724 2.13-4.34-1.663-.355-1.34 2.057-.812 1.914zm6.503-13.234c.91-.386 1.323-1.46.925-2.4-.4-.94-1.46-1.388-2.37-1.002-.909.386-1.323 1.46-.924 2.4.399.94 1.46 1.388 2.369 1.002zm-3.844-1.51l.336-3.81-1.729-.2-.364 4.141c-.019.214.04.43.164.607l1.071 1.526.988.858.666 1.569-1.584 3.693 1.628.185-5.144-.686a1.012 1.012 0 00-.49.056l-3.652 1.373.758 2 3.416-1.283 4.89.652c.476.063.91-.21 1.08-.638.04-.115 1.953-5.245 1.953-5.245l3.16 4.072 1.46-.715-3.666-5.1-1.565-.615c-.587-.225-2.254-.845-2.254-.845l-1.122-1.596z\" fill-rule=\"nonzero\" fill=\"#000\"/><path fill=\"#000\" d=\"M13 38h8v3h-8z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});