define("ember-svg-jar/inlined/pui_symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>faultyMachinery</title><path d=\"M3.258 4.495a.503.503 0 00-.779.09A6.473 6.473 0 001.5 8c0 3.584 2.916 6.5 6.5 6.5a6.478 6.478 0 003.415-.979.502.502 0 00.09-.778L3.258 4.495zM8 14c-3.308 0-6-2.692-6-6a5.95 5.95 0 01.904-3.151l8.247 8.247A5.952 5.952 0 018 14z\"/><path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zm0 15.5C3.865 15.5.5 12.135.5 8S3.865.5 8 .5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5z\"/><path d=\"M8 1.5a6.494 6.494 0 00-3.178.835.5.5 0 00-.109.79l8.162 8.162a.501.501 0 00.79-.109c.546-.97.835-2.069.835-3.178 0-3.584-2.916-6.5-6.5-6.5zm5.229 9.433L5.067 2.771A5.958 5.958 0 018 2c3.308 0 6 2.692 6 6a5.958 5.958 0 01-.771 2.933z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});