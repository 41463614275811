define("ember-svg-jar/inlined/l-ham", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.144 21.144A7.274 10.445 45 102.856 10.856M13.144 21.144A7.274 4.365 45 002.856 10.856a7.274 4.365 45 0010.288 10.288m3.421-10.709L18.6 8.4a2.501 2.501 0 101.65-4.65 2.5 2.5 0 10-4.66 1.66l-2.024 2.025M8.5 16.5l-1-1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-ham",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});