define("ember-svg-jar/inlined/pui_steering_wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 1.9c3 0 5.6 2.2 6 5.2h-1.6c-1 0-2-.4-2.7-1.1-.9-1-2.5-1-3.4 0-.7.6-1.7 1.1-2.7 1.1H2c.4-3 3-5.2 6-5.2zm0 8c-.8 0-1.4-.6-1.4-1.4S7.2 7.1 8 7.1s1.4.6 1.4 1.4S8.8 9.9 8 9.9zm-5.7.3c.6-.2.9-.3 1.5-.3 1.7 0 3 1.5 2.8 3.2 0 .3-.1.5-.2.8-1.9-.6-3.4-1.9-4.1-3.7zm7.4 3.7c-.1-.2-.2-.5-.2-.8-.3-2 1.5-3.6 3.4-3.2.2.1.5.1.8.3-.7 1.8-2.2 3.1-4 3.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});