define("ember-svg-jar/inlined/pui_water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.792 11.313a4.7 4.7 0 01-4.935 4.693c-2.624-.128-4.585-2.601-4.45-5.225.241-4.672 3.591-7.671 4.474-8.383a.34.34 0 01.432.004c.93.738 4.479 3.886 4.479 8.911zM14.68 4.68a2.446 2.446 0 01-2.569 2.443c-1.366-.067-2.387-1.354-2.316-2.72C9.92 1.971 11.664.41 12.124.039a.178.178 0 01.225.002c.483.384 2.331 2.023 2.331 4.639z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});