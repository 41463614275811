define("ember-svg-jar/inlined/vis_gauge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.877 5.847l-1.02 1.02a.5.5 0 01-.708-.707l1.1-1.099c-.05-.053-.1-.106-.152-.157A6.471 6.471 0 008 3.019V4.5a.5.5 0 01-1 0V3.019a6.47 6.47 0 00-4.261 2.055l1.07 1.071a.5.5 0 01-.706.707l-.99-.99A6.46 6.46 0 001.018 10H2.5a.5.5 0 110 1H1.174c.083.353.196.697.337 1.03a.5.5 0 11-.922.39A7.487 7.487 0 010 9.5a7.483 7.483 0 012.197-5.304A7.487 7.487 0 017.5 2a7.487 7.487 0 015.304 2.197A7.483 7.483 0 0115 9.5a7.487 7.487 0 01-.59 2.92.5.5 0 01-.92-.39c.14-.333.253-.677.336-1.03H12.5a.5.5 0 110-1h1.481a6.483 6.483 0 00-1.104-4.153zm-6.041 5.317a.993.993 0 01-.01-1.404c.384-.385 2.882-2.002 3.149-1.735.267.267-1.35 2.765-1.735 3.15a.993.993 0 01-1.404-.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});