define("ember-svg-jar/inlined/pui_noise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.029 1.935c-.1 0-.3 0-.4.1L7.331 3.734a10.993 10.993 0 01-3.797 2.898l-2.198.899C.237 7.931-.263 9.13.137 10.129l.3.699a2.102 2.102 0 002.398 1.299l.599 1.699c.1.2.3.3.5.2l1.499-.599c.2-.1.3-.3.2-.5l-.599-1.699c1.599-.5 3.297-.699 4.996-.5l1.998.3c.1 0 .3 0 .4-.1.1-.1.1-.2.1-.4L9.429 2.235c-.2-.2-.3-.3-.4-.3zM11.277 4.983a.5.5 0 01-.455-.707l.647-1.423a.5.5 0 01.91.414l-.647 1.423a.5.5 0 01-.455.293zM12.266 6.846a.498.498 0 01-.353-.852l1.395-1.395a.5.5 0 01.706 0l.506.506.626-.626a.5.5 0 11.706.706l-.98.98a.5.5 0 01-.706 0l-.506-.506-1.042 1.042a.5.5 0 01-.352.145zM13.883 9.246a.495.495 0 01-.482-.369l-.18-.66-.803.258a.499.499 0 11-.304-.951l1.3-.417a.497.497 0 01.634.345l.165.604.625-.275a.5.5 0 01.402.914l-1.156.509a.507.507 0 01-.201.042z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});