define("ember-svg-jar/inlined/pui_weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavyObject</title><path d=\"M15.974 14.751l-2.935-9.833a.61.61 0 00-.585-.436h-2.309a2.582 2.582 0 10-4.292 0h-2.94a.61.61 0 00-.594.471L.016 14.786a.61.61 0 00.594.749h14.779a.61.61 0 00.585-.784zM6.466 3.047a1.534 1.534 0 013.068 0 1.53 1.53 0 01-1.005 1.435H7.471a1.53 1.53 0 01-1.005-1.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});