define("ember-svg-jar/inlined/l-pound-sterling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 7c0-5.333-8-5.333-8 0m0 0v14m-4 0h12M6 13h10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pound-sterling",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});