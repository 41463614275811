define("ember-m3/managed-array", ["exports", "@ember/object", "ember-m3/resolve-attribute-util", "ember-m3/utils/resolve", "ember-m3/base-record-array", "ember-m3/-private", "@ember/debug", "ember-m3/model", "@ember-data/store"], function (_exports, _object, _resolveAttributeUtil, _resolve, _baseRecordArray, _private, _debug, _model, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * M3TrackedArray
   *
   * @class ManagedArray
   * @extends {BaseRecordArray}
   */
  let ManagedArray;
  if (false /* CUSTOM_MODEL_CLASS */) {
    ManagedArray = class ManagedArray extends _baseRecordArray.default {
      init() {
        super.init(...arguments);
        this._key = (0, _object.get)(this, 'key');
        this._modelName = (0, _object.get)(this, 'modelName');
        this._schema = (0, _object.get)(this, 'schema');
        this._record = (0, _object.get)(this, 'model');
        this._resolved = true;
      }
      get content() {
        (false && !(false) && (0, _debug.deprecate)('Accessing content on an M3TrackedArray was private and is deprecated.', false, {
          id: 'm3.tracked-array.value',
          until: '4.0'
        }));
        return this._objects;
      }
      get value() {
        (false && !(false) && (0, _debug.deprecate)('Accessing value on an M3TrackedArray was private and is deprecated.', false, {
          id: 'm3.tracked-array.value',
          until: '1.0'
        }));
        return this._value;
      }
      replace(idx, removeAmt, newItems) {
        // if we are empty, and haven't affirmed we are a reference array
        // and somebody gave us records, we need to check whether we should
        // a reference or a nested array
        if (!this._isAllReference && this.length === 0) {
          let firstItem = newItems[0];
          if (firstItem && (0, _resolve.isResolvedValue)(firstItem) && !(firstItem instanceof _model.EmbeddedMegamorphicModel)) {
            this._isAllReference = true;
          }
        }
        if (this._isAllReference) {
          super.replace(idx, removeAmt, newItems);
          // update attr in recordData and model state
          this.record._setAttribute(this.key, this, true);
          return;
        }

        // Update childRecordDatas array
        // mapping to array of nested models
        (0, _private.recordDataFor)(this._record)._resizeChildRecordData(this._key, idx, removeAmt, newItems.length);
        newItems = newItems.map((item, index) => {
          if ((0, _resolve.isResolvedValue)(item)) {
            let parentRecordData = (0, _private.recordDataFor)(this._record);
            let childRecordData;
            if (item instanceof _model.default) {
              childRecordData = (0, _private.recordDataFor)(item);
            } else {
              let identifier = (0, _store.recordIdentifierFor)(item);
              childRecordData = parentRecordData.storeWrapper.recordDataFor(identifier.type, identifier.id, identifier.lid);
            }
            // TODO: clean up this ridiculous hack
            // adding a resolved value to a tracked array requires the child model
            // data stitching to be maintained
            parentRecordData._setChildRecordData(this._key, index + idx, childRecordData);
            return item;
          }
          return (0, _resolveAttributeUtil.resolveValue)(this._key, item, this._modelName, this.store, this._schema, this._record, index + idx);
        });
        super.replace(idx, removeAmt, newItems);

        // Set attribute in recordData and update model state and changedAttributes
        // object
        this._record._setAttribute(this._key, this._objects, true);
      }
    };
  } else {
    ManagedArray = class ManagedArray extends _baseRecordArray.default {
      init() {
        super.init(...arguments);
        this._key = (0, _object.get)(this, 'key');
        this._modelName = (0, _object.get)(this, 'modelName');
        this._store = (0, _object.get)(this, 'store');
        this._schema = (0, _object.get)(this, 'schema');
        this._record = (0, _object.get)(this, 'model');
      }
      get value() {
        (false && !(false) && (0, _debug.deprecate)('Accessing value on an M3TrackedArray was private and is deprecated.', false, {
          id: 'm3.tracked-array.value',
          until: '1.0'
        }));
        return this._value;
      }
      get content() {
        (false && !(false) && (0, _debug.deprecate)('Accessing content on an M3TrackedArray was private and is deprecated.', false, {
          id: 'm3.tracked-array.value',
          until: '4.0'
        }));
        return this.toArray();
      }
      replace(idx, removeAmt, newItems) {
        // if we are empty, and haven't affirmed we are a reference array
        // and somebody gave us records, we need to check whether we should
        // a reference or a nested array

        if (!this._isAllReference && this.length === 0) {
          let firstItem = newItems[0];
          if (firstItem && (0, _resolve.isResolvedValue)(firstItem) && !(firstItem instanceof _model.EmbeddedMegamorphicModel)) {
            this._isAllReference = true;
          }
        }
        if (this._isAllReference) {
          super.replace(idx, removeAmt, newItems);
          // update attr in recordData and model state
          this.record._setAttribute(this.key, this, true);
          return;
        }
        // Update childRecordDatas array
        // mapping to array of nested models
        (0, _private.recordDataFor)(this._record)._resizeChildRecordData(this._key, idx, removeAmt, newItems.length);
        newItems = newItems.map((item, index) => {
          if ((0, _resolve.isResolvedValue)(item)) {
            let parentRecordData = (0, _private.recordDataFor)(this._record);
            let childRecordData = (0, _private.recordDataFor)(item);
            // TODO: clean up this ridiculous hack
            // adding a resolved value to a tracked array requires the child model
            // data stitching to be maintained
            parentRecordData._setChildRecordData(this._key, index + idx, childRecordData);
            return item;
          }
          return (0, _resolveAttributeUtil.resolveValue)(this._key, item, this._modelName, this._store, this._schema, this._record, index + idx);
        });
        super.replace(idx, removeAmt, newItems);

        // Set attribute in recordData and update model state and changedAttributes
        // object
        this._record._setAttribute(this._key, this.toArray(), true);
      }
    };
  }
  var _default = _exports.default = ManagedArray;
});