define("ember-svg-jar/inlined/agua_no_potable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>AGUA_NO_POTABLE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-3.077c9.346 0 16.923-7.577 16.923-16.923 0-9.346-7.577-16.923-16.923-16.923-9.346 0-16.923 7.577-16.923 16.923 0 9.346 7.577 16.923 16.923 16.923z\" fill=\"#E11212\"/><path d=\"M20.567 21.375v-3.281c0-.258.204-.47.453-.47h4.395a3.274 3.274 0 011.855-1.497.703.703 0 01-.35-.612c0-.388.304-.703.68-.703h.227v-.937h-2.042a.692.692 0 01-.68-.703c0-.388.304-.703.68-.703h2.042c0-.259.15-.469.453-.469.303 0 .454.21.454.469h2.042c.376 0 .68.315.68.703a.692.692 0 01-.68.703h-2.042v.937h.227c.376 0 .68.315.68.704a.704.704 0 01-.407.643 3.248 3.248 0 011.436.997h3.51V15.75c0-.518.405-.938.907-.938.501 0 .907.42.907.938v7.031c0 .518-.406.937-.907.937-.502 0-.908-.42-.908-.937v-1.875h-3.097c-.544 1.068-1.628 1.797-2.877 1.797-1.59 0-2.91-1.178-3.194-2.734h-1.268c-.25 0-.454.21-.454.468v.938c.25 0 .454.21.454.468v.938a.463.463 0 01-.454.469h-2.722a.463.463 0 01-.454-.47v-.937c0-.257.204-.468.454-.468z\" fill-rule=\"nonzero\" fill=\"#000\"/><g fill-rule=\"nonzero\" fill=\"#000\"><path d=\"M16.01 26l.217 3.028c.135-.039.341-.09.595-.129l-.159-2.22h6.544l-.274 3.823a4.24 4.24 0 00.614-.146L23.86 26h-7.85zM16.747 36.268l.095 1.315h6.186l.095-1.315z\"/><path d=\"M23.189 31.143a5.663 5.663 0 01-.843.1 4.803 4.803 0 01-.17.003c-.978 0-1.815-.308-2.43-.895-.944-.9-2.151-.89-2.875-.774a4.42 4.42 0 00-.594.14l.422 5.873h6.473l.324-4.518a5.214 5.214 0 01-.307.07z\"/></g><path fill=\"#E11212\" d=\"M11.682 13.806l24.521 24.508 2.288-2.02L13.62 11.49z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});