define("ember-svg-jar/inlined/pui_sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sunOverexposure</title><circle cx=\"8\" cy=\"7.969\" r=\"3.336\"/><path d=\"M7.801 0h-.065a.94.94 0 00-.94.94v1.579c0 .519.421.94.94.94h.065a.94.94 0 00.94-.94V.94a.94.94 0 00-.94-.94zM2.202 2.484l-.046.046a.94.94 0 000 1.33l1.116 1.116a.94.94 0 001.33 0l.047-.046a.94.94 0 000-1.33L3.532 2.484a.94.94 0 00-1.33 0zM0 8.199v.065c0 .519.421.94.94.94h1.579a.94.94 0 00.94-.94v-.065a.94.94 0 00-.94-.94H.94a.94.94 0 00-.94.94zM2.484 13.798l.046.046a.94.94 0 001.33 0l1.116-1.116a.94.94 0 000-1.33l-.046-.047a.94.94 0 00-1.33 0l-1.116 1.116a.942.942 0 000 1.331zM8.199 16h.065a.94.94 0 00.94-.94v-1.579a.94.94 0 00-.94-.94h-.065a.94.94 0 00-.94.94v1.579c0 .519.421.94.94.94zM13.798 13.516l.046-.046a.94.94 0 000-1.33l-1.116-1.116a.94.94 0 00-1.33 0l-.046.046a.94.94 0 000 1.33l1.116 1.116a.94.94 0 001.33 0zM16 7.801v-.065a.94.94 0 00-.94-.94h-1.579a.94.94 0 00-.94.94v.065c0 .519.421.94.94.94h1.579a.94.94 0 00.94-.94zM13.516 2.202l-.046-.046a.94.94 0 00-1.33 0l-1.116 1.116a.94.94 0 000 1.33l.046.046a.94.94 0 001.33 0l1.116-1.116a.94.94 0 000-1.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});