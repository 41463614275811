define("ember-svg-jar/inlined/pui_gloves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.001 4.146L12.275.808c.035-.093.013-.313-.044-.441-.057-.128-.115-.256-.3-.327-.278-.106-.534.008-.64.286l-1.167 3.057c.395.122.707.395.877.763zM14.497 3.885c-.278-.106-.534.008-.64.286l-1.096 2.872c-.035.093-.256.115-.349.079l-.278-.106c-.093-.035-.243-.199-.172-.384l1.556-4.077c.106-.278-.008-.534-.286-.64-.278-.106-.534.008-.64.286l-1.448 3.793v6.785l.143 1.524.632-2.274 2.865-7.505c.106-.277-.009-.533-.287-.639zM9.259 3.355c.009-.002.019-.001.028-.003l.803-2.104c.106-.278-.009-.534-.287-.64s-.626-.028-.732.25L8.58 2.144c.402.25.664.687.679 1.211z\"/><g><path d=\"M10.153 4.793c0-.298-.198-.496-.496-.496s-.496.198-.496.496v3.074c0 .099-.198.198-.298.198h-.297c-.099 0-.298-.099-.298-.298V3.405c0-.298-.198-.496-.496-.496s-.496.198-.496.496v4.364c0 .198-.099.298-.298.298h-.297c-.099 0-.198-.099-.198-.298V2.214c0-.099-.099-.298-.198-.397-.1-.098-.199-.198-.397-.198-.298 0-.496.198-.496.496v5.653c0 .198-.099.298-.298.298h-.198c-.198 0-.298-.099-.298-.298V3.405c0-.298-.198-.496-.496-.496s-.594.198-.594.496v4.959a.213.213 0 01-.198.198c-.099 0-.198 0-.298-.099l-.794-.893a.546.546 0 00-.496-.298h-.099c-.099 0-.198 0-.298.099a.903.903 0 000 .992l2.182 4.265v.099L3.309 16h7.141l-.298-3.174V4.793z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});