define("ember-svg-jar/inlined/l-clover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.17 7.83L2 22m2.02-10a2.827 2.827 0 113.81-4.17A2.827 2.827 0 1112 4.02a2.827 2.827 0 114.17 3.81A2.827 2.827 0 1119.98 12a2.827 2.827 0 11-3.81 4.17A2.827 2.827 0 1112 19.98a2.827 2.827 0 11-4.17-3.81A1 1 0 114 12m3.83-4.17l8.34 8.34\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-clover",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});