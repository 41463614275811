define("ember-svg-jar/inlined/pui_glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vidrio</title><path d=\"M6.512 16H.688A.688.688 0 010 15.312V9.488c0-.38.308-.688.688-.688h5.825c.379 0 .687.308.687.688v5.825a.688.688 0 01-.688.687zM15.312 16H9.488a.688.688 0 01-.688-.688V9.488c0-.38.308-.688.688-.688h5.825c.379 0 .687.308.687.688v5.825a.688.688 0 01-.688.687zM6.512 7.2H.688A.688.688 0 010 6.512V.688C0 .308.308 0 .688 0h5.825c.379 0 .687.308.687.688v5.825a.688.688 0 01-.688.687zM15.312 0H9.488A.688.688 0 008.8.688v5.825c0 .379.308.687.688.687h5.825A.688.688 0 0016 6.512V.688A.688.688 0 0015.312 0zm-4.711 4.774a.375.375 0 01-.266-.64l2.936-2.936a.375.375 0 01.531.53l-2.936 2.936a.372.372 0 01-.265.11zm4.459-1.789l-2.937 2.936a.376.376 0 01-.532-.53l2.937-2.936a.376.376 0 01.532.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});