define("ember-m3/utils/serialize-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeQueryParams;
  function serializeQueryParams(queryParamsObject) {
    return Object.keys(queryParamsObject).map(key => `${key}=${queryParamsObject[key]}`).join('&');
  }
});