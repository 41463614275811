define("ember-svg-jar/inlined/pui_voltage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voltage</title><path d=\"M4.873 15.608l1.662-6.233a.31.31 0 00-.3-.39H2.11a.311.311 0 01-.293-.414L4.77.207A.31.31 0 015.062 0h6.103a.31.31 0 01.266.47L8.824 4.815a.31.31 0 00.266.47h4.799a.31.31 0 01.235.513L5.408 15.891c-.215.249-.619.035-.535-.283z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});