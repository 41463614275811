define("ember-svg-jar/inlined/tokenFile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.867 2.667H4a.667.667 0 00-.667.666v9.334c0 .368.299.666.667.666h8a.667.667 0 00.667-.666V5.619a.669.669 0 00-.183-.459l-2.133-2.285a.668.668 0 00-.484-.208m1.466 4V12H4.667V4h4v2.333c0 .184.149.334.333.334h2.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});