define("ember-svg-jar/inlined/l-stamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 22h14m.27-8.27A2.5 2.5 0 0017.5 13h-11A2.5 2.5 0 004 15.5V17a1 1 0 001 1h14a1 1 0 001-1v-1.5c0-.66-.26-1.3-.73-1.77\"/><path d=\"M14 13V8.5C14 7 15 7 15 5a3 3 0 00-3-3c-1.66 0-3 1-3 3s1 2 1 3.5V13\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-stamp",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});