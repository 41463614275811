define("ember-svg-jar/inlined/l-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 7h.01M3.4 18H12a8 8 0 008-8V7a4 4 0 00-7.28-2.3L2 20\"/><path d=\"M20 7l2 .5-2 .5M10 18v3m4-3.25V21m-7-3a6 6 0 003.84-10.61\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bird",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});