define("ember-svg-jar/inlined/l-book-open-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 21V7m4 5l2 2 4-4\"/><path d=\"M22 6V4a1 1 0 00-1-1h-5a4 4 0 00-4 4 4 4 0 00-4-4H3a1 1 0 00-1 1v13a1 1 0 001 1h6a3 3 0 013 3 3 3 0 013-3h6a1 1 0 001-1v-1.3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-book-open-check",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});