define("ember-svg-jar/inlined/logo_ceph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.471 27.56c-1.06-.567-1.676-1.206-1.811-1.902-.13-.673.17-1.421.918-2.288a11.29 11.29 0 002.773-7.42A11.324 11.324 0 0016.042 4.64l-.05.001h-.05A11.324 11.324 0 004.632 15.949a11.291 11.291 0 002.774 7.422c.753.872 1.047 1.591.928 2.257-.13.694-.74 1.342-1.817 1.93-1.657-1.375-3.116-3.212-4.01-5.056a14.943 14.943 0 01-1.117-3.139A14.997 14.997 0 011 15.95a14.901 14.901 0 014.378-10.57 14.924 14.924 0 014.752-3.204C11.97 1.395 13.95.996 15.948 1h.101a14.888 14.888 0 0110.57 4.378 14.912 14.912 0 013.982 13.985 14.871 14.871 0 01-1.118 3.14c-.9 1.843-2.358 3.681-4.01 5.057h-.003zm-5.523 2.798c-.173-.11-.767-.55-1.345-1.353a6.122 6.122 0 01-1.174-3.738 8.26 8.26 0 01.611-2.964 8.42 8.42 0 011.627-2.54l.004-.008.154-.178c.263-.305.536-.622.753-.99.268-.454.462-.931.578-1.42.178-.758.188-1.547.029-2.31a5.3 5.3 0 00-.858-1.956 5.264 5.264 0 00-4.29-2.217h-.108a5.25 5.25 0 00-4.29 2.216 5.31 5.31 0 00-.856 1.956c-.16.763-.15 1.552.03 2.311.115.488.31.965.576 1.419.219.37.49.684.753.99l.155.179.003.007a8.384 8.384 0 011.628 2.54 8.24 8.24 0 01.61 2.964 6.123 6.123 0 01-1.173 3.738c-.58.795-1.174 1.243-1.346 1.352l-.867-.26a15.434 15.434 0 01-2.49-1.098 4.245 4.245 0 002.234-3.736c0-1.034-.403-2.028-1.195-2.95l-.034-.034-.237-.26-.636-.746a8.924 8.924 0 01-1.525-3.26 8.983 8.983 0 01-.05-3.905 8.906 8.906 0 011.46-3.313A8.993 8.993 0 0111.23 8.39a8.937 8.937 0 014.702-1.344h.112c.42 0 .84.029 1.255.088a8.938 8.938 0 013.448 1.256 9.003 9.003 0 012.554 2.404 9.093 9.093 0 011.459 3.313 9.043 9.043 0 01-.05 3.912 8.925 8.925 0 01-1.526 3.26c-.2.265-.409.495-.637.745l-.23.254c-.017.016-.03.027-.04.043-.804.961-1.195 1.927-1.195 2.948a4.25 4.25 0 002.237 3.735c-.795.441-1.63.809-2.491 1.098l-.862.26-.018-.004zm-3.956-11.314a2.957 2.957 0 01-2.952-2.952 2.957 2.957 0 012.952-2.952 2.956 2.956 0 012.95 2.952 2.957 2.957 0 01-2.95 2.952\" fill=\"#F05C56\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});