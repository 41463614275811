define("ember-svg-jar/inlined/logo_aerospike", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path fill=\"#C4373A\" d=\"M0 31.806h32V.776H0z\"/><path fill=\"#FFF\" d=\"M19.448 12.508l-8.986 3.86 8.986 3.883v-7.743zm-11.304 4.73l-2.094-.863 2.094-.931 17.749-7.741v2.062l-4.654 1.99v9.25l4.654 2.01v1.968L8.143 17.24z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});