define("ember-svg-jar/inlined/l-microscope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 18h8M3 22h18m-7 0a7 7 0 100-14h-1m-4 6h2m-2-2a2 2 0 01-2-2V6h6v4a2 2 0 01-2 2zm3-6V3a1 1 0 00-1-1H9a1 1 0 00-1 1v3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-microscope",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});