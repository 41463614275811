define("ember-svg-jar/inlined/memory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 10h2V6H7zM3 10h2V6H3zM11.025 10h2V6h-2zM3.5 13.75h1v-2.4h-1zM6.175 13.75h1.001v-2.4H6.175zM8.85 13.75h1v-2.4h-1zM11.525 13.75h1v-2.4h-1z\"/><path d=\"M0 3v7.05h1v3.698h1v-3.699h12v3.699h1v-3.699h1V3H0zm1 6h14V4H1v5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});