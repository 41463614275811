define("ember-svg-jar/inlined/pui_lightbulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.15 15.1c0 .3.3.6.6.6h2.5c.3 0 .6-.3.6-.6v-.6h-3.7v.6zM7.6.3h.7V2h-.7zM12.363 3.49l1.202-1.202.495.495-1.202 1.202zM14.3 7.6H16v.7h-1.7zM0 7.6h1.7v.7H0zM2.3 2.3l1.2 1.2-.4.6-1.3-1.3zM8.143 3.2c-2.5 0-4.6 2-4.6 4.6 0 1.5.8 2.8 1.9 3.6v1.4c0 .3.3.6.6.6h1.1v-3.156a.8.8 0 011.6 0V13.4h1.2c.3 0 .6-.3.6-.6v-1.4c1.2-.8 1.9-2.1 1.9-3.6.2-2.6-1.9-4.6-4.3-4.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});