define("ember-svg-jar/inlined/pui_thermometer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.7 9V2.7C10.7 1.2 9.5 0 8 0S5.3 1.2 5.3 2.7V9c-.8.8-1.3 1.9-1.3 3 0 2.2 1.8 4 4 4s4-1.8 4-4c0-1.1-.5-2.2-1.3-3zM8 14.6c-1.5 0-2.7-1.2-2.7-2.7 0-.8.3-1.5.9-2l.4-.4V2.6c0-.7.5-1.3 1.2-1.3h.1c.7 0 1.3.5 1.3 1.2v7l.4.4c.6.6 1 1.3 1 2.1 0 1.5-1.1 2.6-2.6 2.6z\"/><path d=\"M8.55 10.1V5.3h-1.2v4.8c-.8.3-1.3 1-1.3 1.8 0 1.1.9 2 2 2 1 0 1.9-.8 1.9-1.8v-.2c0-.8-.6-1.5-1.4-1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});