define("ember-svg-jar/inlined/pui_transportation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 7.032l-3-.5-2.5-2.1c-.2-.2-.5-.3-.8-.3H4.9c-.4 0-.83.2-1.13.5l-1.89 1.9H1.1c-.6 0-1.1.5-1.1 1.1v1.4c0 .8.6 1.4 1.4 1.4h.3c-.2-1.1.5-2.1 1.6-2.3.126-.023.253-.034.377-.034.963 0 1.846.66 2.023 1.634v.8h5.2c-.2-1.1.5-2.2 1.6-2.4.126-.023.253-.034.377-.034.963 0 1.846.66 2.023 1.634v.8c.6 0 1.1-.5 1.1-1.1v-1.2c0-.6-.4-1.1-1-1.2zm-8.5-1.9c0-.2.2-.4.4-.4h1.821c.1 0 .24.003.318.081.084.084 1.561 1.319 1.561 1.319.1.1.001.4-.1.4H6.9c-.2 0-.4-.2-.4-.4v-1zm-.339-.5a.92.92 0 00-.129.27.94.94 0 00-.174-.27h.303zM3.3 6.232l1.31-1.3c.1-.1.2-.1.3-.1h.29c.2 0 .4.2.4.4v.9c0 .2-.2.4-.4.4H3.5c-.1 0-.2-.1-.2-.2v-.1zm12.2 3.2a.592.592 0 01-.1.327v-.027c0-.03-.003-.06-.008-.089-.215-1.185-1.273-2.045-2.515-2.045-.156 0-.313.014-.467.042a2.445 2.445 0 00-1.616 1.08c-.26.396-.405.848-.427 1.312H6.2v-.3c0-.03-.003-.06-.008-.089-.215-1.185-1.273-2.045-2.515-2.045-.156 0-.313.014-.467.042a2.482 2.482 0 00-1.608 1.019c-.258.37-.405.799-.432 1.247C.771 9.812.5 9.473.5 9.032v-1.4c0-.325.275-.6.6-.6h.78a.499.499 0 00.354-.147l.566-.569v.016c0 .373.327.7.7.7h1.7a.929.929 0 00.85-.615c.125.35.469.615.85.615h3.6c.248 0 .461-.158.571-.424a.879.879 0 00.05-.161l.557.468a.486.486 0 00.239.11l3 .5c.349.058.583.342.583.707v1.2z\"/><path d=\"M12.899 8.466a1.702 1.702 0 10.001 3.403 1.702 1.702 0 00-.001-3.403zm0 2.902c-.662 0-1.201-.539-1.201-1.201s.539-1.201 1.201-1.201 1.201.539 1.201 1.201-.539 1.201-1.201 1.201zM3.709 8.466a1.702 1.702 0 10.001 3.403 1.702 1.702 0 00-.001-3.403zm0 2.902c-.662 0-1.201-.539-1.201-1.201s.539-1.201 1.201-1.201 1.201.539 1.201 1.201-.539 1.201-1.201 1.201z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});