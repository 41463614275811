define("ember-svg-jar/inlined/l-dessert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"4\" r=\"2\"/><path d=\"M10.2 3.2C5.5 4 2 8.1 2 13a2 2 0 004 0v-1a2 2 0 014 0v4a2 2 0 004 0v-4a2 2 0 014 0v1a2 2 0 004 0c0-4.9-3.5-9-8.2-9.8M3.2 14.8a9 9 0 0017.6 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-dessert",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});