define("ember-svg-jar/inlined/pui_visit_still", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M43.965 32.265c-4.438 3.207-8.868 5.34-10.554 10.432-.631 1.907-1.656 3.708-2.677 5.455-1.253 2.143-3.225 2.865-5.453 1.711-2.202-1.141-2.854-3.021-1.73-5.389.737-1.553 1.542-3.102 2.036-4.737 1.952-6.455 7.527-9.483 12.193-13.498 4.221-3.632 8.698-5.062 14.187-4.628 5.087.402 7.546 2.58 7.569 7.813.033 7.41-.024 14.821.058 22.231.013 1.129.198 2.71.934 3.307 8.256 6.708 10.976 16.248 13.586 25.883.821 3.032 1.854 6.009 2.644 9.049.829 3.19-.626 5.789-3.579 6.733-2.549.815-5.371-.715-6.346-3.762-2.069-6.468-3.847-13.031-6.011-19.466a19.418 19.418 0 00-3.521-6.232c-3.807-4.484-7.964-8.668-11.829-13.105-.832-.955-1.409-2.458-1.442-3.726-.149-5.777-.065-11.56-.065-18.071z\"/><path d=\"M44.111 54.578c2.324 2.535 4.669 5.05 6.948 7.624.354.4.696 1.17.549 1.598-1.212 3.518-1.977 7.361-3.951 10.41-4.348 6.717-9.313 13.036-14.051 19.498-2.495 3.404-5.279 4.093-7.984 2.05-2.578-1.946-2.722-5.096-.379-8.337.357-.493.74-.968 1.081-1.471 6.692-9.882 15.959-18.332 16.797-31.471l.99.099zM53.719 3.094c4.266-.013 7.744 3.43 7.77 7.69.026 4.304-3.326 7.741-7.626 7.822-4.39.082-8.024-3.488-7.967-7.826.055-4.231 3.558-7.673 7.823-7.686zM61.433 28.494c1.157 2.639 2.456 5.228 3.432 7.932.939 2.6 2.427 4.55 4.87 5.878a26.602 26.602 0 014.251 2.903c1.901 1.577 2.258 3.63.849 5.66-1.319 1.901-3.296 2.436-5.327 1.21-2.679-1.616-5.243-3.427-7.806-5.227-.419-.294-.813-.958-.818-1.455-.057-5.596-.036-11.193-.036-16.79.196-.036.39-.074.585-.111z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100",
      "shape-rendering": "geometricPrecision",
      "text-rendering": "geometricPrecision"
    }
  };
});