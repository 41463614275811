define("ember-svg-jar/inlined/pui_hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.872 16h3.487c.923 0 1.846-.513 2.359-1.436l2.974-5.744-.205-.103c-.308-.205-.615-.41-.923-.308-.41 0-.718.205-.923.513l-.821 1.026c-.103.103-.205.103-.308.103s-.205-.103-.205-.205V2.667c0-.513-.41-.923-.821-.923-.41 0-.821.41-.821.821v5.538c0 .103 0 .103-.103.205 0 0-.103.103-.205.103-.103 0-.103 0-.205-.103 0 0-.103-.103-.103-.205V4.821 2.565.924C8.049.411 7.641 0 7.231 0S6.41.41 6.41.923v7.179a.22.22 0 01-.205.205C6.103 8.308 6 8.205 6 8.103V1.846c0-.513-.308-.923-.821-.923-.41 0-.718.41-.718.923v6.256a.22.22 0 01-.205.205c-.205 0-.308-.103-.308-.205V4.41c0-.513-.41-.923-.821-.923-.513 0-.821.41-.821.923v8.718C2.308 14.667 3.436 16 4.872 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});