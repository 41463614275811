define("ember-bind-helper/helpers/bind", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bind = bind;
  _exports.default = void 0;
  function bind([f, ...params], {
    target
  }) {
    if (!f || typeof f !== "function") {
      throw "bind needs to receive at least one argument, a function";
    }
    return f.bind(target, ...params);
  }
  var _default = _exports.default = (0, _helper.helper)(bind);
});