define("ember-svg-jar/inlined/pui_mechanical_arm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.803 5.461h.039V3.992a1.88 1.88 0 10-2.16-2.991H7.453a2.286 2.286 0 00-4.179 1.288c0 .748.364 1.405.918 1.823l-1.209 3.81h-.899a1.539 1.539 0 100 3.078h.073l-.003.012-.467 3.6H.741a.694.694 0 000 1.388h5.925a.694.694 0 000-1.388h-.694v-3.611h.338V7.922h-.506l1.115-3.799a2.29 2.29 0 00.472-.471h5.365c.19.18.418.317.671.406v1.346a1.494 1.494 0 00-.573.39c-.578.637-.501 1.622-.491 1.732a.682.682 0 00.752.622.696.696 0 00.629-.751c-.016-.2.021-.545.137-.671.015-.016.045-.049.167-.049.462 0 .517.812.517.816a.693.693 0 00.692.661l.034-.001a.694.694 0 00.66-.727c-.027-.586-.321-1.576-1.148-1.965zm-9.24-2.579a.594.594 0 110-1.188.594.594 0 010 1.188zm8.486 0a.594.594 0 110-1.188.594.594 0 010 1.188z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});