define("ember-svg-jar/inlined/tokenNamespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.556 8.416l-.804-1.68h-.036v5.64H5V4h1.992l2.292 3.96.804 1.68h.036V4h1.716v8.376H9.848l-2.292-3.96z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});