define("ember-svg-jar/inlined/figure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<ellipse cx=\"67.5\" cy=\"172\" rx=\"67.5\" ry=\"12\" fill=\"#888\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M135 15.265H0V98.82h.01c.635 5.507 30.608 9.941 67.49 9.941 36.882 0 66.855-4.434 67.49-9.94h.01V15.264z\" fill=\"#C4C4C4\"/><path fill=\"#E3E3E3\" d=\"M0 11.926h33.952v160.28H0z\"/><path fill=\"#C8C8C8\" d=\"M33.144 11.926h33.952v160.28H33.144z\"/><path fill=\"#9C9C9C\" d=\"M67.096 11.926h33.952v160.28H67.096z\"/><path fill=\"#7E7E7E\" d=\"M101.047 11.926h33.952v160.28h-33.952z\"/><path fill=\"url(#paint0_linear_417_2143)\" d=\"M0 11.926h33.952v160.28H0z\"/><path fill=\"url(#paint1_linear_417_2143)\" d=\"M33.144 11.926h33.952v160.28H33.144z\"/><path fill=\"url(#paint2_linear_417_2143)\" d=\"M67.096 11.926h33.952v160.28H67.096z\"/><path fill=\"url(#paint3_linear_417_2143)\" d=\"M101.048 11.926H135v160.28h-33.952z\"/><ellipse cx=\"67.5\" cy=\"12\" rx=\"67.5\" ry=\"12\" fill=\"#C4C4C4\"/><defs><linearGradient id=\"paint0_linear_417_2143\" x1=\"16.976\" y1=\"11.926\" x2=\"16.976\" y2=\"172.205\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".064\" stop-color=\"#E7E7E7\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#888\"/></linearGradient><linearGradient id=\"paint1_linear_417_2143\" x1=\"50.12\" y1=\"11.926\" x2=\"50.12\" y2=\"172.205\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".064\" stop-color=\"#E7E7E7\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#888\"/></linearGradient><linearGradient id=\"paint2_linear_417_2143\" x1=\"84.072\" y1=\"11.926\" x2=\"84.072\" y2=\"172.205\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".064\" stop-color=\"#E7E7E7\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#888\"/></linearGradient><linearGradient id=\"paint3_linear_417_2143\" x1=\"118.024\" y1=\"11.926\" x2=\"118.024\" y2=\"172.205\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".064\" stop-color=\"#E7E7E7\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#888\"/></linearGradient></defs>",
    "attrs": {
      "width": "135",
      "height": "184",
      "viewBox": "0 0 135 184",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});