define("ember-svg-jar/inlined/map_builder_undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Deshacer</title><path d=\"M28.62 25.58a6.28 6.28 0 00-2.48-2.65 5.48 5.48 0 00-1.58-.55l.17-.54a.72.72 0 00.11-.42.82.82 0 00-.56-.79h-.43a1.1 1.1 0 00-.45.24l-.39.34-1.3 1.12a.93.93 0 00-.16 1.25c.49.7 1 1.42 1.46 2.13a.83.83 0 001.49-.12.61.61 0 00.05-.12.32.32 0 000-.14.3.3 0 000-.13.35.35 0 000-.17l-.16-1a3.87 3.87 0 013.1 3.38 3.8 3.8 0 01-1.49 3.5l-.22.17a.46.46 0 00-.2.29.54.54 0 00.07.33l.42.61a.51.51 0 00.37.21.39.39 0 00.23-.09 5.53 5.53 0 001.79-2.08 5 5 0 00.46-1.68 6.88 6.88 0 00-.3-3.09z\" fill=\"#b6b6b7\" opacity=\".4\"/><path d=\"M28.31 25.36a6.17 6.17 0 00-2.48-2.65 5.2 5.2 0 00-1.58-.55l.17-.54a.69.69 0 00.11-.41.81.81 0 00-.56-.79.2.2 0 00-.11 0h-.32a1 1 0 00-.45.25l-.39.33-1.3 1.12a.93.93 0 00-.16 1.25c.49.7 1 1.42 1.46 2.14a.86.86 0 001.12.29.8.8 0 00.37-.42.54.54 0 000-.11.23.23 0 000-.14.27.27 0 000-.13.32.32 0 000-.17l-.16-1a3.67 3.67 0 011.84.93 3.71 3.71 0 011.26 2.45 3.83 3.83 0 01-1.46 3.51l-.22.16a.48.48 0 00-.2.3.55.55 0 00.07.33l.42.61a.53.53 0 00.37.2.39.39 0 00.23-.09 5.53 5.53 0 001.79-2.08 4.9 4.9 0 00.46-1.68 6.83 6.83 0 00-.28-3.11z\" fill=\"#437287\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});