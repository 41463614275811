define("ember-svg-jar/inlined/pui_wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 1.3a6.7 6.7 0 110 13.4A6.7 6.7 0 018 1.3M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z\"/><path d=\"M8 2.1C4.7 2.1 2.1 4.7 2.1 8s2.7 5.9 5.9 5.9 5.9-2.7 5.9-5.9c0-3.3-2.6-5.9-5.9-5.9zm5.1 6.7h-2.2c-.2 0-.3.1-.4.2-.1.1 0 .3.1.4l1.6 1.6c-.3.4-.7.8-1.1 1.1l-1.6-1.6c-.1-.1-.3-.1-.4-.1-.1.1-.2.2-.2.4V13c-.5.1-1.1.1-1.6 0v-2.2c0-.2-.1-.3-.2-.4-.1-.1-.3 0-.4.1L5 12.1c-.4-.3-.8-.7-1.1-1.1l1.6-1.6s.1-.2 0-.4c-.1-.1-.2-.2-.4-.2H2.9c-.1-.5-.1-1.1 0-1.6h2.2c.2 0 .3-.1.4-.2s0-.3-.1-.4L3.9 5c.3-.5.6-.8 1.1-1.1l1.6 1.6c.1.1.2.1.3.1.2 0 .4-.2.4-.4V2.9c.5-.1 1.1-.1 1.6 0v2.2c0 .2.2.4.4.4.1 0 .2 0 .3-.1L11 3.9c.4.3.8.7 1.1 1.1l-1.6 1.6c-.1.1-.1.3-.1.4.1.1.2.2.4.2H13c.2.5.2 1.1.1 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});