define("ember-svg-jar/inlined/l-origami", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 12V4a1 1 0 011-1h6.297a1 1 0 01.651 1.759l-4.696 4.025M12 21l-7.414-7.414A2 2 0 014 12.172V6.415a1.002 1.002 0 011.707-.707L20 20.009\"/><path d=\"M12.214 3.381l8.414 14.966a1 1 0 01-.167 1.199l-1.168 1.163a1 1 0 01-.706.291H6.351a1 1 0 01-.625-.219L3.25 18.8a1 1 0 01.631-1.781l4.165.027\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-origami",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});