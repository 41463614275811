define("ember-svg-jar/inlined/pui_recognition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.4 10.3c.6-1 .9-2.1.9-3.3C14.3 3.5 11.5.7 8 .7S1.7 3.5 1.7 7c0 1.2.3 2.3.9 3.2L0 12.7l2.8.5.8 2.5 2.5-2.8c.6.2 1.2.3 1.9.3.6 0 1.2-.1 1.8-.2l2.7 2.8.7-2.6 2.8-.7-2.6-2.2zm-3.1.7L8 9.5 5.7 11l.6-2.8-2-1.8L7 6.1l1-2.5 1.1 2.5 2.6.3-2 1.8.6 2.8z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});