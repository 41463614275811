define("ember-svg-jar/inlined/pui_board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.8 1.478a.494.494 0 00-.494-.494H8.691V.268a.24.24 0 00-.239-.239h-.904a.24.24 0 00-.239.239v.716H.789a.494.494 0 00-.494.494v9.051H15.8V1.478zM15.854 10.829H.146a.147.147 0 00-.146.146v.621c0 .081.066.146.146.146h5.31L3.79 14.947a.7.7 0 101.242.646l1.881-3.619a.671.671 0 00.067-.231h2.039a.67.67 0 00.067.232l1.882 3.619a.702.702 0 00.944.298.7.7 0 00.298-.944l-1.667-3.205h5.31a.146.146 0 00.146-.146v-.621a.146.146 0 00-.145-.147z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});