define("ember-svg-jar/inlined/pui_health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.9 8.1l1.2-3.6c.1-.3.4-.4.6-.4.3 0 .5.1.7.4L8 10.6l2.2-8.3c.1-.3.329-.422.6-.4.432.05.6.1.7.4l1.9 5.8h1.9c.4-.8.7-1.7.7-2.6.1-2.4-1.7-4.4-4.1-4.6h-.2c-.9 0-1.7.3-2.4.8-.6.5-1 1-1.4 1.7-.2-.6-.7-1.2-1.3-1.6-.7-.6-1.5-.9-2.3-.9C1.9 1 0 3 0 5.4v.2c0 .9.2 1.8.7 2.5h2.2z\"/><path d=\"M12.9 9.2c-.3 0-.5-.2-.7-.4l-1.3-4-2.1 8c-.1.3-.3.4-.6.4s-.5-.1-.6-.4L4.8 6.5l-.7 2.3c-.1.3-.4.4-.7.4H1.5c1.3 1.5 2.7 2.8 4.3 4.1.6.5 1.2 1.1 1.9 1.7.2.2.5.2.7 0 .7-.6 1.4-1.2 1.9-1.7 1.5-1.2 3-2.6 4.3-4.1h-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});