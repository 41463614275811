define("ember-svg-jar/inlined/pui_snowflake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.651 10.884l-1.21-.698 1.017-.565c.372-.246.485-.641.24-1.014-.196-.459-.641-.486-.964-.326l-1.762 1.056-2.376-1.371 2.346-1.355 1.762 1.056c.186.123.409.11.632.096.223-.013.346-.2.469-.386.246-.372.083-.854-.289-1.1l-1.017-.565 1.123-.648a.786.786 0 00.292-1.09.786.786 0 00-1.09-.292l-1.21.698.02-1.163c-.027-.445-.313-.741-.758-.714-.495-.06-.741.313-.764.672l.034 2.054L8.768 6.6V3.891l1.796-.998c.2-.1.299-.299.399-.499.1-.2 0-.399-.1-.599-.2-.399-.698-.499-1.097-.299l-.998.599V.798A.787.787 0 007.97 0a.787.787 0 00-.798.798v1.397l-.998-.599c-.399-.199-.798-.1-.998.299-.299.399-.1.798.2.998l1.796.998v2.744L4.826 5.28l.034-2.054c.013-.223-.11-.409-.232-.595-.124-.186-.346-.2-.569-.213-.446-.027-.781.355-.808.801l.02 1.163-1.124-.648a.786.786 0 00-1.09.292.787.787 0 00.292 1.09l1.21.698-1.018.565c-.372.246-.485.641-.24 1.014.196.459.641.486.964.326l1.762-1.056 2.376 1.372-2.346 1.354-1.762-1.056c-.186-.123-.408-.11-.631-.097-.223.013-.346.2-.469.386-.246.372-.083.854.289 1.1l1.017.565-1.123.648a.786.786 0 00-.292 1.09c.2.346.658.542 1.09.292l1.21-.698-.02 1.163c.027.445.313.741.758.714.495.06.741-.313.764-.672l-.034-2.054L7.232 9.4v2.709l-1.796.998c-.2.1-.299.299-.399.499-.1.199 0 .399.1.599.199.399.698.499 1.097.299l.998-.599v1.297c0 .499.399.798.798.798a.787.787 0 00.798-.798v-1.397l.998.599c.399.2.798.1.998-.299.299-.399.1-.798-.199-.998l-1.796-.998V9.365l2.346 1.355-.034 2.054c-.013.223.11.409.232.595.123.186.346.2.568.213.445.027.781-.355.808-.801l-.02-1.163 1.123.648a.787.787 0 001.09-.292.785.785 0 00-.291-1.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});