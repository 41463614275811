define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 5a2 2 0 11-4 0 2 2 0 014 0zm9-4a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1zm-3.448 6.134l-3.76 2.769a.5.5 0 01-.436.077l-.087-.034-1.713-.87L1 11.8V14h14V9.751l-3.448-2.617zM15 2H1v8.635l4.28-2.558a.5.5 0 01.389-.054l.094.037 1.684.855 3.813-2.807a.5.5 0 01.52-.045l.079.05L15 8.495V2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});