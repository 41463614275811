define("ember-svg-jar/inlined/pui_plug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blueprints</title><title>blueprints</title><path d=\"M13.394 5.03h-1.671V.866a.866.866 0 00-1.732 0V5.03H6.02V.866a.866.866 0 00-1.731 0V5.03H2.606a.229.229 0 00-.229.229v2.677c0 .126.102.229.229.229h.61v1.74c0 2.176 1.463 3.992 3.452 4.572-.006.028-.017.054-.017.084V15.6c.001.22.18.4.401.4h1.895c.221 0 .401-.18.401-.401V14.56c0-.03-.011-.056-.017-.084 1.989-.58 3.452-2.396 3.452-4.572v-1.74h.61a.229.229 0 00.229-.229V5.259a.228.228 0 00-.228-.229z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});