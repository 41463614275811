define("ember-svg-jar/inlined/pui_helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.237 11.589h-.422c.006-.116.018-.231.018-.348 0-2.3-1.14-4.33-2.882-5.568v2.598a.75.75 0 01-1.5 0V4.916c0-.015.008-.028.009-.043a6.706 6.706 0 00-1.61-.406V6.99a.75.75 0 01-1.5 0V4.441a6.75 6.75 0 00-1.576.346c.008.043.026.083.026.129v3.355a.75.75 0 01-1.5 0V5.502a6.823 6.823 0 00-3.133 5.739c0 .118.012.232.018.348H.763a.764.764 0 000 1.527h14.474a.764.764 0 000-1.527z\"/><path d=\"M11.951 5.673v-.757a.745.745 0 00-1.491-.043 6.744 6.744 0 011.491.8zM8 4.408c.289 0 .571.024.85.058v-.832a.75.75 0 00-1.5 0v.807c.214-.02.431-.033.65-.033zM5.774 4.787a.74.74 0 00-.724-.621.75.75 0 00-.75.75v.587a6.746 6.746 0 011.474-.716z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});