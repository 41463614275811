define("ember-svg-jar/inlined/pui_polisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>forklift</title><path d=\"M8.2 13.9H1.3c-.7 0-1.3.6-1.3 1.3v.8h9.5v-.8c0-.7-.6-1.3-1.3-1.3zM15.7 3.1H9.1V3L8.6.5C8.5.2 8.3 0 8 0H3.5c-.3 0-.6.2-.7.5l-.6 2.4v8.7H3v1.2h3.4v-1.2H7s1.925-1.821 2-1.9.1-.23.1-.4V7.6c0-.1.1-.3.2-.3l1.4-.3v.7l-.8.4v1.2l1.5-.8c.11-.07.2-.112.2-.3V7c0-.1.1-.3.2-.3l3.9-.5c.2 0 .3-.1.3-.3V3.4c0-.1-.2-.3-.3-.3zM5.1 1.3h-.3V.2h.4v1.1h-.1zm.745 0h-.4V.2h.4v1.1zm.655 0h-.4V.2h.4v1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});