define("ember-svg-jar/inlined/pui_category_local_government", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.299 20.556h17.339M4.189 16.645V9.8M8.042 16.645V9.8M11.895 16.645V9.8M15.748 16.645V9.8M9.968 1l7.707 4.889H2.262L9.968 1z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "22",
      "viewBox": "0 0 20 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});