define("ember-svg-jar/inlined/pui_vibration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vibration</title><title>vibration</title><path d=\"M13.077 8l2.518-2.35c.304-.293.405-.585.405-.974 0-.39-.101-.682-.405-.974L12.153.39C11.85.097 11.546 0 11.141 0s-.709.097-1.012.487c-.506.487-.506 1.364 0 1.851l2.429 2.338-2.429 2.338c-.304.292-.404.67-.404.986 0 .316.1.694.404.987l2.429 2.338-2.429 2.338c-.506.487-.506 1.364 0 1.851.303.389.607.486 1.012.486s.709-.097 1.012-.39l3.442-3.312c.304-.292.405-.584.405-.974s-.101-.682-.405-.974L13.077 8zM3.353 8L5.87 5.65c.304-.292.405-.584.405-.974s-.101-.682-.405-.974L2.429.39C2.125.097 1.821 0 1.416 0 1.011 0 .708.097.404.487c-.506.487-.506 1.364 0 1.851l2.429 2.338L.404 7.013A1.356 1.356 0 000 8c0 .316.1.694.404.987l2.429 2.338-2.429 2.338c-.506.487-.506 1.364 0 1.851.304.389.607.486 1.012.486s.709-.097 1.012-.39l3.442-3.312c.304-.292.405-.584.405-.974s-.101-.682-.405-.974L3.353 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});