define("ember-svg-jar/inlined/l-plane-takeoff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 22h20M6.36 17.4L4 17l-2-4 1.1-.55a2 2 0 011.8 0l.17.1a2 2 0 001.8 0L8 12 5 6l.9-.45a2 2 0 012.09.2l4.02 3a2 2 0 002.1.2l4.19-2.06a2.4 2.4 0 011.73-.17L21 7a1.4 1.4 0 01.87 1.99l-.38.76c-.23.46-.6.84-1.07 1.08L7.58 17.2a2 2 0 01-1.22.18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-plane-takeoff",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});