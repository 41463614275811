define("ember-svg-jar/inlined/pui_falling_object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fallenObject</title><path d=\"M10.326 15.842zM10.326 15.842l5.574-5.773v-.1l.1-1.792v-.1L10.923 3.2H8.932L3.159 8.973l7.167 6.869zm2.588-10.253l.597.597-.299 1.095-1.394-1.394 1.096-.298zM11.421 7.58l-1.095-.299 1.095-1.194 1.593 1.493-1.095 1.194-.398-1.095c0-.099-.1-.099-.1-.099zM6.018 3.766a.498.498 0 01-.352-.146l-2.51-2.51A.499.499 0 013.86.406l2.51 2.51a.499.499 0 01-.352.85zM5.205 5.71a.498.498 0 01-.352-.146L.146.857A.499.499 0 01.85.153L5.557 4.86a.499.499 0 01-.352.85zM3.008 6.776a.498.498 0 01-.352-.146L.146 4.12a.499.499 0 01.704-.704l2.51 2.51a.499.499 0 01-.352.85z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});