define("ember-svg-jar/inlined/pui_forklift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8.538\" cy=\"12.822\" r=\".953\"/><path d=\"M15.5 11.807h-4.506L11 2.725c0-.3-.2-.5-.5-.5s-.5.2-.5.5v5.8h-.8l-1.6-4.4c-.2-.8-.9-1.3-1.6-1.3H3.8c-.3 0-.5.2-.5.5v3.6H1.9c-1.1 0-1.9.8-1.9 1.8v3c0 .569.454 1.033 1.011 1.082h.353a1.949 1.949 0 013.896 0h1.328a1.949 1.949 0 013.896 0H15.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM5.2 8.425c-.4 0-.8-.4-.8-.8-.1 0-.1-3.7-.1-3.7H6c.3 0 .7.2.8.5l1.4 4h-3z\"/><circle cx=\"3.313\" cy=\"12.822\" r=\".953\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});