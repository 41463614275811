define("ember-svg-jar/inlined/l-layers-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.83 2.18a2 2 0 00-1.66 0L2.6 6.08a1 1 0 000 1.83l8.58 3.91a2 2 0 001.66 0l8.58-3.9a1 1 0 000-1.83z\"/><path d=\"M6.08 9.5l-3.5 1.6a1 1 0 000 1.81l8.6 3.91a2 2 0 001.65 0l8.58-3.9a1 1 0 000-1.83l-3.5-1.59\"/><path d=\"M6.08 14.5l-3.5 1.6a1 1 0 000 1.81l8.6 3.91a2 2 0 001.65 0l8.58-3.9a1 1 0 000-1.83l-3.5-1.59\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-layers-3",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});