define("ember-svg-jar/inlined/pui_like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.696 5.29c-.084-.014-4.023 0-4.023 0l.55-1.501c.38-1.037.134-2.62-.92-3.182C9.96.423 9.481.331 9.095.428a.872.872 0 00-.532.399c-.133.226-.12.49-.167.741a3.203 3.203 0 01-.886 1.694c-.625.609-2.259 2.416-2.978 3.217-.14-.115-.368-.195-.638-.195H.779c-.428 0-.779.19-.779.424v7.625c0 .234.349.424.779.424h3.115c.43 0 .779-.19.779-.424v-.007l8.213.367c1.175.001 1.945-1.311 1.365-2.336a1.58 1.58 0 00.522-2.089 1.58 1.58 0 00.522-2.089c1.192-.765.793-2.654-.599-2.889zM3.104 13.814a.355.355 0 01-.356.356h-1.62a.323.323 0 01-.323-.323V6.985c0-.063.051-.114.114-.114H2.99c.063 0 .114.051.114.114v6.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});