define("ember-svg-jar/inlined/l-message-square-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z\"/><path d=\"M14.8 7.5a1.84 1.84 0 00-2.6 0l-.2.3-.3-.3a1.84 1.84 0 10-2.4 2.8L12 13l2.7-2.7c.9-.9.8-2.1.1-2.8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-message-square-heart",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});