define("ember-svg-jar/inlined/tokenException", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.461 7.31h3.055a.74.74 0 01.66 1.074l-2.141 4.211a.74.74 0 11-1.319-.67L9.31 8.79H6.256a.74.74 0 01-.66-1.075l2.19-4.31a.74.74 0 011.319.67L7.461 7.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});