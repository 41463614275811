define("ember-svg-jar/inlined/riesgo_por_baja_temperatura_o_condiciones_de_congelamiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_POR_BAJA_TEMPERATURA_O_CONDICIONES_DE_CONGELAMIENTO</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M31.807 31.454l-2.922.778-1.126-.646v-1.212l1.113-.636 2.934.782a.682.682 0 00.836-.48.679.679 0 00-.483-.832l-1.608-.428 1.483-.847a.677.677 0 00.252-.927.684.684 0 00-.932-.25l-1.486.848.431-1.6a.678.678 0 00-.483-.832.686.686 0 00-.835.48l-.783 2.906-1.12.64-1.062-.611v-1.281l2.147-2.136a.676.676 0 000-.96.685.685 0 00-.965 0l-1.182 1.175V23.68a.68.68 0 00-.683-.679.68.68 0 00-.683.679v1.698l-1.173-1.167a.685.685 0 00-.965 0 .676.676 0 000 .96l2.138 2.127v1.295l-1.053.604-1.12-.639-.783-2.905a.684.684 0 00-.836-.48.679.679 0 00-.482.832l.43 1.6-1.485-.849a.684.684 0 00-.932.251.677.677 0 00.252.927l1.483.847-1.609.428a.679.679 0 10.353 1.312l2.935-.782 1.112.635v1.213l-1.125.647-2.922-.78a.678.678 0 10-.353 1.311l1.603.429-1.479.849a.676.676 0 00-.25.927.681.681 0 00.933.248l1.486-.853-.432 1.606a.679.679 0 00.66.855c.3 0 .576-.2.658-.504l.786-2.917 1.119-.642 1.051.604v1.311l-2.138 2.127a.676.676 0 00.482 1.158c.175 0 .35-.065.483-.198l1.173-1.167v1.698a.68.68 0 00.683.679.68.68 0 00.683-.68V36.59l1.182 1.175a.681.681 0 00.482.198.676.676 0 00.483-1.158l-2.147-2.135V33.37l1.06-.609 1.119.642.786 2.917a.682.682 0 001.318-.351l-.433-1.606 1.487.853a.681.681 0 00.932-.248.676.676 0 00-.25-.928l-1.478-.848 1.602-.428a.678.678 0 10-.352-1.311zm-5.413.132l-1.057.607-1.055-.607-.002-1.214 1.057-.607 1.057.607v1.214z\" fill-rule=\"nonzero\" fill=\"#000\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});