define("ember-svg-jar/inlined/prohibido_fumar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_FUMAR_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><g fill-rule=\"nonzero\" fill=\"#000\"><path d=\"M34.452 14.517c-.558.662-.74 1.635-.26 2.393.367.582 1.003.9 1.3 1.54.41.882-.042 2.037-1.007 2.226-.185.037-.017-.189.017-.25.119-.217.224-.448.27-.695.091-.478-.132-1-.385-1.392-.098-.152-.21-.29-.32-.432-.31-.404-.555-.87-.61-1.391a2.214 2.214 0 01.35-1.427 2.616 2.616 0 01.572-.632c.043-.032.12.007.073.06zM31.256 20.071c.208.456.4.942.417 1.453.017.527-.166 1.04-.391 1.504-.1.205-.21.403-.326.599-.064.11-.048.217.1.194.39-.059.776-.26 1.09-.5a2.97 2.97 0 001.14-2.514c-.053-1.028-.614-1.844-1.303-2.533-.279-.279-.587-.522-.858-.809-.706-.745-1.194-1.692-1.128-2.762.055-.9.463-1.824 1.03-2.501.049-.058.005-.154-.071-.148-.033.003-.062.031-.087.05a4.936 4.936 0 00-.934.991 4.345 4.345 0 00-.77 1.89c-.198 1.384.39 2.676 1.224 3.718.34.426.638.863.867 1.368z\"/></g><path d=\"M12 26.998c0-1.104.895-1.998 1.994-1.998H28v6H13.994A1.998 1.998 0 0112 29.002v-2.004z\" fill=\"#070606\"/><path d=\"M24.5 46C13.178 46 4 36.822 4 25.5S13.178 5 24.5 5 45 14.178 45 25.5 35.822 46 24.5 46zm0-3.154c9.58 0 17.346-7.766 17.346-17.346S34.08 8.154 24.5 8.154 7.154 15.92 7.154 25.5 14.92 42.846 24.5 42.846z\" fill=\"#E11212\"/><path d=\"M33 26.004v3.992c0 .553-.397 1.004-.888 1.004H29v-6h3.112c.49 0 .888.45.888 1.004z\" fill=\"#070606\"/><path fill=\"#E11212\" d=\"M10.868 14.052l25.205 25.191 2.352-2.076L12.86 11.671z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});