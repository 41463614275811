define("ember-svg-jar/inlined/pui_print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M437 129h-14V75c0-41.355-33.645-75-75-75H164c-41.355 0-75 33.645-75 75v54H75c-41.355 0-75 33.645-75 75v120c0 41.355 33.645 75 75 75h14v68c0 24.813 20.187 45 45 45h244c24.813 0 45-20.187 45-45v-68h14c41.355 0 75-33.645 75-75V204c0-41.355-33.645-75-75-75zM119 75c0-24.813 20.187-45 45-45h184c24.813 0 45 20.187 45 45v54H119zm274 392c0 8.271-6.729 15-15 15H134c-8.271 0-15-6.729-15-15V319h274zm89-143c0 24.813-20.187 45-45 45h-14v-50h9c8.284 0 15-6.716 15-15s-6.716-15-15-15H80c-8.284 0-15 6.716-15 15s6.716 15 15 15h9v50H75c-24.813 0-45-20.187-45-45V204c0-24.813 20.187-45 45-45h362c24.813 0 45 20.187 45 45z\"/><path d=\"M296 353h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM296 417h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM128 193H80c-8.284 0-15 6.716-15 15s6.716 15 15 15h48c8.284 0 15-6.716 15-15s-6.716-15-15-15z\"/>",
    "attrs": {
      "height": "512",
      "viewBox": "0 0 512 512",
      "width": "512",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});