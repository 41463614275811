define("ember-svg-jar/inlined/pui_bucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fundicion</title><path d=\"M12.536 9.043c.298.298.497.596.596 1.093.298 1.59.497 3.28.596 4.87 0 .497.398.795.994.994a.996.996 0 00.994-.994c0-1.988-.199-3.975-.398-6.062-.099-1.391-.497-2.484-1.193-3.677-.398-.696-1.193-1.193-1.988-1.193a8.11 8.11 0 00-3.081.596c-.395.299-.395.398-.097.696 1.192 1.292 2.385 2.386 3.577 3.677z\"/><path d=\"M11.526 8.753l-4.84-4.84a.889.889 0 00-.492-.237v4.107c.446.438.707 1.041.707 1.678 0 1.304-1.061 2.365-2.365 2.365s-2.365-1.061-2.365-2.365c0-.638.261-1.24.707-1.679V6.427L.552 8.753a.915.915 0 000 1.295l4.84 4.84a.915.915 0 001.295 0l4.84-4.84a.916.916 0 00-.001-1.295z\"/><path d=\"M5.201 8.268V0H3.872v8.268c-.42.234-.707.678-.707 1.193a1.371 1.371 0 002.742 0 1.365 1.365 0 00-.706-1.193z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});