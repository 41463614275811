define("ember-svg-jar/inlined/pui_sprinkler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.342 14.657c0 .764-.638 1.379-1.41 1.341-.75-.037-1.31-.743-1.272-1.493.069-1.335 1.026-2.192 1.279-2.396a.098.098 0 01.124.001c.265.211 1.279 1.111 1.279 2.547zM10.4.9H16V0H0v.9h5.6c.2 0 .4.1.5.2l.6 1.1H5c-.3 0-.5.2-.5.5v4.7c0 .2.1.3.2.4l2 1.6v.7H4.4c-.2 0-.3.1-.4.2l-.8.8.7.7.6-.6c.1-.1.3-.2.4-.2h1.8v.9h2.6V11h1.8c.1 0 .3.1.4.2l.6.6.7-.7-.8-.8c-.1-.1-.2-.2-.4-.2H9.3v-.7l2-1.6c.1-.1.2-.2.2-.4V2.7c0-.3-.2-.5-.5-.5H9.3l.6-1.1c.1-.1.3-.2.5-.2zM7.6 6.6h-.9v1.7l-1.1-.9c-.1-.1-.2-.2-.2-.4V4h.9c0 .7.5 1.4 1.3 1.6v1zM9.7 4h.9v3c0 .2-.1.3-.2.4l-1.1.9V6.6h-.9v-1c.8-.2 1.3-.9 1.3-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});