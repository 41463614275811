define("ember-svg-jar/inlined/pui_bulldozer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>excavator</title><path d=\"M15.7 11.2c.1 0 .3-.1.3-.3V8.3c0-.2-.1-.3-.3-.3h-5.1V4.5c0-.1-.1-.3-.3-.3H7.7c-.1 0-.1 0-.2.1l-.6.7L2.1.1C2 0 1.9 0 1.9 0H.3C.1 0 0 .1 0 .3v9.1c0 .1 0 .2.1.2l2.4 1.6c.1.1.3 0 .4-.1V11l.8-3.2.3-1c0-.1 0-.3-.2-.3-.1 0-.3.1-.3.2l-.3.8H1.4L2 3.1l3.4 3.3-.5.6c0 .1-.1.1-.1.2v3.5h-.3c-.1 0-.3.1-.3.3v1.3c-1 .2-1.7 1.1-1.6 2.1.2.9 1 1.6 1.9 1.6h9.6c1 0 1.9-.9 1.9-1.9 0-.9-.7-1.7-1.6-1.8v-1.1h1.3zM3.1 8l-.3 1.2-2.3-.6V8h2.6zm2.8-.4c0-.1 0-.1.1-.2l2.1-2s.1-.1.2-.1h1.2c.1 0 .3.1.3.3v2.7c0 .1-.1.3-.3.3H6.1c-.1-.1-.2-.2-.2-.3v-.7zM5.7 15c-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8.5 0 .8.4.8.8s-.4.8-.8.8zm2.4 0c-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8.5 0 .8.4.8.8.1.4-.3.8-.8.8zm2.5 0c-.5 0-.8-.4-.8-.8s.4-.8.8-.8.8.4.8.8-.4.8-.8.8zm2.4 0c-.5 0-.8-.4-.8-.8s.4-.8.8-.8c.5 0 .8.4.8.8s-.4.7-.8.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});