define("ember-svg-jar/inlined/l-test-tube-diagonal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 7L6.82 21.18a2.83 2.83 0 01-3.99-.01 2.83 2.83 0 010-4L17 3m-1-1l6 6m-10 8H4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-test-tube-diagonal",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});