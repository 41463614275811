define("ember-svg-jar/inlined/precaucion_zona_de_transito_de_montacargas_u_otros_vehiculos_industriales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_ZONA_DE_TRANSITO_DE_MONTACARGAS_U_OTROS_VEHICULOS_INDUSTRIALES</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M19.448 29.734v-2.629h1.218v6.409c1.116.686 2.328-.097 2.328-.097s.427-7.878 3.662-7.878h3.413v7.245h1.168c1.168 0 0 4.505 0 4.505h-.723c-.05-1.12-.9-2.014-1.939-2.014-1.039 0-1.888.894-1.938 2.014h-2.364l.002-.03c0-1.242-.927-2.252-2.067-2.252-1.139 0-2.065 1.01-2.065 2.252 0 .144.013.285.037.422h-.731v-6.855h-4.423v-.49l4.422-.602zm6.13 3.932l1.077-2.056h2.336v-4.896h-2.336c-.963 0-1.497 1.464-1.775 2.693-.177.784-.722 3.637-.772 4.259h1.47zm-3.38 1.603c-.99 0-1.793.874-1.793 1.954s.803 1.955 1.794 1.955c.99 0 1.793-.875 1.793-1.955s-.802-1.954-1.793-1.954zm6.428.359c-.94 0-1.704.83-1.704 1.856 0 1.025.763 1.857 1.704 1.857.94 0 1.703-.832 1.703-1.857s-.762-1.856-1.703-1.856z\" fill-rule=\"nonzero\" fill=\"#060606\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});