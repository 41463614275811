define("ember-svg-jar/inlined/pui_category_education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.82 7.045v7.253M1 7.045L12.91 1l11.91 6.045-11.91 6.044L1 7.045z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5.764 9.462v6.045c3.573 3.627 10.719 3.627 14.292 0V9.462\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "26",
      "height": "20",
      "viewBox": "0 0 26 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});