define("ember-svg-jar/inlined/pui_evacuation_route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.698 7.538l-6.352-3.98a.447.447 0 00-.685.379v2.208c0 .247-.2.448-.448.448H.448c-.248 0-.448.2-.448.448v1.754c0 .247.2.448.448.448h7.766c.247 0 .448.2.448.448v2.208c0 .352.387.566.685.379l6.352-3.98a.45.45 0 00-.001-.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});