define("ember-svg-jar/inlined/map_builder_isolate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-2{fill:#b6b6b7}.cls-3{fill:#437287}</style></defs><title>Aislar</title><g opacity=\".4\"><path class=\"cls-2\" d=\"M21.03 27.18h2.68v2.68h-2.68zM21.03 21.5h2.68v2.68h-2.68zM25.64 24.11h2.68v2.68h-2.68zM29.3 28h-1.06v-.41h1.06zm-2.12 0h-1.05v-.41h1.05zm-2.11 0h-.61v-.85h.41v.44h.2zm4.43-.79h-.41v-1.08h.41zm-4.63-1.11h-.41V25h.41zm4.63-1h-.41V24h.41zM24.87 24h-.41v-1h.44v.41zm4.25-.61h-1.05v-.41h1.05zm-2.11 0H26v-.41h1zM30.82 31.48H28.9v-.75h1.92zm-3.85 0H25v-.75h2zm-3.85 0h-1.93v-.75h1.93zm-3.42-.43H19v-1.93h.75zm11.5-1.63h-.76v-1.93h.76zm-11.5-2.23H19v-1.92h.75zm11.5-1.63h-.76v-1.92h.76zm-11.5-2.22H19v-1.92h.75zm11.5-1.63h-.76v-1.92h.76zM29.07 20h-1.92v-.76h1.92zm-3.85 0H23.3v-.76h1.92zm-3.85 0h-1.93v-.76h1.93z\"/></g><path class=\"cls-3\" d=\"M20.88 26.98h2.68v2.68h-2.68zM20.88 21.3h2.68v2.68h-2.68zM25.49 23.9h2.68v2.68h-2.68zM29.15 27.77h-1.06v-.42h1.06zm-2.11 0H26v-.42h1zm-2.11 0h-.62v-.86h.41v.44h.21zm4.42-.77h-.41v-1.08h.41zm-4.63-1.12h-.41V24.8h.41zm4.63-1h-.41v-1.07h.41zm-4.63-1.12h-.41v-1h.44v.42zm4.28-.62h-1v-.42h1zm-2.11 0h-1.08v-.42h1.05zM30.67 31.28h-1.92v-.76h1.92zm-3.85 0H24.9v-.76h1.92zm-3.85 0h-1.92v-.76H23zm-3.41-.44h-.76v-1.93h.76zm11.49-1.63h-.75v-1.93h.75zM19.56 27h-.76v-1.94h.76zm11.49-1.63h-.75v-1.94h.75zm-11.49-2.23h-.76v-1.93h.76zm11.49-1.63h-.75v-1.93h.75zm-2.13-1.73H27V19h1.92zm-3.85 0h-1.92V19h1.92zm-3.85 0H19.3V19h1.92z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});