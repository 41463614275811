define("ember-svg-jar/inlined/l-file-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 2v4a2 2 0 002 2h4\"/><path d=\"M4.268 21a2 2 0 001.727 1H18a2 2 0 002-2V7l-5-5H6a2 2 0 00-2 2v3m5 11l-1.5-1.5\"/><circle cx=\"5\" cy=\"14\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-file-search",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});