define("ember-svg-jar/inlined/pui_category_horticultural", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.205 18.352H14.89M7.41 18.352c5.877-2.71.855-6.94 3.206-10.845\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.876 6.856c1.176.868 1.924 2.386 2.458 4.013-2.137.434-3.74.434-5.129-.325C2.923 9.894 1.748 8.484 1 5.989c2.992-.542 4.701 0 5.876.867v0zM11.791 3.169a7.677 7.677 0 00-1.175 4.338c2.03-.108 3.526-.65 4.594-1.518C16.279 4.904 16.92 3.494 17.026 1c-2.884.108-4.273 1.085-5.235 2.169z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "19",
      "height": "20",
      "viewBox": "0 0 19 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});