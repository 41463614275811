define("ember-parachute/-private/query-param", ["exports", "@ember/object", "@ember/debug", "@ember/utils", "ember-parachute/utils/can-invoke"], function (_exports, _object, _debug, _utils, _canInvoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Normalized query param object.
   *
   * @export
   * @class QueryParam
   */
  class QueryParam {
    constructor(key, options = {}) {
      (false && !((0, _utils.isPresent)(key)) && (0, _debug.assert)(`[ember-parachute] You must specify a key to the QueryParam Class`, (0, _utils.isPresent)(key)));
      this.key = key;
      this.as = options.as || key;
      this.scope = options.scope;
      this.defaultValue = options.defaultValue;
      this.refresh = Boolean(options.refresh);
      this.replace = Boolean(options.replace);
      this.serialize = options.serialize;
      this.deserialize = options.deserialize;
    }

    /**
     * Current query param value.
     *
     * @param {Ember.Controller} controller
     * @returns {any}
     *
     * @memberof QueryParam
     */
    value(controller) {
      return (0, _object.get)(controller, this.key);
    }

    /**
     * Current query param serialized value.
     *
     * @param {Ember.Controller} controller
     * @returns {any}
     *
     * @memberof QueryParam
     */
    serializedValue(controller) {
      const value = this.value(controller);
      return (0, _canInvoke.canInvoke)(this, 'serialize') ? this.serialize(value, controller) : value;
    }

    /**
     * String representation of the query param object.
     *
     * @returns {string}
     *
     * @memberof QueryParam
     */
    toString() {
      return `QueryParam<${this.key}>`;
    }
  }
  _exports.default = QueryParam;
});