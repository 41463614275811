define("ember-svg-jar/inlined/pui_production", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>enlatado</title><path d=\"M14.07 12.162H1.923a1.915 1.915 0 000 3.83H14.07a1.915 1.915 0 000-3.83zM2.007 14.736a.66.66 0 110-1.32.66.66 0 010 1.32zm3.995 0a.66.66 0 110-1.32.66.66 0 010 1.32zm3.996 0a.66.66 0 110-1.32.66.66 0 010 1.32zm3.995 0a.66.66 0 110-1.32.66.66 0 010 1.32zM5.311 6.989h-.059v-.097a.343.343 0 00-.343-.343H3.034a.343.343 0 00-.343.343v.1a.236.236 0 00-.232.236v3.88c0 .132.107.239.239.239h.017a.715.715 0 00.701.608h1.112a.715.715 0 00.701-.608h.082a.239.239 0 00.239-.239v-3.88a.239.239 0 00-.239-.239zM9.306 6.989h-.058v-.097a.343.343 0 00-.343-.343H7.03a.343.343 0 00-.343.343v.1a.236.236 0 00-.233.236v3.88c0 .132.107.239.239.239h.017a.715.715 0 00.701.608h1.112a.715.715 0 00.701-.608h.082a.239.239 0 00.239-.239v-3.88a.238.238 0 00-.239-.239zM13.302 6.989h-.058v-.097a.343.343 0 00-.343-.343h-1.875a.343.343 0 00-.343.343v.1a.238.238 0 00-.233.237v3.88c0 .132.107.239.239.239h.017a.715.715 0 00.701.608h1.112a.715.715 0 00.701-.608h.082a.239.239 0 00.239-.239v-3.88a.24.24 0 00-.239-.24zM5.668 4.257L4.585 3.245a.8.8 0 00.186-.499.802.802 0 00-.315-.628V.491a.498.498 0 10-.999 0v1.628a.8.8 0 00-.315.628c0 .191.076.36.186.499L2.244 4.257a.498.498 0 00-.158.365v1.216a.498.498 0 10.998 0v-.999l.872-.814.872.814v.999a.498.498 0 10.998 0V4.622a.494.494 0 00-.158-.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});