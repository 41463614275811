define("ember-svg-jar/inlined/pui_empty_container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>emptyContainer</title><path d=\"M14 6.1V5c0-.2-.1-.3-.2-.4L6 .1c-.3-.1-.7-.1-1 0L.2 2.9c-.1.1-.2.2-.2.4v6.4c0 .2.1.3.2.4L8 14.6c.2.1.5.2.7.1l2.2 1.3.1-.1v-2.5l2.3-1.4 2.2 1.3.5-.1v-6l-2-1.1zM.8 9.9l-.5-.2v-5l.5.3v4.9zm1 .6l-.5-.3v-5l.5.3v5zm.9.5l-.5-.3v-5l.5.3v5zm1 .6l-.4-.3v-5l.5.3-.1 5zm1 .5l-.5-.3v-5l.5.3v5zm1 .5l-.5-.3v-5l.5.3v5zm1 .6l-.5-.3v-5l.5.3v5zm1 .5l-.6-.3v-5l.6.3v5zm5.6-2L11 13V9.9L8.8 8.5 13.3 6v5.7zm.4-5.9V6l-.2-.1.2-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});