define("ember-svg-jar/inlined/logo_kafka", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.565 17.623c-1.173 0-2.225.52-2.945 1.337l-1.845-1.306c.196-.54.309-1.118.309-1.724 0-.596-.109-1.165-.298-1.696l1.84-1.293a3.914 3.914 0 002.939 1.331 3.93 3.93 0 003.926-3.926 3.93 3.93 0 00-3.926-3.926 3.93 3.93 0 00-3.926 3.926c0 .387.058.761.163 1.115l-1.842 1.293a5.068 5.068 0 00-3.14-1.825V8.71a3.932 3.932 0 003.119-3.842A3.93 3.93 0 0012.012.941a3.93 3.93 0 00-3.926 3.926 3.93 3.93 0 003.048 3.824v2.249a5.075 5.075 0 00-4.193 4.99 5.075 5.075 0 004.229 4.995V23.3a3.931 3.931 0 00-3.084 3.833 3.93 3.93 0 003.926 3.926 3.93 3.93 0 003.927-3.926 3.931 3.931 0 00-3.084-3.833v-2.375a5.07 5.07 0 003.087-1.795l1.857 1.314c-.103.351-.16.721-.16 1.105a3.93 3.93 0 003.926 3.926 3.93 3.93 0 003.926-3.926 3.93 3.93 0 00-3.926-3.926zm0-9.18c1.05 0 1.903.853 1.903 1.903s-.854 1.903-1.903 1.903a1.906 1.906 0 01-1.904-1.903c0-1.05.854-1.904 1.904-1.904zM10.109 4.866c0-1.05.854-1.903 1.903-1.903 1.05 0 1.904.854 1.904 1.903 0 1.05-.854 1.904-1.904 1.904a1.906 1.906 0 01-1.903-1.904zm3.807 22.266c0 1.05-.854 1.903-1.904 1.903a1.906 1.906 0 01-1.903-1.903c0-1.05.854-1.904 1.903-1.904 1.05 0 1.904.854 1.904 1.904zm-1.904-8.548a2.658 2.658 0 01-2.655-2.655 2.658 2.658 0 012.655-2.655 2.658 2.658 0 012.655 2.655 2.658 2.658 0 01-2.655 2.655zm9.553 4.867a1.906 1.906 0 01-1.904-1.903c0-1.05.854-1.904 1.904-1.904 1.05 0 1.903.854 1.903 1.904 0 1.05-.854 1.903-1.903 1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});