define("ember-svg-jar/inlined/beta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 6.394h1.03c.72 0 1.283.153 1.688.459a2.2 2.2 0 01.813 1.163c.138.47.165.952.083 1.447-.1.597-.33 1.135-.69 1.616a3.95 3.95 0 01-1.333 1.137 3.689 3.689 0 01-1.735.418c-.381 0-.747-.07-1.097-.212-.35-.144-.636-.417-.86-.818l-.082.041.33-2.018c-.055.337-.024.647.092.932.12.285.32.513.598.685.281.171.634.257 1.06.257a2.54 2.54 0 002.075-1.03c.226-.308.369-.645.427-1.008a1.929 1.929 0 00-.072-.973 1.524 1.524 0 00-.536-.752c-.25-.196-.57-.293-.957-.293H7.825L8 6.395zm1.359-4.591c.49 0 .913.079 1.266.237a2.258 2.258 0 011.303 1.528c.082.33.094.668.036 1.014-.069.433-.25.864-.54 1.293-.29.425-.684.78-1.185 1.065-.497.282-1.096.422-1.796.422h-.597l.175-1.05h.576a2.182 2.182 0 001.812-.89c.192-.265.312-.544.36-.84.08-.473-.036-.866-.344-1.178-.31-.313-.726-.469-1.251-.469-.35 0-.683.072-.999.216a2.256 2.256 0 00-.793.603 1.852 1.852 0 00-.412.89l-1.78 10.81H3.973l1.782-10.81a3.17 3.17 0 01.648-1.457c.34-.429.765-.767 1.277-1.014a3.827 3.827 0 011.678-.37z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});