define("ember-svg-jar/inlined/pui_cancelled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>faultyMachinery</title><path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zm6 8a5.958 5.958 0 01-.771 2.933L5.067 2.771A5.958 5.958 0 018 2c3.308 0 6 2.692 6 6zM2 8a5.95 5.95 0 01.904-3.151l8.247 8.247A5.952 5.952 0 018 14c-3.308 0-6-2.692-6-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});