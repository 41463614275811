define("ember-svg-jar/inlined/l-crop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 2v14a2 2 0 002 2h14\"/><path d=\"M18 22V8a2 2 0 00-2-2H2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-crop",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});