define("ember-svg-jar/inlined/pui_category_retail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.156 9.819V7.622c0-.386-.1-.764-.29-1.098a2.18 2.18 0 00-.792-.803l-7.575-4.393a2.14 2.14 0 00-2.165 0L2.76 5.72a2.18 2.18 0 00-.791.803c-.19.334-.29.712-.291 1.098v8.787c0 .386.1.764.29 1.098.19.333.463.61.792.803l7.575 4.393a2.14 2.14 0 002.165 0l2.164-1.252M16.286 9.16L6.601 3.49\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1.99 6.523l9.426 5.492 9.426-5.492M11.416 23V12.015M18.45 18.606c1.495 0 2.706-1.23 2.706-2.746s-1.211-2.746-2.706-2.746c-1.494 0-2.705 1.23-2.705 2.746 0 1.517 1.211 2.746 2.705 2.746zM20.366 17.804l1.872 1.9\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});