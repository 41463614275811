define("ember-svg-jar/inlined/l-tractor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 11l11 .9a1 1 0 01.8 1.1l-.665 4.158a1 1 0 01-.988.842H20m-4 0h-5\"/><path d=\"M18 5a1 1 0 00-1 1v5.573M3 4h8.129a1 1 0 01.99.863L13 11.246M4 11V4m3 11h.01M8 10.1V4\"/><circle cx=\"18\" cy=\"18\" r=\"2\"/><circle cx=\"7\" cy=\"15\" r=\"5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-tractor",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});