define("ember-svg-jar/inlined/l-candy-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 8.5l-1 1a4.95 4.95 0 007 7l1-1m-3.657-9.313A4.95 4.95 0 0116.5 7.5a4.95 4.95 0 011.313 4.657M14 16.5V14m0-7.5v1.843M10 10v7.5\"/><path d=\"M16 7l1-5 1.367.683A3 3 0 0019.708 3H21v1.292a3 3 0 00.317 1.341L22 7l-5 1m-9 9l-1 5-1.367-.683A3 3 0 004.292 21H3v-1.292a3 3 0 00-.317-1.341L2 17l5-1M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-candy-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});