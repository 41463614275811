define("ember-svg-jar/inlined/tokenMetricCounter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 3a1 1 0 00-1 1v1H9a1 1 0 000 2h1v1a1 1 0 102 0V7h1a1 1 0 100-2h-1V4a1 1 0 00-1-1zM5 7a1 1 0 00-1 1v1H3a1 1 0 000 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2H6V8a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});