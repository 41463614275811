define("ember-svg-jar/inlined/pui_access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.9 7.2c-.061-.141-.022-.051-.1-.2l-2-2c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l.9.9h-3.7c-.4 0-.6.3-.7.7 0 .4.3.6.7.7h3.7l-.9.8c-.2.3-.2.6 0 .9.1.1.3.2.4.2.2 0 .3-.1.4-.2l2-2c.153-.154.08-.072.175-.203.091-.113.125-.297.025-.497z\"/><path d=\"M11.278 9.411c-.4 0-.595.198-.578.689v3.3H8V2.9c0-.3-.2-.5-.4-.7l-2.4-.7h5.3v3.3c0 .4.3.7.7.7s.7-.3.7-.7V1c0-.4-.3-.6-.7-.7H.4C.3.3.3.3.2.4L0 .6V14c0 .3.2.6.6.7L7.1 16h.1c.2 0 .3 0 .4-.1.1-.2.2-.4.2-.6v-.7h3.3c.4 0 .6-.3.7-.7V10c.004-.649-.522-.589-.522-.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});