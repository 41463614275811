define("ember-svg-jar/inlined/l-lectern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 12h3a2 2 0 001.902-1.38l1.056-3.333A1 1 0 0021 6H3a1 1 0 00-.958 1.287l1.056 3.334A2 2 0 005 12h3\"/><path d=\"M18 6V3a1 1 0 00-1-1h-3\"/><rect width=\"8\" height=\"12\" x=\"8\" y=\"10\" rx=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-lectern",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});