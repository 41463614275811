define("ember-svg-jar/inlined/l-axe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 12l-8.5 8.5a2.12 2.12 0 11-3-3L11 9\"/><path d=\"M15 13L9 7l4-4 6 6h3a8 8 0 01-7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-axe",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});