define("ember-svg-jar/inlined/handrail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M108.923 95.547l-5.255-4.616V200.17l7.166 1.911-1.911-106.534z\" fill=\"#AB984F\"/><path d=\"M118 200.648V90.769l-9.077 4.778 1.911 106.534 7.166-1.433z\" fill=\"#91834C\"/><path d=\"M5.255 4.777L0 .162v109.239l7.166 1.911L5.256 4.777z\" fill=\"#AB984F\"/><path d=\"M14.332 109.879V0L5.255 4.777l1.91 106.535 7.167-1.433z\" fill=\"#91834C\"/><path d=\"M108.923 95.547L0 0h15.287L118 90.77l-9.077 4.777z\" fill=\"#DBB315\"/><path d=\"M0 12.899V0l103.668 90.77v17.198L0 12.898z\" fill=\"#80690C\"/>",
    "attrs": {
      "width": "118",
      "height": "203",
      "viewBox": "0 0 118 203",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});