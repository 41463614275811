define("ember-svg-jar/inlined/pui_restroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.6 3.4H1.4C.6 3.4 0 4.1 0 4.8v4.3c0 .3.2.5.5.5h.1c.3 0 .5-.2.5-.5V4.8c0-.1 0-.1.1-.1s.1 0 .1.1v9.9c0 .3.3.6.6.7H2c.3 0 .6-.3.7-.6V9.1H3v5.7c0 .3.3.6.6.7h.1c.3 0 .6-.3.7-.6V4.8c0-.1 0-.1.1-.1s.1 0 .1.1v4.3c0 .3.2.5.5.5h.1c.3 0 .5-.2.5-.5V4.8c-.2-.9-.4-1.4-1.1-1.4z\"/><circle cx=\"3\" cy=\"1.8\" r=\"1.2\"/><path d=\"M16 8.2s-1.1-3-1.2-3.6c-.1-.6-.7-1.1-1.3-1.2h-2.1c-.7 0-1.1.4-1.3 1.2-.2.5-1.2 3.6-1.2 3.6-.1.3 0 .7.3.8s.7 0 .8-.3c0-.1.7-1.8 1.1-2.9 0 .3.1-.3-.9 4.3 0 .2.1.4.3.4h.5v4.2c0 .3.3.6.6.7h.1c.3 0 .6-.3.7-.7v-4.2h.3v4.2c0 .3.3.6.6.7h.1c.3 0 .6-.3.7-.7v-4.2h.4c.2 0 .3-.2.3-.4-.9-4.7-.8-4.1-.9-4.3.3 1.1 1 2.8 1 2.9.1.3.4.4.7.3.3-.1.5-.5.4-.8z\"/><circle cx=\"12.4\" cy=\"1.9\" r=\"1.2\"/><path d=\"M7.5 1.4c-.2 0-.4.2-.4.4v12.4c0 .2.2.4.4.4s.4-.2.4-.4V1.8c0-.2-.2-.4-.4-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});