define("ember-svg-jar/inlined/pui_clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.55 2h-2.6c0-1.1-.9-2-2.1-2s-2 .9-2.1 2h-2.4c-.8 0-1.3.6-1.3 1.3v11.4c0 .7.6 1.3 1.3 1.3h9.3c.7 0 1.3-.6 1.3-1.3V3.3c-.1-.7-.6-1.3-1.4-1.3zm-4.7-1c.5 0 1 .4 1 1h-2c0-.5.5-1 1-1zm-4 12.2v-2h5.9v2h-5.9zm8.2-3.1h-8.2v-2h8.2v2zm0-3.2h-8.2v-2h8.2v2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});