define("ember-svg-jar/inlined/pui_boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.141 13.272c0 .21 0 .42.105.629 1.888 0 2.518-.734 2.623-.944v-.629a6.526 6.526 0 01-2.728.944zM9.679 15.371H8.42l-.906-.525H.132V16h.525v-.315c0-.21.105-.315.315-.315s.315.105.315.315V16h.523v-.315c0-.21.105-.315.315-.315s.315.105.315.315V16h.525v-.315c0-.21.105-.315.315-.315s.315.105.315.315V16h.525v-.315c0-.21.105-.315.315-.315h2.892c.054 0 .107.017.152.048l.836.582h1.888c1.993 0 2.728-.734 2.833-.944v-.525c-.629.21-1.049.42-1.469.525l-1.888.315zM7.475 1.837v.21l-.629 6.924c0 1.154.839 2.098 1.993 2.203h.42c1.469.21 2.938.315 3.567 1.679 1.049 0 2.098-.315 2.833-1.049-.315-1.679-1.679-1.783-3.357-1.993h-.42c-.944-.21-1.783-.734-2.203-1.574a2.792 2.792 0 01-.315-1.259l.734-6.924c-2.308-.21-4.616.21-6.714 1.049v.525c1.154-.105 2.518-.105 3.777-.105.21-.001.314.104.314.314z\"/><path d=\"M8.525 14.846h1.049c1.049 0 2.098-.315 2.938-1.049-.315-1.679-1.574-1.888-3.252-1.993h-.42c-.944-.105-1.783-.734-2.203-1.574a2.792 2.792 0 01-.315-1.259l.525-6.819c-2.308-.21-4.616.21-6.714 1.049 0 .21 0 .42.105.629 0 .21.734 4.721.839 5.141.105 1.364-.105 2.728-.734 3.987-.105.21-.105.525-.105.734v.629h7.659l.628.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});