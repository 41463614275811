define("ember-svg-jar/inlined/pui_medicine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medicine</title><path d=\"M15.196 10.093a2.485 2.485 0 00-3.397-.91l-.994.574V7.598c0-.456-.136-.897-.393-1.276L9.187 4.521a1.267 1.267 0 01-.22-.715v-.642a1.08 1.08 0 00.598-.958V1.09A1.09 1.09 0 008.475 0H2.8a1.09 1.09 0 00-1.09 1.09v1.116c0 .422.247.778.598.959v.642c0 .256-.076.503-.22.715L.863 6.323A2.267 2.267 0 00.47 7.598v5.633c0 .934.76 1.694 1.695 1.694h5.084A2.477 2.477 0 009.275 16c.422 0 .85-.107 1.241-.334l3.77-2.177a2.486 2.486 0 00.91-3.396zm-10.999.285a.375.375 0 010-.75h.884v-.883a.375.375 0 01.75 0v.884h.884a.375.375 0 010 .75H5.83v.885a.375.375 0 01-.75 0v-.885h-.883zM1.483 6.743l1.225-1.801c.229-.337.35-.73.35-1.136v-.493h5.161v.493c0 .407.121.801.35 1.137l1.224 1.8c.087.129.153.268.198.413H1.286c.044-.145.11-.284.197-.413zm-.264 6.488v-.332h5.646a2.47 2.47 0 00.019 1.277H2.165a.947.947 0 01-.946-.945zm12.567-.606l-1.571.907-1.411-2.43v-.192l1.493-.862c.226-.131.483-.2.742-.2a1.489 1.489 0 01.747 2.777z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});