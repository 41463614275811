define("ember-svg-jar/inlined/l-bone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 10c.7-.7 1.69 0 2.5 0a2.5 2.5 0 100-5 .5.5 0 01-.5-.5 2.5 2.5 0 10-5 0c0 .81.7 1.8 0 2.5l-7 7c-.7.7-1.69 0-2.5 0a2.5 2.5 0 000 5c.28 0 .5.22.5.5a2.5 2.5 0 105 0c0-.81-.7-1.8 0-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bone",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});