define("ember-svg-jar/inlined/l-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 5l6.3 6.3a2.4 2.4 0 010 3.4L17 19\"/><path d=\"M9.586 5.586A2 2 0 008.172 5H3a1 1 0 00-1 1v5.172a2 2 0 00.586 1.414L8.29 18.29a2.426 2.426 0 003.42 0l3.58-3.58a2.426 2.426 0 000-3.42z\"/><circle cx=\"6.5\" cy=\"9.5\" r=\".5\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-tags",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});