define("ember-svg-jar/inlined/package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.447 3.724l-6-3a1 1 0 00-.894 0l-6 3A1 1 0 001 4.618v6.764a1 1 0 00.553.894l6 3a1 1 0 00.894 0l6-3a1 1 0 00.553-.894V4.618a1 1 0 00-.553-.894zM5.871 5.897l5.343-2.672 2.158 1.079L8 6.943zM8 1.618l2.096 1.048-5.353 2.677-2.115-1.039zM2 5.11l2.25 1.105V9a.5.5 0 001 0V6.706L7.5 7.811v6.321L2 11.382zm6.5 9.022v-6.32L14 5.11v6.272z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});