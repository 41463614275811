define("ember-svg-jar/inlined/pui_boxes_horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0h4v4H0zm3 3zm3-3h4v4H6zm3 3zm3-3h4v4h-4zm1 3l2 .084z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "4",
      "viewBox": "0 0 16 4"
    }
  };
});