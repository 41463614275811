define("ember-svg-jar/inlined/pui_category_defence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.543 22s7.978-4.05 7.978-10.123V4.791L9.543 1.754 1.564 4.791v7.086C1.564 17.951 9.543 22 9.543 22z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "19",
      "height": "23",
      "viewBox": "0 0 19 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});