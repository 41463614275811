define("ember-svg-jar/inlined/pui_harness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.2 10.4h3.6v4.4H6.2z\"/><path d=\"M15.4 11V2c0-.4-.2-.7-.5-.8-.3-.1-.7-.1-1 .2l-2.785 2.689-.402-.402-1.238 1.238.346.345L8 7 6.122 5.216l.291-.291-1.239-1.238-.346.347L2.1 1.4c-.3-.2-.6-.3-1-.2-.3.2-.5.5-.5.9v9H0v3.1h5.5V11h-.4L8 8.3l2.9 2.8h-.4v3.1H16V11h-.6zm-11.5-.1l-.1.1H1.5V2.1l2.725 2.537-.288.288 1.238 1.238.25-.25L7.3 7.6l-3.4 3.3zm10.7.1h-2.3c-.1 0-.2-.1-.2-.1L8.7 7.6l1.816-1.634.197.197 1.238-1.238-.186-.186L14.6 2.1V11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});