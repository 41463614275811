define("ember-svg-jar/inlined/l-bug-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.765 21.522a.5.5 0 01-.765-.424v-8.196a.5.5 0 01.765-.424l5.878 3.674a1 1 0 010 1.696zM14.12 3.88L16 2\"/><path d=\"M18 11a4 4 0 00-4-4h-4a4 4 0 00-4 4v3a6.1 6.1 0 002 4.5\"/><path d=\"M20.97 5c0 2.1-1.6 3.8-3.5 4M3 21c0-2.1 1.7-3.9 3.8-4M6 13H2m4.53-4C4.6 8.8 3 7.1 3 5m5-3l1.88 1.88M9 7.13v-1a3.003 3.003 0 116 0v1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bug-play",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});