define("ember-svg-jar/inlined/pui_scaffold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.685 0a.49.49 0 00-.49.49v2.179h-2.321V.49a.49.49 0 10-.98 0v2.224l-3.786.91V2.151a.49.49 0 10-.98 0v1.71l-2.323.557V2.151a.49.49 0 10-.98 0V15.51a.49.49 0 10.98 0v-3.245h2.321v3.245a.49.49 0 10.98 0V5.302h.034L9.893 4.4v9.45a.49.49 0 10.98 0v-3.245h2.321v3.245a.49.49 0 10.98 0V.49a.487.487 0 00-.489-.49zM5.126 11.284H2.805V8.32h2.321v2.964zm0-3.944H2.805V5.303l.008-.001h2.313V7.34zm8.069 2.283h-2.321V6.66h2.321v2.963zm-2.321-3.944V4.164l2.321-.558v2.073h-2.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});