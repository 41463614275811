define("ember-svg-jar/inlined/l-pocket-knife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 2v1c0 1 2 1 2 2S3 6 3 7s2 1 2 2-2 1-2 2 2 1 2 2m13-7h.01M6 18h.01m14.82-9.17a4 4 0 00-5.66-5.66l-12 12a4 4 0 105.66 5.66z\"/><path d=\"M18 11.66V22a4 4 0 004-4V6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pocket-knife",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});