define("ember-svg-jar/inlined/pui_eyewash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 8.2c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.6-2.6c0-1.4-1.2-2.6-2.6-2.6z\"/><path d=\"M14.1 8.5c-.156 0-.371.109-.581.104a3.795 3.795 0 01-.301-.017c-1.3-.216-2.416-1.12-2.943-2.307a.456.456 0 00-.262-.248A6.038 6.038 0 008 5.7c-3.8 0-6.9 3.5-7.8 4.6-.2.3-.2.8 0 1.1.9 1.1 4 4.6 7.8 4.6s6.9-3.5 7.8-4.6c.3-.4.3-.8 0-1.1-.3-.4-.9-1.1-1.7-1.8zm-6.1 6c-2.1 0-3.7-1.7-3.7-3.7S5.9 7.1 8 7.1s3.7 1.7 3.7 3.7c0 2.1-1.6 3.7-3.7 3.7zM16 4.4c.1 1.4-1 2.6-2.5 2.6h-.1c-1.3-.2-2.2-1.4-2.2-2.6 0-1.1.4-2.1 1.1-3 .3-.5.7-1 1.1-1.4.1-.1.2-.1.3 0 .4.4.8.8 1.1 1.2.8 1.1 1.2 2.2 1.2 3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});