define("@prysmex-engineering/ember-fetch-request/-private/utils/get-header", ["exports", "@ember/array", "@ember/utils"], function (_exports, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getHeader;
  /**
   * Do a case-insensitive lookup of an HTTP header
   *
   * @function getHeader
   * @private
   */
  function getHeader(headers, name) {
    if ((0, _utils.isNone)(headers) || (0, _utils.isNone)(name)) {
      return undefined;
    }
    const matchedKey = (0, _array.A)(Object.keys(headers)).find(key => {
      return key.toLowerCase() === name.toLowerCase();
    });
    return matchedKey ? headers[matchedKey] : undefined;
  }
});