define("ember-svg-jar/inlined/l-replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 4a2 2 0 012-2m0 8a2 2 0 01-2-2m6-6a2 2 0 012 2m0 4a2 2 0 01-2 2M3 7l3 3 3-3\"/><path d=\"M6 10V5a3 3 0 013-3h1\"/><rect width=\"8\" height=\"8\" x=\"2\" y=\"14\" rx=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-replace",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});