define("ember-svg-jar/inlined/pui_question_mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.8 11.2H6.5c-.4 0-.6-.1-.7-.5-.1-.7-.1-1.4.3-2 .5-.9 1.2-1.5 2.1-1.9.3-.2.528-.277 1-.6.5-.3.524-.914.5-1.4-.049-.441-.258-.627-.499-.824-.8-.6-2.301-.376-3.001.424-.3.3-.6.6-.9 1-.3.3-.6.4-1 .1-.6-.5-1.2-1-1.8-1.4-.3-.3-.4-.5-.1-.9.5-.8.993-1.274 1.9-2C5.1.6 6 .2 7 .1c1.8-.3 3.5.2 4.9 1.4 1 .8 1.6 1.7 1.8 3 .2 1.4.082 2.258-1.005 3.404C12.181 8.334 11.5 8.7 11 9s-.539.297-.839.797c-.1.2-.319.708-.361.903-.09.42-.3.6-.6.6-.5-.1-1-.1-1.4-.1z\"/><circle cx=\"7.96\" cy=\"13.8\" r=\"2.217\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});