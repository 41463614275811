define("ember-svg-jar/inlined/pui_category_mining", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.152 20v-4.501l4.435-4.501 4.434 4.5L5.587 20H1.152zM7.804 13.248l5.765-5.851M14.456 1.996l4.434 4.5-.443.45a3.09 3.09 0 01-4.434 0s0 0 0 0a3.183 3.183 0 01-.682-1.031 3.22 3.22 0 01.681-3.47l.444-.45z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});