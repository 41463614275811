define("ember-svg-jar/inlined/l-route-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"6\" cy=\"19\" r=\"3\"/><path d=\"M9 19h8.5c.4 0 .9-.1 1.3-.2M5.2 5.2A3.5 3.53 0 006.5 12H12M2 2l20 20m-1-6.7a3.5 3.5 0 00-3.3-3.3M15 5h-4.3\"/><circle cx=\"18\" cy=\"5\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-route-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});