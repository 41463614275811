define("ember-svg-jar/inlined/l-cctv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.75 12h3.632a1 1 0 01.894 1.447l-2.034 4.069a1 1 0 01-1.708.134l-2.124-2.97m1.696-5.627a1 1 0 01.447 1.341l-3.106 6.211a1 1 0 01-1.342.447L3.61 12.3a2.92 2.92 0 01-1.3-3.91L3.69 5.6a2.92 2.92 0 013.92-1.3zM2 19h3.76a2 2 0 001.8-1.1L9 15m-7 6v-4m5-8h.01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cctv",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});