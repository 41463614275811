define("ember-svg-jar/inlined/l-fish-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 12.47v.03m0-.5v.47m-.475 5.056A6.7 6.7 0 0115 18c-3.56 0-7.56-2.53-8.5-6 .348-1.28 1.114-2.433 2.121-3.38m3.444-2.088A8.8 8.8 0 0115 6c3.56 0 6.06 2.54 7 6-.309 1.14-.786 2.177-1.413 3.058\"/><path d=\"M7 10.67C7 8 5.58 5.97 2.73 5.5c-1 1.5-1 5 .23 6.5-1.24 1.5-1.24 5-.23 6.5C5.58 18.03 7 16 7 13.33m7.48-4.372A9.8 9.8 0 0116 6.07m0 11.86a9.8 9.8 0 01-1.728-3.618\"/><path d=\"M16.01 17.93l-.23 1.4A2 2 0 0113.8 21H9.5a5.96 5.96 0 001.49-3.98M8.53 3h5.27a2 2 0 011.98 1.67l.23 1.4M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-fish-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});