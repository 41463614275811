define("ember-svg-jar/inlined/l-arrows-up-from-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 6l3-3 3 3M7 17V3m7 3l3-3 3 3m-3 11V3M4 21h16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-arrows-up-from-line",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});