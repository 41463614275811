define("ember-svg-jar/inlined/pui_dump_truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dumpTruck</title><circle cx=\"12.913\" cy=\"11.455\" r=\"1.165\"/><path d=\"M15.576 9.025l-.71-.222V7.415a1.334 1.334 0 00-1.281-1.324h-1.949a1.63 1.63 0 00-1.631 1.631v.402h-.508a.477.477 0 00-.477.477v1.324H7.464v-.381h.371a.213.213 0 00.191-.064l2.521-3.813h.253a.753.753 0 00.508-.201l.688-.657 1.059-.318a.246.246 0 00.159-.222v-.656a.233.233 0 00-.233-.233h-1.303a.703.703 0 00-.498.201l-.667.604H2.042a.72.72 0 00-.551.244L.273 5.806a.73.73 0 101.058.985l.339.328v2.806h-.126a1.292 1.292 0 00-1.154.699l-.149.286a.211.211 0 000 .222c.043.07.119.114.201.116h1.36A1.926 1.926 0 013.714 9.52c1.008 0 1.826.774 1.915 1.759l5.37-.032c.104-.97.915-1.728 1.912-1.728s1.808.758 1.912 1.728h.613a.56.56 0 00.561-.561V9.555a.556.556 0 00-.421-.53zm-6.089.9V8.602h.519l.042 1.324h-.561zm4.893-1.503h-1.694a.402.402 0 01-.424-.371v-.466c0-.216.175-.392.392-.392h1.726v1.229z\"/><circle cx=\"3.715\" cy=\"11.455\" r=\"1.165\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});