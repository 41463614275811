define("ember-svg-jar/inlined/ubicacion_de_alarma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#FE0000\" d=\"M0 0h500v500H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M132.019 166.75c-26.935 35.386-33.515 85.93-17.127 131.558 4.756 13.242 13.052 28.055 20.047 35.799l3.518 3.893h15.383c8.461 0 15.384-.274 15.384-.608 0-.335-2.177-3.372-4.837-6.75-24.71-31.372-34.036-64.471-28.605-101.514 3.41-23.251 14.984-49.741 27.255-62.378l4.612-4.75h-32.014l-3.616 4.75zm201.205-4.2c0 .302 1.641 2.214 3.646 4.25 10.517 10.675 21.257 33.538 25.931 55.2 2.191 10.153 2.993 33.6 1.509 44.123-3.165 22.452-13.781 45.958-29.407 65.113-2.573 3.155-4.679 5.967-4.679 6.25 0 .283 7.174.514 15.942.514h15.941l4.398-5.75c13.019-17.023 21.337-37.088 25.387-61.238 2.219-13.227 2.17-35.814-.103-48.512-3.744-20.907-12.66-41.52-24.331-56.25L364.09 162h-15.433c-8.488 0-15.433.247-15.433.55zm-163.966 38.2c-8.667 18.516-12.04 31.739-12.017 47.109.024 15.967 2.663 27.463 10.148 44.202l3.103 6.939h14.366c9.196 0 14.366-.371 14.366-1.031 0-.567-1.754-4.126-3.898-7.91-4.545-8.02-9.585-20.431-11.191-27.559-1.748-7.756-1.265-23.848.979-32.593 2.145-8.362 6.49-18.553 11.112-26.064 1.649-2.68 2.998-5.091 2.998-5.358 0-.267-6.453-.485-14.339-.485h-14.34l-1.287 2.75zm131.966-1.719c0 .567 1.707 4.054 3.794 7.75 8.544 15.13 11.654 26.502 11.544 42.219-.067 9.673-.516 12.851-2.827 20-2.77 8.569-8.174 20.251-11.637 25.154-1.03 1.46-1.874 3.148-1.874 3.75 0 .744 4.66 1.096 14.513 1.096h14.512l2.601-5.75c6.747-14.917 11.374-33.121 11.374-44.75 0-11.762-4.802-30.516-11.458-44.75l-2.689-5.75h-13.926c-8.894 0-13.927.372-13.927 1.031zm-63.714 5.365c-10.039 3.269-20.768 12.702-25.847 22.724-9.408 18.566-6.205 41.298 7.979 56.618 17.975 19.415 46.281 17.851 63.078-3.486 15.196-19.303 13.495-47.423-3.921-64.821-8.905-8.896-15.511-11.808-27.542-12.143-6.062-.168-11.067.235-13.747 1.108zm17.808 33.153c6.783 3.507 9.118 13.873 4.626 20.539-7.529 11.172-22.706 5.498-22.716-8.494-.008-10.299 9.379-16.55 18.09-12.045z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "500",
      "height": "500",
      "viewBox": "0 0 500 500",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});