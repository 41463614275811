define("ember-m3/resolve-attribute-util", ["exports", "@ember/string", "ember-m3/-private", "ember-m3/model", "@ember/array", "ember-m3/managed-array", "ember-m3/utils/schema-types-info", "ember-m3/utils/resolve"], function (_exports, _string, _private, _model, _array, _managedArray, _schemaTypesInfo, _resolve) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resolveRecordArray = resolveRecordArray;
  _exports.resolveValue = resolveValue;
  let EmbeddedInternalModel;
  if (!false /* CUSTOM_MODEL_CLASS */) {
    // TODO: shouldn't need this anymore; this level of indirection for nested recordData isn't useful
    EmbeddedInternalModel = class EmbeddedInternalModel {
      constructor({
        id,
        modelName,
        parentInternalModel,
        parentKey,
        parentIdx
      }) {
        if (false /* CUSTOM_MODEL_CLASS */) {
          //asert we dont need this class anymore
          return;
        }
        this.id = id;
        this.modelName = modelName;
        let recordData = (0, _private.recordDataFor)(parentInternalModel)._getChildRecordData(parentKey, parentIdx, modelName, id, this);
        this._recordData = recordData;
        this.parentInternalModel = parentInternalModel;
        this.record = null;
      }
      getRecord() {
        return this.record;
      }
      createSnapshot() {
        return new _model.EmbeddedSnapshot(this.record);
      }
      changedAttributes() {
        return this._recordData.changedAttributes();
      }
    };
  }

  // takes in a single computedValue returned by schema hooks and resolves it as either
  // a reference or a nestedModel

  function resolveSingleValue(computedValue, key, store, record, recordData, parentIdx) {
    // we received a resolved record and need to transfer it to the new record data
    if (computedValue instanceof _model.EmbeddedMegamorphicModel) {
      // transfer ownership to the new RecordData
      recordData._setChildRecordData(key, parentIdx, (0, _private.recordDataFor)(computedValue));
      return computedValue;
    }
    let valueType = _schemaTypesInfo.schemaTypesInfo.get(computedValue);
    if (valueType === _schemaTypesInfo.REFERENCE) {
      let reference = computedValue;
      let {
        id
      } = reference;
      if (reference.type === null) {
        // for schemas with a global id-space but multiple types, schemas may
        // report a type of null
        if (false /* CUSTOM_MODEL_CLASS */) {
          let rd = store._globalM3RecordDataCache[reference.id];
          return rd ? (0, _resolve.getOrCreateRecordFromRecordData)(rd, store) : null;
        } else {
          let internalModel = store._globalM3Cache[id];
          return internalModel ? internalModel.getRecord() : null;
        }
      } else {
        // respect the user schema's type if provided
        return id !== null && id !== undefined ? store.peekRecord(reference.type, reference.id) : null;
      }
    } else if (valueType === _schemaTypesInfo.NESTED) {
      return createNestedModel(store, record, recordData, key, computedValue, parentIdx);
    } else {
      return computedValue;
    }
  }
  function resolveRecordArray(store, record, key, references) {
    let recordArrayManager = store._recordArrayManager;
    let array = _managedArray.default.create({
      modelName: '-ember-m3',
      store: store,
      manager: recordArrayManager,
      _isAllReference: true,
      key,
      record
    });
    if (false /* CUSTOM_MODEL_CLASS */) {
      let records = (0, _resolve.resolveReferencesWithRecords)(store, references);
      array._setObjects(records, false);
    } else {
      let internalModels = (0, _resolve.resolveReferencesWithInternalModels)(store, references);
      array._setInternalModels(internalModels, false);
    }
    return array;
  }

  /**
   * There are two different type of values we have to worry about:
   * 1. References
   * 2. Nested Models
   *
   * Here is a mapping of input -> output:
   * 1. Single reference -> resolved reference
   * 2. Array of references -> RecordArray of resolved references
   * 3. Single nested model -> EmbeddedMegaMorphicModel
   * 4. Array of nested models -> array of EmbeddedMegaMorphicModel
   */
  function resolveValue(key, value, modelName, store, schema, record, parentIdx) {
    const recordData = (0, _private.recordDataFor)(record);
    const schemaInterface = recordData.schemaInterface;
    let computedValue;
    if (schema.useComputeAttribute()) {
      computedValue = (0, _resolve.computeAttribute)(key, value, modelName, schemaInterface, schema);
    } else {
      // TODO remove this if branch once we remove support for old compute hooks
      // We invoke the old hooks and mark the results with the new apis
      value = schema.transformValue(modelName, key, value);
      let computedReference = (0, _resolve.computeAttributeReference)(key, value, modelName, schemaInterface, schema);
      // First check to see if given value is either a reference or an array of references
      if (computedReference) {
        if (Array.isArray(computedReference)) {
          computedReference.forEach(v => schemaInterface.reference(v));
          computedValue = schemaInterface.managedArray(computedReference);
        } else {
          computedValue = schemaInterface.reference(computedReference);
        }
      } else {
        let computedNested = (0, _resolve.computeNestedModel)(key, value, modelName, schemaInterface, schema);
        computedValue = computedNested;
        if (Array.isArray(computedNested)) {
          computedNested.forEach(v => schemaInterface.nested(v));
          computedValue = schemaInterface.managedArray(computedNested);
        } else if (computedNested !== null && typeof computedNested === 'object') {
          schemaInterface.nested(computedNested);
          // If computeNestedModel returned null, we used to iterate the value array manually
          // and process each element individually
        } else if (Array.isArray(value)) {
          let content = value.map((v, i) => transferOrResolveValue(store, schema, record, recordData, modelName, key, v, i));
          return resolveManagedArray(content, key, value, modelName, store, schema, record);
        }
      }
    }
    let valueType = _schemaTypesInfo.schemaTypesInfo.get(computedValue);
    if (valueType === _schemaTypesInfo.REFERENCE || valueType === _schemaTypesInfo.NESTED) {
      return resolveSingleValue(computedValue, key, store, record, recordData, parentIdx);
    } else if (valueType === _schemaTypesInfo.MANAGED_ARRAY) {
      if (_schemaTypesInfo.schemaTypesInfo.get(computedValue[0]) === _schemaTypesInfo.REFERENCE) {
        return resolveRecordArray(store, record, key, computedValue);
      } else {
        let content = computedValue.map((v, i) => resolveSingleValue(v, key, store, record, recordData, i));
        return resolveManagedArray(content, key, value, modelName, store, schema, record);
      }
    } else if (Array.isArray(computedValue)) {
      return computedValue.map((v, i) => resolveSingleValue(v, key, store, record, recordData, i));
    } else if (computedValue) {
      return computedValue;
    } else {
      return value;
    }
  }
  function resolveManagedArray(content, key, value, modelName, store, schema, record) {
    if (false /* CUSTOM_MODEL_CLASS */) {
      return _managedArray.default.create({
        _objects: (0, _array.A)(content),
        key,
        _value: value,
        modelName,
        store,
        schema,
        model: record,
        record
      });
    } else {
      let array = _managedArray.default.create({
        key,
        _value: value,
        modelName,
        store,
        schema,
        model: record,
        record
      });
      array._setInternalModels(content.map(c => c._internalModel || c), false);
      return array;
    }
  }
  function transferOrResolveValue(store, schema, record, recordData, modelName, key, value, index) {
    if (value instanceof _model.EmbeddedMegamorphicModel) {
      // transfer ownership to the new RecordData
      recordData._setChildRecordData(key, index, (0, _private.recordDataFor)(value));
      return value;
    }
    return resolveValue(key, value, modelName, store, schema, record, index);
  }
  function createNestedModel(store, record, recordData, key, nestedValue, parentIdx = null) {
    if (parentIdx !== null && nestedValue instanceof _model.EmbeddedMegamorphicModel) {
      recordData._setChildRecordData(key, parentIdx, (0, _private.recordDataFor)(nestedValue));
      return nestedValue;
    }
    let modelName, nestedRecordData, internalModel;
    if (false /* CUSTOM_MODEL_CLASS */) {
      // TODO
      // for backwards compat we will still need to dasherize,
      // but it would be good to confirm that this is no longer a requirement
      modelName = nestedValue.type ? (0, _string.dasherize)(nestedValue.type) : null;
      nestedRecordData = recordData._getChildRecordData(key, parentIdx, modelName, nestedValue.id);
    } else {
      internalModel = new EmbeddedInternalModel({
        // nested models with ids is pretty misleading; all they really ought to need is type
        id: nestedValue.id,
        // maintain consistency with internalmodel.modelName, which is normalized
        // internally within ember-data
        modelName: nestedValue.type ? (0, _string.dasherize)(nestedValue.type) : null,
        parentInternalModel: record._internalModel,
        parentKey: key,
        parentIdx
      });
    }
    let nestedModel;
    if (false /* CUSTOM_MODEL_CLASS */) {
      nestedModel = _model.EmbeddedMegamorphicModel.create({
        store,
        _parentModel: record,
        _topModel: record._topModel,
        _recordData: nestedRecordData
      });
    } else {
      nestedModel = _model.EmbeddedMegamorphicModel.create({
        store,
        _parentModel: record,
        _topModel: record._topModel,
        _internalModel: internalModel
      });
      internalModel.record = nestedModel;
      nestedRecordData = (0, _private.recordDataFor)(internalModel);
    }
    if (!recordData.getServerAttr || recordData.getServerAttr(key) !== null && recordData.getServerAttr(key) !== undefined) {
      nestedRecordData.pushData({
        attributes: nestedValue.attributes
      }, false, false, true);
    } else {
      Object.keys(nestedValue.attributes).forEach(key => {
        nestedRecordData.setAttr(key, nestedValue.attributes[key], true);
      });
    }
    return nestedModel;
  }
});