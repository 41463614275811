define("ember-svg-jar/inlined/logo_business_analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#00BFB3\" d=\"M0 22c0 5.522 4.478 10 10 10V12C4.478 12 0 16.478 0 22\"/><path class=\"euiIcon__fillNegative\" d=\"M10 12v10h10c0-5.522-4.478-10-10-10\"/><path fill=\"#F04E98\" d=\"M10 0v9c7.168 0 13 5.832 13 13h9C32 9.85 22.15 0 10 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});