define("ember-svg-jar/inlined/pui_category_financial_services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.735 1.764H3.35c-1.2 0-2.173.988-2.173 2.206v8.824c0 1.218.973 2.206 2.173 2.206h17.386c1.2 0 2.174-.988 2.174-2.206V3.97c0-1.218-.973-2.206-2.174-2.206z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M12.042 10.588c1.2 0 2.174-.988 2.174-2.206s-.973-2.206-2.174-2.206c-1.2 0-2.173.988-2.173 2.206s.973 2.206 2.173 2.206zM5.522 8.382h.011m13.03 0h.01\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "16",
      "viewBox": "0 0 24 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});