define("ember-svg-jar/inlined/pui_exclamation_mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.942 11.904c.732 0 1.347-.535 1.399-1.218l.666-8.693A1.82 1.82 0 009.474.576C8.713-.188 7.303-.193 6.536.568a1.794 1.794 0 00-.544 1.401l.551 8.701c.043.693.658 1.234 1.399 1.234zM8 12.126c-1.142 0-2.071.869-2.071 1.937S6.858 16 8 16c1.143 0 2.071-.869 2.071-1.938 0-1.067-.929-1.936-2.071-1.936z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});