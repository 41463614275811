define("ember-svg-jar/inlined/pui_stroke_s", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 6.18c1.256-1.17 2.566-1.816 4.258-2.425.135-.049 2.377-1.02 2.735-.685.084.078.185.155.247.25.336.516-.437 1.198-.73 1.56-.784.97-1.804 1.644-2.74 2.47-.532.47-.92.955-1.335 1.51-.285.38-.697.843-.697 1.33 0 .337.908-.22 1.024-.285.828-.463 1.582-1.032 2.397-1.515.523-.31 1.905-.932 2.553-.63.76.354-.19 1.86-.494 2.2-.637.713-1.247 1.226-1.324 2.205-.014.168-.1.688.016.835\" stroke=\"currentColor\" stroke-width=\".5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "fill-opacity": "0",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});