define("ember-svg-jar/inlined/pui_pallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pallet</title><path d=\"M8.6 11.595L0 6.495v2.1c0 .1 0 .1.1.2l1 .6c.1.1.3.1.4 0l.8-.4 1 .6v1c0 .1 0 .1.1.2l1.2.7c.1.1.3.1.4 0l.8-.4 1.4.8v1c0 .1 0 .1.1.2l1.2.7c.1.1.3.1.4 0l1.8-1.1c.1 0 .1-.1.1-.2V10.795l1.9-1.1 1.2.7c.1.1.3.1.4 0l1.7-1v-2.3l-7.4 4.5z\"/><path d=\"M9.268 3.179l-1.66-.976c-.098-.098-.293-.098-.39 0L.287 6.205l1.757 1.074 7.224-4.1zM8.062 8.868l-.371-.278 2.412-1.391.464.278 2.504-1.391-.649-.371V5.251L5.465 9.146l1.02.649 1.577-.927zM15.613 6.889l-1.66-1.074h-.195l-7.224 4.1 1.952 1.171 7.127-4.197zM4.906 7.051l-.477-.191 2.477-1.429.476.286 2.573-1.524-.667-.381v-.381L2.333 7.337l1.048.666zM10.732 4.058l-7.224 4.1 1.562.976 7.224-4.1-1.562-.976z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});