define("ember-svg-jar/inlined/l-bell-electric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.8 4A6.3 8.7 0 0120 9M9 9h.01\"/><circle cx=\"9\" cy=\"9\" r=\"7\"/><rect width=\"10\" height=\"6\" x=\"4\" y=\"16\" rx=\"2\"/><path d=\"M14 19c3 0 4.6-1.6 4.6-1.6\"/><circle cx=\"20\" cy=\"16\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bell-electric",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});