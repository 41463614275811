define("ember-svg-jar/inlined/pui_category_publishing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.98 1.52h5.412c.957 0 1.875.386 2.551 1.073A3.69 3.69 0 0111 5.183V18c0-.728-.285-1.427-.792-1.942a2.686 2.686 0 00-1.914-.805H1.98V1.52z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M20.02 1.52h-5.412c-.957 0-1.875.386-2.551 1.073A3.69 3.69 0 0011 5.183V18c0-.728.285-1.427.793-1.942a2.686 2.686 0 011.913-.805h6.314V1.52z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "19",
      "viewBox": "0 0 22 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});