define("ember-svg-jar/inlined/pui_plastic_barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.945 13.317v-1.789c.298-.298.398-.696.199-1.093l-.099.099-.099.099c-1.391 1.093-4.87 1.093-4.87 1.093-3.379-.099-4.671-.795-5.068-1.093l-.199-.199c-.199.398-.099.894.199 1.093v1.789c-.199.199-.298.398-.298.696C2.709 15.106 5.094 16 7.976 16s5.267-.894 5.267-1.988c0-.298-.099-.596-.298-.695z\"/><path d=\"M3.007 10.137c1.59 1.292 5.168 1.193 5.168 1.193 3.677-.099 4.671-.994 4.87-1.193V7.155c.298-.298.398-.696.199-1.093-1.194 1.292-5.069 1.292-5.069 1.292-4.87-.199-5.466-1.391-5.466-1.391-.1.397-.1.894.298 1.093v3.081z\"/><path d=\"M13.243 1.988C13.243.894 10.858 0 7.976 0S2.709.894 2.709 1.988c0 .298.099.497.298.696v3.081c1.59 1.292 5.168 1.193 5.168 1.193 3.677-.099 4.671-.994 4.87-1.193v-3.18c-.001-.001.198-.399.198-.597zM7.976 3.379c-2.683 0-4.87-.696-4.87-1.491S5.292.298 7.976.298s4.87.696 4.87 1.491-2.187 1.59-4.87 1.59z\"/><ellipse cx=\"4.995\" cy=\"1.789\" rx=\"1.093\" ry=\".497\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});