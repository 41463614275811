define("ember-svg-jar/inlined/pui_mechanical_maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dangerousMachinery</title><path d=\"M15.407 6.33l-2.15-.514 1.213-1.78a.643.643 0 00-.077-.817l-1.545-1.545a.643.643 0 00-.791-.093l-1.883 1.157L9.772.623A.642.642 0 009.141.1H6.956a.643.643 0 00-.625.493l-.514 2.15-1.78-1.213a.645.645 0 00-.817.077L1.675 3.152a.643.643 0 00-.093.791l1.157 1.883-2.116.402a.642.642 0 00-.523.631v2.185c0 .297.204.556.494.625l2.15.514 1.639-.973C4.3 8.887 4.3 8.577 4.3 8.3c0-1.263.721-2.349 1.765-2.904A3.23 3.23 0 018 4.746 3.254 3.254 0 0111.254 8c0 .988-.45 1.863-1.146 2.46A3.418 3.418 0 017.7 11.7c-.277 0-.587 0-.911-.082l-.963 1.643.401 2.116a.644.644 0 00.632.523h2.185a.643.643 0 00.625-.494l.514-2.15 1.78 1.213a.643.643 0 00.817-.077l1.545-1.545a.643.643 0 00.093-.791l-1.157-1.883 2.116-.401a.642.642 0 00.523-.631V6.956a.643.643 0 00-.493-.626z\"/><path d=\"M7.6 8.4c-.3-.3-.3-.8 0-1.1l1-1c.2-.2.1-.6-.2-.7s-.5-.1-.8-.1c-1.5 0-2.8 1.2-2.8 2.8 0 .3 0 .6.1.9 0 .1 0 .2-.1.3L.9 13.4c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l3.9-3.9c.1-.1.2-.1.3-.1.3.1.6.1.9.1 1.5-.1 2.6-1.3 2.7-2.7 0-.3 0-.5-.1-.8s-.5-.4-.7-.2l-.9.9c-.3.3-.8.3-1.1 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});