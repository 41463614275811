define("ember-svg-jar/inlined/pui_graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.143 5.471c.104 0 .104 0 0 0 .103 0 1.135-.103 2.581-.619 1.342-.516 3.303-1.445 5.161-3.2v.723c0 .31.206.516.516.516.31 0 .516-.206.516-.516V.516c0-.31-.206-.516-.516-.516h-1.858c-.31 0-.516.206-.516.516s.206.516.516.516h.723c-1.755 1.548-3.51 2.477-4.748 2.89-1.446.517-2.375.62-2.375.62-.31 0-.413.206-.413.516 0 .207.207.413.413.413zM9.211 7.742h-2.29a.61.61 0 00-.61.61v5.687h3.51V8.352a.61.61 0 00-.61-.61zM4.419 6.916h-2.29a.61.61 0 00-.61.61v6.513h3.51V7.526a.61.61 0 00-.61-.61zM13.753 4.129h-2.29a.61.61 0 00-.61.61v9.3h3.51v-9.3a.61.61 0 00-.61-.61zM14.727 16H1.273a.516.516 0 110-1.032h13.454a.516.516 0 110 1.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});