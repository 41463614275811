define("ember-svg-jar/inlined/pui_forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0C3.7 0 .2 3.4.2 8.2.2 12.6 3.7 16 8 16s7.8-3.4 7.8-8.1C15.9 3.4 12.5 0 8 0zm0 13.8c-3.1 0-5.7-2.5-5.7-5.7 0-3.5 2.5-6 5.7-6 3.3 0 5.7 2.5 5.7 5.7 0 3.6-2.6 6-5.7 6z\"/><path d=\"M11.8 9.6H4.2c-.1 0-.2-.1-.2-.3V6.7c0-.2.1-.3.3-.3h7.5c.2 0 .3.1.3.3v2.6c-.1.2-.2.3-.3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});