define("ember-svg-jar/inlined/pui_outbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M160.914 98.834l53.29-53.812v193.306c0 5.771 4.678 10.449 10.449 10.449s10.449-4.678 10.449-10.449V45.021l53.29 53.812a11.496 11.496 0 0014.629 0 10.971 10.971 0 000-15.151l-71.053-71.576a10.972 10.972 0 00-14.629 0l-71.053 71.576a10.971 10.971 0 000 15.151 10.97 10.97 0 0014.628.001z\"/><path d=\"M447.739 255.568L388.702 128.09a10.97 10.97 0 00-9.404-6.792h-50.155c-5.771 0-10.449 4.678-10.449 10.449s4.678 10.449 10.449 10.449h43.363l48.588 109.714h-59.559c-27.004-.133-51.563 15.625-62.694 40.229a48.588 48.588 0 01-43.886 27.69h-60.604a48.59 48.59 0 01-43.886-27.69c-11.131-24.604-35.69-40.362-62.694-40.229H29.257l57.469-109.714h33.437c5.771 0 10.449-4.678 10.449-10.449s-4.678-10.449-10.449-10.449H80.457a11.494 11.494 0 00-9.404 6.269L2.09 254.523A10.975 10.975 0 000 259.747V390.36c0 28.735 25.078 49.633 53.812 49.633h341.682c28.735 0 53.812-20.898 53.812-49.633V259.748a13.592 13.592 0 00-1.567-4.18zM428.408 390.36c0 17.241-15.673 28.735-32.914 28.735H53.812c-17.241 0-32.914-11.494-32.914-28.735V272.809h66.873a48.59 48.59 0 0143.886 27.69c11.131 24.604 35.69 40.362 62.694 40.229h60.604c27.004.133 51.563-15.625 62.694-40.229a48.588 48.588 0 0143.886-27.69h66.873V390.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 449.306 449.306"
    }
  };
});