define("ember-svg-jar/inlined/l-armchair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 9V6a2 2 0 00-2-2H7a2 2 0 00-2 2v3\"/><path d=\"M3 16a2 2 0 002 2h14a2 2 0 002-2v-5a2 2 0 00-4 0v1.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V11a2 2 0 00-4 0zm2 2v2m14-2v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-armchair",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});