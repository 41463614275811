define("ember-svg-jar/inlined/l-calendar-cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.2 16.9l-.9-.4m.9 2.6l-.9.4M16 2v4m.9 9.2l-.4-.9m.4 6.5l-.4.9m3-7.4l-.4.9m.4 6.5l-.4-.9M21 10.5V6a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h6m10.7-5.5l-.9.4m.9 2.6l-.9-.4M3 10h18M8 2v4\"/><circle cx=\"18\" cy=\"18\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-calendar-cog",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});