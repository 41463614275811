define("ember-svg-jar/inlined/l-house-plug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 12V8.964M14 12V8.964M15 12a1 1 0 011 1v2a2 2 0 01-2 2h-4a2 2 0 01-2-2v-2a1 1 0 011-1z\"/><path d=\"M8.5 21H5a2 2 0 01-2-2v-9a2 2 0 01.709-1.528l7-5.999a2 2 0 012.582 0l7 5.999A2 2 0 0121 10v9a2 2 0 01-2 2h-5a2 2 0 01-2-2v-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-house-plug",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});