define("ember-svg-jar/inlined/l-house-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.22 2.416a2 2 0 00-2.511.057l-7 5.999A2 2 0 003 10v9a2 2 0 002 2h14a2 2 0 002-2v-7.354\"/><path d=\"M15 21v-8a1 1 0 00-1-1h-4a1 1 0 00-1 1v8m6-15h6m-3-3v6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-house-plus",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});