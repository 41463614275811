define("ember-svg-jar/inlined/l-lamp-ceiling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2v5M6 7h12l4 9H2zm3.17 9a3 3 0 105.66 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-lamp-ceiling",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});