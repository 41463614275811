define("ember-svg-jar/inlined/ubicacion_de_un_hidrante", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_UN_HIDRANTE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#E11212\" d=\"M0 0h50v50H0z\"/><path d=\"M10.667 40.385H8L12 45l4-4.615h-2.667V33h-2.666v7.385z\" fill=\"#FFF\"/><path d=\"M35.813 29.252h.443V9.207c0-1.134 1.155-2.089 2.578-2.089h.088c.087 0 .174 0 .268.035 1.337.104 2.31.99 2.31 2.054v20.045h.443c.581 0 1.025.353 1.025.816 0 .463-.487.816-1.025.816h-.842l-.581 7.297c-.044.463-.53.816-1.112.816h-1.104c-.581 0-1.068-.353-1.111-.816l-.53-7.262h-.843c-.581 0-1.024-.388-1.024-.816-.051-.498.435-.851 1.017-.851zm1.38 2.69h3.377l.044-.67h-3.465l.044.67zm-9.603-6.729c-.13 1.169.937 2.193 2.448 2.303 1.467.104 2.753-.746 2.89-1.95l1.78-16.22c.131-1.169-.937-2.193-2.447-2.303-1.468-.104-2.754.747-2.892 1.95l-1.78 16.22zm-12.095 2.268c1.468.104 2.753-.746 2.891-1.95l1.78-16.22c.13-1.168-.98-2.193-2.448-2.303-1.467-.104-2.753.782-2.89 1.95l-1.78 16.22c-.131 1.204.936 2.2 2.447 2.303zm4.845-2.268c-.13 1.169.937 2.193 2.448 2.303 1.468.104 2.753-.746 2.891-1.95l1.78-16.22c.13-1.169-.937-2.193-2.448-2.303-1.467-.104-2.753.747-2.89 1.95l-1.78 16.22z\" fill-rule=\"nonzero\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#e11212"
    }
  };
});