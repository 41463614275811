define("ember-svg-jar/inlined/l-trees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 10v.2A3 3 0 018.9 16H5a3 3 0 01-1-5.8V10a3 3 0 016 0m-3 6v6m6-3v3\"/><path d=\"M12 19h8.3a1 1 0 00.7-1.7L18 14h.3a1 1 0 00.7-1.7L16 9h.2a1 1 0 00.8-1.7L13 3l-1.4 1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-trees",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});