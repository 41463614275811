define("ember-svg-jar/inlined/l-dumbbell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.4 14.4L9.6 9.6m9.057 11.885a2 2 0 11-2.829-2.828l-1.767 1.768a2 2 0 11-2.829-2.829l6.364-6.364a2 2 0 112.829 2.829l-1.768 1.767a2 2 0 112.828 2.829zm2.843.015l-1.4-1.4M3.9 3.9L2.5 2.5m3.904 10.268a2 2 0 11-2.829-2.829l1.768-1.767a2 2 0 11-2.828-2.829l2.828-2.828a2 2 0 112.829 2.828l1.767-1.768a2 2 0 112.829 2.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-dumbbell",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});