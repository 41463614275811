define("ember-svg-jar/inlined/pui_emergency_kit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.38 3.747h-3.241V2.228c0-.911-.709-1.519-1.62-1.519H6.38c-.911 0-1.62.709-1.62 1.519v1.519H1.62C.709 3.747 0 4.456 0 5.266v8.506c0 .911.709 1.519 1.62 1.519h12.76c.911 0 1.62-.709 1.62-1.519V5.266c0-.81-.709-1.519-1.62-1.519zm-4.76 0H6.38V2.228h3.24v1.519zm1.621 6.38H8.81v2.329H7.19v-2.329H4.76V8.608h2.43v-2.33h1.62v2.329h2.43l.001 1.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});