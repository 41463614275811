define("ember-svg-jar/inlined/marker_15", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marker-15.svg</title><path fill=\"none\" d=\"M0 0h19v19H0z\"/><path d=\"M9.5 2C7.068 2 4.23 3.486 4.23 7.27c0 2.568 4.054 8.244 5.27 9.73 1.081-1.486 5.27-7.027 5.27-9.73C14.77 3.487 11.932 2 9.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 19",
      "height": "19",
      "width": "19"
    }
  };
});