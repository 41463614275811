define("ember-svg-jar/inlined/precaucion_sustancia_toxica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_SUSTANCIA_TOXICA</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212zm21.144-9.447l-.651 2.08h-5.051l-.644-2.08a5.772 5.772 0 01-2.24-4.588 5.658 5.658 0 015.411-5.73 5.658 5.658 0 015.416 5.726 5.772 5.772 0 01-2.24 4.588v.004zm-3.175-2.078l-.89 1.975h1.78l-.89-1.975zm2.524-2.77a1.628 1.628 0 00-1.626 1.622c0 .896.728 1.622 1.626 1.622.898 0 1.626-.726 1.626-1.622 0-.896-.728-1.623-1.626-1.623zm-5.04 0c-.898 0-1.626.726-1.626 1.622 0 .896.728 1.622 1.626 1.622.898 0 1.626-.726 1.626-1.622 0-.896-.728-1.623-1.626-1.623zm-.144 7.465h5.324l.702-1.307h.482l-.26 2.324-1.918 2.459h-3.336l-1.92-2.46-.262-2.323h.486l.702 1.307zm-1.644-.83l-2.748-1.806c-.133-.088-.552-.216-.648-.088a1.216 1.216 0 01-2.176-.85c.053-.54.46-.978.995-1.073a1.21 1.21 0 01.615-1.32 1.216 1.216 0 011.441.222c.378.386.455.974.188 1.443-.084.14.195.477.331.565l2.002 1.333v1.574zm8.612 0v-1.574l2.031-1.336c.136-.088.416-.426.331-.566a1.21 1.21 0 01.172-1.455 1.216 1.216 0 011.452-.225c.48.26.727.805.605 1.335a1.211 1.211 0 01.23 2.32 1.216 1.216 0 01-1.41-.396c-.096-.129-.515 0-.648.088l-2.763 1.81zm-8.612.852v1.574l-2.002 1.333c-.136.088-.415.426-.33.565a1.21 1.21 0 01-.189 1.443 1.216 1.216 0 01-1.441.221 1.21 1.21 0 01-.615-1.319 1.211 1.211 0 01-.23-2.32 1.216 1.216 0 011.411.397c.096.128.515 0 .648-.088l2.748-1.806zm8.612 0l2.763 1.81c.133.087.552.216.648.087a1.216 1.216 0 012.176.85c-.054.54-.46.979-.996 1.074a1.21 1.21 0 01-.605 1.335 1.216 1.216 0 01-1.452-.225 1.21 1.21 0 01-.172-1.455c.085-.14-.195-.477-.331-.566l-2.03-1.336v-1.574z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});