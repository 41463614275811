define("ember-svg-jar/inlined/l-unplug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 5l3-3M2 22l3-3m1.3 1.3a2.4 2.4 0 003.4 0L12 18l-6-6-2.3 2.3a2.4 2.4 0 000 3.4zm1.2-6.8L10 11m.5 5.5L13 14m-1-8l6 6 2.3-2.3a2.4 2.4 0 000-3.4l-2.6-2.6a2.4 2.4 0 00-3.4 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-unplug",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});