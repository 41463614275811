define("ember-svg-jar/inlined/pui_tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.61 12.004v3.097H3.814V16h8.592v-.899H8.509v-3.097H7.61z\"/><path d=\"M5.912 8.407l.599-.599L7.61 8.907V6.909L5.912 5.21l.699-.699L7.71 5.61V4.111h.799v3.097L9.608 6.11l.599.599-1.698 1.698v3.597h3.696a2.579 2.579 0 002.597-2.597c0-.599-.2-1.199-.599-1.698-.2-.3-.599-.599-.899-.699.1-.3.1-.699.1-1.099 0-.799-.3-1.698-.799-2.298s-1.099-.999-1.798-1.199c-.1-.599-.4-1.199-.799-1.598-.5-.5-1.199-.899-1.898-.799-.799 0-1.499.3-1.998.799-.4.4-.699.999-.799 1.598-.699.2-1.399.599-1.798 1.199-.6.598-.9 1.497-.9 2.297 0 .4 0 .699.1 1.099-.3.1-.699.4-.899.699-.4.4-.699 1.099-.599 1.698a2.579 2.579 0 002.597 2.597H7.61v-1.898L5.912 8.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});