define("ember-svg-jar/inlined/l-cooking-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 12h20m-2 0v8a2 2 0 01-2 2H6a2 2 0 01-2-2v-8m0-4l16-4M8.86 6.78l-.45-1.81a2 2 0 011.45-2.43l1.94-.48a2 2 0 012.43 1.46l.45 1.8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cooking-pot",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});