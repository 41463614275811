define("ember-svg-jar/inlined/pui_leak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.498 14.392c0 .915-.767 1.651-1.696 1.606-.902-.044-1.575-.89-1.529-1.788.082-1.599 1.234-2.625 1.537-2.869a.117.117 0 01.148.001c.321.253 1.54 1.33 1.54 3.05z\"/><path d=\"M15.474 5.138h-1.358a.525.525 0 00-.526.524v.298h-.405c-.072-.438-.436-.778-.896-.778s-.824.34-.896.778H5.98V2.402h.255a.525.525 0 00.526-.524V.524A.526.526 0 006.234 0H.526A.526.526 0 000 .524v1.353c0 .29.236.524.526.524h.342v7.564c0 .6.488 1.086 1.09 1.086h5.426l.323-.323-.875-.875a.5.5 0 01-.001-.708l.578-.577.707.707-.223.224.875.875a.5.5 0 01.146.354c0 .12-.048.233-.126.323h2.604a.912.912 0 00.896.778c.46 0 .824-.34.896-.778h.405v.297c0 .29.236.524.526.524h1.358a.525.525 0 00.526-.524V5.663a.524.524 0 00-.525-.525z\"/><title>liquidLeak</title>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});