define("ember-svg-jar/inlined/l-podcast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.85 18.58a9 9 0 10-9.7 0M8 14a5 5 0 118 0\"/><circle cx=\"12\" cy=\"11\" r=\"1\"/><path d=\"M13 17a1 1 0 10-2 0l.5 4.5a.5.5 0 101 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-podcast",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});