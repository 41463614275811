define("ember-svg-jar/inlined/l-git-branch-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 3v12m12-6a3 3 0 100-6 3 3 0 000 6M6 21a3 3 0 100-6 3 3 0 000 6\"/><path d=\"M15 6a9 9 0 00-9 9m12 0v6m3-3h-6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-git-branch-plus",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});