define("ember-svg-jar/inlined/pui_plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.472 2.868c-1.208.201-1.907 1.139-2.008 2.346 0 0 4.725-1.451 4.725-1.239 0 .212-4.624 1.465-4.725 1.566-1.409.905-2.319 2.66-2.621 4.371 0-1.509-.503-3.019-1.509-4.126-.101-.201-3.736-3.406-3.623-3.522.113-.116 3.824 3.22 3.925 3.22.403-1.208 0-2.516-1.107-3.22C3.019 1.057.906 1.66 0 1.157c.906 1.409.704 2.314 2.214 3.824.704.805 1.711 1.208 2.717 1.006 1.308 1.308 1.509 3.22 1.509 5.031a.768.768 0 00-.503-.201c-.503 0-.906.302-1.107.704-.805-.302-1.61.101-1.912.906 0 .101-.101.302-.101.403-.604-.201-1.208.403-1.208 1.006 0 .503.403 1.006 1.006 1.006h8.553s.906-.503.906-1.006c0-.604-.503-1.006-1.006-1.006h-.201s-.101-1.811-1.61-1.107c-.101-.503-.302-.906-1.208-.906-.302 0-.704.101-.906.302.304-1.911.907-3.924 2.517-4.93a2.602 2.602 0 002.818.201c2.013-.805 2.214-1.711 3.522-2.717-.906 0-2.717-1.308-4.528-.805z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});