define("ember-svg-jar/inlined/l-package-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 9h18v10a2 2 0 01-2 2H5a2 2 0 01-2-2zm0 0l2.45-4.9A2 2 0 017.24 3h9.52a2 2 0 011.8 1.1L21 9m-9-6v6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-package-2",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});