define("ember-svg-jar/inlined/pui_disability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.787 3.132a1.566 1.566 0 10-.001-3.133 1.566 1.566 0 00.001 3.133zM10.207 12.006a3.466 3.466 0 01-3.325 2.506 3.466 3.466 0 01-3.463-3.462c0-1.413.853-2.63 2.069-3.167V6.414a4.848 4.848 0 00-3.449 4.635 4.847 4.847 0 004.842 4.842 4.844 4.844 0 004.392-2.816l-.547-1.074-.519.005z\"/><path d=\"M14.769 13.865l-2.098-4.124a.918.918 0 00-.819-.503H9.166v-.613h2.312a.676.676 0 00.55-.295.676.676 0 00.139-.395.69.69 0 00-.69-.69H9.166V5.168c0-.571-.424-1.379-1.379-1.379a1.38 1.38 0 00-1.379 1.379V9.67c0 .777.63 1.407 1.407 1.407h3.474l1.842 3.621a.919.919 0 101.638-.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});