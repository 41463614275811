define("ember-svg-jar/inlined/tokenModule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 2l5.196 3v.178l-.866.468V5.5L8 3 3.67 5.5v5L8 13l4.33-2.5V5.77l.866-.474V11L8 14l-5.196-3V5L8 2z\"/><path d=\"M5.243 4.429L9.597 7.04 8 7.928 3.743 5.563a.5.5 0 10-.486.874L7.5 8.794V13.5h1V8.794l4.243-2.357a.508.508 0 00.06-.04l.392-.202V5.047l-.917.505a.573.573 0 00-.02.01l-.106.06-.191.105-1.355.753-4.849-2.909-.514.858z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});