define("ember-svg-jar/inlined/pui_oil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>oil</title><path d=\"M14.273 2.981L11.391 1.59c-.099-.099-.199 0-.298.099l-.397.894-.199-.099v-.795C10.596.696 9.801 0 8.907 0H3.342c-.895 0-1.69.696-1.69 1.689V14.31c0 .895.696 1.69 1.69 1.69h9.242c.894 0 1.689-.795 1.689-1.689V4.472c0-.099 0-.199-.099-.199l-.298-.099.398-.994c.099-.099.099-.199-.001-.199zM3.54 2.087c0-.099.099-.199.199-.199H8.41c.099 0 .199.099.199.199v1.391H3.54V2.087zm4.092 10.131c-.947-.046-1.654-.938-1.605-1.885.087-1.685 1.295-2.766 1.614-3.023a.124.124 0 01.156.001c.335.266 1.615 1.402 1.615 3.214 0 .965-.805 1.741-1.78 1.693z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});