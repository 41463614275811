define("ember-svg-jar/inlined/pui_pollution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution</title><path d=\"M7.143 12.043c.206-.617.309-1.132.515-1.749.206-.72.412-1.44.515-2.264.103-.617.103-1.338.103-1.955 0-.348 0-.304.294-.309l2.558-.004c.444.023.444-.055.444.211 0 1.132 0 2.264.309 3.292.309 1.132.618 2.264 1.03 3.395l.309.926c0 .103.103.206.206.206h1.03c.103 0 .206 0 .309.103l1.133 1.132c.102.103.102.206.102.309v.304c0 .206-.103.36-.206.36H.245a.24.24 0 01-.238-.297c.182-.754.363-1.185.545-1.911.412-1.646.824-3.19 1.236-4.836.206-.72.206-1.44.206-2.058V5.56c0-.103 0-.206.206-.206h3.605c.103 0 .206 0 .206.206v1.029c0 1.029.103 2.058.412 3.087.206.72.412 1.543.618 2.264-.001-.103.102 0 .102.103z\"/><path d=\"M9.1 4.018v-.823c.103-.103 0-.103 0-.206-.103 0-.309-.103-.412-.206s-.103 0-.206.103c-.103.412-.309.617-.515.823-.412.206-.824.206-1.133-.103-.103-.103-.721-.617-1.133-.514-.309.206-.412.411-.515.823v.412c0 .103 0 .206-.103.206H2.92c-.103 0-.103-.103-.103-.206V3.71c0-.309 0-.309-.206-.309-.823-.206-1.235-.721-1.338-1.441C1.273.828 2.406.211 3.333.725c.103.103.206.103.206-.103.412-.617 1.339-.926 1.957-.206.515.514 1.03.72 1.751.72.206 0 .412.103.618.206.102.104.205.104.205-.102.206-.617.824-1.029 1.442-.926.618 0 1.133.514 1.236 1.132.103.309 0 .72-.206 1.029-.103.206-.103.309 0 .412.206.103.309.206.515.206.309 0 .309-.103.309-.309 0-.206 0-.309.103-.514.103-.206.206-.412.515-.514.206 0 .206-.103.309-.309.103-.72.721-1.132 1.442-1.132.206 0 .515.103.721.206.103.103.103.103.206 0 .103-.412.412-.514.721-.514.412.103.591 1.213.591 2.028s.236 2.808-.797 2.602c-.103 0-.203 0-.206.103-.049 1.904-2.301 1.561-2.678.412 0-.206-.103-.309-.103-.514 0-.412-.309-.617-.618-.617-.412 0-.721.309-.721.617v.103c0 .309 0 .309-.309.309H9.409c-.103 0-.206 0-.206-.206-.103-.312-.103-.518-.103-.826z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});