define("ember-svg-jar/inlined/l-chart-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 3v16a2 2 0 002 2h16\"/><path d=\"M7 11.207a.5.5 0 01.146-.353l2-2a.5.5 0 01.708 0l3.292 3.292a.5.5 0 00.708 0l4.292-4.292a.5.5 0 01.854.353V16a1 1 0 01-1 1H8a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-chart-area",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});