define("ember-svg-jar/inlined/map_builder_rotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>58</title><path d=\"M13.692 45.745L5.122 35.42a.529.529 0 01.408-.866h17.138a.529.529 0 01.407.866l-8.57 10.326a.529.529 0 01-.813 0zM95 34.48V86.52A8.479 8.479 0 0186.521 95H40.48A8.479 8.479 0 0132 86.521V34.48A8.479 8.479 0 0140.479 26H86.52A8.479 8.479 0 0195 34.479zm-6 0A2.482 2.482 0 0086.521 32H40.478A2.482 2.482 0 0038 34.479v52.043A2.482 2.482 0 0040.479 89h46.043A2.482 2.482 0 0089 86.521zM40.553 5A29.586 29.586 0 0011 34.553h6A23.579 23.579 0 0140.553 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 100 100"
    }
  };
});