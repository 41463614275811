define("ember-svg-jar/inlined/pui_category_emergency_services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.152 11.603c0-1.378.54-2.7 1.5-3.676a5.083 5.083 0 013.621-1.522v0c1.359 0 2.661.547 3.622 1.522.96.975 1.5 2.298 1.5 3.676v6.238H6.152v-6.238zM4.104 19.92c0-.55.215-1.08.6-1.47.384-.39.905-.609 1.448-.609h10.243c.543 0 1.064.22 1.448.61.384.39.6.918.6 1.47V22H4.103v-2.08zM20.492 11.603h1.024M17.931 3.806l-.512.52M1.03 11.603h1.025M11.273 1.206v1.04M4.03 4.252l.725.735M11.273 11.603v6.238\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});