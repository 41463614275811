define("ember-svg-jar/inlined/pui_mineral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mineral</title><path d=\"M16 10.3l-1.1-4c0-.1-.1-.2-.2-.2h-.2l-3.2 2.4-.1.1-.7 1.5.9-8.1v-.1s0-.1-.1-.1L8.1 0h-.3L4.7 2.2s-.1 0-.1.1v.1l.5 5.8-2.2-1.8h-.3c-.1 0-.1.1-.2.1l-2.4 4v.2l1.7 4.7h-.6v.6h11.7v-.5h-.5l3.7-4.9v-.1-.2zM2.8 6.9l2.8 2.4L3 11.2l-.2-4.3zm2.9 8.7h-1l-1.6-4.1 2.6-1.9v6zM5 2.4l3-2 3 1.7-1.9 2H6.5L5 2.4zm3.1 13.2h-.8L6.5 4.4h2.4l-.8 11.2zm2.5 0H8.5l1.7-3.7 1.3-2.8 1.2 2.6-2.1 3.9zm2.2-4.3l-1.2-2.5 2.8-2.1-1.6 4.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});