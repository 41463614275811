define("ember-svg-jar/inlined/riesgo_por_radiacion_no_ionizante", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_POR_RADIACION_NO_IONIZANTE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><g transform=\"translate(18 25)\" fill-rule=\"nonzero\" fill=\"#000\"><path d=\"M2.821.738L2.084 0c-2.747 2.75-2.747 7.226 0 9.976l.737-.738a6.023 6.023 0 010-8.5zM12.048 0l-.737.738a6.023 6.023 0 010 8.5l.737.738c2.747-2.75 2.747-7.226 0-9.976z\"/><path d=\"M3.089 4.988c0-1.064.413-2.064 1.165-2.816l-.737-.738a4.996 4.996 0 00-1.47 3.554c0 1.342.522 2.604 1.47 3.554l.737-.738a3.959 3.959 0 01-1.165-2.816zM10.615 1.434l-.736.738a3.99 3.99 0 010 5.632l.736.738a5.036 5.036 0 000-7.108z\"/><path d=\"M5.052 7.005l.737-.738a1.812 1.812 0 010-2.558l-.737-.738a2.858 2.858 0 000 4.034zM9.08 7.005a2.858 2.858 0 000-4.034l-.737.738a1.812 1.812 0 010 2.558l.737.738z\"/><ellipse cx=\"7.066\" cy=\"4.988\" rx=\"1.079\" ry=\"1.08\"/><path d=\"M4.224 14.567h5.685l-2.843-7.94z\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});