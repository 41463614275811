define("ember-svg-jar/inlined/tokenKeyword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.33 10.28c-.12.04-.29.07-.42.07-.23 0-.43-.08-.53-.3l-.63-1.34 2.32-2.81H9.3L7.76 7.93h-.09L8.22 4H6.59l-1.05 7.5h1.63l.27-1.94h.1l.43 1.12c.27.71.74.92 1.33.92.23 0 .6-.04.86-.11l.17-1.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});