define("ember-svg-jar/inlined/pui_paint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.618 15.473c.388-.395.646-.922.905-1.449.353-.84.811-1.616 1.573-1.947a.372.372 0 01.405.092l1.378 1.404c.129.131.258.264.517.264.129 0 .388-.131.517-.264l2.196-2.238a.61.61 0 00.16-.541l4.521-3.196a.498.498 0 00.205-.359.474.474 0 00-.134-.39l-4.197-4.416a.479.479 0 00-.556-.103l-.915.442.416-.796a.5.5 0 00-.093-.59L9.214.196a.75.75 0 00-1.116.115L5.094 4.444s-.31-.146-.457.067L2.645 7.4a.985.985 0 00.092 1.227l.941 1.002c.104.11.144.279.08.418-.347.755-1.129 1.258-1.819 1.609-.517.264-.905.527-1.422.922-.388.262-.517.789-.517 1.184 0 .527.238 1.11.783 1.666.545.555 1.156.572 1.672.572s.904-.264 1.163-.527zM6.03 5.37a.384.384 0 01-.044-.482L8.643.93l.939.914-.931 1.785a.499.499 0 00.078.572c.145.154.37.196.558.105l1.683-.815a.367.367 0 01.427.079l1.076 1.131-2.608 2.292a.5.5 0 00-.051.697.48.48 0 00.684.051l2.383-2.094a.367.367 0 01.51.023l1.072 1.127a.383.383 0 01-.055.575l-3.531 2.496a.368.368 0 01-.475-.043L7.581 6.95l-.001-.001L6.03 5.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});