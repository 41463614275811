define("ember-svg-jar/inlined/pui_objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.001.567l6.795 3.63-.092 7.023-6.701 4.188L1.3 11.126V4.199l2.283-1.223L9.8 6.78v1.75l.734-.389 1.7-.9.266-.14V5.222l-.236-.147-5.867-3.644L8.001.567M8 0L5.4 1.4 12 5.5v1.3l-1.7.9V6.5L3.6 2.4.8 3.9v7.5L8 16l7.2-4.5.1-7.6L8 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});