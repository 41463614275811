define("ember-svg-jar/inlined/precaucion_materiales_inflamables_y_combustibles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_MATERIALES_INFLAMABLES_Y_COMBUSTIBLES</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212zm21.976-14.085c4.599 3.837 2.965 9.732.726 10.556a.285.285 0 01-.372-.346c.696-2.335-.16-5.117-1.828-5.696a.264.264 0 00-.087-.015.294.294 0 00-.262.174c-.333.722-.98.99-1.43 1.09a.284.284 0 01-.33-.361c.505-1.634.01-3.772-1.23-4.704a.272.272 0 00-.164-.056.288.288 0 00-.287.312c.12 1.357-.047 2.358-1.277 3.601-1.215 1.229-1.893 3.692-1.101 5.465.088.196-.07.396-.261.396a.286.286 0 01-.114-.024c-1.215-.527-3.394-1.964-2.45-5.298.73-2.572 4.71-5.59 1.59-7.118-.263-.129-.176-.529.117-.54.067-.002.136-.003.206-.003.734 0 1.61.142 2.241.696l.003-.024c.544-3.832 2.889-4.609 4-4.76.23-.031.399.216.286.419-.543.977-1.34 3.43 2.024 6.236z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});