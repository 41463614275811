define("ember-svg-jar/inlined/l-cup-soda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 8l1.75 12.28a2 2 0 002 1.72h4.54a2 2 0 002-1.72L18 8M5 8h14\"/><path d=\"M7 15a6.47 6.47 0 015 0 6.47 6.47 0 005 0m-5-7l1-6h2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cup-soda",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});