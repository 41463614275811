define("ember-svg-jar/inlined/indicacion_general_de_obligacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>INDICACION_GENERAL_DE_OBLIGACION</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#00438C\" d=\"M0 0h50v50H0z\"/><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-2.222c9.818 0 17.778-7.96 17.778-17.778 0-9.818-7.96-17.778-17.778-17.778-9.818 0-17.778 7.96-17.778 17.778 0 9.818 7.96 17.778 17.778 17.778zM27.667 37h-4.89v-4.889h4.89V37zm0-8.526h-4.89V12.556h4.89v15.918z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#00438c"
    }
  };
});