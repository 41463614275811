define("ember-svg-jar/inlined/pui_category_community_services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.318 2.503a5.988 5.988 0 00-1.947-1.324 5.916 5.916 0 00-4.596 0 5.988 5.988 0 00-1.947 1.324l-.854.879-.855-.879A5.989 5.989 0 009.172 1.18a5.916 5.916 0 00-4.596 0A5.988 5.988 0 002.63 2.503C.277 4.891.132 8.924 3.095 11.988L11.974 21l8.878-9.012c2.963-3.064 2.819-7.097.466-9.485z\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M11.973 3.382L8.5 6.919a2.418 2.418 0 00-.686 1.69c0 .633.247 1.24.686 1.69v0a2.346 2.346 0 001.664.695c.624 0 1.222-.25 1.665-.696l2.508-2.49a3.304 3.304 0 012.342-.976c.877 0 1.718.35 2.341.977l2.664 2.703M18.633 14.241l-2.22-2.253M15.303 17.62l-2.22-2.252\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "22",
      "viewBox": "0 0 24 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});