define("ember-svg-jar/inlined/pui_category_construction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.808 8.515l9.198-7.262 9.197 7.262v11.41a2.09 2.09 0 01-.598 1.467A2.029 2.029 0 0118.16 22H3.852a2.029 2.029 0 01-1.445-.608 2.09 2.09 0 01-.599-1.467V8.515z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.94 22V11.627h6.132V22\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "23",
      "viewBox": "0 0 22 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});