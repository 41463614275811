define("ember-svg-jar/inlined/l-smartphone-nfc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"7\" height=\"12\" x=\"2\" y=\"6\" rx=\"1\"/><path d=\"M13 8.32a7.43 7.43 0 010 7.36m3.46-9.47a11.76 11.76 0 010 11.58M19.91 4.1a15.91 15.91 0 01.01 15.8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-smartphone-nfc",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});