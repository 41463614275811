define("ember-svg-jar/inlined/pui_warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.026 16h4.052c.727 0 1.142-.831.831-1.455l-2.078-3.428c-.312-.52-1.247-.52-1.559.104L9.194 14.65c-.311.623.104 1.35.832 1.35zm2.389-.935h-.623v-.623h.623v.623zm0-1.351h-.623v-1.246h.623v1.246zM6.597 2.909c.935 0 1.558-.623 1.558-1.455S7.428 0 6.597 0 5.039.623 5.039 1.455c.103.831.727 1.454 1.558 1.454zM3.792 5.506L1.714 16h1.247c.208 0 .312-.104.416-.312l1.143-4.987c.104-.312.416-.416.727-.208l.935.935c.104.104.104.208.104.312v3.844c-.001.208.207.416.415.416h.727a.447.447 0 00.416-.416v-4.987c0-.104 0-.208-.104-.312L6.389 9.038c-.104-.104-.104-.208-.104-.416l.208-1.247c.104-.312.519-.416.727-.208a5.663 5.663 0 003.221 1.351.447.447 0 00.416-.416v-.727a.447.447 0 00-.416-.416c-1.247 0-2.286-.727-2.909-1.766l-.727-1.247c-.312-.312-.727-.623-1.351-.623-.208 0-.416 0-.519.104l-3.74 1.455c-.104.104-.208.208-.208.416v2.494a.82.82 0 00.831.831h.312a.447.447 0 00.416-.416v-1.87c0-.208.104-.312.208-.416l1.038-.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});