define("ember-svg-jar/inlined/pui_high_temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>highTemperatures</title><path d=\"M6.6 10.2V5c0-1.2-1-2.2-2.2-2.2S2.2 3.8 2.2 5v5.2c-.7.6-1.1 1.5-1.1 2.5 0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3c0-1-.4-1.9-1.1-2.5zm-2.2 4.7c-1.2 0-2.2-1-2.2-2.2 0-.6.3-1.2.7-1.6l.4-.4V5c0-.6.5-1.1 1.1-1.1s1 .5 1 1.1v5.7l.4.4c.5.4.7 1 .7 1.6 0 1.2-.9 2.2-2.1 2.2z\"/><path d=\"M4.85 11.2v-4h-1v4c-.9.3-1.3 1.2-1 2.1s1.2 1.3 2.1 1 1.3-1.2 1-2.1c-.2-.5-.6-.8-1.1-1z\"/><circle cx=\"11.1\" cy=\"3.785\" r=\"1.585\"/><path d=\"M11.005 0h-.031c-.247 0-.447.2-.447.446v.75c0 .247.2.446.447.446h.031c.247 0 .447-.2.447-.446v-.75c0-.246-.2-.446-.447-.446zM8.346 1.18l-.022.022a.448.448 0 000 .632l.53.53a.448.448 0 00.632 0l.022-.022a.448.448 0 000-.632l-.53-.53a.448.448 0 00-.632 0zM7.3 3.895v.031c0 .247.2.446.446.446h.75c.247 0 .446-.2.446-.446v-.031c0-.247-.2-.447-.446-.447h-.75c-.246 0-.446.2-.446.447zM8.48 6.554l.022.022a.448.448 0 00.632 0l.53-.53a.448.448 0 000-.632l-.022-.022a.448.448 0 00-.632 0l-.53.53a.448.448 0 000 .632zM11.195 7.6h.031c.247 0 .446-.2.446-.446v-.75c0-.247-.2-.446-.446-.446h-.031c-.247 0-.446.2-.446.446v.75a.445.445 0 00.446.446zM13.854 6.42l.022-.022a.448.448 0 000-.632l-.53-.53a.448.448 0 00-.632 0l-.022.022a.448.448 0 000 .632l.53.53a.448.448 0 00.632 0zM14.9 3.705v-.03c0-.247-.2-.447-.446-.447h-.75c-.247 0-.446.2-.446.447v.031c0 .247.2.446.446.446h.75c.246 0 .446-.2.446-.447zM13.72 1.046l-.022-.022a.448.448 0 00-.632 0l-.53.53a.448.448 0 000 .632l.022.022a.448.448 0 00.632 0l.53-.53a.448.448 0 000-.632z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});