define("ember-svg-jar/inlined/pui_warehouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 13H0v1.424h.008c-.001.014-.008.025-.008.038v1.029A.51.51 0 00.509 16h2.684a.51.51 0 00.509-.509v-1.029c0-.013-.007-.024-.008-.037h2.064c-.001.013-.008.024-.008.037v1.029a.51.51 0 00.509.509h3.483a.51.51 0 00.509-.509v-1.029c0-.013-.007-.024-.008-.037h2.064c-.001.013-.008.024-.008.037v1.029a.51.51 0 00.509.509h2.684c.28 0 .508-.228.508-.509v-1.029c0-.013-.007-.024-.008-.037H16V13zM6.784 6.7H4.85V8a.7.7 0 11-1.4 0V6.7H1.516a.715.715 0 00-.716.716v3.769c0 .395.32.716.716.716h5.269c.395 0 .716-.32.716-.716V7.416a.717.717 0 00-.717-.716zM14.784 6.7H12.85V8a.7.7 0 11-1.4 0V6.7H9.516a.715.715 0 00-.716.716v3.769c0 .395.32.716.716.716h5.269c.395 0 .716-.32.716-.716V7.416a.717.717 0 00-.717-.716zM10.634 0H8.7v1.3a.7.7 0 11-1.4 0V0H5.366a.715.715 0 00-.716.716v3.769c0 .395.32.715.716.715h5.269c.395 0 .716-.32.716-.716V.716A.717.717 0 0010.634 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});