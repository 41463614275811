define("ember-svg-jar/inlined/pui_airplane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.582.494a1.587 1.587 0 00-2.225 0l-3.229 3.327a.254.254 0 01-.258.064L2.068 1.349a.252.252 0 00-.25.056L.447 2.696a.252.252 0 00.041.398l6.228 3.811a.252.252 0 01.049.39l-3.127 3.219a.251.251 0 01-.225.072l-1.959-.356a.252.252 0 00-.228.075L.068 11.54a.251.251 0 00.102.41l2.753.946a.252.252 0 01.156.156l.942 2.74c.06.176.284.228.416.096l1.139-1.139a.255.255 0 00.07-.223l-.357-1.962a.252.252 0 01.075-.228l3.317-3.133a.25.25 0 01.387.052l3.819 6.24a.251.251 0 00.392.047l1.385-1.385a.25.25 0 00.061-.256l-2.534-7.796a.25.25 0 01.064-.258l3.327-3.229c.506-.506.607-1.517 0-2.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});