define("ember-svg-jar/inlined/pui_certificate_request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.5 0H16v11.5H0V0h.5zm15 11V.5H.5V11h15zM7 4H3v-.5h4V4zm2 2H3v-.5h6V6zm1 .951l.572-.571v-.808h.808L11.95 5l.572.572h.809v.808l.57.571-.57.572v.808h-.809l-.572.572-.571-.572h-.808v-.808L10 6.951zM14.5 1H1v9.5h14V1h-.5zm0 .5V10h-13V1.5h13z\"/>",
    "attrs": {
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});