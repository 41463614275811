define("ember-svg-jar/inlined/pui_rack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.685 0a.49.49 0 00-.49.49v2.023c0 .086-.07.156-.156.156h-2.01a.156.156 0 01-.156-.156V.49a.49.49 0 10-.98 0v2.101a.154.154 0 01-.119.151l-3.667.883V2.151a.49.49 0 10-.98 0v1.587a.154.154 0 01-.119.151l-2.01.483a.155.155 0 01-.193-.151v-2.07a.49.49 0 10-.98 0V15.51a.49.49 0 10.98 0v-3.09c0-.086.07-.156.156-.156h2.01c.086 0 .156.07.156.156v3.09a.49.49 0 10.98 0v-3.254h.033l3.561-.856a.155.155 0 01.192.151v2.298a.49.49 0 10.98 0V11.24c0-.072.049-.135.119-.151l2.017-.485h.184v3.245a.49.49 0 10.98 0V.49a.486.486 0 00-.488-.49zm-.49 3.803v1.72c0 .086-.07.156-.156.156h-2.01a.156.156 0 01-.156-.156V4.286c0-.072.049-.135.119-.151l2.01-.483a.156.156 0 01.193.151zM11.03 6.66h2.01c.086 0 .156.07.156.156v2.652c0 .086-.07.156-.156.156h-2.01a.156.156 0 01-.156-.156V6.816c0-.086.07-.156.156-.156zM2.813 5.302H4.97c.086 0 .156.07.156.156v1.726c0 .086-.07.156-.156.156H2.96a.156.156 0 01-.156-.156V5.303l.009-.001zm.362 5.982h-.37V8.476c0-.086.07-.156.156-.156h2.01c.086 0 .156.07.156.156v2.216a.154.154 0 01-.119.151l-1.833.441zm3.124-.752a.155.155 0 01-.192-.151V5.302h.033l3.561-.856a.155.155 0 01.192.151v4.948a.154.154 0 01-.119.151l-3.475.836z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});