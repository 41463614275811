define("ember-svg-jar/inlined/pui_security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.4 6.7c-.5-1.4-.5-2.9 0-4.3l.3-1L13.4 0C11.8 1 9.7 1 8 0 6.4 1 4.3 1 2.6 0L1.3 1.4l.3 1c.4 1.4.4 2.9 0 4.3-1 3.1.4 6.5 3.4 7.9L8 16l3-1.4c2.9-1.4 4.4-4.8 3.4-7.9zm-2.3 3.5H9.5l-1.3 2.4-1.3-2.4H4.1l1.3-2.4-1.3-2.3h2.7l1.3-2.4 1.3 2.4h2.7l-1.3 2.4 1.3 2.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});