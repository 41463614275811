define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8 9a4 4 0 100-8 4 4 0 000 8zm0-1a3 3 0 100-6 3 3 0 000 6z\" clip-rule=\"evenodd\"/><path d=\"M15 14.291A9.053 9.053 0 008 11a9.053 9.053 0 00-7 3.291l.715.71A8.047 8.047 0 018 11.996 8.047 8.047 0 0114.286 15l.714-.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});