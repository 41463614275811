define("ember-svg-jar/inlined/l-spade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 9c-1.5 1.5-3 3.2-3 5.5A5.5 5.5 0 007.5 20c1.8 0 3-.5 4.5-2 1.5 1.5 2.7 2 4.5 2a5.5 5.5 0 005.5-5.5c0-2.3-1.5-4-3-5.5l-7-7zm7 9v4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-spade",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});