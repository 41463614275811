define("ember-svg-jar/inlined/l-concierge-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 20a1 1 0 01-1-1v-1a2 2 0 012-2h16a2 2 0 012 2v1a1 1 0 01-1 1zm17-4a8 8 0 10-16 0m8-12v4m-2-4h4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-concierge-bell",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});