define("ember-m3/-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.recordDataFor = recordDataFor;
  function recordDataFor(recordOrInternalModel) {
    if (false /* CUSTOM_MODEL_CLASS */) {
      return recordOrInternalModel._recordData;
    }
    let internalModel = recordOrInternalModel._internalModel || recordOrInternalModel;
    return internalModel._recordData;
  }
});