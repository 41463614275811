define("ember-svg-jar/inlined/l-key-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.4 2.7a2.5 2.5 0 013.4 0l5.5 5.5a2.5 2.5 0 010 3.4l-3.7 3.7a2.5 2.5 0 01-3.4 0L8.7 9.8a2.5 2.5 0 010-3.4zM14 7l3 3m-7.6.6l-6.814 6.814A2 2 0 002 18.828V21a1 1 0 001 1h3a1 1 0 001-1v-1a1 1 0 011-1h1a1 1 0 001-1v-1a1 1 0 011-1h.172a2 2 0 001.414-.586l.814-.814\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-key-square",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});