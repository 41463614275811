define("ember-svg-jar/inlined/l-mouse-pointer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.586 12.586L19 19M3.688 3.037a.497.497 0 00-.651.651l6.5 15.999a.501.501 0 00.947-.062l1.569-6.083a2 2 0 011.448-1.479l6.124-1.579a.5.5 0 00.063-.947z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-mouse-pointer",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});