define("ember-svg-jar/inlined/pui_recycling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.083 5.273l1.002.783-.943 1.805c-.471.833.338 1.644.854 1.927.508.278 1.296.311 2.033.301l1.326-2.167 1.002.542-1.738-3.22-3.536.029zM.23 9.694l2.15 3.973c.432.552 1.247.682 2.092.662H6.74v-3.762l-4.301-.03c-.667.039-1.541-.101-2.209-.843zm13.53-7.322l-1.17.497-1.122-1.7c-.5-.816-1.602-.501-2.099-.187-.489.309-.9.983-1.249 1.632l1.253 2.21-.96.613 3.659.041 1.688-3.106zM9.822.358L5.305.313c-.692.11-1.201.76-1.593 1.508l-1.1 1.984 3.29 1.824 2.111-3.747c.289-.604.836-1.3 1.809-1.524zm.197 15.329l.133-1.264 2.031-.158c.956-.042 1.215-1.158 1.181-1.745-.033-.578-.423-1.264-.822-1.883l-2.54.024-.072-1.136-1.81 3.18 1.899 2.982zm3.67-2.467l2.23-3.929c.239-.659-.082-1.419-.547-2.125L14.17 5.243l-3.19 1.994 2.254 3.663c.387.545.731 1.36.455 2.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});