define("ember-svg-jar/inlined/l-watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"6\"/><path d=\"M12 10v2l1 1m3.13-5.34l-.81-4.05a2 2 0 00-2-1.61h-2.68a2 2 0 00-2 1.61l-.78 4.05m.02 8.7l.8 4a2 2 0 002 1.61h2.72a2 2 0 002-1.61l.81-4.05\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-watch",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});