define("ember-svg-jar/inlined/l-map-pin-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.75 7.09a3 3 0 012.16 2.16m2.162 7.822c-1.634 2.17-3.527 3.912-4.471 4.727a1 1 0 01-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 011.432-4.568M2 2l20 20\"/><path d=\"M8.475 2.818A8 8 0 0120 10c0 1.183-.31 2.377-.81 3.533M9.13 9.13a3 3 0 003.74 3.74\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-map-pin-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});