define("ember-svg-jar/inlined/l-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 12l-8.373 8.373a1 1 0 11-3-3L12 9m6 6l4-4\"/><path d=\"M21.5 11.5l-1.914-1.914A2 2 0 0119 8.172V7l-2.26-2.26a6 6 0 00-4.202-1.756L9 2.96l.92.82A6.18 6.18 0 0112 8.4V10l2 2h1.172a2 2 0 011.414.586L18.5 14.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-hammer",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});