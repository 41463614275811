define("ember-svg-jar/inlined/lineDashed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.707 4.707a1 1 0 11-1.414-1.414l2-2a1 1 0 111.414 1.414l-2 2zm-6.414 5a1 1 0 001.414 0l2-2a1 1 0 10-1.414-1.414l-2 2a1 1 0 000 1.414zm-5 5a1 1 0 001.414 0l2-2a1 1 0 10-1.414-1.414l-2 2a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});