define("ember-parachute/utils/can-invoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canInvoke = canInvoke;
  function canInvoke(obj, methodName) {
    return obj != null && typeof obj[methodName] === 'function';
  }
});