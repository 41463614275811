define("ember-svg-jar/inlined/app_search_profiler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"euiIcon__fillSecondary\" d=\"M11.63 8h7.38v2h-7.38z\"/><path d=\"M7 8h3.19v2H7z\"/><path class=\"euiIcon__fillSecondary\" d=\"M7 16h7.38v2H7z\"/><path d=\"M15.81 16H19v2h-3.19zM7 12h9v2H7z\"/><path d=\"M13 0C5.82 0 0 5.82 0 13s5.82 13 13 13 13-5.82 13-13A13 13 0 0013 0zm0 24C6.925 24 2 19.075 2 13S6.925 2 13 2s11 4.925 11 11-4.925 11-11 11zM22.581 23.993l1.414-1.414 7.708 7.708-1.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});