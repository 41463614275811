define("ember-svg-jar/inlined/pui_security_camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.2 6.5c-.1-.1-.2-.2-.4-.2h-.4c-.3.6-.8 1.1-1.4 1.3l-.8 2.2 1 .8c.2.2.5.1.7-.1l.1-.1 1.3-3.5c0-.1 0-.3-.1-.4zM9.4 7.9c-.4.1-.8.1-1.2-.1L1.4 5.4c-.2-.1-.3-.2-.4-.2l-.2.5c-.3.7.1 1.5.8 1.8l7.9 2.9c.7.3 1.5-.1 1.8-.8l.7-1.9-2.6.2z\"/><path d=\"M13 3.6L3.3.1c-.7-.3-1.5.1-1.8.8L.9 2.7c-.3.7.1 1.5.8 1.8L8.5 7c.2 0 .5.1.7 0l3.5-.3c.5-.1.8-.4 1-.9l.1-.4c.3-.7-.1-1.5-.8-1.8zM5.9 10v1.3c0 .5-.4.9-.9.9H2.6v-.5c0-.8-.6-1.4-1.4-1.4-.3 0-.5.2-.5.5v4.7c0 .3.2.5.5.5.8 0 1.4-.6 1.4-1.4v-.5h2.3c1.6 0 2.8-1.3 2.8-2.8v-.6L5.9 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});