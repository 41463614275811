define("ember-svg-jar/inlined/l-brain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 5a3 3 0 10-5.997.125 4 4 0 00-2.526 5.77 4 4 0 00.556 6.588A4 4 0 1012 18z\"/><path d=\"M12 5a3 3 0 115.997.125 4 4 0 012.526 5.77 4 4 0 01-.556 6.588A4 4 0 1112 18z\"/><path d=\"M15 13a4.5 4.5 0 01-3-4 4.5 4.5 0 01-3 4m8.599-6.5a3 3 0 00.399-1.375m-11.995 0A3 3 0 006.401 6.5m-2.924 4.396a4 4 0 01.585-.396m15.876 0a4 4 0 01.585.396M6 18a4 4 0 01-1.967-.516m15.934 0A4 4 0 0118 18\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-brain",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});