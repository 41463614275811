define("ember-svg-jar/inlined/pui_suspended_load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.9 8.6h.6V16H.9zM14.5 8.6h.6V16h-.6zM9.3 4.7c0-.1 0-.2-.1-.2s-.2 0-.2.1c-.3.5-.8.7-1.3.5-.5-.2-.7-.8-.5-1.2.1-.3.5-.6.9-.6.1 0 .2-.1.2-.2V1.6h.8V.7h-.6V0h-1v.7H7v.9h.9v1.3c-.7.1-1.2.8-1.1 1.5 0 .3.2.6.5.8.1.1.2.1.3.2L1.8 8.8v7.1h12.4V8.8L8.7 5.4c.3-.2.5-.4.6-.7zm4.2 4.1h-11l5.7-3.3h.1l5.2 3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});