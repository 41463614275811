define("ember-m3/services/store", ["exports", "@ember-data/store", "@ember/service", "@ember/object", "ember-m3/record-data", "ember-m3/factory", "ember-m3/query-cache", "ember-m3/utils/notify-changes", "@ember/string", "@ember/application", "ember-m3/utils/seen-types-per-store", "@ember/runloop", "@ember/polyfills", "ember-m3/utils/caches"], function (_exports, _store, _service, _object, _recordData, _factory, _queryCache, _notifyChanges, _string, _application, _seenTypesPerStore, _runloop, _polyfills, _caches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function internalModelFactoryRemoveMonkeyPatch(internalModel) {
    if (typeof internalModel.store._globalM3Cache !== 'undefined') {
      delete internalModel.store._globalM3Cache[internalModel.id];
    }
    return this.__originalRemove.apply(this, arguments);
  }
  let internalModelFactoryRemoveMonkeyPatched = false;
  class SchemaDefinition {
    constructor(store, dsModelSchema) {
      this.store = store;
      this.dsModelSchema = dsModelSchema;
      this._internalModelMapModified = false;
    }
    attributesDefinitionFor(identifier) {
      let modelName;
      if (identifier.type) {
        modelName = identifier.type;
      } else {
        modelName = identifier;
      }
      if ((0, _object.get)(this.store, '_schemaManager').includesModel(modelName)) {
        if (identifier) {
          return this.store.recordDataFor(identifier).attributesDefinition();
        } else {
          return {};
        }
      }
      return this.dsModelSchema.attributesDefinitionFor(modelName);
    }
    relationshipsDefinitionFor(identifier) {
      let modelName;
      if (identifier.type) {
        modelName = identifier.type;
      } else {
        modelName = identifier;
      }
      if ((0, _object.get)(this.store, '_schemaManager').includesModel(modelName)) {
        return Object.create(null);
      }
      return this.dsModelSchema.relationshipsDefinitionFor(modelName);
    }
    doesTypeExist(modelName) {
      if ((0, _object.get)(this.store, '_schemaManager').includesModel(modelName)) {
        return true;
      }
      return this.dsModelSchema.doesTypeExist(modelName);
    }
  }
  let M3Store = _exports.default = (_dec = (0, _service.inject)('m3-schema-manager'), _class = class M3Store extends _store.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_schemaManager", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      this._queryCache = new _queryCache.default({
        store: this
      });
      _seenTypesPerStore.default.set(this, new Set());
      this._modifiedInternalModelMapProto = undefined;
      if (false /* CUSTOM_MODEL_CLASS */) {
        this._globalM3RecordDataCache = Object.create(null);
        this._recordDataToRecordMap = _caches.recordDataToRecordMap;
        let defaultSchema = this.getSchemaDefinitionService();
        this.registerSchemaDefinitionService(new SchemaDefinition(this, defaultSchema));
      } else {
        this._globalM3Cache = Object.create(null);
      }
    }
    createRecordDataFor(modelName, id, clientId, storeWrapper) {
      let schemaManager = (0, _object.get)(this, '_schemaManager');
      if (schemaManager.includesModel(modelName)) {
        _seenTypesPerStore.default.get(this).add(modelName);
        if ((0, _object.get)(schemaManager, 'schema').watchModelTypes) {
          (0, _runloop.next)(() => {
            // We need this to execute in the next task queue so that wrapRecord is not called
            // before the M3RecordData is created
            (0, _application.getOwner)(this).lookup('data-adapter:main').addedType(modelName);
          });
        }
        return new _recordData.default(modelName, id, clientId, storeWrapper, schemaManager, null, null, this._globalM3RecordDataCache);
      }

      // TODO: what is the purpose of this check?
      if (true /* HAS_RECORD_DATA_PACKAGE */) {
        return super.createRecordDataFor(modelName, id, clientId, storeWrapper);
      }
    }

    // Store hooks necessary for using a single model class
    _hasModelFor(modelName) {
      return (0, _object.get)(this, '_schemaManager').includesModel(modelName) || super._hasModelFor(modelName);
    }
    _modelFactoryFor(modelName) {
      if ((0, _object.get)(this, '_schemaManager').includesModel(modelName)) {
        return _factory.default;
      }
      return super._modelFactoryFor(modelName);
    }
    adapterFor(modelName) {
      if ((0, _object.get)(this, '_schemaManager').includesModel(modelName)) {
        return super.adapterFor('-ember-m3');
      }
      return super.adapterFor(modelName);
    }
    serializerFor(modelName) {
      if ((0, _object.get)(this, '_schemaManager').includesModel(modelName)) {
        return super.serializerFor('-ember-m3');
      }
      return super.serializerFor(modelName);
    }
    instantiateRecord(identifier, createRecordArgs, recordDataFor, notificationManager) {
      let recordData = recordDataFor(identifier);
      _caches.recordDataToQueryCache.set(recordData, this._queryCache);
      let modelName = identifier.type;
      if ((0, _object.get)(this, '_schemaManager').includesModel(modelName)) {
        let createOptions = (0, _polyfills.assign)({
          _recordData: recordData,
          store: this
        }, createRecordArgs);
        // TODO remove the megamorphicModelFactory
        let record = _factory.default.create(createOptions);
        notificationManager.subscribe(identifier, (_identifier, value) => {
          if (value === 'state') {
            record.notifyPropertyChange('isNew');
            record.notifyPropertyChange('isDeleted');
          } else if (value === 'identity') {
            record.notifyPropertyChange('id');
          }
        });
        record._setIdentifier(identifier);
        return record;
      }
      return super.instantiateRecord(...arguments);
    }
    teardownRecord(record) {
      if (!(record instanceof _factory.default.class)) {
        let recordArrays = _caches.recordToRecordArrayMap.get(record);
        if (recordArrays) {
          recordArrays.forEach(recordArray => recordArray._removeObject(record));
        }
      }
      return super.teardownRecord(record);
    }

    /**
     * A thin wrapper around the API response that knows how to look up references
     *
     * @param {string} url The URL path to query
     * @param {Object} options
     * @param {string} [options.method=GET] The HTTP method to use
     * @param {Object} [options.params] The parameters to include
     * @param {string} [options.cacheKey] A string to uniquely identify this request
     * @param {boolean} [options.reload=false] If true, issue a request even a cached value exists
     * @param {boolean} [options.backgroundReload=false] If true and a cached value exists,
     * issue a non-blocking request but immediately fulfill with the cached value
     * @param {Object} [options.adapterOptions] The custom options to pass along to the `queryURL` function on the adapter
     * @returns {Promise<M3RecordData|RecordArray,Error>} Promise for loading `url` that fulfills to
     * an `M3RecordData` if the response is a single resource or a `RecordArray` of `M3RecordData`s
     * if the response is an array of resources
     */
    queryURL(url, options) {
      return this._queryCache.queryURL(url, options);
    }
    cacheURL(cacheKey, result) {
      return this._queryCache.cacheURL(cacheKey, result);
    }

    /**
     * Manually unload the cached response identified by cacheKey
     *
     * @param {string} cacheKey
     * @returns
     */
    unloadURL(cacheKey) {
      return this._queryCache.unloadURL(cacheKey);
    }

    /**
     * Check existence of the cachedKey in cache
     *
     * @param {string} cacheKey
     * @returns {boolean}
     */
    containsURL(cacheKey) {
      return this._queryCache.contains(cacheKey);
    }

    // override _push to batch change notifications which we're obliged to do
    // since all our properties are treated as volatiles as they come from
    // `unknownProperty`
    _push(jsonApiDoc) {
      let result = super._push(jsonApiDoc);
      (0, _notifyChanges.flushChanges)(this);
      return result;
    }

    // These two hooks are used for the secondary cache
    // TODO: make secondary caches possible via public API

    _pushInternalModel(jsonAPIResource) {
      if (false /* CUSTOM_MODEL_CLASS */) {
        return super._pushInternalModel(jsonAPIResource);
      } else {
        let internalModel = super._pushInternalModel(jsonAPIResource);
        let schemaManager = (0, _object.get)(this, '_schemaManager');
        let {
          type
        } = jsonAPIResource;
        if (schemaManager.includesModel(type)) {
          let baseName = schemaManager.computeBaseModelName((0, _string.dasherize)(type));
          if (baseName === null || baseName === undefined) {
            // only populate base records in the global cache
            this._globalM3Cache[internalModel.id] = internalModel;
          }
        }
        if (internalModelFactoryRemoveMonkeyPatched === false) {
          // set this up for removals
          let internalModelFactory = this._internalModelsFor(internalModel.modelName);
          let modelFactoryPrototype = Object.getPrototypeOf(internalModelFactory);
          if (modelFactoryPrototype.remove !== internalModelFactoryRemoveMonkeyPatch) {
            modelFactoryPrototype.__originalRemove = modelFactoryPrototype.remove;
            modelFactoryPrototype.remove = internalModelFactoryRemoveMonkeyPatch;
            internalModelFactoryRemoveMonkeyPatched = true;
          }
        }
        return internalModel;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_schemaManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});