define("ember-svg-jar/inlined/pui_category_transport_and_logistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.328 14.68h4.36V1.405H1.608V14.68h3.27M21.228 14.68h2.18v-3.695c0-.581-.112-1.157-.33-1.694a4.432 4.432 0 00-.945-1.437L20.138 5.83h-5.45M14.688 14.68h1.09\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.603 18c1.505 0 2.725-1.238 2.725-2.766s-1.22-2.766-2.725-2.766c-1.505 0-2.725 1.238-2.725 2.766S6.098 18 7.603 18zM18.503 18c1.505 0 2.725-1.238 2.725-2.766s-1.22-2.766-2.725-2.766c-1.505 0-2.725 1.238-2.725 2.766S16.998 18 18.503 18z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "19",
      "viewBox": "0 0 25 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});