define("ember-svg-jar/inlined/map_builder_snap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Snap</title><path d=\"M31.38 29.58v-8.59a.88.88 0 00.32-.07 1.27 1.27 0 10-1.7-1.36h-7.37A2.5 2.5 0 1020 22.24v7.29a1.26 1.26 0 00-1.09 1.23v.07a1.27 1.27 0 001.27 1.27 1.26 1.26 0 001.23-1.08h8.58a1.26 1.26 0 002.5-.19v-.08a1.24 1.24 0 00-1.11-1.17zm-10.81 0V22.2a2.49 2.49 0 002-2.05H30a1.26 1.26 0 00.76.77v8.68a1.28 1.28 0 00-.78.84h-8.63a1.25 1.25 0 00-.78-.86zm0-8.68a1.25 1.25 0 00.77-.75h.46a1.72 1.72 0 01-1.23 1.27zm-2.21-1.16a1.74 1.74 0 013.47-.21h-.44a1.25 1.25 0 00-1.23-1.09A1.26 1.26 0 0020 21v.53a1.74 1.74 0 01-1.61-1.79z\" fill=\"#b6b6b7\" opacity=\".4\"/><path d=\"M31.23 29.53v-8.6a1.25 1.25 0 001-1.22A1.27 1.27 0 0031 18.44a1.25 1.25 0 00-1.22 1h-7.31a2.5 2.5 0 10-2.74 2.72v7.35a1.25 1.25 0 00-1 1.22A1.27 1.27 0 0020 32a1.25 1.25 0 001.22-1h8.57A1.26 1.26 0 0031 32a1.27 1.27 0 001.27-1.28 1.25 1.25 0 00-1.04-1.19zm-10.9 0v-7.38A2.5 2.5 0 0022.47 20h7.34a1.23 1.23 0 00.83.83v8.66a1.22 1.22 0 00-.83.83h-8.62a1.25 1.25 0 00-.86-.8zM21.2 20h.51a1.76 1.76 0 01-1.35 1.34v-.51a1.27 1.27 0 00.84-.83zm-2.95-.37a1.74 1.74 0 013.47-.25h-.5a1.25 1.25 0 00-1.22-1 1.26 1.26 0 00-.22 2.5v.49a1.74 1.74 0 01-1.53-1.7z\" fill=\"#437287\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});