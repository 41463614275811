define("ember-svg-jar/inlined/precaucion_zona_a_alta_temperatura", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_ZONA_A_ALTA_TEMPERATURA</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M31.704 36.057v3.764h-1.412v-3.764h-1.411l2.117-2.353 2.118 2.353h-1.412zM3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212zm19.749-19.33h3.764v.94h-3.764v-.94zm0 1.411h2.823v.941h-2.823v-.941zm0 1.412h1.882v.94h-1.882v-.94zm-.712-2.941v10.703a3.98 3.98 0 011.292 2.94c0 2.207-1.805 4.003-4.025 4.003-2.22 0-4.026-1.796-4.026-4.003 0-1.26.592-2.434 1.596-3.19V22.764h5.163zM24.566 34.18l.04-7.048h-1.773l-.04 6.947c-.911.315-1.529 1.367-1.529 2.38a2.324 2.324 0 002.33 2.318 2.324 2.324 0 002.33-2.318c0-.917-.58-1.903-1.358-2.279z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});