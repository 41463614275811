define("ember-svg-jar/inlined/wordWrapDisabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 3.5L12 2v1H2v1h10v1l2-1.5zM12 9V8H2V7h10V6l2 1.5L12 9zm0 3v1l2-1.5-2-1.5v1H2v1h10z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});