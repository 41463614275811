define("ember-svg-jar/inlined/l-circle-fading-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2a10 10 0 017.38 16.75M12 8v8m4-4H8M2.5 8.875a10 10 0 00-.5 3M2.83 16a10 10 0 002.43 3.4M4.636 5.235a10 10 0 01.891-.857M8.644 21.42a10 10 0 007.631-.38\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-circle-fading-plus",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});