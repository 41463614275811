define("@prysmex-engineering/ember-fetch-request/services/fetch-request", ["exports", "@ember/service", "fetch", "@ember/object", "@ember/utils", "@prysmex-engineering/ember-fetch-request/errors", "@prysmex-engineering/ember-fetch-request/-private/utils/url-helpers", "@prysmex-engineering/ember-fetch-request/-private/utils/is-string", "@prysmex-engineering/ember-fetch-request/-private/utils/get-header"], function (_exports, _service, _fetch, _object, _utils, _errors, _urlHelpers, _isString, _getHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSONContentType = /^application\/(?:vnd\.api\+)?json/i;
  function isJSONContentType(header) {
    if (!(0, _isString.default)(header)) {
      return false;
    }
    return !!header.match(JSONContentType);
  }
  function isJSONStringifyable({
    method,
    contentType,
    body,
    headers
  }) {
    if (method === 'GET') {
      return false;
    }
    if (!isJSONContentType(contentType) && !isJSONContentType((0, _getHeader.default)(headers, 'Content-Type'))) {
      return false;
    }
    if (typeof body !== 'object') {
      return false;
    }
    return true;
  }
  function endsWithSlash(string) {
    return string.charAt(string.length - 1) === '/';
  }
  function removeTrailingSlash(string) {
    return string.slice(0, -1);
  }
  function startsWithSlash(string) {
    return string.charAt(0) === '/';
  }
  function removeLeadingSlash(string) {
    return string.substring(1);
  }
  function stripSlashes(path) {
    // make sure path starts with `/`
    if (startsWithSlash(path)) {
      path = removeLeadingSlash(path);
    }

    // remove end `/`
    if (endsWithSlash(path)) {
      path = removeTrailingSlash(path);
    }
    return path;
  }
  class FetchRequestService extends _service.default {
    /**
      The request can target other hosts by setting the `host` property.
      @property host
      @type {String}
    */

    /**
      The request can target other hosts by setting the `host` property.
      @property host
      @type {String}
    */

    /**
      The request can target another namespace by setting the `namespace` property.
      @property namespace
      @type {String}
    */

    /**
      The request can merge custom/dynamic headers by setting the `headers` property.
      @property headers
      @type {Object}
    */

    async request(endpoint, settings) {
      settings = this.options(endpoint, settings);
      let response = await (0, _fetch.default)(settings.url, settings);
      if (response.status === null || response.status === undefined) {
        throw new _errors.NetworkError(response);
      }
      try {
        if (!response.ok) {
          this._handleErrors(response);
        }
        if (settings.returnRawResponse === true) {
          return response;
        } else {
          return await this.getBody(response);
        }
      } catch (error) {
        error.payload = await this.getBody(response);
        throw error;
      }
    }
    async getBody(response) {
      const contentType = response.headers.get('content-type');
      if (contentType === null) {
        return null;
      }

      // For application/json and application/vnd.api+json
      if (contentType.includes('json')) {
        return await response.json();
      }

      // For text/plain and text/html
      if (contentType.includes('text')) {
        return await response.text();
      }
      throw new Error(`Unsupported response content-type: ${contentType}`);
    }
    _handleErrors(response) {
      switch (response.status) {
        case 422:
          throw new _errors.InvalidError();
        case 401:
          throw new _errors.UnauthorizedError();
        case 403:
          throw new _errors.ForbiddenError();
        case 400:
          throw new _errors.BadRequestError();
        case 404:
          throw new _errors.NotFoundError();
        case 410:
          throw new _errors.GoneError();
        case -1:
          throw new _errors.TimeoutError();
        case 0:
          throw new _errors.AbortError();
        case 409:
          throw new _errors.ConflictError();
        case 500:
          throw new _errors.ServerError();
        default:
          throw new _errors.ServerError();
      }
    }

    /**
     * Created a normalized set of options from the per-request and
     * service-level settings
     */
    options(url, options = {}) {
      options.url = this._buildURL(url, options);
      options.method = options.method || 'GET';
      if (this._shouldSendHeaders(options)) {
        options.headers = this._getFullHeadersHash(options.headers);
      } else {
        options.headers = options.headers || {};
      }
      if (!(0, _utils.isEmpty)(options.contentType)) {
        (0, _object.set)(options.headers, 'Content-Type', options.contentType);
      }
      if (options.body) {
        if (isJSONStringifyable(options)) {
          options.body = JSON.stringify(options.body);
        } else if (options.method == 'GET') {
          options.url = options.url + '?' + (0, _urlHelpers.param)(options.body);
          delete options.body;
        }
      }
      return options;
    }

    /**
     * Build a URL for a request
     *
     * If the provided `url` is deemed to be a complete URL, it will be returned
     * directly.  If it is not complete, then the segment provided will be combined
     * with the `host` and `namespace` options of the request class to create the
     * full URL.
     */
    _buildURL(url, options = {}) {
      if ((0, _urlHelpers.isFullURL)(url)) {
        return url;
      }
      const urlParts = [];
      let host = options.host || this.host;
      if (host) {
        host = endsWithSlash(host) ? removeTrailingSlash(host) : host;
        urlParts.push(host);
      }
      let namespace = options.namespace || this.namespace;
      if (namespace) {
        // If host is given then we need to strip leading slash too (as it will be added through join)
        if (host) {
          namespace = stripSlashes(namespace);
        } else if (endsWithSlash(namespace)) {
          namespace = removeTrailingSlash(namespace);
        }

        // If the URL has already been constructed (presumably, by Ember Data), then we should just leave it alone
        const hasNamespaceRegex = new RegExp(`^(/)?${stripSlashes(namespace)}/`);
        if (!hasNamespaceRegex.test(url)) {
          urlParts.push(namespace);
        }
      }

      // *Only* remove a leading slash when there is host or namespace -- we need to maintain a trailing slash for
      // APIs that differentiate between it being and not being present
      if (startsWithSlash(url) && urlParts.length !== 0) {
        url = removeLeadingSlash(url);
      }
      urlParts.push(url);
      return urlParts.join('/');
    }
    _shouldSendHeaders({
      url,
      host
    }) {
      url = url || '';
      host = host || this.host || '';

      // Add headers on relative URLs
      if (!(0, _urlHelpers.isFullURL)(url)) {
        return true;
      }

      // Add headers on matching host
      return (0, _urlHelpers.haveSameHost)(url, host);
    }

    /**
       * Get the full 'headers' hash, combining the service-defined headers with
       * the ones provided for the request
    */
    _getFullHeadersHash(headers = {}) {
      return Object.assign({}, this.headers, headers);
    }
  }
  _exports.default = FetchRequestService;
});