define("ember-svg-jar/inlined/tokenShape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13 10v3h-3v-1H6v1H3v-3h1V6H3V3h3v1h4V3h3v3h-1v4h1zm-8 1H4v1h1v-1zm7 0h-1v1h1v-1zM5 4H4v1h1V4zm7 0h-1v1h1V4zm-1 2h-1V5H6v1H5v4h1v1h4v-1h1V6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});