define("ember-svg-jar/inlined/l-radar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.07 4.93A10 10 0 006.99 3.34M4 6h.01M2.29 9.62a10 10 0 1019.02-1.27\"/><path d=\"M16.24 7.76a6 6 0 10-8.01 8.91M12 18h.01m5.98-6.34a6 6 0 01-2.22 5.01\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/><path d=\"M13.41 10.59l5.66-5.66\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-radar",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});