define("ember-svg-jar/inlined/pui_wagon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.987 2.004H2.013c-.836 0-1.514.678-1.514 1.514V9.55h15.003V3.519c0-.837-.678-1.515-1.515-1.515zM3.853 7.558a.498.498 0 01-.996 0V3.997a.498.498 0 01.996 0v3.561zm2.322 0a.498.498 0 01-.996 0V3.997a.498.498 0 01.996 0v3.561zm2.323 0a.498.498 0 01-.996 0V3.997a.498.498 0 01.996 0v3.561zm2.322 0a.498.498 0 01-.996 0V3.997a.498.498 0 01.996 0v3.561zm2.323 0a.498.498 0 01-.996 0V3.997a.498.498 0 01.996 0v3.561zM0 10.865v1.032c0 .504.408.912.912.912h.134c.08.665.624 1.187 1.311 1.187s1.231-.522 1.311-1.187h.596c.08.665.625 1.187 1.311 1.187s1.231-.522 1.311-1.187h2.161c.08.665.624 1.187 1.311 1.187s1.231-.522 1.311-1.187h.596c.08.665.624 1.187 1.311 1.187s1.231-.522 1.311-1.187h.203a.912.912 0 00.912-.912v-1.032H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});