define("ember-m3/utils/seen-types-per-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This weakmap is used to keep track of all record types recognized by the m3 schema
  // It will be keyed on a per store basis
  let seenTypesPerStore = new WeakMap();
  var _default = _exports.default = seenTypesPerStore;
});