define("ember-svg-jar/inlined/pui_container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>emptyContainer</title><path d=\"M15.58 5.463a.448.448 0 00-.234-.405L6.923.143c-.33-.19-.736-.19-1.066 0L.633 3.171a.48.48 0 00-.234.415v6.951a.447.447 0 00.235.405l8.422 4.915c.33.19.736.19 1.066 0l5.245-3.028a.481.481 0 00.235-.416v-6.95h-.022zm-14.339 5.33l-.522-.277V5.1l.522.277v5.416zm1.066.576l-.544-.299V5.676l.544.288v5.405zm1.066.597l-.522-.277V6.284l.522.277v5.405zm1.066.586l-.458-.288V6.859l.544.288-.086 5.405zm1.066.587l-.543-.299V7.446l.544.288v5.405zm1.066.586l-.544-.299V8l.544.299v5.426zm1.067.586l-.554-.299V8.608l.554.299v5.404zm1.151.608l-.608-.32V9.194l.608.32v5.405zm1.215.309V9.365l2.484-1.439v2.918l-.235.131a.43.43 0 00-.192.352c0 .139.085.203.192.149l.235-.131v2.487l-2.484 1.396zm4.979-2.878l-2.25 1.301v-2.443l.586-.328a.48.48 0 00.181-.362c0-.139-.085-.203-.181-.139l-.586.328v-2.92l2.25-1.301v5.864z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});