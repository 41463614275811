define("ember-svg-jar/inlined/pui_sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voltage</title><path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zM4.058 6.887a.6.6 0 11-1.192-.137c.061-.524.571-1.517 2.071-1.517 1.485 0 2.026.978 2.105 1.495a.601.601 0 01-.594.691.6.6 0 01-.592-.51c-.015-.065-.143-.475-.919-.475-.817 0-.878.449-.879.453zm3.938 2.619c2.644 0 3.544 1.812 3.65 2.77a.6.6 0 01-.53.663.61.61 0 01-.662-.525c-.023-.178-.288-1.708-2.458-1.708-2.272 0-2.579 1.666-2.59 1.736a.6.6 0 01-1.183-.199c.159-.947 1.152-2.737 3.773-2.737zm4.639-2.094a.6.6 0 01-.684-.503c-.016-.065-.144-.475-.92-.475-.817 0-.878.449-.879.453a.608.608 0 01-.664.528.601.601 0 01-.528-.665c.06-.524.571-1.517 2.071-1.517 1.485 0 2.027.978 2.105 1.495a.598.598 0 01-.501.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});