define("ember-svg-jar/inlined/app_add_data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M32 30H0V3h12.57l3 5H32v22zM2 28h28V10H14.43l-3-5H2v23z\"/><path class=\"euiIcon__fillSecondary\" d=\"M21 18h-4v-4h-2v4h-4v2h4v4h2v-4h4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});