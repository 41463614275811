define("ember-svg-jar/inlined/l-drill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 9c0 .6-.4 1-1 1H4a2 2 0 01-2-2V4a2 2 0 012-2h9c.6 0 1 .4 1 1zm4-3h4m-8-2h3a1 1 0 011 1v2a1 1 0 01-1 1h-3m-9 2l-2 8m9-8v3c0 .6-.4 1-1 1H8m-1 4l2-8M5 22c-1.7 0-3-1.3-3-3 0-.6.4-1 1-1h7c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-drill",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});