define("ember-svg-jar/inlined/pui_no_smoking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 16c4.4-.1 7.9-3.6 8-8-.1-4.4-3.6-7.9-8-8C3.6.1.1 3.6 0 8c0 4.4 3.5 8 8 8-.1 0 0 0 0 0zM8 1.4c3.7 0 6.6 3 6.6 6.6 0 1.4-.5 2.9-1.4 4l-3-3h1.6V6.9h-4l-4-4c1.3-1 2.7-1.5 4.2-1.5zM2.9 4l2.9 2.9H2.2v2.3H8l4.1 4.1C9.2 15.5 5 14.9 2.7 12c-.9-1.1-1.4-2.6-1.4-4 .1-1.5.6-2.9 1.6-4z\"/><path d=\"M12.1 6.9h.8v2.121h-.8zM13.4 6.9h.8v2.121h-.8zM10.2 4.5s2.7 0 3.2 1.7c0 0-.1-2.7-3.5-2.7S6.9 2 6.9 2s-1 2 3.3 2.5z\"/><path d=\"M12.7 6.7s-.1-1.9-2.9-1.8c-2.7 0-2.6-1-2.6-1s-.8 1.4 2.7 1.7c1.1-.3 2.2.2 2.8 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});