define("ember-svg-jar/inlined/pui_trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.8 2.25v-.8H3.2v.8H0v.5c0 1.7.6 3.3 1.8 4.5.7.7 1.7 1 2.7 1H5c.5.7 1.1 1.2 1.9 1.6.1 0 .1.1.1.3v1.7c0 .2-.2.4-.4.4H5.1c-.2 0-.4.2-.4.4v1.1H4v.8h8v-.8h-.7v-1.1c0-.2-.2-.4-.4-.4H9.4c-.2 0-.4-.2-.4-.4v-1.7c0-.2 0-.3.1-.3.8-.4 1.4-.9 1.9-1.6h.5c1 0 2-.3 2.7-1 1.2-1.2 1.8-2.8 1.8-4.5v-.5h-3.2zM2.5 6.45c-.8-.7-1.2-1.7-1.4-2.7 0-.2.2-.4.4-.4h1.4c.2 0 .4.1.4.3.1 1 .9 3.3 1 3.5-.7 0-1.3-.2-1.8-.7zm11 0c-.5.5-1.1.7-1.8.7.1-.2.9-2.5 1-3.5 0-.2.2-.3.4-.3h1.4c.2 0 .4.2.4.4-.2 1-.6 2-1.4 2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});