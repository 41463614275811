define("ember-svg-jar/inlined/l-wind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.7 7.7a2.5 2.5 0 111.8 4.3H2m7.6-7.4A2 2 0 1111 8H2m10.6 11.4A2 2 0 1014 16H2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-wind",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});