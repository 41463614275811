define("ember-svg-jar/inlined/pui_person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8\" cy=\"3.894\" r=\"3.894\"/><path d=\"M11.794 7.189C10.895 8.287 9.498 8.986 8 8.986s-2.895-.699-3.794-1.797c-1.897 1.298-3.195 3.395-3.195 5.891 0 3.894 13.978 3.894 13.978 0 0-2.497-1.298-4.593-3.195-5.891z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});