define("ember-svg-jar/inlined/riesgo_por_superficie_resbalosa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_POR_SUPERFICIE_RESBALOSA</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M20.862 36.47l1.182.707 2.31-2.917-1.223-.439-1.349 1.348-.92 1.301zm7.01-8.826c.761-.176 1.242-.904 1.075-1.627-.166-.722-.918-1.166-1.679-.99-.76.175-1.241.904-1.074 1.626.166.723.918 1.166 1.679.99zm-2.74-1.525l.83-2.708-1.305-.34-.903 2.945a.62.62 0 00.035.456l.599 1.221.633.731.28 1.207-1.78 2.484 1.23.318-3.87-1.076a.85.85 0 00-.386-.016l-3.028.578.285 1.528 2.831-.54 3.68 1.024c.357.1.734-.048.93-.338.047-.078 2.296-3.562 2.296-3.562l1.829 3.294 1.235-.35-2.065-4.094s-1.06-.59-1.117-.62a302.67 302.67 0 00-1.614-.864l-.626-1.278z\" fill-rule=\"nonzero\" fill=\"#000\"/><path fill=\"#000\" d=\"M11 39h28v2H11z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});