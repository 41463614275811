define("ember-svg-jar/inlined/pui_explosion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.3 6.2c0 .1.1.2.2.2.7.2 3.5.6 3.5.6v.1l-4.5 2c-.1 0-.2.2-.1.2l4.5 3.5v.1l-6.1-1.7c-.1 0-.1 0-.2.1L8.5 16c0 .1-.2.1-.2 0l-1.5-4.5c0-.1-.1-.1-.2-.1l-2.5 2.1H4l.6-3.1c0-.1-.1-.2-.2-.1l-4.2 1c-.2-.1-.3-.2-.2-.3l3.3-3.6c.1-.1 0-.2-.1-.2l-2.8-1v-.1l3.8-1c.1 0 .1-.1.1-.2L1.8.1s0-.1.1-.1c.8.8 3.6 3.3 4.6 4.1.2.1.4 0 .5-.2.2-.7.6-2.4.8-3.1 0-.1.2-.1.2 0L9.8 5h.1L12.6.9c.1-.2.4-.1.3.1l-.6 5.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});