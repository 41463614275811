define("ember-svg-jar/inlined/pui_category_agriculture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.24 19.047a6.521 6.521 0 01-4.458-1.75 6.712 6.712 0 01-2.113-4.35 6.745 6.745 0 011.347-4.654 6.559 6.559 0 014.096-2.499c5.354-1.049 6.763-1.545 8.642-3.909.939 1.907 1.878 3.985 1.878 7.627 0 5.244-4.49 9.535-9.393 9.535z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1.786 20c0-2.86 1.738-5.11 4.771-5.72 2.274-.458 4.622-1.907 5.561-2.86\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});