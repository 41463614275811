define("ember-svg-jar/inlined/pui_check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.493 5.066L7.3 13.4c-.3.3-.8.5-1.4.5s-1-.2-1.4-.5l-4-4.1C.2 8.9 0 8.4 0 7.9c0-.479.184-.959.463-1.35.024-.033.099-.105.13-.132C.905 6.141 1.423 6 1.9 6c.5 0 1 .2 1.4.5l2.607 2.607a.415.415 0 00.587 0L13 2.6c.3-.3.8-.5 1.4-.5.544 0 .751.052 1.115.306.073.051.27.173.333.318.251.58.211 1.682-.355 2.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});