define("ember-m3/utils/resolve", ["exports", "@ember/string", "ember-m3/utils/caches"], function (_exports, _string, _caches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeAttribute = computeAttribute;
  _exports.computeAttributeReference = computeAttributeReference;
  _exports.computeNestedModel = computeNestedModel;
  _exports.getOrCreateRecordFromRecordData = getOrCreateRecordFromRecordData;
  _exports.isResolvedValue = isResolvedValue;
  _exports.resolveReferencesWithInternalModels = resolveReferencesWithInternalModels;
  _exports.resolveReferencesWithRecords = resolveReferencesWithRecords;
  function computeAttributeReference(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);
    let reference = schema.computeAttributeReference(key, value, modelName, schemaInterface);
    schemaInterface._endDependentKeyResolution(key);
    return reference;
  }
  function computeNestedModel(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);
    let nestedModel = schema.computeNestedModel(key, value, modelName, schemaInterface);
    schemaInterface._endDependentKeyResolution(key);
    return nestedModel;
  }
  function computeAttribute(key, value, modelName, schemaInterface, schema) {
    schemaInterface._beginDependentKeyResolution(key);
    let model = schema.computeAttribute(key, value, modelName, schemaInterface);
    schemaInterface._endDependentKeyResolution(key);
    return model;
  }
  function resolveReferencesWithInternalModels(store, references) {
    if (!false /* CUSTOM_MODEL_CLASS */) {
      return references.map(reference => reference.type ? store._internalModelForId((0, _string.dasherize)(reference.type), reference.id) : store._globalM3Cache[reference.id]);
    }
  }
  function resolveReferencesWithRecords(store, references) {
    if (false /* CUSTOM_MODEL_CLASS */) {
      return references.map(reference => {
        if (reference.type) {
          return store.peekRecord((0, _string.dasherize)(reference.type), reference.id);
        } else {
          let rd = store._globalM3RecordDataCache[reference.id];
          if (rd) {
            return getOrCreateRecordFromRecordData(rd, store);
          }
        }
      });
    }
  }
  function isResolvedValue(value) {
    return value && value.constructor && value.constructor.isModel;
  }
  function getOrCreateRecordFromRecordData(rd, store) {
    if (false /* CUSTOM_MODEL_CLASS */) {
      let record = _caches.recordDataToRecordMap.get(rd);
      if (_caches.recordDataToRecordMap.get(rd)) {
        return record;
      } else {
        return store.peekRecord(rd.modelName, rd.id);
      }
    }
  }
});