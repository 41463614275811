define("ember-svg-jar/inlined/pui_stop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.839 4.525L11.474.16a.55.55 0 00-.388-.16H4.914a.55.55 0 00-.389.161L.161 4.525A.55.55 0 000 4.914v6.172c0 .146.058.285.161.388l4.365 4.365a.546.546 0 00.388.161h6.172a.548.548 0 00.388-.161l4.365-4.365a.546.546 0 00.161-.388V4.914a.55.55 0 00-.161-.389zm-5.198 8.032c-.356.641-.998.997-1.638.997H6.582c-.998 0-1.781-.926-1.782-1.995V5.506c0-.355.214-.641.57-.641.285 0 .57.284.57.641V8.07c0 .071.071.143.214.143.071 0 .143-.071.143-.143V3.727c0-.355.214-.641.499-.641.356 0 .57.284.57.641v4.344c0 .071.071.143.142.142.071 0 .143-.071.143-.143V3.086c0-.355.285-.641.57-.641s.569.285.569.641V8.07c0 .071.071.143.071.143.071.071.071.071.143.071.071 0 .143-.071.143-.071.071-.071.071-.071.071-.143V5.791 4.225c0-.285.285-.57.57-.57s.57.284.57.641v4.985c0 .071.071.143.143.143.071 0 .143 0 .214-.071l.57-.713a.745.745 0 01.641-.356c.214-.071.427.071.641.214l.143.071-2.069 3.988z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});