define("ember-svg-jar/inlined/l-webhook-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 17h-5c-1.09-.02-1.94.92-2.5 1.9A3 3 0 112.57 15M9 3.4a4 4 0 016.52.66\"/><path d=\"M6 17l3.1-5.8a2.5 2.5 0 00.057-2.05M20.3 20.3a4 4 0 01-2.3.7m.6-8a4 4 0 013.357 3.414M12 6l.6 1M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-webhook-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});