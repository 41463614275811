define("ember-svg-jar/inlined/logo_logging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#0080D5\" d=\"M2 10v13a9 9 0 019 9h13c0-12.15-9.85-22-22-22\"/><path class=\"euiIcon__fillNegative\" d=\"M14 13.565V32h10c0-7.722-3.981-14.51-10-18.436\"/><path fill=\"#00BFB3\" d=\"M14 0v10.226C21.666 14.468 26.869 22.636 26.869 32H30V16c0-8.837-7.163-16-16-16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});