define("ember-svg-jar/inlined/pui_ladder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.343 1.024c0-.2 0-.3-.1-.499-.2-.399-.599-.599-.998-.499s-.699.399-.699.899v1.697c0 .1-.1.2-.2.2H5.554c-.1 0-.2 0-.2-.2V.925c0-.399-.2-.699-.599-.799-.3-.2-.699-.1-.899.1-.3.3-.3.499-.3.799v13.878c0 .3 0 .499.2.699.1.1.3.2.499.3h.399c.499-.2.699-.499.699-1.098v-1.198c0-.1 0-.2.2-.2h4.892c.1 0 .2 0 .2.2v1.298c0 .499.2.899.699 1.098h.399c.499-.2.699-.499.699-.998-.099-4.695-.099-9.288-.099-13.98zm-1.997 10.683H5.554c-.2 0-.2-.1-.2-.2V9.31c0-.1 0-.2.2-.2h4.892c.1 0 .2 0 .2.2v2.296c-.1.101-.1.101-.3.101zm0-4.393H5.554c-.2 0-.2 0-.2-.2V4.818c0-.1 0-.2.2-.2h4.892c.1 0 .2 0 .2.2v2.296c-.1.1-.1.2-.3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});