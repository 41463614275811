define("ember-svg-jar/inlined/ubicacion_de_ruta_de_evacuacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_RUTA_DE_EVACUACION</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><path fill=\"#FFF\" d=\"M5.066 19.776h20.966L12.032 8h14.356L45.13 24.893 26.086 43H12.404l13.151-12.931H5z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#080"
    }
  };
});