define("ember-svg-jar/inlined/l-mailbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 17a2 2 0 01-2 2H4a2 2 0 01-2-2V9.5C2 7 4 5 6.5 5H18c2.2 0 4 1.8 4 4z\"/><path d=\"M15 9h3v2M6.5 5C9 5 11 7 11 9.5V17a2 2 0 01-2 2m-3-9h1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-mailbox",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});