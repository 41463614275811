define("ember-svg-jar/inlined/l-mouse-pointer-ban", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.034 2.681a.498.498 0 01.647-.647l9 3.5a.5.5 0 01-.033.944L8.204 7.545a1 1 0 00-.66.66l-1.066 3.443a.5.5 0 01-.944.033z\"/><circle cx=\"16\" cy=\"16\" r=\"6\"/><path d=\"M11.8 11.8l8.4 8.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-mouse-pointer-ban",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});