define("ember-svg-jar/inlined/prysmex_isotype_white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#fff}</style><g id=\"Group_1_1_\" transform=\"translate(214.688 161.439)\"><path id=\"Path_1_1_\" class=\"st0\" d=\"M770.8 638.9l60.7 105H389.2l-61.6-105h443.2z\"/><path id=\"Path_2_1_\" class=\"st0\" d=\"M263.8 638.9l64.8 104.7-502.3.3L75.5 312l62.9 105.3L13 638.6c-.3.3 250.8.3 250.8.3z\"/><path id=\"Path_3_1_\" class=\"st0\" d=\"M107.3 256.3l63.2 109.4L388.9-24.7l-61.6-100.9-220 381.9z\"/><path id=\"Path_4_1_\" class=\"st0\" d=\"M357.8 146.6l-62.9 111.6 185.8 326.3h127.9L357.8 146.6z\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Capa_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 1061.5 1010.5",
      "xml:space": "preserve"
    }
  };
});