define("ember-svg-jar/inlined/l-hand-platter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 3V2m-7 8a7.1 7.1 0 0114 0M4 10h16M2 14h12a2 2 0 110 4h-2\"/><path d=\"M15.4 17.4l3.2-2.8a2 2 0 012.8 2.9l-3.6 3.3c-.7.8-1.7 1.2-2.8 1.2h-4c-1.1 0-2.1-.4-2.8-1.2L5 18m0-4v7H2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-hand-platter",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});