define("ember-svg-jar/inlined/pui_file_ppt_white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.8 0c.274 0 .537.113.726.312l3.2 3.428c.176.186.274.433.274.689V15a1 1 0 01-1 1H2a1 1 0 01-1-1V1a1 1 0 011-1h8.8zM14 5h-3.5a.5.5 0 01-.5-.5V1H2v14h12V5z\" fill=\"#fff\"/><path d=\"M4.257 10V7.09H5.46c.218 0 .406.044.564.129.159.084.282.202.368.353.086.151.13.326.13.526s-.045.376-.133.527a.886.886 0 01-.374.348 1.257 1.257 0 01-.576.124h-.742v-.554h.611a.572.572 0 00.266-.056.38.38 0 00.163-.155.48.48 0 00.055-.234.466.466 0 00-.055-.233.372.372 0 00-.163-.153.59.59 0 00-.266-.054H4.96V10h-.703zm2.61 0V7.09h1.201c.218 0 .406.044.564.129.16.084.282.202.368.353.086.151.13.326.13.526s-.045.376-.133.527a.886.886 0 01-.373.348 1.257 1.257 0 01-.577.124h-.741v-.554h.61a.572.572 0 00.266-.056.38.38 0 00.163-.155.48.48 0 00.056-.234.466.466 0 00-.056-.233.372.372 0 00-.163-.153.59.59 0 00-.266-.054H7.57V10h-.703zM9.38 7.662V7.09h2.459v.57h-.882V10h-.694V7.662h-.883z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});