define("ember-svg-jar/inlined/pui_toxic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.7 13.7l-7-12.3c-.2-.3-.4-.5-.7-.5s-.6.2-.8.6l-7 12.3c-.2.2-.3.6-.1.8.1.4.4.5.9.5h14.1c.4 0 .7-.1.9-.5 0-.3 0-.6-.3-.9zm-10-6.9c0-1.2.9-2.3 2.3-2.3s2.4 1.1 2.4 2.4c0 .7-.3 1.2-.5 1.6v.8c-.06.558-.4.7-.7.7H6.9c-.4-.2-.5-.4-.5-.7v-.9c-.5-.4-.7-1-.7-1.5v-.1zm5.4 6.2c.3.1.4.5.3.8s-.5.4-.8.3l-2.7-1.2-2.7 1.2c-.3.1-.457.107-.682-.23A.586.586 0 014.8 13l1.9-.8-1.9-1.1c-.389-.154-.4-.4-.3-.7.288-.4.462-.36.792-.16L8 11.6l2.7-1.3c.3-.1.7 0 .8.4.1.3.038.533-.3.7l-1.9.9 1.8.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});