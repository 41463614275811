define("ember-svg-jar/inlined/l-globe-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.686 15A14.5 14.5 0 0112 22a14.5 14.5 0 010-20 10 10 0 109.542 13M2 12h8.5M20 6V4a2 2 0 10-4 0v2\"/><rect width=\"8\" height=\"5\" x=\"14\" y=\"6\" rx=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-globe-lock",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});