define("ember-svg-jar/inlined/pui_upstairs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.7 10.5H8.4v1.361H7L5.993 9.054 4.3 8.259V5.9l.8 1.4 1.8.9c.2.1.5 0 .7-.2.1-.2 0-.5-.2-.7l-1.5-.7S4.447 3.3 3.518 3.3 1 5.3 1 5.3L.3 8.2c0 .3.2.5.4.6.3 0 .6-.1.6-.3l.6-2.6.6-.7v3.1c0 .2.1.3.2.6l-1.612 4.375H0V14.9c.4.3.7.6 1 .9h4.1v-1.4h4.2V13h4.2v-1.4H16V9.4h-3.3v1.1zm-8.5 1.361v1.414H2.215L3.8 9.2l1.5.6.8 2.061H4.2z\"/><circle cx=\"3.895\" cy=\"1.606\" r=\"1.239\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});