define("ember-svg-jar/inlined/l-arrow-up-z-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 8l4-4 4 4M7 4v16m8-16h5l-5 6h5m-5 10v-3.5a2.5 2.5 0 015 0V20m0-2h-5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-arrow-up-z-a",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});