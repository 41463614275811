define("ember-svg-jar/inlined/l-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.5 17a24.1 24.1 0 010-10 2 2 0 011.4-1.4 49.6 49.6 0 0116.2 0A2 2 0 0121.5 7a24.1 24.1 0 010 10 2 2 0 01-1.4 1.4 49.6 49.6 0 01-16.2 0A2 2 0 012.5 17\"/><path d=\"M10 15l5-3-5-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-youtube",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});