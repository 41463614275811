define("ember-svg-jar/inlined/l-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.106 5.553a2 2 0 001.788 0l3.659-1.83A1 1 0 0121 4.619v12.764a1 1 0 01-.553.894l-4.553 2.277a2 2 0 01-1.788 0l-4.212-2.106a2 2 0 00-1.788 0l-3.659 1.83A1 1 0 013 19.381V6.618a1 1 0 01.553-.894l4.553-2.277a2 2 0 011.788 0zm.894.211v15M9 3.236v15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-map",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});