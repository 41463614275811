define("ember-svg-jar/inlined/l-tower-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.2 12.27L20 6H4l1.8 6.27a1 1 0 00.95.73h10.5a1 1 0 00.96-.73zM8 13v9m8 0v-9M9 6l1 7m5-7l-1 7m-2-7V2m1 0h-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-tower-control",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});