define("ember-svg-jar/inlined/l-brain-circuit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 5a3 3 0 10-5.997.125 4 4 0 00-2.526 5.77 4 4 0 00.556 6.588A4 4 0 1012 18z\"/><path d=\"M9 13a4.5 4.5 0 003-4M6.003 5.125A3 3 0 006.401 6.5m-2.924 4.396a4 4 0 01.585-.396M6 18a4 4 0 01-1.967-.516M12 13h4m-4 5h6a2 2 0 012 2v1M12 8h8m-4 0V5a2 2 0 012-2\"/><circle cx=\"16\" cy=\"13\" r=\".5\"/><circle cx=\"18\" cy=\"3\" r=\".5\"/><circle cx=\"20\" cy=\"21\" r=\".5\"/><circle cx=\"20\" cy=\"8\" r=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-brain-circuit",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});