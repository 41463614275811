define("ember-svg-jar/inlined/l-brain-cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 5a3 3 0 10-5.997.142 4 4 0 00-2.526 5.77 4 4 0 00.556 6.588 4 4 0 007.636 2.106 3 3 0 00.164-.546c.028-.13.306-.13.335 0a3 3 0 00.163.546 4 4 0 007.636-2.106 4 4 0 00.556-6.588 4 4 0 00-2.526-5.77A3 3 0 1012 5\"/><path d=\"M17.599 6.5a3 3 0 00.399-1.375m-11.995 0A3 3 0 006.401 6.5m-2.924 4.396a4 4 0 01.585-.396m15.876 0a4 4 0 01.585.396M6 18a4 4 0 01-1.967-.516m15.934 0A4 4 0 0118 18\"/><circle cx=\"12\" cy=\"12\" r=\"3\"/><path d=\"M15.7 10.4l-.9.4m-5.6 2.4l-.9.4m5.3 2.1l-.4-.9m-2.4-5.6l-.4-.9m5.3 5.2l-.9-.4m-5.6-2.2l-.9-.4m2.2 5.2l.4-.9m2.2-5.6l.4-.9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-brain-cog",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});