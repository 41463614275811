define("ember-svg-jar/inlined/pui_sweat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8.317\" cy=\"5.375\" r=\"3.5\"/><path d=\"M11.717 8.275c-.8 1-2 1.6-3.4 1.6s-2.5-.7-3.4-1.6c-1.7 1.1-2.8 3-2.8 5.2 0 3.4 12.3 3.4 12.3 0 .1-2.2-1.1-4.1-2.7-5.2zM4.527 2.799c0 .84-.701 1.516-1.55 1.474-.824-.04-1.44-.817-1.397-1.641C1.655 1.165 2.708.223 2.985-.001A.106.106 0 013.12 0c.292.232 1.407 1.221 1.407 2.799z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});