define("ember-svg-jar/inlined/l-worm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 12l-1.5 3m2.13 3.81L22 20M6.47 8.23a1.68 1.68 0 012.44 1.93l-.64 2.08a6.76 6.76 0 0010.16 7.67l.42-.27a1 1 0 10-2.73-4.21l-.42.27a1.76 1.76 0 01-2.63-1.99l.64-2.08A6.66 6.66 0 003.94 3.9l-.7.4a1 1 0 102.55 4.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-worm",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});