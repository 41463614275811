define("ember-svg-jar/inlined/l-pizza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 11h.01M11 15h.01M16 16h.01M2 16l20 6-6-20A20 20 0 002 16M5.71 17.11a17.04 17.04 0 0111.4-11.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pizza",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});