define("ember-svg-jar/inlined/pui_car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.79 6.448l.203-1.061a.412.412 0 00-.073-.324.347.347 0 00-.279-.143h-1.079l-.352-.943-.009-.033C13.723 2.415 13.046 1 11.33 1H4.767C3.008 1 2.386 2.45 1.9 3.936l-.384.984H.36a.35.35 0 00-.279.143.409.409 0 00-.073.324L.21 6.448c.035.181.182.31.353.31h.236l-.321.824a2.374 2.374 0 00-.323 1.195v1.132c0 .175.019.346.056.511a.965.965 0 00-.056.32v3.366c0 .494.371.894.828.894h1.718c.457 0 .828-.4.828-.894v-1.683h9.004v1.683c0 .494.371.894.828.894h1.62c.458 0 .828-.4.828-.894v-3.367a.936.936 0 00-.056-.32c.036-.165.056-.336.056-.511V8.777c0-.332-.07-.65-.195-.94a3.5 3.5 0 00-.046-.222l-.32-.856h.189a.37.37 0 00.353-.311zM3.351 4.293c.495-1.508.893-1.968 1.698-1.968h6.001c.799 0 1.244.513 1.697 1.961l.654 1.801H2.734l.617-1.794zm1.056 5.993l-2.943.05c-.068.001-.123-.058-.123-.131V8.877c0-.072.054-.131.121-.131H3.49c.038 0 .073.019.096.051l.914 1.278c.062.086.007.209-.093.211zm10.145-.081c0 .073-.055.132-.123.131l-2.943-.05c-.1-.002-.155-.126-.094-.211l.914-1.278a.118.118 0 01.096-.051h2.029c.067 0 .121.059.121.131v1.328z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});