define("ember-svg-jar/inlined/l-beer-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 13v5m4-6.53V8m0 3h1a3 3 0 012.745 4.211M2 2l20 20M5 8v12a2 2 0 002 2h8a2 2 0 002-2v-3\"/><path d=\"M7.536 7.535C6.766 7.649 6.154 8 5.5 8a2.5 2.5 0 01-1.768-4.268m4.995-.528C9.306 2.767 9.885 2 11 2c1.56 0 2 1.5 3 1.5s1.72-.5 2.5-.5a1 1 0 110 5c-.78 0-1.5-.5-2.5-.5a3.2 3.2 0 00-.842.12M9 14.6V18\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-beer-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});