define("ember-svg-jar/inlined/uso_obligatorio_de_guantes_de_proteccion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>USO_OBLIGATORIO_DE_GUANTES_DE_PROTECCION</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#00438C\" d=\"M0 0h50v50H0z\"/><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-2.222c9.818 0 17.778-7.96 17.778-17.778 0-9.818-7.96-17.778-17.778-17.778-9.818 0-17.778 7.96-17.778 17.778 0 9.818 7.96 17.778 17.778 17.778z\" fill=\"#FFF\"/><g fill-rule=\"nonzero\" fill=\"#FFF\"><path d=\"M25.22 18.686l.18.1c.322.181.544.495.61.863l.159.892a.1.1 0 00.098.084.1.1 0 00.094-.067l.941-2.663a.922.922 0 00-.03-.696.882.882 0 00-1.643.077l-.455 1.286c-.017.047.003.1.046.124zM26.904 20.079a.1.1 0 00.063.051l.255.067c.3.08.557.272.723.543l.261.426a.1.1 0 00.095.047.1.1 0 00.084-.067l1.374-3.887a.922.922 0 00-.031-.696.882.882 0 00-1.643.077L26.9 19.997a.104.104 0 00.005.082z\"/><path d=\"M27.77 21.68c0-.502-.4-.91-.89-.91s-.89.408-.89.91v3.522h-.32v-5.046a.9.9 0 00-.89-.91.9.9 0 00-.89.91v5.046h-.319v-6.497c0-.501-.399-.91-.89-.91a.9.9 0 00-.89.91v6.497h-.319v-5.046a.9.9 0 00-.89-.91.9.9 0 00-.89.91v5.759l-1.022-1.206c-.183-.215-.407-.355-.631-.392a.772.772 0 00-.644.2c-.443.391-.507.896-.196 1.544l2.496 4.877-.33 3.949a.104.104 0 00.026.077.099.099 0 00.074.033h8.548a.099.099 0 00.074-.033.103.103 0 00.026-.077l-.343-3.933V21.68zM32.404 20.349a.882.882 0 00-1.14.546l-1.17 3.313-.301-.11 1.678-4.746a.916.916 0 00-.535-1.164.868.868 0 00-.302-.054.895.895 0 00-.837.6l-1.442 4.079a.103.103 0 00-.006.034v7.801l.282 3.242a.1.1 0 00.1.093.1.1 0 00.095-.074l1.03-3.674 3.083-8.722a.916.916 0 00-.535-1.164z\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#00438c"
    }
  };
});