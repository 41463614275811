define("ember-svg-jar/inlined/l-map-pin-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 22a1 1 0 01-1-1v-4a1 1 0 01.445-.832l3-2a1 1 0 011.11 0l3 2A1 1 0 0122 17v4a1 1 0 01-1 1zm3-12a8 8 0 00-16 0c0 4.993 5.539 10.193 7.399 11.799a1 1 0 00.601.2M18 22v-3\"/><circle cx=\"10\" cy=\"10\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-map-pin-house",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});