define("ember-svg-jar/inlined/l-refresh-cw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 12a9 9 0 019-9 9.75 9.75 0 016.74 2.74L21 8\"/><path d=\"M21 3v5h-5m5 4a9 9 0 01-9 9 9.75 9.75 0 01-6.74-2.74L3 16\"/><path d=\"M8 16H3v5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-refresh-cw",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});