define("ember-svg-jar/inlined/pui_eating_area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.917 7.327l5.872-6.277c.253-.254.366-.479-.169-1.015-.178-.179-.611.239-5.483 3.35-.675.677-1.625 1.847-1.68 2.192-.054.344-.039.845-.039.845l-.48.444-1.118-1.05s-.07-.807-.042-1.202c.052-.736-.211-1.369-.766-1.841A222.934 222.934 0 002.889.154c-.198-.161-.426-.159-.579.008-.146.158-.138.394.034.568.483.491 1.31 1.322 1.796 1.81.286.287.575.57.858.86.157.161.157.357.015.507-.133.143-.324.149-.496.012-.05-.039-.093-.085-.138-.13L1.899 1.3c-.037-.038-.073-.077-.114-.11a.374.374 0 00-.503.002c-.167.149-.199.344-.079.523.04.06.093.113.145.165L3.85 4.393c.041.041.083.081.118.127a.342.342 0 01-.024.461c-.15.161-.362.15-.555-.04-.381-.377-.758-.759-1.137-1.139h-.001l-.245-.246c-.301-.301-.941-.949-1.244-1.248-.175-.173-.386-.187-.55-.042-.165.144-.179.357-.037.551.031.043.061.086.094.127.684.84 1.718 2.016 2.388 2.866.572.724 1.298 1.027 2.211.936l.912-.094 1.102 1.189-6.566 6.32c-.337.338-.422.761-.169 1.015l.675.677c.253.254.675.169 1.012-.169l6.043-6.767 6.289 6.767c.337.338.759.423 1.012.169l.675-.677c.253-.254.169-.677-.169-1.015L8.922 7.789l.458-.494c-.001 0 .318.251.537.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});