define("ember-svg-jar/inlined/tokenElement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.39 9.736l-1.041.94L3.258 8l3.09-2.677 1.041.94-2.032 1.722v.03l2.032 1.721zm2.777.94l-1.04-.94 2.032-1.721v-.03L9.126 6.264l1.04-.94L13.259 8l-3.091 2.677z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});