define("ember-route-helpers/helpers/replace-with-external", ["exports", "@ember/component/helper", "@ember/application"], function (_exports, _helper, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Given this "external routes":
   *
   * ```js
   * // host app
   * dependencies: {
   *   externalRoutes: {
   *     posts: 'blog.posts'
   *   }
   * }
   * ```
   *
   * ```js
   * // engine
   * dependencies: {
   *   externalRoutes: [
   *     'posts'
   *   ]
   * }
   * ```
   *
   * You can then use those external routes within a template:
   *
   * ```hbs
   * {{my-component onclick=(replace-with-external 'posts' (query-params id=3 comments=true)}}
   * ```
   */
  var _default = _exports.default = _helper.default.extend({
    compute([...params]) {
      const _params = params.slice();
      const owner = (0, _application.getOwner)(this);
      const router = owner.lookup('router:main');
      const queryParams = _params[_params.length - 1];
      _params[0] = owner._getExternalRoute(_params[0]);
      if (queryParams.isQueryParams) {
        _params[_params.length - 1] = {
          queryParams: queryParams.values
        };
      }
      return function (...invocationArgs) {
        router.replaceWith(...[..._params, ...invocationArgs]);
      };
    }
  });
});