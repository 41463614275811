define("ember-svg-jar/inlined/l-tangent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"17\" cy=\"4\" r=\"2\"/><path d=\"M15.59 5.41L5.41 15.59\"/><circle cx=\"4\" cy=\"17\" r=\"2\"/><path d=\"M12 22s-4-9-1.5-11.5S22 12 22 12\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-tangent",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});