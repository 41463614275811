define("ember-svg-jar/inlined/pui_ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"3.566\" cy=\"12.65\" r=\".498\"/><path d=\"M11.974 0l-.861.861 2.192 2.192a.258.258 0 11-.365.365l-2.094-2.094a.136.136 0 00-.194 0l-.95.948a.136.136 0 000 .194l1.365 1.365a.258.258 0 11-.365.365L9.337 2.831a.136.136 0 00-.194 0l-.949.949a.136.136 0 000 .194l2.094 2.094a.258.258 0 11-.365.365L7.829 4.34a.136.136 0 00-.194 0l-.949.949a.136.136 0 000 .194L8.05 6.848a.258.258 0 11-.365.365L6.321 5.848a.136.136 0 00-.194 0l-.95.949a.136.136 0 000 .194l2.094 2.094a.258.258 0 11-.365.365L4.812 7.357a.136.136 0 00-.194 0l-.949.949a.136.136 0 000 .194l1.365 1.365a.258.258 0 11-.365.365L3.304 8.865a.136.136 0 00-.194 0L.098 11.877a.136.136 0 000 .194l3.831 3.831a.136.136 0 00.194 0l11.78-11.78a.136.136 0 000-.194L11.974 0zM2.74 13.476a1.168 1.168 0 111.653-1.652 1.168 1.168 0 01-1.653 1.652z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});