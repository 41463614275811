define("ember-svg-jar/inlined/l-hand-metal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 12.5V10a2 2 0 00-2-2 2 2 0 00-2 2v1.4m0-.4V9a2 2 0 10-4 0v2m0-.5V5a2 2 0 10-4 0v9\"/><path d=\"M7 15l-1.76-1.76a2 2 0 00-2.83 2.82l3.6 3.6C7.5 21.14 9.2 22 12 22h2a8 8 0 008-8V7a2 2 0 10-4 0v5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-hand-metal",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});