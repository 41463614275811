define("ember-svg-jar/inlined/l-package-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 22v-9m3.17-10.79a1.67 1.67 0 011.63 0L21 4.57a1.93 1.93 0 010 3.36L8.82 14.79a1.66 1.66 0 01-1.64 0L3 12.43a1.93 1.93 0 010-3.36z\"/><path d=\"M20 13v3.87a2.06 2.06 0 01-1.11 1.83l-6 3.08a1.93 1.93 0 01-1.78 0l-6-3.08A2.06 2.06 0 014 16.87V13\"/><path d=\"M21 12.43a1.93 1.93 0 000-3.36L8.83 2.2a1.64 1.64 0 00-1.63 0L3 4.57a1.93 1.93 0 000 3.36l12.18 6.86a1.64 1.64 0 001.63 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-package-open",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});