define("ember-link/services/link-manager", ["exports", "@ember/application", "@ember/object", "@ember/object/events", "@ember/service", "@glimmer/tracking", "ember-link/link"], function (_exports, _application, _object, _events, _service, _tracking, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LinkManagerService = _exports.default = (_dec = (0, _service.inject)('router'), _dec2 = (0, _service.inject)('-routing'), _class = class LinkManagerService extends _service.default {
    /**
     * Whether the router has been initialized.
     * This will be `false` in render tests.
     *
     * @see https://github.com/buschtoens/ember-link/issues/126
     */
    get isRouterInitialized() {
      // Ideally we would use the public `router` service here, but accessing
      // the `currentURL` on that service automatically starts the routing layer
      // as a side-effect, which is not our intention here. Once or if Ember.js
      // provides a flag on the `router` service to figure out if routing was
      // already initialized we should switch back to the public service instead.
      //
      // Inspiration for this workaround was taken from the `currentURL()` test
      // helper (see https://github.com/emberjs/ember-test-helpers/blob/v2.1.4/addon-test-support/@ember/test-helpers/setup-application-context.ts#L180)

      // eslint-disable-next-line ember/no-private-routing-service
      return Boolean((0, _application.getOwner)(this).lookup('router:main').currentURL);
    }

    /**
     * The currently active `Transition` objects.
     */
    get currentTransitionStack() {
      return this._currentTransitionStack;
    }

    /**
     * Creates a `Link` instance.
     */
    createLink(linkParams) {
      return new _link.default(this, linkParams);
    }

    /**
     * Creates a `UILink` instance.
     */
    createUILink(linkParams, uiParams) {
      return new _link.UILink(this, {
        ...linkParams,
        ...uiParams
      });
    }

    /**
     * Deserializes the `LinkParams` to be passed to `createLink` / `createUILink`
     * from a URL.
     *
     * If the URL cannot be recognized by the router, an error is thrown.
     */
    getLinkParamsFromURL(url) {
      const routeInfo = this.router.recognize(url);
      return LinkManagerService.getLinkParamsFromRouteInfo(routeInfo);
    }

    /**
     * Converts a `RouteInfo` object into `LinkParams`.
     */
    static getLinkParamsFromRouteInfo(routeInfo) {
      const models = routeInfo.paramNames.map(name => routeInfo.params[name]);
      return {
        route: routeInfo.name,
        query: routeInfo.queryParams,
        models
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(properties) {
      super(properties);

      // Ignore `Argument of type ''routeWillChange'' is not assignable to parameter of type ...`

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _initializerDefineProperty(this, "_currentTransitionStack", _descriptor, this);
      /**
       * The `RouterService` instance to be used by the generated `Link` instances.
       */
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "routing", _descriptor3, this);
      (0, _events.addListener)(this.router, 'routeWillChange', this.handleRouteWillChange);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (0, _events.addListener)(this.router, 'routeDidChange', this.handleRouteDidChange);
    }
    willDestroy() {
      super.willDestroy();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (0, _events.removeListener)(this.router, 'routeWillChange', this.handleRouteWillChange);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (0, _events.removeListener)(this.router, 'routeDidChange', this.handleRouteDidChange);
    }
    handleRouteWillChange(transition) {
      this._currentTransitionStack = [...(this._currentTransitionStack || []), transition];
    }
    handleRouteDidChange() {
      this._currentTransitionStack = undefined;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_currentTransitionStack", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "routing", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteDidChange"), _class.prototype), _class);
});