define("ember-svg-jar/inlined/pivot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2.89 13.847L11.239 5.5a.522.522 0 00-.737-.737L2.154 13.11a.522.522 0 00.738.738zM14 6.696a.522.522 0 11-1.043 0v-3.13a.522.522 0 00-.522-.523h-3.13a.522.522 0 110-1.043h3.13C13.299 2 14 2.7 14 3.565v3.13z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});