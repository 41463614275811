define("ember-svg-jar/inlined/l-turtle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 10l2 4v3a1 1 0 001 1h2a1 1 0 001-1v-3a8 8 0 10-16 0v3a1 1 0 001 1h2a1 1 0 001-1v-3l2-4zM4.82 7.9L8 10m7.18-2.1L12 10\"/><path d=\"M16.93 10H20a2 2 0 010 4H2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-turtle",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});