define("ember-svg-jar/inlined/l-church", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 9h4m-2-2v5m2 10v-4a2 2 0 00-4 0v4\"/><path d=\"M18 22V5.618a1 1 0 00-.553-.894l-4.553-2.277a2 2 0 00-1.788 0L6.553 4.724A1 1 0 006 5.618V22\"/><path d=\"M18 7l3.447 1.724a1 1 0 01.553.894V20a2 2 0 01-2 2H4a2 2 0 01-2-2V9.618a1 1 0 01.553-.894L6 7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-church",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});