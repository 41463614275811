define("ember-svg-jar/inlined/l-land-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 8l6-3-6-3v10\"/><path d=\"M8 11.99l-5.5 3.14a1 1 0 000 1.74l8.5 4.86a2 2 0 002 0l8.5-4.86a1 1 0 000-1.74L16 12m-9.51.85l11.02 6.3m0-6.3L6.5 19.15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-land-plot",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});