define("ember-svg-jar/inlined/pui_mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.509 5.678h-.894c-.994 0-1.888-.497-2.484-1.193-.795-.994-1.988-1.59-3.18-1.59s-2.386.596-3.081 1.59c-.597.696-1.491 1.193-2.485 1.193h-.894C.696 5.678 0 6.274 0 7.069c0 2.286 1.789 4.075 4.075 4.174 1.789 2.186 4.969 2.484 7.155.696.298-.199.497-.497.696-.696 2.186-.099 3.975-1.888 4.075-4.174-.1-.696-.697-1.391-1.492-1.391.1 0 .1 0 0 0zM.894 6.97c0-.298.199-.497.497-.497h.596c.199 1.292.696 2.484 1.293 3.577C1.888 9.653.994 8.46.894 6.97zm5.665-1.292h2.783v.894H6.559v-.894zm2.882 4.671H6.559v-.994h2.783v.994h.099zM11.23 8.46H4.77v-.894h6.46v.894zm1.49 1.59c.696-1.093 1.093-2.385 1.391-3.578h.497c.199.099.298.298.398.497a3.22 3.22 0 01-2.286 3.081z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});