define("ember-svg-jar/inlined/riesgo_de_caida_de_cargas_suspendidas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_DE_CAIDA_DE_CARGAS_SUSPENDIDAS</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M26.22 27.852a.336.336 0 00-.445.156c-.165.344-.442.34-.593.306-.235-.052-.485-.256-.485-.533 0-.23.104-.358.303-.582.246-.278.553-.625.553-1.295h.481l1.67-2.848-.986-1.774h-1.13v-2.739h-1.002v2.74h-1.13l-.986 1.773 1.669 2.848h.747c0 .424-.172.618-.39.863-.208.235-.466.527-.466 1.014 0 .551.423 1.045 1.006 1.174.092.02.184.03.274.03.454 0 .856-.254 1.068-.695a.326.326 0 00-.158-.438zm-2.487-5.091v-.548h2.708v.548h-2.708z\" fill=\"#000\" fill-rule=\"nonzero\"/><path fill=\"#000\" d=\"M24.772 28.414l-5.546 5.525.94.21 4.985-5.42 1.745 2.326-.114-.72 1.661 1.634-2.246-3.334-.594-.055z\"/><path fill=\"#000\" d=\"M21.58 32.88l-3.957 1.916-.018 2.088.906-.11 3.834-2.044.013-1.796z\"/><path fill=\"#000\" d=\"M23.287 33.179l-.12 1.859-3.986 2.351 3.94 1.366 4.097-2.65.068-1.55z\"/><path fill=\"#000\" d=\"M27.88 35.005l-.076 1.53-3.976 2.436 1.823.823 3.86-2.46.08-1.85z\"/><path stroke=\"#000\" d=\"M17.667 36.85l8.009 2.906 3.807-2.442.083-1.9-7.882-2.53-3.978 1.897z\"/><path fill=\"#000\" d=\"M28.13 32.308l.847 1.628.418 1.331h.372l-.092-1.718-.698-.34z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});