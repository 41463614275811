define("ember-svg-jar/inlined/pui_mixer_truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"3.513\" cy=\"13.04\" r=\"1.63\"/><circle cx=\"7.899\" cy=\"13.04\" r=\"1.63\"/><circle cx=\"12.739\" cy=\"13.04\" r=\"1.63\"/><path d=\"M16 12.382l-.022-2.866c0-.006-.002-.012-.004-.018L14.639 6.88a.597.597 0 00-.597-.597h-2.42a.597.597 0 00-.597.597v.267l-1.075.001a2.971 2.971 0 00-1.38-3.601l-1.717-.931a.04.04 0 00-.054.015L3.48 8.766l.934.644H2.302a.039.039 0 01-.039-.039V7.927l.391.27 3.269-6.041a.04.04 0 00-.016-.053l-.489-.266C3.93 1.03 2.006 1.289.956 2.616a3.394 3.394 0 00.299 4.545.036.036 0 01.011.026V9.41H.605l-.6 2.452a.039.039 0 00.008.034l1.08 1.346h.205c-.006-.067-.02-.133-.02-.202a2.235 2.235 0 014.397-.559c.01.039.054.039.064 0A2.232 2.232 0 017.9 10.805a2.235 2.235 0 012.235 2.235c0 .069-.014.134-.02.202h.41c-.006-.067-.02-.133-.02-.202a2.235 2.235 0 114.47 0c0 .065-.014.126-.019.19l1.029-.817a.04.04 0 00.015-.031zM11.025 9.41h-2.59c.18-.162.349-.339.49-.549l.482-.718h1.619V9.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});