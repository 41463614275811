define("ember-svg-jar/inlined/pui_category_food_and_hospitality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.446 23.005c1.717 0 3.148 1.232 4.578 1.232 3.434 0 6.868-9.295 6.868-14.198a5.744 5.744 0 00-1.735-3.973 5.575 5.575 0 00-3.988-1.58c-2.541 0-4.579 1.673-5.723 2.323-1.145-.65-3.182-2.324-5.723-2.324a5.563 5.563 0 00-3.992 1.577A5.733 5.733 0 001 10.04c0 4.903 3.434 14.197 6.868 14.197 1.43 0 2.861-1.231 4.578-1.231zM10.157 1c1.144.58 2.289 2.324 2.289 5.81\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "26",
      "viewBox": "0 0 25 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});