define("ember-svg-jar/inlined/list_add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 11H9v1h2v2h1v-2h2v-1h-2V9h-1v2zM7.758 9a4.5 4.5 0 11-.502 4H6v-1h1.027a4.548 4.548 0 01.23-2H6V9h1.758zM2 4V3h2v1H2zm4 0V3h8v1H6zm0 3V6h8v1H6zM2 7V6h2v1H2zm0 3V9h2v1H2zm0 3v-1h2v1H2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});