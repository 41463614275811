define("ember-svg-jar/inlined/ubicacion_de_estaciones_y_botiquin_de_primeros_auxilios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_ESTACIONES_Y_BOTIQUIN_DE_PRIMEROS_AUXILIOS</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><path d=\"M9 41.615H6L10.5 47l4.5-5.385h-3V33H9v8.615zM30.02 16.98V6H19.98v10.98H9v10.04h10.98V38h10.04V27.02H41V16.98H30.02z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#080"
    }
  };
});