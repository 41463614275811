define("ember-svg-jar/inlined/pui_barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.494 13.266a.218.218 0 01-.203.203H5.063c-.101 0-.203 0-.203-.101-.405-1.114-.608-2.329-.709-3.544 0-.101 0-.203.203-.203h3.038c.101 0 .203.101.203.203-.101.606-.101 2.935-.101 3.442zM2.228 9.62h.709c.101 0 .203 0 .203.203a14.66 14.66 0 00.709 3.544c0 .101 0 .203-.101.203H2.229a.218.218 0 01-.203-.203c-.304-1.114-.608-2.228-.709-3.443 0-.304 0-.304.203-.304h.708zM13.449 14.334H2.283l.633 1.41c.07.156.225.256.396.256h9.146a.435.435 0 00.4-.265l.591-1.401zM14.505 8.81H1.495a.28.28 0 01-.279-.279V7.469a.28.28 0 01.279-.279h13.011a.28.28 0 01.279.279v1.062a.28.28 0 01-.28.279zM8.405 9.823c0-.101.101-.203.203-.203h3.038c.203 0 .203.101.203.203-.101 1.215-.304 2.43-.709 3.544 0 .101-.101.101-.203.101H8.709a.218.218 0 01-.203-.203c0-.506 0-2.835-.101-3.442zM14.481 9.62c.203 0 .203 0 .203.304-.101 1.215-.405 2.329-.709 3.443a.218.218 0 01-.203.203h-1.519c-.101 0-.101-.101-.101-.203a14.66 14.66 0 00.709-3.544c0-.203.101-.203.203-.203H14.481zM7.595 6.177a.218.218 0 01-.203.203H4.354c-.203 0-.203-.101-.203-.203.101-1.215.304-2.43.709-3.544 0-.101.101-.101.203-.101h2.228c.101 0 .203.101.203.203 0 .506 0 2.835.101 3.442zM1.519 6.38c-.203 0-.203 0-.203-.304.101-1.215.405-2.329.709-3.443 0-.101.101-.203.203-.203h1.519c.101 0 .101.101.101.203-.405 1.215-.607 2.329-.709 3.544 0 .203-.101.203-.203.203H1.519zM13.449 1.666H2.283l.633-1.41A.434.434 0 013.312 0h9.146c.175 0 .332.105.4.265l.591 1.401zM8.506 2.734c0-.101.101-.203.203-.203h2.228c.101 0 .203 0 .203.101.405 1.114.608 2.329.709 3.544 0 .101 0 .203-.203.203H8.608a.218.218 0 01-.203-.203c.101-.606.101-2.935.101-3.442zM13.772 6.38h-.709c-.101 0-.203 0-.203-.203a14.66 14.66 0 00-.709-3.544c0-.101 0-.203.101-.203h1.519c.101 0 .203.101.203.203.304 1.114.608 2.228.709 3.443 0 .304 0 .304-.203.304h-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});