define("ember-svg-jar/inlined/l-flame-kindling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2c1 3 2.5 3.5 3.5 4.5A5 5 0 0117 10a5 5 0 11-10 0c0-.3 0-.6.1-.9a2 2 0 103.3-2C8 4.5 11 2 12 2M5 22l14-4M5 18l14 4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-flame-kindling",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});