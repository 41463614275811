define("ember-svg-jar/inlined/pui_broom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.445.284a.97.97 0 00-1.372 0l-6.92 6.92-.515-.515a.97.97 0 10-1.372 1.372l.461.461.029.078-.215.216a1.759 1.759 0 00-.215-.269c-.819-.104-1.655.438-2.435 1.145L.367 11.73c-.097.097-.097.194-.097.388.194.582.388 1.165.776 1.553l1.941-1.844.291.388-1.941 1.844.679.776 2.523-2.62.388.291-2.523 2.62c.485.388.97.679 1.65.873.194 0 .194-.097.291-.194l2.232-2.523c.788-.997 1.057-1.66.995-2.634l-.607-.472.085-.127.102-.102.432.432a.973.973 0 001.373 0 .97.97 0 000-1.372l-.431-.431 6.92-6.919a.972.972 0 00-.001-1.373z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});