define("ember-svg-jar/inlined/prohibido_el_paso_a_personas_con_marcapasos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_PERSONAS_PRESION_ALTA_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-3.077c9.346 0 16.923-7.577 16.923-16.923 0-9.346-7.577-16.923-16.923-16.923-9.346 0-16.923 7.577-16.923 16.923 0 9.346 7.577 16.923 16.923 16.923z\" fill=\"#E11212\"/><path d=\"M26.591 30.394v3.205c0 2.62-1.86 4.753-4.145 4.753-2.286 0-4.145-2.132-4.145-4.753v-2.64c-1.061-.192-1.862-1.026-1.862-2.023 0-1.139 1.044-2.065 2.328-2.065 1.283 0 2.328.926 2.328 2.065 0 .997-.801 1.831-1.862 2.023v2.64c0 2.165 1.441 3.927 3.213 3.927s3.213-1.762 3.213-3.927v-3.39l-7.364-6.748c-1.106-.885-1.808-2.183-1.808-3.635 0-2.665 2.358-4.827 5.267-4.827 1.9 0 3.559.925 4.486 2.307.926-1.382 2.585-2.307 4.485-2.307 2.909 0 5.267 2.162 5.267 4.827 0 1.452-.702 2.75-1.808 3.635L26.72 30.3a.675.675 0 01-.129.093z\" fill=\"#000\" fill-rule=\"nonzero\"/><path fill=\"#E11212\" d=\"M11.707 13.839l24.612 24.599 2.296-2.028-24.962-24.896z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});