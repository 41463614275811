define("ember-route-helpers/helpers/transition-to", ["exports", "@ember/application", "@ember/component/helper", "ember-route-helpers/utils/mount-point"], function (_exports, _application, _helper, _mountPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    compute([...params]) {
      const _params = params.slice();
      const owner = (0, _application.getOwner)(this);
      const router = owner.lookup('router:main');
      const queryParams = _params[_params.length - 1];
      const routeName = _params[0];

      // Prefix the route name with the engine's mountPoint, if exists
      _params[0] = (0, _mountPoint.prefixMountPoint)((0, _mountPoint.getMountPoint)(owner), routeName);
      if (queryParams.isQueryParams) {
        _params[_params.length - 1] = {
          queryParams: queryParams.values
        };
      }
      return function (...invocationArgs) {
        router.transitionTo(...[..._params, ...invocationArgs]);
      };
    }
  });
});