define("ember-svg-jar/inlined/l-gitlab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 13.29l-3.33-10a.4.4 0 00-.14-.18.38.38 0 00-.22-.11.4.4 0 00-.23.07.4.4 0 00-.14.18l-2.26 6.67H8.32L6.1 3.26a.4.4 0 00-.1-.18.38.38 0 00-.26-.08.4.4 0 00-.23.07.4.4 0 00-.14.18L2 13.29a.74.74 0 00.27.83L12 21l9.69-6.88a.71.71 0 00.31-.83\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-gitlab",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});