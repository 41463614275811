define("ember-svg-jar/inlined/pui_flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.21 13.27l-4.222-7.741V.804c0-.402-.402-.804-.805-.804H5.861a.793.793 0 00-.805.804v4.624L.834 13.17c-.302.603-.302 1.307 0 1.91s1.005 1.005 1.709.905H13.4c.704 0 1.307-.302 1.709-.905.302-.402.403-1.207.101-1.81zm-2.111.704c-.201.302-.402.402-.704.402H3.448c-.302 0-.603-.101-.704-.402-.101-.302-.101-.603 0-.804l1.005-2.011c.101-.302.402-.503.704-.402 1.508.101 3.016 1.407 4.524 1.307.804-.101 1.508-.302 2.212-.704.302-.201.804-.101 1.005.201l.804 1.407c.302.503.302.805.101 1.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});