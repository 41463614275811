define("ember-svg-jar/inlined/l-beef", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12.5\" cy=\"8.5\" r=\"2.5\"/><path d=\"M12.5 2a6.5 6.5 0 00-6.22 4.6c-1.1 3.13-.78 3.9-3.18 6.08A3 3 0 005 18c4 0 8.4-1.8 11.4-4.3A6.5 6.5 0 0012.5 2\"/><path d=\"M18.5 6l2.19 4.5a6.5 6.5 0 01.31 2 6.49 6.49 0 01-2.6 5.2C15.4 20.2 11 22 7 22a3 3 0 01-2.68-1.66L2.4 16.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-beef",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});