define("ember-svg-jar/inlined/l-file-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 11a5 5 0 010 6m3-15v4a2 2 0 002 2h4\"/><path d=\"M4 6.765V4a2 2 0 012-2h9l5 5v13a2 2 0 01-2 2H6a2 2 0 01-.93-.23\"/><path d=\"M7 10.51a.5.5 0 00-.826-.38l-1.893 1.628A1 1 0 013.63 12H2.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h1.129a1 1 0 01.652.242l1.893 1.63a.5.5 0 00.826-.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-file-volume",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});