define("ember-svg-jar/inlined/l-siren", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 18v-6a5 5 0 1110 0v6M5 21a1 1 0 001 1h12a1 1 0 001-1v-1a2 2 0 00-2-2H7a2 2 0 00-2 2zm16-9h1m-3.5-7.5L18 5M2 12h1m9-10v1M4.929 4.929l.707.707M12 12v6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-siren",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});