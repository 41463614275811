define("ember-svg-jar/inlined/wall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M127 40l-24 2.805V184l22.189-3.74L127 40z\" fill=\"#767B7E\"/><path d=\"M11.673 0L127 39.728 102.254 43 0 7.01 11.673 0z\" fill=\"#CCD5D8\"/><path d=\"M0 127.81V7l103 36.056V184L0 127.81z\" fill=\"#83898C\"/>",
    "attrs": {
      "width": "127",
      "height": "184",
      "viewBox": "0 0 127 184",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});