define("ember-svg-jar/inlined/sessionViewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 6h1v1H4V6zm2 2H5V7h1v1zm0 1V8h1v1H6zm-1 1V9h1v1H5zm0 0v1H4v-1h1zm2 0h4v1H7v-1z\"/><path fill-rule=\"evenodd\" d=\"M1 3a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1H2a1 1 0 01-1-1V3zm1 1h12v9H2V4z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});