define("ember-svg-jar/inlined/l-pen-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.707 21.293a1 1 0 01-1.414 0l-1.586-1.586a1 1 0 010-1.414l5.586-5.586a1 1 0 011.414 0l1.586 1.586a1 1 0 010 1.414z\"/><path d=\"M18 13l-1.375-6.874a1 1 0 00-.746-.776L3.235 2.028a1 1 0 00-1.207 1.207L5.35 15.879a1 1 0 00.776.746L13 18M2.3 2.3l7.286 7.286\"/><circle cx=\"11\" cy=\"11\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pen-tool",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});