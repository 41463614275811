define("ember-svg-jar/inlined/l-file-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7z\"/><circle cx=\"10\" cy=\"16\" r=\"2\"/><path d=\"M16 10l-4.5 4.5M15 11l1 1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-file-key",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});