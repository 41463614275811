define("ember-svg-jar/inlined/pui_trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.9 1.6L10.2.2C10.1.1 10 0 9.8 0H6c-.2 0-.3.1-.4.3l-.7 1.3c-.1.2-.2.3-.4.3H.9v.9h1l.9 12.8c0 .2.2.4.5.4h9.4c.2 0 .4-.2.5-.4l.9-12.8h1v-.9h-3.6c-.4 0-.5-.1-.6-.3zM6.3.9h3.2l.4 1h-4l.4-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});