define("ember-svg-jar/inlined/pui_roll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Artboard 18</title><path d=\"M4.86 0c1.1 0 2.1.3 3.1.7.4.2.7.3 1 .6.3.2.5.5.5.8V15h5.1c.6 0 1.2-.5 1.2-1.2V.5c0-.3-.2-.5-.4-.5H4.86z\"/><path d=\"M4.308.428C2.216.428.52 1.2.52 2.152s1.696 1.724 3.788 1.724 3.788-.772 3.788-1.724S6.4.428 4.308.428zm0 2.388c-.805 0-1.458-.297-1.458-.664s.653-.664 1.458-.664 1.458.297 1.458.664-.652.664-1.458.664z\"/><path d=\"M4.308 4.626c-1.567 0-3.13-.42-3.948-1.216v11.083c0 .236.124.452.331.563.603.325 1.976.944 3.669.944s3.066-.619 3.669-.943a.633.633 0 00.331-.563V3.313c-.785.862-2.42 1.313-4.052 1.313z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});