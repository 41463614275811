define("ember-svg-jar/inlined/l-file-chart-pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 2v4a2 2 0 002 2h4\"/><path d=\"M16 22h2a2 2 0 002-2V7l-5-5H6a2 2 0 00-2 2v3.5m.017 4.012a6 6 0 108.466 8.475\"/><path d=\"M9 16a1 1 0 01-1-1v-4c0-.552.45-1.008.995-.917a6 6 0 014.922 4.922c.091.544-.365.995-.917.995z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-file-chart-pie",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});