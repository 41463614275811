define("ember-svg-jar/inlined/l-mic-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 2l20 20m-3.11-8.77A7 7 0 0019 12v-2M5 10v2a7 7 0 0012 5m-2-7.66V5a3 3 0 00-5.68-1.33\"/><path d=\"M9 9v3a3 3 0 005.12 2.12M12 19v3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-mic-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});