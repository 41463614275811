define("ember-svg-jar/inlined/l-trending-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.828 14.828L21 21m0-5v5h-5m5-18l-9 9-4-4-6 6\"/><path d=\"M21 8V3h-5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-trending-up-down",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});