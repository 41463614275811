define("ember-svg-jar/inlined/pui_hazardous_process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dangerousMachinery</title><path d=\"M13.013 2.555l-.882-.211.497-.73a.262.262 0 00-.031-.334l-.634-.634a.263.263 0 00-.324-.038l-.772.474-.164-.868a.266.266 0 00-.26-.214h-.896a.265.265 0 00-.257.202l-.21.882-.73-.497a.264.264 0 00-.335.031l-.634.634a.263.263 0 00-.038.324l.474.772-.867.165a.264.264 0 00-.215.259v.896c0 .122.084.228.202.256l.882.211-.497.73a.265.265 0 00.031.335l.634.634c.086.086.22.102.324.038l.772-.474.164.868a.266.266 0 00.26.214h.896a.263.263 0 00.256-.202l.211-.882.73.497a.265.265 0 00.335-.031l.634-.634a.263.263 0 00.038-.324l-.474-.772.867-.165a.265.265 0 00.215-.259v-.897a.263.263 0 00-.202-.256zM9.975 4.574a1.335 1.335 0 110-2.67 1.335 1.335 0 010 2.67zM15.632 7.796l-.716-.171.404-.593a.214.214 0 00-.026-.272l-.514-.515a.215.215 0 00-.264-.031l-.628.386-.134-.705a.214.214 0 00-.21-.174h-.728a.214.214 0 00-.208.164l-.171.716-.593-.404a.214.214 0 00-.272.026l-.515.515a.215.215 0 00-.031.264l.386.628-.705.134a.214.214 0 00-.174.21v.728c0 .099.068.185.164.208l.716.171-.404.593a.214.214 0 00.026.272l.515.515c.07.07.179.083.264.031l.628-.386.134.705a.214.214 0 00.21.174h.728a.214.214 0 00.208-.164l.171-.716.593.404a.214.214 0 00.272-.026l.515-.515a.215.215 0 00.031-.264l-.386-.628.705-.134a.214.214 0 00.174-.21v-.727c0-.1-.068-.186-.165-.209zm-2.468 1.641a1.084 1.084 0 110-2.168 1.084 1.084 0 010 2.168zM12.092 15.277L6.625 5.519a.486.486 0 00-.846-.002L.267 15.276A.485.485 0 00.69 16h10.978a.486.486 0 00.424-.723zM6.179 7.762c.398 0 .713.338.684.735l-.281 3.916a.43.43 0 01-.856-.006l-.232-3.918a.686.686 0 01.685-.727zm0 6.894a.71.71 0 110-1.421.71.71 0 010 1.421z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});