define("ember-svg-jar/inlined/prohibido_generar_llama_abierta_e_introducir_objetos_incandescentes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_ENCENDER_CERILLOS_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><g fill-rule=\"nonzero\" fill=\"#010101\"><path d=\"M39.259 19.296a.239.239 0 00-.26-.151.3.3 0 00-.231.232c-.136.56-.41 1.29-.958 1.808.032-.767-.2-1.832-.32-2.553-.184-1.101.401-2.245.823-2.898a.33.33 0 00.017-.333.235.235 0 00-.268-.12c-.703.18-1.843.706-2.708 2.238-.87 1.54-.907 2.872-1.068 4.386-.013-.002-.935-1.033-.44-3.67.024-.123-.023-.244-.117-.303-.093-.06-.214-.044-.301.04-.94.896-2.75 2.837-3.777 5.327-.299.727-.416 1.569-.362 2.423.55-.75 1.185-1.414 1.743-1.803 1.786-1.247 4.034-.686 5.026 1.253.99 1.934.346 4.52-1.436 5.763l-.037.024c1.711-.221 3.113-1.514 3.892-2.739 1.094-1.72 2.444-5.04.782-8.924z\"/><path d=\"M34.693 26.11c-.7-1.333-2.275-1.726-3.519-.877-.994.678-2.558 2.684-2.786 4.142l-9.946 6.787a.992.992 0 00-.311 1.25.75.75 0 001.113.277l9.946-6.786c1.235.46 3.524-.164 4.518-.842 1.244-.849 1.685-2.618.985-3.95z\"/></g><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-3.077c9.346 0 16.923-7.577 16.923-16.923 0-9.346-7.577-16.923-16.923-16.923-9.346 0-16.923 7.577-16.923 16.923 0 9.346 7.577 16.923 16.923 16.923z\" fill=\"#E11212\"/><path fill=\"#E11212\" d=\"M11.7 13.831l24.591 24.577 2.294-2.025-24.94-24.875z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});