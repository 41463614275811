define("ember-svg-jar/inlined/tokenConstant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M9.414 3.757l2.829 2.829a2 2 0 010 2.828l-2.829 2.829a2 2 0 01-2.828 0L3.757 9.414a2 2 0 010-2.828l2.829-2.829a2 2 0 012.828 0zm-1.747 2.91a1 1 0 00-1 1v.666a1 1 0 001 1h.666a1 1 0 001-1v-.666a1 1 0 00-1-1h-.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});