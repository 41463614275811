define("ember-svg-jar/inlined/pui_fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.224 4.273s-.596-.298-.894-.298c.199.199.994 1.59.398 2.981-1.689-1.888-2.385-3.081-2.584-4.174 0-.596.199-1.292.596-1.789L10.634 0 9.54.199c-.993.397-1.788.994-2.285 1.789-.696.894-.795 1.888-1.193 2.882a9.558 9.558 0 00-.298 3.378 2.143 2.143 0 01-.497-.795c-.298-.695-.596-1.788-.497-2.583l.1-.795c-.497 0-.199 0-.696-.099l-.298.794c-.199.795-1.689 2.882-1.689 5.565C2.286 13.416 4.77 16 7.95 16c2.981 0 5.863-2.981 5.863-5.863.001-3.876-1.589-5.864-1.589-5.864z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});