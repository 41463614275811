define("ember-svg-jar/inlined/pui_blueprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blueprints</title><path d=\"M4.25 8.9l-2.2-.6v4l2 1.4 4.2-1.2V7.6l-3.9 1.3h-.1zM5.85 5.7l.9-.9V3.5l-2.6.7h-.3l-1.8-.9v1.8l2 .6h1.8z\"/><path d=\"M11.65.2L4.05 2l-4-2v13.8l4 2.2 7.4-2.4 4.5 1.6V2L11.65.2zm3.3 12.8c0 .1-.1.3-.2.4-.1.1-.3.1-.4.1l-2.8-1-7.3 2.1h-.2c-.1 0-.2 0-.3-.1L1.35 13c-.1-.1-.2-.2-.2-.4l-.1-10.1c0-.2.1-.3.2-.4.2-.1.4-.1.5 0l2.3 1.2 7.3-1.9h.3l3 1.4c.2.1.3.2.3.4V13z\"/><title>blueprints</title><path d=\"M11.45 2.3l-1.5.3-2.7.7v1.6c0 .1 0 .1-.1.2l-1 1s-.1.1-.2.1h-1.9l-2-.7v2.3l2.2.6L8.45 7h.2c.1 0 .1.1.1.2v5l2.6-.8h.3l.59.221V10.7h-.89c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h.89v-.4h-.89c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h.89v-.4h-.89c-.2 0-.3-.1-.3-.3 0-.1.1-.2.2-.2h.99v-.7a.25.25 0 01.5 0v.179c.001.008.01.013.01.021V12l1 .4.3-8.9-2.6-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});