define("ember-svg-jar/inlined/pui_microscope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.187 14.946h-3.144v-.935h1.549a.26.26 0 00.26-.26v-2.19h.004c.594-1.093.793-2.597.694-3.79-.1-1.794-.897-3.188-2.292-4.185a6.244 6.244 0 00-2.192-.995l-.019-.018a1.45 1.45 0 00-1.995-.432c-.015-.003-.024-.017-.039-.017h-.637V.26a.26.26 0 00-.26-.26H3.71a.26.26 0 00-.26.26v1.864h-.637a.26.26 0 00-.26.26v6.257c0 .143.117.26.26.26h1.073v1.197c0 .143.117.26.26.26h.534a.26.26 0 00.26-.26V8.9h1.073a.26.26 0 00.26-.26V4.719a1.443 1.443 0 001.319-.11l.075.075C8.863 5.88 9.461 7.275 9.461 8.969c0 .653-.141 1.533-.354 2.247H7.643a.26.26 0 00-.26.26v2.277c0 .143.117.26.26.26h1.346v.935H2.845a.26.26 0 00-.26.26v.534c0 .143.117.26.26.26h10.341a.26.26 0 00.26-.26v-.534a.26.26 0 00-.259-.262zM6.828 4.028a.657.657 0 110-1.314.657.657 0 010 1.314z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});