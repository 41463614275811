define("ember-svg-jar/inlined/paper_clip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.84 2.019L3.046 8.57c-.987.952-1.133 2.517-.199 3.516.951 1.021 2.58 1.106 3.64.19.034-.03.068-.061.1-.092l5.655-5.452a.484.484 0 000-.703.53.53 0 00-.729 0L5.92 11.421c-.572.551-1.505.657-2.131.163a1.455 1.455 0 01-.118-2.211l6.899-6.651a2.646 2.646 0 013.644 0 2.422 2.422 0 010 3.513L7.3 12.901c-1.333 1.285-3.497 1.493-4.95.336-1.54-1.22-1.764-3.411-.5-4.897a3.33 3.33 0 01.238-.252l5.78-5.572a.484.484 0 000-.703.53.53 0 00-.73 0l-5.78 5.572a4.36 4.36 0 000 6.324c2.188 2.109 5.202 1.31 6.66-.095l6.925-6.676a3.39 3.39 0 000-4.92C13.534.66 11.25.66 9.841 2.019z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});