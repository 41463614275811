define("ember-svg-jar/inlined/l-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.5 17l-.5-1-.5 1z\"/><path d=\"M15 22a1 1 0 001-1v-1a2 2 0 001.56-3.25 8 8 0 10-11.12 0A2 2 0 008 20v1a1 1 0 001 1z\"/><circle cx=\"15\" cy=\"12\" r=\"1\"/><circle cx=\"9\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-skull",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});