define("ember-svg-jar/inlined/pui_category_health_services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.856 1.328a.313.313 0 00.225.282.302.302 0 00.335-.127.314.314 0 00-.168-.47.302.302 0 00-.188.005H3.04c-.541 0-1.06.218-1.443.606A2.087 2.087 0 001 3.088v5.177a6.26 6.26 0 001.792 4.393 6.074 6.074 0 004.328 1.819c1.623 0 3.18-.655 4.327-1.82a6.26 6.26 0 001.793-4.392V3.088c0-.549-.215-1.076-.598-1.464a2.025 2.025 0 00-1.442-.606h-1.02a.222.222 0 00.07.358.214.214 0 00.236-.048\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.12 14.477v1.035a6.26 6.26 0 001.792 4.393 6.074 6.074 0 004.327 1.82v0c1.624 0 3.18-.655 4.328-1.82a6.26 6.26 0 001.792-4.393v-4.141M19.36 11.371c1.126 0 2.04-.927 2.04-2.07 0-1.144-.914-2.071-2.04-2.071-1.127 0-2.04.927-2.04 2.07 0 1.144.913 2.071 2.04 2.071z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});