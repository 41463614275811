define("ember-svg-jar/inlined/pui_star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.963 6.109c-.1-.199-.199-.299-.498-.299l-4.777-.697L8.498.735C8.399.536 8.2.337 7.901.337c-.199 0-.299.1-.498.398l-2.09 4.379-4.677.697c-.398.099-.597.199-.597.298-.1.199 0 .398.199.597l3.384 3.384-.796 4.877c0 .199 0 .398.1.498.1.1.199.199.299.199s.199 0 .398-.1l4.379-2.289 4.379 2.289c.1.1.299.1.398.1s.199-.1.299-.199c.1-.1.1-.299.1-.498l-.995-4.777 3.483-3.384c.297-.299.396-.498.297-.697z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});