define("ember-svg-jar/inlined/logo_azure_mono", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.448 4l-9.414 8.01L0 26.157h7.243L17.448 4zM18.7 5.874l-4.018 11.11 7.704 9.497L7.441 29h24.494L18.7 5.874z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});