define("ember-svg-jar/inlined/l-send-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.714 3.048a.498.498 0 00-.683.627l2.843 7.627a2 2 0 010 1.396l-2.842 7.627a.498.498 0 00.682.627l18-8.5a.5.5 0 000-.904zM6 12h16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-send-horizontal",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});