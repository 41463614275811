define("ember-svg-jar/inlined/index_edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2H2v11h4v1H1V1h12v5h-1V2zM5 5h5.999V4H5v1zM3 5V4h1v1H3zm2 3V7h6v1H5zM3 8V7h1v1H3zm2 3v-1h3v1H5zm-2 0v-1h1v1H3zm4.502 1.41L12.913 7 15 9.087l-5.41 5.41L7 15l.502-2.59z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});