define("ember-svg-jar/inlined/pui_faulty_process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>faultyMachinery</title><path d=\"M13.114 2.555l-.882-.211.497-.73a.265.265 0 00-.031-.335l-.634-.634a.263.263 0 00-.324-.038l-.772.474-.164-.868A.265.265 0 0010.545 0h-.897a.263.263 0 00-.256.202l-.211.882-.73-.497a.262.262 0 00-.334.031l-.634.634a.263.263 0 00-.038.324l.474.772-.867.165a.264.264 0 00-.215.259v.896c0 .122.084.228.202.256l.882.211-.497.73a.264.264 0 00.031.335l.634.634c.086.086.22.102.324.038l.772-.474.165.867a.264.264 0 00.259.215h.896a.263.263 0 00.256-.202l.211-.882.73.497a.265.265 0 00.335-.031l.634-.634a.263.263 0 00.038-.324l-.474-.772.868-.164a.265.265 0 00.215-.259v-.898a.265.265 0 00-.204-.256zm-3.037 2.019a1.335 1.335 0 110-2.67 1.335 1.335 0 010 2.67zM15.734 7.796l-.716-.171.404-.593a.214.214 0 00-.026-.272l-.515-.515a.215.215 0 00-.264-.031l-.627.386-.134-.705a.214.214 0 00-.21-.174h-.728a.214.214 0 00-.208.164l-.171.716-.593-.404a.214.214 0 00-.272.026l-.515.515a.215.215 0 00-.031.264l.386.628-.705.134a.214.214 0 00-.174.21v.728c0 .099.068.185.164.208l.716.171-.405.592a.214.214 0 00.026.272l.515.515c.07.07.179.083.264.031l.628-.386.134.705a.214.214 0 00.21.174h.728a.214.214 0 00.208-.164l.171-.716.593.404a.214.214 0 00.272-.026l.515-.515a.215.215 0 00.031-.264l-.386-.628.705-.134a.214.214 0 00.174-.21v-.726c0-.1-.068-.186-.164-.209zm-2.468 1.641a1.084 1.084 0 110-2.168 1.084 1.084 0 010 2.168zM5.404 5.396a5.302 5.302 0 100 10.605 5.302 5.302 0 000-10.605zm3.976 5.302c0 .706-.187 1.369-.511 1.944l-5.41-5.41a3.949 3.949 0 011.944-.511 3.981 3.981 0 013.977 3.977zm-7.953 0c0-.766.221-1.481.599-2.088l5.466 5.466a3.94 3.94 0 01-2.088.599 3.983 3.983 0 01-3.977-3.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});