define("ember-svg-jar/inlined/l-paintbrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.622 17.897l-10.68-2.913M18.376 2.622a1 1 0 113.002 3.002L17.36 9.643a.5.5 0 000 .707l.944.944a2.41 2.41 0 010 3.408l-.944.944a.5.5 0 01-.707 0L8.354 7.348a.5.5 0 010-.707l.944-.944a2.41 2.41 0 013.408 0l.944.944a.5.5 0 00.707 0zM9 8c-1.804 2.71-3.97 3.46-6.583 3.948a.507.507 0 00-.302.819l7.32 8.883a1 1 0 001.185.204C12.735 20.405 16 16.792 16 15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-paintbrush",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});