define("ember-svg-jar/inlined/l-captions-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.5 5H19a2 2 0 012 2v8.5M17 11h-.5m2.5 8H5a2 2 0 01-2-2V7a2 2 0 012-2M2 2l20 20M7 11h4m-4 4h2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-captions-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});