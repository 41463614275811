define("ember-svg-jar/inlined/l-microchip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 12h2m-2 4h2m-2 4h2M18 4h2m-2 4h2M4 12h2m-2 4h2m-2 4h2M4 4h2M4 8h2m2-6a2 2 0 00-2 2v16a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2h-1.5c-.276 0-.494.227-.562.495a2 2 0 01-3.876 0C9.994 2.227 9.776 2 9.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-microchip",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});