define("ember-svg-jar/inlined/sortLeft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.308 7h8.136c.307 0 .556.224.556.5s-.249.5-.556.5H4.308l4.096 4.096a.5.5 0 01-.707.707L3.454 8.561a1.494 1.494 0 01-.433-.925.454.454 0 010-.272c.03-.338.175-.666.433-.925l4.243-4.242a.5.5 0 11.707.707L4.308 7z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});