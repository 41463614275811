define("ember-svg-jar/inlined/pui_documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.1 14.7c-.6 0-1.2-.6-1.2-1.2V2l-2.4.4c-.2 0-.3.2-.3.4l2.5 12.9c0 .2.2.3.4.3l6.9-1.3H5.1z\"/><path d=\"M13.2 2.8c-.6 0-1.1-.4-1.1-1.1V0h-7c-.1 0-.3.2-.3.3v13.1c0 .2.2.3.3.3h9.3c.2 0 .3-.2.3-.3V2.8h-1.5z\"/><path d=\"M13.2 2.2h1.3L12.8.5v1.3c-.1.2.1.4.4.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});