define("ember-svg-jar/inlined/riesgo_por_presencia_de_campos_magneticos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_POR_PRESENCIA_DE_CAMPOS_MAGNETICOS</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M27.83 23.074a.119.119 0 00-.138-.07l-3.551.926a.121.121 0 00-.086.086.125.125 0 00.03.12l.786.804-1.816 1.205a.124.124 0 00-.02.19c.03.031.076.044.12.03l3.451-1.071a.121.121 0 00.081-.087.125.125 0 00-.03-.117l-.66-.68 1.79-1.184a.124.124 0 00.044-.152zM31.129 29.048a.077.077 0 00-.09-.046l-2.3.6a.079.079 0 00-.056.056.08.08 0 00.02.077l.509.522-1.177.78a.08.08 0 00-.012.123c.02.02.049.028.077.02l2.236-.694a.079.079 0 00.052-.057.08.08 0 00-.02-.075l-.426-.44 1.158-.768a.08.08 0 00.029-.098zM25.826 30.133l-4.649 4.761a1.35 1.35 0 01-1.945 0 1.417 1.417 0 01-.403-.996c0-.377.143-.73.403-.996l4.648-4.762-2.897-2.967-4.767 4.882a5.638 5.638 0 00-1.603 3.964c0 1.497.57 2.905 1.603 3.964a5.373 5.373 0 003.87 1.642 5.373 5.373 0 003.87-1.642l4.767-4.883-2.897-2.967zm-4.843-3.467l1.44 1.474-1.337 1.37-1.44-1.475 1.337-1.37zm3.506 6.33l1.337-1.37 1.44 1.474-1.338 1.37-1.44-1.475z\" fill=\"#000\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});