define("ember-svg-jar/inlined/pui_ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.876 6.82h-2.539l-.303.642H9.398v-.963a.125.125 0 00-.124-.124H7.906a.125.125 0 00-.124.124v.963h-.55V5.673H5.716V3.2h.926v-.596H0V3.2h.865v4.262H0l1.589 4.829a.124.124 0 00.118.086h11.852a.125.125 0 00.114-.074l2.317-5.309a.124.124 0 00-.114-.174zM5.006 5.597H1.575V3.39h3.431v2.207zm7.424 3.459a.377.377 0 110-.755.377.377 0 010 .755z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});