define("ember-svg-jar/inlined/pui_file_pdf_white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.8 0c.274 0 .537.113.726.312l3.2 3.428c.176.186.274.433.274.689V15a1 1 0 01-1 1H2a1 1 0 01-1-1V1a1 1 0 011-1h8.8zM14 5h-3.5a.5.5 0 01-.5-.5V1H2v14h12V5z\" fill=\"#fff\"/><path d=\"M4.285 10V7.09h1.201c.218 0 .406.044.564.129.16.084.282.202.368.353.086.151.13.326.13.526s-.045.376-.133.527a.886.886 0 01-.373.348 1.257 1.257 0 01-.577.124h-.741v-.554h.61a.572.572 0 00.266-.056.38.38 0 00.163-.155.48.48 0 00.056-.234.466.466 0 00-.056-.233.372.372 0 00-.163-.153.59.59 0 00-.266-.054h-.346V10h-.703zm3.684 0H6.894V7.09h1.074c.296 0 .551.06.765.176.215.115.381.282.498.5.116.216.174.476.174.778 0 .303-.058.563-.174.781-.116.218-.281.385-.496.502-.215.115-.47.173-.766.173zm-.372-.6h.345c.163 0 .301-.027.414-.082a.545.545 0 00.257-.271c.058-.126.088-.294.088-.503 0-.21-.03-.376-.088-.5a.543.543 0 00-.26-.27.948.948 0 00-.42-.084h-.336V9.4zm2.219.6V7.09h1.986v.572h-1.283v.597h1.156v.572H10.52V10h-.703z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});