define("ember-svg-jar/inlined/pui_file_word_white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.8 0c.274 0 .537.113.726.312l3.2 3.428c.176.186.274.433.274.689V15a1 1 0 01-1 1H2a1 1 0 01-1-1V1a1 1 0 011-1h8.8zM14 5h-3.5a.5.5 0 01-.5-.5V1H2v14h12V5z\" fill=\"#fff\"/><path d=\"M6.216 11L4.932 6.636h.878l.855 3.205h.043l.858-3.205h.878l.852 3.19h.042l.85-3.19h.878L9.784 11h-.866l-.886-3.15h-.066L7.08 11h-.864z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});