define("ember-svg-jar/inlined/pui_nested_arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path data-name=\"Union 2\" d=\"M5.592 18.8a.5.5 0 010-.705l4.1-4.1h-9.2a.5.5 0 01-.5-.5v-13a.5.5 0 111 0v12.5h8.693l-4.1-4.1a.5.5 0 01.707-.705l4.248 4.24a1.49 1.49 0 01.43.928.394.394 0 01.021.137.372.372 0 01-.021.133 1.476 1.476 0 01-.43.928l-4.248 4.24a.485.485 0 01-.352.15.5.5 0 01-.348-.146z\" fill=\"#343741\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10.998",
      "height": "18.953",
      "viewBox": "0 0 10.998 18.953"
    }
  };
});