define("ember-svg-jar/inlined/prohibido_el_paso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_ELPASO_EN_ZONA_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><path d=\"M27.255 19.667c-1.183-.693-2.031-.932-3.416-.335-.208.09-.741.656-.886.86l-4.437 4.712 1.791 1.494 3.63-3.886c.251-.07-.348-.558-.098-.616l-1.082 4.425a2.382 2.382 0 00-.065.677l-.72 3.045-3.137 3.522 1.631 1.622s.413-.327.639-.595l2.799-3.32c.113-.133.193-.292.233-.461l.705-2.979c.03.068.065.134.108.197l2.179 3.154v3.964h2.286v-4.309a1.11 1.11 0 00-.197-.63l-1.73-2.506a2.41 2.41 0 00.076-.246l.95-3.886c.31.287.575.54.742.705.146.144.33.246.53.296l3.087.871c.094.024 1.134.035 1.134.035l.279-2.213-3.639-.797c-.621-.598-2.275-2.145-3.392-2.8zM26.99 18.283c1.443 0 2.615-1.158 2.615-2.586 0-1.43-1.172-2.587-2.616-2.587-1.444 0-2.615 1.158-2.615 2.587 0 1.428 1.17 2.586 2.615 2.586zM30.394 36.516l.61 2.582h4.487l-2.443-2.582h-2.654zM15.166 39.098h4.486l.61-2.582H17.61l-2.443 2.582zM23.634 36.516l-.611 2.582h4.611l-.61-2.582h-3.39z\" fill=\"#000\" fill-rule=\"nonzero\"/><path d=\"M25.5 46C14.178 46 5 36.822 5 25.5S14.178 5 25.5 5 46 14.178 46 25.5 36.822 46 25.5 46zm0-3.154c9.58 0 17.346-7.766 17.346-17.346S35.08 8.154 25.5 8.154 8.154 15.92 8.154 25.5 15.92 42.846 25.5 42.846z\" fill=\"#E11212\"/><path fill=\"#E11212\" d=\"M11.868 14.052l25.205 25.191 2.352-2.076L13.86 11.671z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});