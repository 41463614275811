define("ember-svg-jar/inlined/l-message-circle-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.9 20A9 9 0 104 16.1L2 22z\"/><path d=\"M15.8 9.2a2.5 2.5 0 00-3.5 0l-.3.4-.35-.3a2.42 2.42 0 10-3.2 3.6l3.6 3.5 3.6-3.5c1.2-1.2 1.1-2.7.2-3.7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-message-circle-heart",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});