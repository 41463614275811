define("ember-svg-jar/inlined/discapacidad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>HANDICAP</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#00438C\" d=\"M0 0h50v50H0z\"/><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-2.222c9.818 0 17.778-7.96 17.778-17.778 0-9.818-7.96-17.778-17.778-17.778-9.818 0-17.778 7.96-17.778 17.778 0 9.818 7.96 17.778 17.778 17.778z\" fill=\"#FFF\"/><g transform=\"translate(16 13)\" fill-rule=\"nonzero\" fill=\"#FFF\"><ellipse cx=\"6.709\" cy=\"2.649\" rx=\"2.664\" ry=\"2.649\"/><path d=\"M8.096 23.986a8.115 8.115 0 005.351-2.01 8.073 8.073 0 001.569-1.864l-2.356-2.343a4.919 4.919 0 01-4.564 3.063c-2.713 0-4.921-2.197-4.921-4.895 0-.814.202-1.591.565-2.274V9.156A8.045 8.045 0 00.003 15.94c0 2.15.842 4.169 2.37 5.692a8.048 8.048 0 005.723 2.354z\" stroke=\"#00438C\"/><path d=\"M4.749 7.234v8.517c0 .476.386.86.865.86h7.309l.04.04 2.54 2.526.986.98c.17.17.39.253.611.253a.855.855 0 00.612-.252l1.017-1.013a.857.857 0 000-1.215l-4.24-4.221a.867.867 0 00-.612-.252l-5.957-.003V9.525h5.45c.23 0 .35-.732.358-1.491.009-.816-.11-1.663-.358-1.663H5.614a.866.866 0 00-.865.863z\"/></g></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#00438c"
    }
  };
});