define("ember-svg-jar/inlined/tokenTag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 8a1 1 0 11-2 0 1 1 0 012 0z\"/><path fill-rule=\"evenodd\" d=\"M4 4a1 1 0 00-1 1v6a1 1 0 001 1h6.989a1 1 0 00.825-.436l2.05-3a1 1 0 000-1.128l-2.05-3A1 1 0 0010.99 4H4zm.75 1.25a.5.5 0 00-.5.5v4.5a.5.5 0 00.5.5h5.745a.5.5 0 00.405-.206l1.636-2.25a.5.5 0 000-.588L10.9 5.456a.5.5 0 00-.405-.206H4.75z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});