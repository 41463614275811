define("ember-svg-jar/inlined/pui_radio_outline_checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 8A5 5 0 113 8a5 5 0 0110 0zm1 0A6 6 0 112 8a6 6 0 0112 0zm-6 3a3 3 0 100-6 3 3 0 000 6z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});