define("ember-svg-jar/inlined/logo_logstash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#3EBEB0\" d=\"M19 32h11V20H19z\"/><path fill=\"#FEC514\" d=\"M4 0H3v20h13v-8C16 5.373 10.627 0 4 0\"/><path class=\"euiIcon__fillNegative\" d=\"M3 20c0 6.627 5.373 12 12 12h1V20H3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});