define("ember-svg-jar/inlined/l-file-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.5 22H18a2 2 0 002-2V7l-5-5H6a2 2 0 00-2 2v4\"/><path d=\"M14 2v4a2 2 0 002 2h4M3 13.1a2 2 0 00-1 1.76v3.24a2 2 0 00.97 1.78L6 21.7a2 2 0 002.03.01L11 19.9a2 2 0 001-1.76V14.9a2 2 0 00-.97-1.78L8 11.3a2 2 0 00-2.03-.01zM7 17v5\"/><path d=\"M11.7 14.2L7 17l-4.7-2.8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-file-box",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});