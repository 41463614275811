define("ember-svg-jar/inlined/pui_dislike", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path data-name=\"like (1)\" d=\"M1.3 9.467c.084.014 4.023 0 4.023 0l-.55 1.5A2.705 2.705 0 005.7 14.15a1.8 1.8 0 001.2.179.872.872 0 00.532-.4 2.168 2.168 0 00.167-.741 3.2 3.2 0 01.891-1.693c.625-.609 2.259-2.416 2.978-3.217a1.02 1.02 0 00.638.2h3.115c.428 0 .779-.19.779-.424V.429c0-.234-.349-.424-.779-.424h-3.115c-.43 0-.779.19-.779.424v.007L3.114.069a1.581 1.581 0 00-1.365 2.336 1.58 1.58 0 00-.522 2.089A1.58 1.58 0 00.7 6.578a1.6 1.6 0 00.6 2.889zM12.9.943a.355.355 0 01.356-.356h1.616a.323.323 0 01.323.323v6.862a.114.114 0 01-.114.114H13.01a.114.114 0 01-.114-.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "14.368",
      "viewBox": "0 0 16 14.368"
    }
  };
});