define("ember-svg-jar/inlined/l-pickaxe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.531 12.469L6.619 20.38a1 1 0 11-3-3l7.912-7.912m4.155-5.154A12.5 12.5 0 005.461 2.958 1 1 0 005.58 4.71a22 22 0 016.318 3.393\"/><path d=\"M17.7 3.7a1 1 0 00-1.4 0l-4.6 4.6a1 1 0 000 1.4l2.6 2.6a1 1 0 001.4 0l4.6-4.6a1 1 0 000-1.4z\"/><path d=\"M19.686 8.314a12.5 12.5 0 011.356 10.225 1 1 0 01-1.751-.119 22 22 0 00-3.393-6.319\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pickaxe",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});