define("ember-svg-jar/inlined/l-ear-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 18.5a3.5 3.5 0 107 0c0-1.57.92-2.52 2.04-3.46M6 8.5c0-.75.13-1.47.36-2.14M8.8 3.15A6.5 6.5 0 0119 8.5c0 1.63-.44 2.81-1.09 3.76\"/><path d=\"M12.5 6A2.5 2.5 0 0115 8.5M10 13a2 2 0 001.82-1.18M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-ear-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});