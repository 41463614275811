define("ember-svg-jar/inlined/pui_crown_white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 3.676V12.8c0 .11.09.2.2.2h11.298a.2.2 0 00.2-.194l.28-9.09c.006-.203-.258-.285-.368-.115L10.8 7.932a.2.2 0 01-.344-.015L8.033 3.353a.2.2 0 00-.357.008L5.538 7.893a.2.2 0 01-.349.023l-2.821-4.35C2.259 3.4 2 3.477 2 3.677z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});