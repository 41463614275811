define("ember-svg-jar/inlined/pui_meeting_point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.4 3.9l-.9.9c-.1.1-.1.2-.1.3s.2.2.3.2H5c.2 0 .3-.1.3-.2V1.6c0-.1-.1-.2-.2-.3-.1 0-.2 0-.3.1l-.8.8L1.8.1C1.4-.2.9-.2.5.1L.2.5C.1.7 0 1 0 1.2s.1.5.2.6l2.2 2.1zM5.1 10.4H1.8c-.1 0-.2.1-.3.2 0 .1 0 .2.1.3l.9.9-2.2 2.1c-.2.2-.3.4-.3.7 0 .2.1.5.2.6l.4.4c.4.4 1 .4 1.3 0L4 13.5l.9.9c.1.1.2.1.3.1.1 0 .2-.2.2-.3v-3.4c0-.2-.1-.3-.3-.4zM13.6 11.8l.9-.9c.1-.1.1-.2.1-.3s-.2-.2-.3-.2H11c-.2 0-.3.1-.3.2v3.5c0 .1.1.2.2.3.1 0 .2 0 .3-.1l.7-.9 2.1 2.1c.4.3.9.3 1.3 0l.4-.4c.2-.2.2-.4.2-.6 0-.2-.1-.5-.2-.6l-2.1-2.1zM10.9 5.3h3.4c.1 0 .2-.1.3-.2 0-.1 0-.3-.1-.3l-.9-.9 2.1-2.1c.2-.2.3-.4.3-.6s-.1-.5-.2-.6l-.4-.4c-.4-.3-.9-.3-1.3 0l-2.1 2-.9-.9c-.1-.1-.2-.1-.3-.1s-.2.2-.2.3v3.4c0 .3.2.4.3.4z\"/><circle cx=\"7.9\" cy=\"7.9\" r=\"2.6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});