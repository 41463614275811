define("ember-svg-jar/inlined/l-sun-snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 9a3 3 0 100 6m-8-3h1m11 9V3m-4 1V3m0 18v-1m-6.36-1.64l.7-.7m0-11.32l-.7-.7M14 12h8m-5-8l-3 3m0 10l3 3m4-5l-3-3 3-3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-sun-snow",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});