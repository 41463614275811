define("ember-svg-jar/inlined/pui_fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"9.193\" cy=\"2.8\" r=\"1.961\"/><path d=\"M12.33 7.407c-.882.294-1.666.49-2.549.588-.294-.882-1.47-2.941-1.47-2.941-.196-.196-.981-.391-1.078-.391-1.177.098-2.354.195-3.432.588A11.02 11.02 0 012.036 3.29c-.294-.294-.784-.392-1.176-.098-.294.294-.393.784-.196 1.078.687.882 2.45 2.45 2.549 2.549.098 0 .098.098.196.098.097 0 1.177-.294 1.666-.393-.686.982-2.059 2.942-2.059 3.04-.882.49-1.666 1.078-2.45 1.765-.294.294-.49.687-.393 1.078.196 1.078.687 2.156 1.275 3.137.687.981 2.156 0 1.471-.981a8.263 8.263 0 01-.882-2.156c.49-.393 1.471-1.078 1.471-1.078s.294 2.059.49 3.039c0 .49.49.882.981.784.098 0 3.431-.784 3.431-.784 1.177-.294.588-1.961-.49-1.666l-2.156.49c-.098-.687.098-2.255.098-2.353.783-1.08 1.47-2.158 2.156-3.139.195.394.391.883.489 1.374.098.294.294.393.588.49.098 0 2.843-.393 3.824-.687.393-.098.687-.588.49-.981-.196-.489-.687-.685-1.079-.489zM12.339 2.02a.492.492 0 01-.347-.838l.989-.989a.49.49 0 11.694.694l-.989.99a.487.487 0 01-.347.143zM14.003 3.936a.49.49 0 01-.115-.967l1.359-.328a.485.485 0 01.591.362.489.489 0 01-.362.591l-1.359.328a.445.445 0 01-.114.014zM15.223 6.914a.498.498 0 01-.131-.018l-1.348-.373a.49.49 0 01.262-.945l1.348.373a.49.49 0 01-.131.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});