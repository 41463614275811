define("ember-svg-jar/inlined/pui_hose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 1.6H5v8.2H3.5V1.6zM5.9 1.6h1.6v8.2H5.9V1.6zM8.5 1.6h1.6v8.2H8.5V1.6zM11.4 15.7c0 .1 0 .2.1.2h.4c.1 0 .1-.1.1-.2l.1-1.2H11l.4 1.2zM11 1.6h1.6v10.3H11V1.6zM12.7 12.8h-1.8c-.3 0-.5.2-.5.5s.2.5.5.5h1.8c.3 0 .5-.2.5-.5 0-.2-.2-.5-.5-.5zM15.5.1c-.3 0-.5.2-.5.5v1h-1.6v8.2H15v1c0 .3.2.5.5.5s.5-.2.5-.5V.6c0-.3-.3-.5-.5-.5zM1.1.7C1.1.4.9.2.6.2.2.1 0 .4 0 .7v10.2c0 .3.2.5.5.5s.5-.2.5-.5v-1h1.6V1.5H1.1V.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});