define("ember-svg-jar/inlined/riesgo_de_obstaculos_en_zonas_transitables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>RIESGO_DE_OBSTACULOS_EN_ZONAS_TRANSITABLES</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M19.727 36l-3.375-2.16.859-1.258 2.08 1.457 1.09-2.19 1.225 1.27L19.727 36zm5.037-11.083a1.482 1.482 0 011.005-1.84c.782-.224 1.6.236 1.827 1.028a1.482 1.482 0 01-1.005 1.84 1.482 1.482 0 01-1.827-1.028zm2.894 1.727l2.174-2.182 1.006 1.024-2.363 2.371a.703.703 0 01-.457.206l-1.488.088-1.033-.201-1.306.374-1.465 2.936-.946-.965 3.05 2.998a.872.872 0 01.217.348l1.021 3.089-1.62.537-.954-2.888-2.9-2.851a.873.873 0 01-.16-1.033c.051-.085 2.232-3.976 2.232-3.976l-4.122.039-.306-1.32 5.014-.24s1.12.668 1.178.704c.439.266 1.672 1.033 1.672 1.033l1.556-.091z\" fill-rule=\"nonzero\" fill=\"#000\"/><path fill=\"#000\" d=\"M10 39.681h10.342L20.857 37h2.66l.462 2.681h15.118v1.025H10z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});