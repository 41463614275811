define("ember-svg-jar/inlined/l-flip-vertical-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 3l-5 5-5-5zm0 18l-5-5-5 5zM4 12H2m8 0H8m8 0h-2m8 0h-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-flip-vertical-2",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});