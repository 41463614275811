define("ember-svg-jar/inlined/ubicacion_de_una_regadera_de_emergencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_UNA_REGADERA_DE_EMERGENCIA</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><path d=\"M11 42.385H8L12.5 47l4.5-4.615h-3V35h-3v7.385z\" fill=\"#FFF\"/><path d=\"M27.14 10.924l-.437-.015.03-.854.438.015-.031.854zm-.059 1.711l-.436-.016.032-.853.437.016-.033.853zm1.606-4.075l.29.806.411-.14-.288-.808-.413.142zM26.8 8.35l.438.015-.031.854-.439-.014.032-.855zm2.754 2.629l.412-.141-.287-.806-.414.14.289.807zm.577 1.613l.413-.14-.29-.807-.412.141.29.806zm5.493 5.752l-4.158-4.514-1.951 1.715 3.162 3.433c-1.276.88-3.2 2.247-4.112 3.094-1.097.468-2.196.353-3.144.021-.901-.845-2.85-2.229-4.135-3.115l3.162-3.433-1.952-1.715-4.158 4.514a1.264 1.264 0 00-.328 1.01c.045.367.251.697.564.905.862.573 2.993 2.032 4.297 3.066l.05.709-.028 10.458v10.41h3.177V34.054s.053-.55.626-.556c.575-.009.56.526.56.526v10.849h3.269l.056-11.192.014-9.873c1.15-1.07 3.804-2.891 4.797-3.549.314-.208.518-.538.564-.905.04-.367-.08-.736-.332-1.01zm-11.937-6.588l-.388-.195-.403.758.39.197.4-.76zm3.244 4.12c1.534 0 2.776 1.213 2.776 2.71 0 1.495-1.242 2.708-2.776 2.708-1.533 0-2.777-1.213-2.777-2.709s1.244-2.708 2.777-2.708zm-2.44-5.637l-.39-.196-.402.758.39.196.402-.758zm2.863-4.49V3.033h-.847V5.75L24.63 7.874h4.597L27.353 5.75zm-2.06 2.971l-.39-.195-.401.759.39.196.4-.76z\" fill-rule=\"nonzero\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#080"
    }
  };
});