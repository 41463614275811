define("ember-svg-jar/inlined/pui_user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 16c-.6-1.5-1.6-2.7-3-3.6l-.2.2c-1 1-2.4 1.6-3.8 1.6s-2.8-.6-3.8-1.6l-.2-.2c-1.3.8-2.4 2.1-3 3.6h14z\"/><path d=\"M4 7.6c-.2.4-.2.8-.2 1.2C3.8 11.1 5.7 13 8 13s4.2-1.9 4.2-4.2c0-.4-.1-.8-.2-1.2H4zM12.703 5.657h-.274c.004-.075.012-.15.012-.226a4.429 4.429 0 00-1.873-3.618v1.688a.487.487 0 11-.975 0v-2.18c0-.01.005-.018.006-.028a4.358 4.358 0 00-1.047-.264v1.64a.487.487 0 11-.974 0V1.012a4.407 4.407 0 00-1.025.225c.006.028.017.054.017.084v2.18a.487.487 0 11-.974 0v-1.8a4.434 4.434 0 00-2.036 3.73c0 .077.008.151.012.226h-.275a.496.496 0 100 .992h9.406a.496.496 0 100-.992zM10.568 1.812v-.491a.485.485 0 00-.969-.028c.346.133.671.308.969.519zM8 .99c.188 0 .371.016.552.038V.487a.487.487 0 10-.974 0v.524C7.717.999 7.858.99 8 .99z\"/><path d=\"M6.553 1.237a.48.48 0 00-.957.083v.381c.297-.191.618-.347.957-.464z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});