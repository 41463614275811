define("ember-svg-jar/inlined/app_saved_objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"euiIcon__fillSecondary\" d=\"M20.38 7.001L17 9.619V0h-2v9.619l-3.37-2.618-1.25 1.513L16 12.878l5.63-4.364z\"/><path d=\"M21.06.165l-1.11 1.61 9.25 5.983L16 16.29 2.8 7.758l9.25-5.983-1.1-1.61L0 7.234v13.653l16 10.337 16-10.337V7.234L21.06.164zM2 9.57l13 8.407v10.279L2 19.84V9.57zm15 18.676V17.978l13-8.407V19.85l-13 8.397z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});