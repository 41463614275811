define("ember-svg-jar/inlined/qr_icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 0H0v4h4V0zM3 1H1v2h2V1zM4 10H0v4h4v-4zm-1 1H1v2h2v-2zM14 10h-4v4h4v-4zm-1 1h-2v2h2v-2zM14 0h-4v4h4V0zm-1 1h-2v2h2V1z\"/><path d=\"M0 6h4v1H0zM5 0h1v1H5zM6 1h1v1H6zM5 2h1v1H5zM5 3h1v1H5zM5 5h1v1H5zM7 6h1v1H7zM8 5h1v1H8zM9 5h4v1H9zM5 9h1v1H5zM7 9h1v1H7zM6 10h1v1H6zM7 11h1v3H7zM5 11h1v1H5zM9 7h1v1H9zM10 8h1v1h-1zM1 7h1v1H1zM1 8h1v1H1zM3 7h1v1H3zM8 3h1v1H8zM8 1h1v1H8zM11 7h1v1h-1z\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});