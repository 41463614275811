define("ember-svg-jar/inlined/l-layers-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.02 12l5.48 3.13a1 1 0 010 1.74L13 21.74a2 2 0 01-2 0l-8.5-4.87a1 1 0 010-1.74L7.98 12\"/><path d=\"M13 13.74a2 2 0 01-2 0L2.5 8.87a1 1 0 010-1.74L11 2.26a2 2 0 012 0l8.5 4.87a1 1 0 010 1.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-layers-2",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});