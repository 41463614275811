define("ember-svg-jar/inlined/pui_category_maritime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.032 7.389c1.773 0 3.21-1.21 3.21-2.703 0-1.492-1.437-2.702-3.21-2.702s-3.21 1.21-3.21 2.702c0 1.493 1.438 2.703 3.21 2.703zM12.032 20V7.389M4.543 10.992h-3.21c0 2.39 1.127 4.68 3.134 6.37C6.473 19.05 9.194 20 12.032 20s5.559-.95 7.565-2.638c2.007-1.69 3.134-3.98 3.134-6.37h-3.21\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "21",
      "viewBox": "0 0 24 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});