define("ember-svg-jar/inlined/l-test-tubes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 2v17.5A2.5 2.5 0 016.5 22 2.5 2.5 0 014 19.5V2m16 0v17.5a2.5 2.5 0 01-2.5 2.5 2.5 2.5 0 01-2.5-2.5V2M3 2h7m4 0h7M9 16H4m16 0h-5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-test-tubes",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});