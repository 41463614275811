define("ember-svg-jar/inlined/advertencia_de_riesgo_electrico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ADVERTENCIA_DE_RIESGO_ELECTRICO</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L24.796 5l21.797 40H3zm4.185-2.788h35.242l-17.51-32.52-17.732 32.52z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M23.438 34.65h-3.291l3.862 4.796 3.877-4.796h-2.908l2.565-4.114a.311.311 0 00-.286-.466l-2.083.11 2.259-4.584a.311.311 0 00-.298-.448l-1.79.108 2.058-6.41a.31.31 0 00-.559-.262l-5.51 8.704a.31.31 0 00.28.477l2.05-.108-2.209 4.357a.31.31 0 00.294.452l2.279-.12-.59 2.304z\" fill=\"#040404\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});