define("ember-svg-jar/inlined/pui_electric_tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electricalTower</title><path d=\"M1.406 8.907h.5v.599c0 .2.2.5.4.5h.1c.3 0 .5-.2.5-.5v-.599h2.597v.1l-1.099 3.996c-.1.5-.2.899-.2 1.399v.3h-.5c-.1 0-.2.1-.2.2V16h2.298v-1.099c0-.1-.1-.2-.2-.2h-.4v-.3-.4L8 12.803l2.797 1.199v.7h-.4c-.1 0-.2.1-.2.2V16h2.298v-1.099c0-.1-.1-.2-.2-.2h-.4v-.2c0-.5-.1-1.099-.2-1.598l-1.199-3.896v-.1h2.597v.599c0 .2.2.5.4.5h.1c.3 0 .5-.2.5-.5v-.599h.5c.1 0 .2 0 .2-.1.1 0 .2-.1.2-.2v-.2c0-.3-.2-.599-.5-.699L10.498 6.01v-.7h1.898v.6c0 .3.2.5.4.5h.1c.3 0 .5-.2.5-.5v-.6h.5c.2 0 .3-.1.3-.3v-.2c0-.3-.2-.599-.4-.699l-3.297-1.798c0-.1-.1-.3-.2-.4L8.3.115c-.2-.2-.5-.1-.699 0L5.702 2.014c-.1.1-.2.2-.2.4L2.206 4.111c-.3.1-.5.4-.5.599v.2c0 .2.1.3.3.3h.5v.6c0 .2.2.5.4.5h.1c.3 0 .5-.2.5-.5v-.5h1.898v.7L1.406 7.708c-.2.2-.4.4-.4.799v.1c0 .1 0 .2.1.2.101.1.2.1.3.1zm4.696 2.597L8 10.605l1.898.799-1.898.9-1.898-.8zm4.495 1.699v.2l-1.998-.799 1.499-.599.499 1.198zm-.599-2.298l-1.299-.599 1.099-.4.2.999zM8 1.214l1.199 1.099H6.801L8 1.214zM6.601 5.31h2.897v.7H6.601v-.7zm0 3.897V8.907h2.897v.2l.1.2L8 10.106l-1.499-.7.1-.199zm-.199.699l.999.4-1.299.599.3-.999zm-.6 1.998l1.499.699-1.998.799v-.1l.499-1.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});