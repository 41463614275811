define("ember-svg-jar/inlined/l-cuboid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.12 6.4l-6.05-4.06a2 2 0 00-2.17-.05L2.95 8.41a2 2 0 00-.95 1.7v5.82a2 2 0 00.88 1.66l6.05 4.07a2 2 0 002.17.05l9.95-6.12a2 2 0 00.95-1.7V8.06a2 2 0 00-.88-1.66\"/><path d=\"M10 22v-8L2.25 9.15M10 14l11.77-6.87\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cuboid",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});