define("ember-svg-jar/inlined/pui_faulty_product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>faultyProduct</title><path d=\"M2.817 9.721c0 1.013.824 1.836 1.836 1.836.354 0 .684-.102.964-.276L3.094 8.756a1.828 1.828 0 00-.277.965zM4.654 7.884c-.326 0-.632.086-.898.236l2.498 2.498c.15-.266.236-.572.236-.898a1.839 1.839 0 00-1.836-1.836z\"/><path d=\"M8 0L5.4 1.4 12 5.5v1.3l-1.7.9V6.5L3.6 2.4.8 3.9v7.5L8 16l7.2-4.5c.1-.3.1-7 .1-7.6L8 0zM4.654 12.169a2.45 2.45 0 110-4.898 2.45 2.45 0 010 4.898z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});