define("ember-svg-jar/inlined/l-salad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 21h10m-5 0a9 9 0 009-9H3a9 9 0 009 9\"/><path d=\"M11.38 12a2.4 2.4 0 01-.4-4.77 2.4 2.4 0 013.2-2.77 2.4 2.4 0 013.47-.63 2.4 2.4 0 013.37 3.37 2.4 2.4 0 01-1.1 3.7 2.5 2.5 0 01.03 1.1M13 12l4-4\"/><path d=\"M10.9 7.25A3.99 3.99 0 004 10c0 .73.2 1.41.54 2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-salad",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});