define("ember-svg-jar/inlined/asterisk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.928 8.468L4 7.618l.446-1.427L7.375 7.25 7.287 4h1.484l-.097 3.296 2.88-1.039L12 7.693l-2.977.86 1.92 2.56L9.741 12 7.937 9.28l-1.745 2.654-1.213-.86z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});