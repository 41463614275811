define("ember-svg-jar/inlined/temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 15a3.5 3.5 0 01-1.75-6.532L7 8.324V2.5A1.496 1.496 0 019.908 2H8.5v1H10v1H8.5v1H10v1H8.5v1H10v1.324l.25.144A3.5 3.5 0 018.5 15M11 7.758V2.5a2.5 2.5 0 10-5 0v5.258a4.5 4.5 0 105 0\"/><path d=\"M8.5 9a2.5 2.5 0 110 5 2.5 2.5 0 010-5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});