define("ember-svg-jar/inlined/l-square-parking-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.6 3.6A2 2 0 015 3h14a2 2 0 012 2v14a2 2 0 01-.59 1.41M3 8.7V19a2 2 0 002 2h10.3M2 2l20 20\"/><path d=\"M13 13a3 3 0 100-6H9v2m0 8v-2.3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-square-parking-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});