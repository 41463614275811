define("ember-svg-jar/inlined/logo_kibana", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#F04E98\" d=\"M4 0v28.789L28.935.017z\"/><path class=\"euiIcon__fillNegative\" d=\"M4 12v16.789l11.906-13.738A24.721 24.721 0 004 12\"/><path fill=\"#00BFB3\" d=\"M18.479 16.664L6.268 30.754l-1.073 1.237h23.191c-1.252-6.292-4.883-11.719-9.908-15.327\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});