define("ember-svg-jar/inlined/l-text-cursor-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 4h1a3 3 0 013 3 3 3 0 013-3h1m0 16h-1a3 3 0 01-3-3 3 3 0 01-3 3H5m0-4H4a2 2 0 01-2-2v-4a2 2 0 012-2h1m8 0h7a2 2 0 012 2v4a2 2 0 01-2 2h-7M9 7v10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-text-cursor-input",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});