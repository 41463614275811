define("ember-svg-jar/inlined/pui_exit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.361.812h-5.166a.6.6 0 00-.6.6v1.583a.922.922 0 01.508 1.441l-.509.683V6.95h1.496v-.038a.64.64 0 011.033-.505l1.648 1.284a.64.64 0 010 1.01l-1.648 1.284a.64.64 0 01-1.033-.505v-.038H9.594v5.18a.6.6 0 00.6.6h5.166a.6.6 0 00.6-.6V1.413a.6.6 0 00-.599-.601z\"/><circle cx=\"6.354\" cy=\"2.438\" r=\"1.303\"/><path d=\"M9.715 3.413a.59.59 0 00-.827.122L7.722 5.103l-1.126-.385.576.075a.782.782 0 00-.64-.552l-1.616-.224a1.48 1.48 0 00-.95.189L2.058 5.322a.59.59 0 00-.291.55l.184 2.689A.59.59 0 103.13 8.48l-.158-2.323.777-.456-.423 2.865a5.518 5.518 0 00-.046.425l-.134 1.982-2.557.441a.71.71 0 00.242 1.398l3.106-.536a.71.71 0 00.587-.651l.146-2.164.839.138.813 1.725.345 3.265a.71.71 0 001.411-.149l-.358-3.384a.7.7 0 00-.064-.228L6.69 8.775l.34-2.46-.817-.478 1.535.525a.593.593 0 00.666-.207L9.837 4.24a.59.59 0 00-.122-.827z\"/><path d=\"M11.467 9.065v.415c0 .219.252.342.425.208l1.648-1.284a.263.263 0 000-.415l-1.648-1.284a.263.263 0 00-.425.208v.415H9.239a.28.28 0 00-.28.28v1.177c0 .155.126.28.28.28h2.228z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});