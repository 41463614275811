define("ember-svg-jar/inlined/desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2 2a1 1 0 00-1 1v7a1 1 0 001 1h4l-1 3H3.5a.5.5 0 000 1h9a.5.5 0 000-1H11l-1-3h4a1 1 0 001-1V3a1 1 0 00-1-1H2zm0 8V3h12v7H2zm5 1h2l1 3H6l1-3z\" clip-rule=\"evenodd\"/><path d=\"M4.5 6a.5.5 0 000 1h1a.5.5 0 000-1h-1zm3 0a.5.5 0 000 1h1a.5.5 0 000-1h-1zm2.5.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});