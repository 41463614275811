define("ember-svg-jar/inlined/l-map-pin-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.43 12.935c.357-.967.57-1.955.57-2.935a8 8 0 00-16 0c0 4.993 5.539 10.193 7.399 11.799a1 1 0 001.202 0 32 32 0 00.813-.728\"/><circle cx=\"12\" cy=\"10\" r=\"3\"/><path d=\"M16 18l2 2 4-4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-map-pin-check",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});