define("ember-svg-jar/inlined/map_builder_move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M49 16.172l6.586-6.586a2 2 0 012.828 2.828L51.828 19H55a2 2 0 010 4h-8a2 2 0 01-2-2v-8a2 2 0 014 0v3.172zM34 0a2 2 0 110 4 2 2 0 010-4zm-8 0a2 2 0 110 4 2 2 0 010-4zm16 0a2 2 0 110 4 2 2 0 010-4zm16 0a2 2 0 110 4 2 2 0 010-4zm-8 0a2 2 0 110 4 2 2 0 010-4zm8 40a2 2 0 110 4 2 2 0 010-4zm-8 0a2 2 0 110 4 2 2 0 010-4zm16 0a2 2 0 110 4 2 2 0 010-4zm0-16a2 2 0 110 4 2 2 0 010-4zm0-16a2 2 0 110 4 2 2 0 010-4zm0 8a2 2 0 110 4 2 2 0 010-4zM26 8a2 2 0 110 4 2 2 0 010-4zm0 8a2 2 0 110 4 2 2 0 010-4zm40 16a2 2 0 110 4 2 2 0 010-4zm0-32a2 2 0 110 4 2 2 0 010-4zM3.997 64l.003.003L3.997 64zM4 27.997L3.997 28 4 27.997zM40.003 28L40 27.997l.003.003zM40 64.003l.003-.003-.003.003zm4 0A3.996 3.996 0 0140.003 68H3.997A3.996 3.996 0 010 64.003V27.997A3.996 3.996 0 013.997 24h36.006A3.996 3.996 0 0144 27.997v36.006zM40 28H4v36h36V28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 68 68"
    }
  };
});