define("ember-svg-jar/inlined/pui_map_filled_pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g data-name=\"Group 998\"><path data-name=\"Path 2637\" d=\"M4 .272a4.556 4.556 0 014.078 4.066c0 1.933-2.166 7.853-3.525 7.275S.5 5.705.5 3.772A3.5 3.5 0 014 .272z\" fill=\"#ef5c5c\"/><path data-name=\"Path 2636\" d=\"M4.05 12.724a.5.5 0 00.9 0l4-8a.493.493 0 00.053-.235 4.673 4.673 0 00-.084-.705 5.551 5.551 0 00-.5-1.512 4.5 4.5 0 00-7.822 0 5.551 5.551 0 00-.5 1.512 4.673 4.673 0 00-.084.705.493.493 0 00.053.235zM4.5 3.5a1 1 0 101 1 1 1 0 00-1-1zm-3.493.895c.009-.106.027-.253.06-.429a4.546 4.546 0 01.413-1.238 3.5 3.5 0 016.04 0 4.546 4.546 0 01.413 1.238c.033.176.051.323.06.429L4.5 11.382z\" fill=\"#343741\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "13",
      "viewBox": "0 0 9 13"
    }
  };
});