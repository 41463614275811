define("ember-svg-jar/inlined/prohibido_el_paso_a_montacargas_y_otros_vehiculos_industriales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_TOMAR_MONTACARGAS_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><path d=\"M15.608 24.67v-3.553h1.82v8.662c1.668.927 3.479-.13 3.479-.13S21.544 19 26.378 19h5.101v9.793h1.746c1.744 0 0 6.088 0 6.088h-1.082c-.075-1.513-1.343-2.722-2.897-2.722-1.552 0-2.82 1.209-2.895 2.722h-3.533l.002-.04c0-1.679-1.385-3.044-3.088-3.044-1.702 0-3.087 1.366-3.087 3.043 0 .196.02.386.057.57h-1.094v-9.264H9v-.661l6.608-.814zm9.159 5.314l1.61-2.779h3.491v-6.617h-3.49c-1.44 0-2.237 1.98-2.653 3.64-.265 1.06-1.079 4.916-1.154 5.756h2.196zm-5.05 2.167c-1.479 0-2.679 1.182-2.679 2.641 0 1.46 1.2 2.643 2.68 2.643 1.48 0 2.68-1.183 2.68-2.643 0-1.46-1.2-2.641-2.68-2.641zm9.605.485c-1.405 0-2.545 1.123-2.545 2.51 0 1.384 1.14 2.51 2.545 2.51 1.406 0 2.546-1.126 2.546-2.51 0-1.387-1.14-2.51-2.546-2.51z\" fill-rule=\"nonzero\" fill=\"#000\"/><path d=\"M24 45C12.954 45 4 36.046 4 25S12.954 5 24 5s20 8.954 20 20-8.954 20-20 20zm0-3.077c9.346 0 16.923-7.577 16.923-16.923 0-9.346-7.577-16.923-16.923-16.923-9.346 0-16.923 7.577-16.923 16.923 0 9.346 7.577 16.923 16.923 16.923z\" fill=\"#E11212\"/><path fill=\"#E11212\" d=\"M10.7 13.831l24.591 24.577 2.294-2.025-24.94-24.875z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});