define("ember-svg-jar/inlined/pui_distributor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Proveedores</title><path d=\"M14.929 10.188h-4.794c-.4 0-.699.3-.699.699v1.898c0 .4.3.699.699.699h4.794c.399 0 .699-.3.699-.699v-1.898c0-.4-.399-.699-.699-.699z\"/><path d=\"M14.33 14.183H8.337V8.19c0-.2-.3-.499-.599-.499h-.7c.1-.2.1-.4 0-.599-.2-.399-.599-.599-.999-.499l-.999.4.3-.699c.1-.799-.399-1.698-1.298-1.798-.599 0-.999.2-1.398.499-.1.1-1.598 4.195-1.598 4.195-.1.399-.1.799.1 1.199 0 .1.799 2.097.799 2.097L.547 14.384c-.3.399-.2.999.2 1.298.436.319.909.106 1.099-.1l1.698-2.397c.069-.232.197-.514.2-.799l-.1-.2.699.399-.3 2.397c0 .499.3.899.799.999h.1c.499 0 .899-.4.899-.799l.3-2.996c0-.3-.1-.699-.4-.899l-1.798-1.099.399-1.199L5.54 8.49c.1.1.2.2.4.2h1.298v6.093c0 .3.2.499.499.499h.599c.2.4.499.699.899.699s.799-.3.799-.699h1.998c.1.499.599.799.999.699.3-.1.599-.3.699-.699h.499c.3 0 .499-.2.499-.499.101-.3-.099-.6-.398-.6zM3.064 2.3h.16c-.06.166-.098.342-.098.529a1.565 1.565 0 103.13 0c0-.187-.038-.363-.098-.529h.16a.172.172 0 100-.343h-.095c.001-.026.004-.052.004-.078 0-.517-.256-.973-.648-1.252v.584a.17.17 0 01-.169.169.17.17 0 01-.169-.169V.457l.002-.01a1.482 1.482 0 00-.361-.091v.567a.17.17 0 01-.169.169.17.17 0 01-.169-.169V.35c-.122.012-.24.039-.353.078.002.01.006.019.006.029v.754a.17.17 0 01-.169.169.17.17 0 01-.169-.169V.589c.103-.067.214-.12.331-.161a.166.166 0 00-.163-.14.17.17 0 00-.169.169v.132a1.535 1.535 0 00-.704 1.29c0 .027.003.052.004.078h-.094a.171.171 0 000 .343z\"/><path d=\"M5.579.457A.17.17 0 005.41.288a.168.168 0 00-.167.159c.12.046.232.107.335.18v-.17zM4.882.356V.169a.169.169 0 10-.337 0V.35a1.413 1.413 0 01.337.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});