define("ember-svg-jar/inlined/precaucion_superficie_caliente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_SUPERFICIE_CALIENTE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 45L25.132 5l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 42.212zm10.337-2.338v-2.422a1.2 1.2 0 01.092-.004h1.574c-1.47-3.322-.58-4.714.567-5.94.425-.454.632-.69.68-1.097.053-.452-.077-1.359-.997-3.105l2.146-1.13c2.156 4.093 1.203 5.641-.058 6.989-.425.454-.631.69-.679 1.097-.053.452.077 1.359.997 3.105.014.027.025.054.037.082h1.998c-1.47-3.323-.579-4.715.568-5.94.425-.455.631-.691.679-1.097.053-.453-.076-1.36-.997-3.106l2.146-1.13c2.157 4.093 1.204 5.642-.057 6.989-.425.454-.632.69-.68 1.097-.053.453.077 1.359.997 3.105.014.027.026.054.038.082h1.998c-1.47-3.323-.58-4.715.567-5.94.425-.455.632-.691.68-1.097.053-.453-.077-1.36-.997-3.106l2.146-1.13c2.156 4.093 1.203 5.642-.058 6.99-.425.453-.63.69-.679 1.096-.053.453.077 1.36.997 3.106.014.026.026.054.037.08h1.677v2.426h.11-15.529z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});