define("ember-svg-jar/inlined/pui_sample", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chemicalSample</title><path d=\"M14.5 0h-13C1.1 0 .7.3.7.8v2.1c0 .4.3.8.8.8h.4v11.4c0 .5.4.9.9.9h10.4c.5 0 .9-.4.9-.9V3.7h.4c.4 0 .8-.3.8-.8V.8c0-.5-.4-.8-.8-.8zM13 1.105a.25.25 0 01.5 0v1.438a.25.25 0 01-.5.001V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5 0V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5.001V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5 0V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5.001V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5 0V1.105zm-1.75 0a.25.25 0 01.5 0v1.438a.25.25 0 01-.5.001V1.105zM2.3 5V3.9h11.3V5H2.3zm11.4 8.3H6.1c-.3 0-.6-.3-.6-.6V7.2c0-.3.3-.6.6-.6h7.6v6.7zm-1.712-3.95H7.439a.25.25 0 010-.5h4.549a.25.25 0 010 .5zm0 1.9H7.439a.25.25 0 010-.5h4.549a.25.25 0 010 .5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});