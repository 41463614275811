define("ember-svg-jar/inlined/pui_floor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.3 11L8 8.7.6 16h4.6zM.1 15.4l1.7-1.7L0 11.8zM16 6.4L6.4 16H11l5-5zM16 12.2l-1.6 1.6 1.6 1.6zM16 .6L8.6 8.1l2.3 2.3L16 5.3zM12.1 16h3.3l-1.6-1.6zM0 9.8l4.7-4.7-2.1-2.4L0 5.2zM0 4.1C1 3.2 2.6 1.5 4.1 0H0v4.1zM3.2 2.1l2.1 2.5L9.8 0H5.2zM13.6 1.9L15.4 0h-3.7zM.2 10.8l2.2 2.3L13 2.5 10.8.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});