define("ember-svg-jar/inlined/logo_vulnerability_management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.05 1.95L6.808 6.192 16 15.385l9.192-9.193L20.95 1.95 16 6.9l-4.95-4.95z\" fill=\"#00BFB3\"/><path d=\"M4.686 19.627a8 8 0 010-11.313L16 19.627v11.314L4.686 19.627z\" fill=\"#FA744E\"/><path d=\"M27.314 8.314a8 8 0 010 11.313L16 30.941V19.627L27.314 8.314z\" fill=\"#343741\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});