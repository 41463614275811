define("ember-svg-jar/inlined/pui_process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.414 9.743l-1.405-.336.793-1.164a.42.42 0 00-.05-.534L8.741 6.7a.419.419 0 00-.517-.061l-1.231.756-.262-1.383a.42.42 0 00-.413-.342H4.889a.42.42 0 00-.409.323l-.336 1.405-1.163-.793a.42.42 0 00-.534.05l-1.01 1.01a.42.42 0 00-.061.518l.756 1.231-1.383.262a.42.42 0 00-.342.413v1.429a.42.42 0 00.323.409l1.406.336-.793 1.164a.42.42 0 00.05.534l1.01 1.01a.42.42 0 00.517.061l1.231-.756.262 1.383a.421.421 0 00.413.341h1.429a.42.42 0 00.409-.323L7 14.271l1.164.793a.42.42 0 00.534-.05l1.01-1.01a.42.42 0 00.061-.517l-.756-1.231 1.383-.262a.421.421 0 00.342-.413v-1.429a.421.421 0 00-.324-.409zm-4.842 3.22a2.128 2.128 0 110-4.257 2.128 2.128 0 010 4.257zM12.809 2.555l-.882-.211.497-.73a.265.265 0 00-.031-.335L11.76.646a.263.263 0 00-.324-.038l-.772.474L10.5.214A.266.266 0 0010.24 0h-.897a.263.263 0 00-.256.202l-.211.882-.73-.497a.262.262 0 00-.334.031l-.634.634a.263.263 0 00-.038.324l.474.772-.867.165a.264.264 0 00-.215.259v.896c0 .122.084.228.202.256l.882.211-.497.73a.264.264 0 00.031.335l.634.634c.086.086.22.102.324.038l.772-.474.164.868a.266.266 0 00.26.214h.896a.263.263 0 00.256-.202l.211-.882.73.497a.265.265 0 00.335-.031l.634-.634a.263.263 0 00.038-.324l-.474-.772.868-.164a.265.265 0 00.215-.259v-.898a.265.265 0 00-.204-.256zM9.772 4.574a1.335 1.335 0 110-2.67 1.335 1.335 0 010 2.67zM15.429 7.796l-.716-.171.404-.593a.214.214 0 00-.026-.272l-.515-.515a.215.215 0 00-.264-.031l-.627.386-.134-.705a.214.214 0 00-.21-.174h-.728a.214.214 0 00-.208.164l-.171.716-.594-.404a.214.214 0 00-.272.026l-.515.515a.215.215 0 00-.031.264l.386.628-.705.134a.214.214 0 00-.174.21v.728c0 .099.068.185.164.208l.716.171-.404.593a.214.214 0 00.026.272l.515.515c.07.07.179.083.264.031l.628-.386.134.705a.214.214 0 00.21.174h.728a.214.214 0 00.208-.164l.171-.716.593.404a.214.214 0 00.272-.026l.515-.515a.215.215 0 00.031-.264l-.386-.628.705-.134a.214.214 0 00.174-.21v-.727c0-.1-.068-.186-.164-.209zm-2.468 1.641a1.084 1.084 0 110-2.168 1.084 1.084 0 010 2.168z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});