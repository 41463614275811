define("ember-svg-jar/inlined/pui_oxidizing_agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.9 10.4v-.2c.6-1.7 1.6-1.4 1.3-3.4-.1-.6-.2-1.1-.5-1.6-.3.4-.7.8-1.1.9.4-.9.7-1.4.2-2.4-.2.5-.5.9-1 1 .1-1.1 0-1.5-.7-2.5-.2.6-.1.8-1 1-.3-1.6-.7-1.6-1.3-3.2-.8 1.2-.3 1.8-1 3.1-.5-.6-.6-.6-1.2-1.1-.2 1.2-.4 1.3-.3 2.5-.7-.3-1-1-1.5-1.5.1 1.1.2 1.7.3 2.8-.5-.3-.9-.6-1.4-.9.1 1.1-.2 3.7 1 4.4 0 .4-.1.7-.1 1.1 0 2 1.5 3.7 3.4 4.1H3.4V16h8.9v-1.5H8.5c2-.3 3.4-2 3.4-4.1zm-7.3 0c0-.5.1-.9.3-1.3.1-.1.2-.3.3-.5.6-.8 1.5-1.3 2.6-1.3 1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.2-1.3-3.2-3.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});