define("ember-svg-jar/inlined/l-shield-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 2l20 20M5 5a1 1 0 00-1 1v7c0 5 3.5 7.5 7.67 8.94a1 1 0 00.67.01c2.35-.82 4.48-1.97 5.9-3.71M9.309 3.652A12.3 12.3 0 0011.24 2.28a1.17 1.17 0 011.52 0C14.51 3.81 17 5 19 5a1 1 0 011 1v7a10 10 0 01-.08 1.264\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-shield-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});