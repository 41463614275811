define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10 1a1 1 0 01.707.293l4 4A1 1 0 0115 6v5a1 1 0 01-.293.707l-4 4A1 1 0 0110 16H5a1 1 0 01-.707-.293l-4-4A1 1 0 010 11V6a1 1 0 01.293-.707l4-4A1 1 0 015 1h5zM4.146 5.146a.5.5 0 01.708 0L7.5 7.793l2.646-2.647a.5.5 0 01.708.708L8.207 8.5l2.647 2.646a.5.5 0 01-.708.708L7.5 9.207l-2.646 2.647a.5.5 0 01-.708-.708L6.793 8.5 4.146 5.854a.5.5 0 010-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});