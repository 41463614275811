define("ember-svg-jar/inlined/l-git-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"5\" cy=\"6\" r=\"3\"/><path d=\"M5 9v6\"/><circle cx=\"5\" cy=\"18\" r=\"3\"/><path d=\"M12 3v18\"/><circle cx=\"19\" cy=\"6\" r=\"3\"/><path d=\"M16 15.7A9 9 0 0019 9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-git-graph",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});