define("ember-svg-jar/inlined/no_utilizar_agua_como_agente_extinguidor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PROHIBIDO_APAGAR_FUEGO_CON_CUBETA_WHITE</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M0 0h50v50H0z\"/><g fill-rule=\"nonzero\" fill=\"#000\"><path d=\"M20.336 28.228c0 3.99 2.898 3.314 2.835 6.015-.035 1.524-1.353 2.397-2.832 2.567.355-.4 1.918-2.386-.508-3.979-.078 1.536-1.386 1.78-1.386 2.884 0 .466.1.778.217.984-1.22-.31-2.255-1.139-2.423-2.518-.44-3.62 4.097-5.953 4.097-5.953zM27.347 21.8l9.14 5.443 4.802-5.982-7.64-7.311.377-.468a.408.408 0 00-.072-.584.435.435 0 00-.6.074L26.3 21.758a.408.408 0 00.072.584.432.432 0 00.6-.073l.375-.469zM22.033 27.94c.44-1.237 1.481-2.478 3.64-3.381 0 0-1.262 1.706-1.2 3.671a1.269 1.269 0 01-1.26 1.306h-.04a1.193 1.193 0 01-1.14-1.596zM18.766 23.789c1.115-.719 2.696-1.07 4.924-.465 0 0-2.043.588-3.23 2.195-.426.578-1.24.707-1.79.269l-.03-.024c-.647-.514-.585-1.517.126-1.975z\"/><path d=\"M28.106 20.73a15.639 15.639 0 00.676.504c.71.497 1.444.908 2.19 1.225a.435.435 0 00.562-.216.409.409 0 00-.217-.547 11.423 11.423 0 01-2.006-1.12 12.91 12.91 0 01-.677-.504c-1.198-.957-2.138-2.063-2.662-3.14-.505-1.039-.553-1.937-.13-2.465.424-.526 1.331-.7 2.49-.473 1.2.234 2.53.853 3.774 1.753l.528-.657c-2.985-2.167-6.226-2.673-7.463-1.132-1.236 1.54.052 4.477 2.935 6.771z\"/></g><path d=\"M25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zm0-3.077c9.346 0 16.923-7.577 16.923-16.923 0-9.346-7.577-16.923-16.923-16.923-9.346 0-16.923 7.577-16.923 16.923 0 9.346 7.577 16.923 16.923 16.923z\" fill=\"#E11212\"/><path fill=\"#E11212\" d=\"M11.7 13.831l24.591 24.577 2.294-2.025-24.94-24.875z\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#fff"
    }
  };
});