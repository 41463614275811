define("ember-svg-jar/inlined/l-bug-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 7.13V6a3 3 0 00-5.14-2.1L8 2m6.12 1.88L16 2\"/><path d=\"M22 13h-4v-2a4 4 0 00-4-4h-1.3\"/><path d=\"M20.97 5c0 2.1-1.6 3.8-3.5 4M2 2l20 20M7.7 7.7A4 4 0 006 11v3a6 6 0 0011.13 3.13M12 20v-8m-6 1H2M3 21c0-2.1 1.7-3.9 3.8-4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bug-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});