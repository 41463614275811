define("ember-svg-jar/inlined/l-venetian-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 12a5 5 0 005 5 8 8 0 015 2 8 8 0 015-2 5 5 0 005-5V7h-5a8 8 0 00-5 2 8 8 0 00-5-2H2z\"/><path d=\"M6 11c1.5 0 3 .5 3 2-2 0-3 0-3-2m12 0c-1.5 0-3 .5-3 2 2 0 3 0 3-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-venetian-mask",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});