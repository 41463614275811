define("ember-svg-jar/inlined/pui_category_other", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.092 1.862H1.526v6.665h6.566V1.862zM18.41 1.862h-6.566v6.665h6.566V1.862zM18.41 12.335h-6.566V19h6.566v-6.665zM8.092 12.335H1.526V19h6.566v-6.665z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});