define("ember-svg-jar/inlined/l-user-round-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 21a8 8 0 0111.873-7\"/><circle cx=\"10\" cy=\"8\" r=\"5\"/><path d=\"M17 17l5 5m0-5l-5 5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-user-round-x",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});