define("ember-svg-jar/inlined/l-snowflake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 12h20M12 2v20m8-6l-4-4 4-4M4 8l4 4-4 4M16 4l-4 4-4-4m0 16l4-4 4 4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-snowflake",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});