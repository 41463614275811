define("ember-svg-jar/inlined/l-bean-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 9c-.64.64-1.521.954-2.402 1.165A6 6 0 008 22a13.96 13.96 0 009.9-4.1M10.75 5.093A6 6 0 0122 8c0 2.411-.61 4.68-1.683 6.66\"/><path d=\"M5.341 10.62a4 4 0 006.487 1.208M10.62 5.341a4.01 4.01 0 012.039 2.04M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-bean-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});