define("ember-svg-jar/inlined/pui_pipe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pipes</title><path d=\"M15.069 13.703h-.222v-.398c.327-.081.574-.363.574-.715s-.247-.634-.574-.715V9.148a3.435 3.435 0 00-3.435-3.435H8.669c-.052-.363-.352-.648-.73-.648s-.678.285-.729.649h-.897V4.629c.364-.051.649-.351.649-.729s-.285-.678-.649-.73v-.873h.296a.476.476 0 00.476-.476V.476A.477.477 0 006.609 0H.931a.476.476 0 00-.476.476v1.346c0 .263.213.476.476.476h.296v.872c-.364.052-.649.352-.649.73s.285.678.648.729v2.736A3.435 3.435 0 004.661 10.8H7.21c.052.364.351.648.729.648s.678-.285.729-.648h.944v1.076c-.327.081-.574.363-.574.715s.247.634.574.715v.398h-.221a.476.476 0 00-.476.476v1.346a.477.477 0 00.476.474h5.678a.476.476 0 00.476-.476v-1.346a.476.476 0 00-.476-.475zM1.328 4.275a.375.375 0 010-.75h4.883a.375.375 0 01.001.75H1.328zm6.986 6.423a.375.375 0 01-.75 0V5.815a.375.375 0 01.75 0v4.883zm1.475 2.268a.375.375 0 010-.75h4.883a.375.375 0 010 .75H9.789z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});