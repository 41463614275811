define("ember-svg-jar/inlined/pui_crane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grua</title><circle cx=\"4.55\" cy=\"13.43\" r=\"1.151\"/><circle cx=\"12.749\" cy=\"13.43\" r=\"1.151\"/><path d=\"M16 8.803a.36.36 0 00-.075-.221l-.102-.134-1.209-1.585-1.23-1.613a.364.364 0 00-.276-.14l-.694-1.096a.315.315 0 00-.266-.146H9.824a.277.277 0 00-.276.276v1.083a.36.36 0 00-.098.245v5.848L4.319 3.763c.138-.232.222-.5.222-.789a1.554 1.554 0 00-3.109 0c0 .276.078.531.204.755v1.377a.25.25 0 00.251.251.97.97 0 010 1.937.97.97 0 01-.969-.969.25.25 0 10-.501 0 1.471 1.471 0 101.721-1.449v-.603c.244.16.535.255.849.255.087 0 .172-.012.255-.026l1.278 7.005H.571a.572.572 0 00-.571.572v.762a.57.57 0 00.571.571h2.23a1.75 1.75 0 013.498 0H11a1.75 1.75 0 013.498 0h.931a.57.57 0 00.571-.571v-.762l-.001-.003.001-.003v-3.27zM5.539 11.507L4.501 5.815l3.864 5.692H5.539zm6.202-3.059a.38.38 0 01-.379-.379v-.826a.38.38 0 01.379-.379h1.903l1.328 1.585h-3.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});