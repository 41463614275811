define("ember-svg-jar/inlined/pui_cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brokenGlass</title><path d=\"M12.67 5.068L12.272.397C12.174.199 11.975 0 11.776 0H9.888l-.596.497 1.292 1.292c.199.199.199.596 0 .795L9.49 3.677l-.695-.795.695-.696L8.198.894C8 .696 8 .298 8.198.099l.1-.099H4.223c-.199 0-.397.199-.397.398l-.497 4.67c-.199 2.584 1.689 4.87 4.174 5.168v4.77H6.111c-.298 0-.497.199-.497.497.001.298.199.497.497.497h3.776c.298 0 .497-.199.497-.497s-.199-.497-.497-.497h-1.39v-4.77c2.583-.298 4.372-2.584 4.173-5.168z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});