define("ember-svg-jar/inlined/pui_hygiene", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.6 3.4c.1 1-.7 2-1.8 2h-.1c-1-.1-1.7-1-1.7-2 0-.8.3-1.6.8-2.3.263-.415.5-.7.8-1 .1-.1.133-.1.2 0 .3.3.488.57.8.9.7.7 1 1.6 1 2.4z\"/><path d=\"M2.855 15.08c3.181.003 6.734.006 8.75.001.488-.001.877-.366.877-.813v-.023c0-.448-.384-.821-.873-.821h-3.69a.245.245 0 010-.49h6.257c.49 0 .888-.357.888-.808 0-.448-.398-.805-.888-.805H7.919a.245.245 0 010-.49h7.186c.49 0 .882-.372.882-.82v-.026c0-.448-.395-.813-.884-.813h-1.6l-.041.001-.017-.001H7.92a.24.24 0 01-.242-.245.244.244 0 01.244-.244h3.309l2.266-.001c.474-.017.853-.374.853-.812 0-.448-.398-.812-.888-.813l-7.18-.006a.245.245 0 01-.156-.435l1.044-.857a1.276 1.276 0 00.143-1.881l-.008-.009-.137-.168-5.709 2.973C.557 7.143 0 8.03 0 8.989v3.436c0 1.463 1.279 2.654 2.855 2.655z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});