define("ember-svg-jar/inlined/logo_elastic_stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#F04E98\" d=\"M32 9V2.5A2.5 2.5 0 0029.5 0h-27A2.5 2.5 0 000 2.5V9h32z\"/><path fill=\"#00BFB3\" d=\"M0 20h32v-8H0z\"/><path fill=\"#0080D5\" d=\"M14.5 23H0v6.5A2.5 2.5 0 002.5 32h12v-9z\"/><path fill=\"#FEC514\" d=\"M17.5 23v9h12a2.5 2.5 0 002.5-2.5V23H17.5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});