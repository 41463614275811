define("ember-svg-jar/inlined/pui_ear_muff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.824 6.186s-.298-.103-.298-.185c0-.103-.003-2.55-1.744-4.303C11.663.571 10.1 0 8.137 0a.39.39 0 00-.05.004C8.071.002 8.055 0 8.037 0 6.074 0 4.511.571 3.392 1.698c-1.742 1.753-1.745 4.2-1.745 4.303 0 .026.011.049.015.074-.16.067-.312.111-.312.111-.687.294-1.079.98-1.079 1.765l.883 3.432c.392.588 1.177.883 1.863.883h.588c.098.196.098.392.196.49l1.275 1.863C5.902 15.821 7.37 15.997 8 15.997s2.272-.176 3.098-1.378l1.275-1.863c.098-.098.098-.294.196-.49h.588c.686 0 1.471-.294 1.863-.883l.883-3.432c0-.785-.392-1.471-1.079-1.765zM4.094 2.383C5.022 1.453 6.349.981 8.037.981h.1c1.688 0 3.015.472 3.943 1.402a4.722 4.722 0 011.019 1.584 1.843 1.843 0 01-.334-.428c-1.079-1.373-2.746-2.255-4.511-2.255a6.199 6.199 0 00-.334 0c-1.765 0-3.432.883-4.511 2.255a1.837 1.837 0 01-.334.427c.217-.534.532-1.096 1.019-1.583zm9.063 5.372c-1.177 1.177-.588 2.059-.588 2.059-1.121 5.603-3.832 5.532-4.569 5.532s-3.274.071-4.395-5.531c0 0 .588-.883-.588-2.059 0 0-.069-5.884 4.983-5.884s5.157 5.883 5.157 5.883z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});