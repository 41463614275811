define("ember-svg-jar/inlined/l-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.9 12.1l4.514-4.514M20.1 2.3a1 1 0 00-1.4 0l-1.114 1.114A2 2 0 0017 4.828v1.344a2 2 0 01-.586 1.414A2 2 0 0117.828 7h1.344a2 2 0 001.414-.586L21.7 5.3a1 1 0 000-1.4zM6 16l2 2m.2-8.1C8.7 8.8 9.8 8 11 8c2.8 0 5 2.2 5 5 0 1.2-.8 2.3-1.9 2.8l-.9.4A2 2 0 0012 18a4 4 0 01-4 4c-3.3 0-6-2.7-6-6a4 4 0 014-4 2 2 0 001.8-1.2z\"/><circle cx=\"11.5\" cy=\"12.5\" r=\".5\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-guitar",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});