define("ember-svg-jar/inlined/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.8 0c.274 0 .537.113.726.312l3.2 3.428c.176.186.274.433.274.689V13a1 1 0 01-1 1H2a1 1 0 01-1-1V1a1 1 0 011-1h6.8zM12 5H8.5a.5.5 0 01-.5-.5V1H2v12h10V5zm-7.5 6a.5.5 0 110-1h5a.5.5 0 110 1h-5zm0-3a.5.5 0 010-1h5a.5.5 0 110 1h-5zm1 8a.5.5 0 110-1H14V6.5a.5.5 0 111 0V15a1 1 0 01-1 1H5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});