define("ember-svg-jar/inlined/pui_camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.335 8.497c0 1.292-1.093 2.385-2.385 2.385S5.565 9.789 5.565 8.497c0-1.292 1.093-2.385 2.385-2.385s2.385 1.093 2.385 2.385z\"/><path d=\"M14.263 3.76h-1.904l-.036-.034c-.298-.398-.497-.795-.795-1.193-.398-.595-.994-.893-1.689-.893H6.162c-.696 0-1.292.298-1.689.894a4.406 4.406 0 00-.796 1.093.422.422 0 01-.097.133H1.737c-.917 0-1.66.713-1.725 1.614-.003.015-.009.028-.012.042v7.355c.005.03.017.054.023.083a1.732 1.732 0 001.714 1.507h12.525c.959 0 1.737-.778 1.737-1.737v-.151-6.975a1.736 1.736 0 00-1.736-1.738zM7.95 12.675c-2.263 0-4.178-1.915-4.178-4.178S5.687 4.319 7.95 4.319s4.178 1.915 4.178 4.178-1.915 4.178-4.178 4.178z\"/><path d=\"M10.335 8.497c0 1.292-1.093 2.385-2.385 2.385S5.565 9.789 5.565 8.497c0-1.292 1.093-2.385 2.385-2.385s2.385 1.093 2.385 2.385z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});