define("ember-svg-jar/inlined/app_cross_cluster_replication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0v16h8.7l5.3-6V0H0zm2 2h10v7H7v5H2V2zm8.45 9L9 12.64V11h1.45zM18 16v16h8.7l5.3-6V16H18zm2 2h10v7h-5v5h-5V18zm8.45 9L27 28.64V27h1.45z\"/><path class=\"euiIcon__fillSecondary\" d=\"M5 18H3c0 6.075 4.925 11 11 11h2v-2h-2a9 9 0 01-9-9zM18 3h-2v2h2a9 9 0 019 9h2c0-6.075-4.925-11-11-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});