define("ember-svg-jar/inlined/pui_near_me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 20a.5.5 0 01-.5-.5V12H.5a.5.5 0 01-.251-.932l19-11a.5.5 0 01.683.683l-11 19a.5.5 0 01-.433.249zm-6.138-9H8.5a.5.5 0 01.5.5v6.138l9.128-15.766L2.362 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
});