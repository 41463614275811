define("ember-svg-jar/inlined/pui_material", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.6 5.45l2.8.9.1-.1v-.3l-.624-1.07a.428.428 0 00-.216-.184L11.5 4.25l-1.1-1.1h-.3l-.7.4 1.2 1.9zM8.1 8.15l1.7-2.1-1.5-2.3H6.6l-1.1.6-.1.1-.6 1-.8-.1c-.1 0-.2 0-.3.1l-.5 1.1 4.9 1.6zM16 8.95l-1-.8-.1-.1-4.2-1.5-1.8 2.3v4H16v-2.9c0-.1-.1-.3-.3-.3l.3-.4v-.3zM7.9 9.15L2 7.25h-.2c-.1 0-.2.1-.2.2l-.2 1.9s-1.4.4-1.4.6v2.9h7.9v-3.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});