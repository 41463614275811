define("ember-svg-jar/inlined/pui_radioactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8.004 8.004 0 100 16.008A8.004 8.004 0 008 0zM4.301 1.6a7.442 7.442 0 017.344 0 .426.426 0 01.142.567L9.734 5.706a.408.408 0 01-.548.142 2.498 2.498 0 00-2.442 0 .408.408 0 01-.549-.142L4.142 2.167a.36.36 0 01-.053-.195c0-.159.089-.301.212-.372zm2.602 9.079L4.85 14.218a.408.408 0 01-.354.195c-.07 0-.141-.036-.212-.071A7.393 7.393 0 01.621 7.971a.4.4 0 01.407-.407h4.087a.4.4 0 01.407.407c0 .867.478 1.681 1.239 2.124a.426.426 0 01.142.584zm1.079-1.08a1.624 1.624 0 01-1.628-1.628c0-.902.725-1.628 1.628-1.628s1.628.725 1.628 1.628-.726 1.628-1.628 1.628zm3.716 4.743c-.194.123-.442.053-.566-.142l-2.053-3.539a.406.406 0 01.142-.566 2.5 2.5 0 001.221-2.124.4.4 0 01.407-.407h4.088c.23 0 .407.177.442.407a7.389 7.389 0 01-3.681 6.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});