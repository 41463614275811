define("ember-svg-jar/inlined/pui_people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 .5c1.9 0 3.4 1.5 3.4 3.4S9.9 7.3 8 7.3 4.6 5.8 4.6 3.9 6.1.5 8 .5M8 0C5.8 0 4.1 1.7 4.1 3.9S5.8 7.8 8 7.8s3.9-1.7 3.9-3.9S10.2 0 8 0z\"/><path d=\"M11.9 7.9c1.7 1.3 2.6 3.2 2.6 5.2 0 1.4-2.8 2.4-6.5 2.4s-6.5-1-6.5-2.4c0-2.1 1-3.9 2.6-5.2 1 1 2.4 1.6 3.9 1.6s2.9-.6 3.9-1.6m-.1-.7C10.9 8.3 9.5 9 8 9s-2.9-.7-3.8-1.8C2.3 8.5 1 10.6 1 13.1c0 2 3.5 2.9 7 2.9s7-1 7-2.9c0-2.5-1.3-4.6-3.2-5.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});