define("ember-svg-jar/inlined/pui_cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.4 11.9l-4-3.9 3.9-3.9c.9-.9.9-2.5.2-3.4-.4-.5-1.1-.7-1.8-.7s-1.4.2-1.8.7L8 4.6 4.1.7C3.6.2 3 0 2.3 0S.9.2.5.7c-.7.9-.7 2.5.2 3.4L4.6 8 .7 11.9c-.5.4-.7 1.1-.7 1.8s.2 1.4.7 1.8c.9.7 2.5.7 3.4-.2L8 11.4l3.9 3.9c.9.9 2.5.9 3.4.2.5-.5.7-1.1.7-1.8s-.2-1.4-.6-1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});