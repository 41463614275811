define("ember-svg-jar/inlined/qr_scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1742_27799)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 2h4.286v4.286H8v-.857h.857v1.714H8V8h-.857v-.857H5.429v-.857H2.857v-.857H5.43V2.857H2.857v3.429H2V2zm6.857 5.143h.857V8h-.857v-.857zM4.571 3.714h-.857v.857h.857v-.857zm9.429 6V14H9.714v-1.714h-.857v-.857h.857V9.714h3.429v-.857H14v.857zm-6.857 0v-.857H9.714v.857H7.143zm-3.429-.857H5.43v.857h1.714v.857h-.857v.858h.857v.857h-.857V14H2.857v-.857H5.43V10.57H2.857V14H2V9.714h1.714v-.857zM2.857 8v.857h.857V8h.857v-.857h-.857V8h-.857zm0 0H2v-.857h.857V8zM8 13.143v-.857h.857v.857H8zm0 0V14h-.857v-.857H8zm2.571 0h2.572V10.57H10.57v2.572zm.858-1.714h.857v.857h-.857v-.857zM14 2H9.714v.857H8V2h-.857v.857H8v.857h.857v.857h.857v1.715H11.43v.857h.857v-.857h.857V8H14V2zm-.857 3.429H10.57V2.857h2.572V5.43zm-.857-1.715h-.857v.857h.857v-.857zM3.714 11.43h.857v.857h-.857v-.857zM12.286 8v.857h-.857V8h.857z\"/><path d=\"M0 0h1v4H0z\"/><path d=\"M4 0v1H0V0zM16 0v1h-4V0z\"/><path d=\"M16 4h-1V0h1zM16 16h-1v-4h1z\"/><path d=\"M12 16v-1h4v1zM0 16v-1h4v1z\"/><path d=\"M0 12h1v4H0z\"/></g><defs><clipPath id=\"clip0_1742_27799\"><path d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});