define("ember-svg-jar/inlined/l-pointer-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 4.5V4a2 2 0 00-2.41-1.957M13.9 8.4a2 2 0 00-1.26-1.295M21.7 16.2A8 8 0 0022 14v-3a2 2 0 10-4 0v-1a2 2 0 00-3.63-1.158M7 15l-1.8-1.8a2 2 0 00-2.79 2.86L6 19.7a7.74 7.74 0 006 2.3h2a8 8 0 005.657-2.343M6 6v8M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pointer-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});