define("ember-svg-jar/inlined/l-cloud-sun-rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2v2m-7.07.93l1.41 1.41M20 12h2m-2.93-7.07l-1.41 1.41m-1.713 6.31a4 4 0 00-5.925-4.128M3 20a5 5 0 118.9-4H13a3 3 0 012 5.24M11 20v2m-4-3v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cloud-sun-rain",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});