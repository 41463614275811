define("ember-svg-jar/inlined/l-type-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 16.5a.5.5 0 00.5.5h.5a2 2 0 010 4H9a2 2 0 010-4h.5a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V8a2 2 0 01-4 0V5a2 2 0 012-2h16a2 2 0 012 2v3a2 2 0 01-4 0v-.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-type-outline",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});