define("ember-svg-jar/inlined/l-pencil-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 10l-6.157 6.162a2 2 0 00-.5.833l-1.322 4.36a.5.5 0 00.622.624l4.358-1.323a2 2 0 00.83-.5L14 13.982m-1.171-6.81l4.359-4.346a1 1 0 113.986 3.986l-4.353 4.353M15 5l4 4M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-pencil-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});