define("ember-svg-jar/inlined/pui_extinguisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 2.6c-.1-.1-.1-.2-.2-.2h-2V3H10v-.4zM6.7 3h.6v-.6h-.8c-2.2 0-4.1 1.9-4.1 4.1v6l-.5 2.3c0 .1 0 .2.1.2v.1h1.2c.1 0 .1 0 .1-.1.1 0 .1-.1.1-.2L3 12.5V6.7C3 4.6 4.6 3 6.7 3z\"/><path d=\"M9.7 3.4H8c-1.2 0-2.1.9-2.2 2.1v10h6V5.4c0-1.1-1-2-2.1-2zM10.9.9h3.2c-.1-.4-.4-.6-.8-.6H8.8C8.3.3 8 .6 8 1c0 .5.4.8.8.9.4 0 .8-.3.8-.8 0-.1.1-.2.2-.2h.4l2.3 2.3c.1.1.2.1.4 0 .1-.1.1-.2.1-.2 0-.1 0-.1-.1-.2l-2-1.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});