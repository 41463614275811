define("ember-svg-jar/inlined/l-drumstick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.4 15.63a7.875 6 135 116.23-6.23 4.5 3.43 135 00-6.23 6.23\"/><path d=\"M8.29 12.71l-2.6 2.6a2.5 2.5 0 10-1.65 4.65A2.5 2.5 0 108.7 18.3l2.59-2.59\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-drumstick",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});