define("ember-svg-jar/inlined/pui_gas_mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toxicGases</title><path d=\"M8.051 10.83c-1.585 0-2.797.559-2.797 1.305v2.611c0-.746 1.305-1.305 2.797-1.305 1.492 0 2.797.559 2.797 1.305v-2.611c.001-.746-1.212-1.305-2.797-1.305z\"/><path d=\"M8.051 13.678c-1.327 0-2.488.497-2.488 1.161C5.564 15.42 6.725 16 8.051 16s2.488-.497 2.488-1.161c0-.663-1.161-1.161-2.488-1.161zm-1.492 1.268a.189.189 0 110-.378.189.189 0 010 .378zm1.492.817a.189.189 0 110-.378.189.189 0 010 .378zm0-.817a.189.189 0 110-.378.189.189 0 010 .378zm0-.664a.189.189 0 110-.378.189.189 0 010 .378zm1.493.664a.189.189 0 110-.378.189.189 0 010 .378zM15.132 5.028H13.49C13.182 2.258 10.925 0 8.051 0 5.178 0 2.92 2.258 2.715 5.028H.971a.44.44 0 00-.41.41v1.129c0 .205.205.41.41.41h1.642c.205.514.307 1.13.615 1.746l.103.103-2.155 2.154c-.308.308-.308.924 0 1.334l.616.616c.308.41.924.41 1.334 0l2.155-2.155.308.308c.718-.41 1.642-.616 2.463-.616s1.745.205 2.463.616l.308-.308 2.155 2.155c.308.41.924.41 1.334 0l.616-.616c.41-.308.41-.924 0-1.334l-2.155-2.155.103-.103c.308-.616.41-1.129.513-1.847h1.642a.44.44 0 00.41-.41V5.439c-.001-.205-.104-.411-.309-.411zM5.075 7.491c-.821 0-1.437-1.026-1.437-2.258s.718-2.258 1.437-2.258 1.437 1.026 1.437 2.258-.616 2.258-1.437 2.258zm4.516-2.257c0-1.231.718-2.36 1.539-2.36s1.539 1.129 1.539 2.36-.718 2.36-1.539 2.36-1.539-1.026-1.539-2.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});