define("ember-svg-jar/inlined/pui_category_ict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.365 20.932h4.878M11.122 8.553H16M1.365 13.505h2.44v7.427M11.122 1.125h2.439v7.428M6.243 1.125H1.365v7.428h4.878V1.125zM16 13.505h-4.878v7.427H16v-7.427z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "22",
      "viewBox": "0 0 17 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});