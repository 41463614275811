define("ember-svg-jar/inlined/l-earth-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 3.34V5a3 3 0 003 3m1 13.95V18a2 2 0 00-2-2 2 2 0 01-2-2v-1a2 2 0 00-2-2H2.05m19.49 4H17a2 2 0 00-2 2v4.54\"/><path d=\"M12 2a10 10 0 109.54 13M20 6V4a2 2 0 10-4 0v2\"/><rect width=\"8\" height=\"5\" x=\"14\" y=\"6\" rx=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-earth-lock",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});