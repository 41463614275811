define("ember-svg-jar/inlined/pui_drill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drill</title><path d=\"M15.553 2.186h-2.584v-.397c0-.099 0-.199-.099-.199l-.696-.696c-.099-.099-.199-.099-.298-.099h-1.391L9.193 0H1.441C.646 0 .05.596.05 1.491v2.186c0 .795.596 1.491 1.391 1.491h1.391l-.994 4.87-.696.696c-.298.298-.398.696-.398 1.093v2.783c.001.794.697 1.39 1.393 1.39H6.41c.894-.099 1.59-.696 1.59-1.491V11.826c0-.596-.298-1.093-.795-1.292l-1.292-.596.298-2.286h.298c.199 0 .298-.099.398-.298l.199-1.491c0-.099 0-.199-.099-.298-.1-.099-.597-.099-.597-.099v-.398h2.783l1.391-.696h1.391c.099 0 .199 0 .298-.099l.696-.696v-.696h2.584a.428.428 0 00.398-.398c-.001-.197-.199-.297-.398-.297zm-5.466 1.491l-.696.398V.895l.696.398v2.384zM3.964 2.245H1.675a.496.496 0 110-.994h2.289a.496.496 0 110 .994zm0 1.432H1.675a.496.496 0 110-.994h2.289a.496.496 0 110 .994z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});