define("ember-svg-jar/inlined/pui_box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.3 3.9L8 0 5.4 1.4 12 5.5v1.3l-1.7.9V6.5L3.6 2.4.8 3.9v7.5L8 16l7.2-4.5.1-7.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});