define("ember-svg-jar/inlined/l-utensils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 2v7c0 1.1.9 2 2 2h4a2 2 0 002-2V2M7 2v20m14-7V2a5 5 0 00-5 5v6c0 1.1.9 2 2 2zm0 0v7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-utensils",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});