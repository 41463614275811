define("ember-svg-jar/inlined/l-roller-coaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 19V5m4 14V6.8M14 19v-7.8M18 5v4m0 10v-6m4 6V9M2 19V9a4 4 0 014-4c2 0 4 1.33 6 4s4 4 6 4a4 4 0 10-3-6.65\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-roller-coaster",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});