define("ember-svg-jar/inlined/pui_document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.9 3.3c-.7 0-1.3-.5-1.3-1.3V0h-8c-.2 0-.4.2-.4.4v15.2c0 .2.2.4.4.4h10.8c.2 0 .4-.2.4-.4V3.3h-1.9z\"/><path d=\"M11.9 2.5h1.5l-2-2V2c0 .3.2.5.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});