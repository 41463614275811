define("ember-svg-jar/inlined/pui_virus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.098 7.098a.886.886 0 00-.731.402H13.19a5.181 5.181 0 00-1.165-2.818l.831-.831a.889.889 0 00.801-.232.903.903 0 00-1.276-1.276.884.884 0 00-.232.801l-.83.83A5.187 5.187 0 008.5 2.81V1.633a.886.886 0 00.402-.731.902.902 0 00-1.804 0c0 .309.165.568.402.731V2.81a5.174 5.174 0 00-2.818 1.165l-.83-.83a.886.886 0 00-.232-.801.903.903 0 00-1.276 1.276.886.886 0 00.801.232l.83.83A5.174 5.174 0 002.81 7.5H1.633a.886.886 0 00-.731-.402.902.902 0 000 1.804.886.886 0 00.731-.402H2.81a5.188 5.188 0 001.165 2.819l-.83.83a.885.885 0 00-.801.232.903.903 0 001.276 1.276.885.885 0 00.232-.801l.831-.831a5.181 5.181 0 002.818 1.165v1.177a.886.886 0 00-.402.731.9.9 0 101.803 0 .886.886 0 00-.402-.731V13.19a5.181 5.181 0 002.818-1.165l.83.83a.888.888 0 00.232.801.903.903 0 001.276-1.276.886.886 0 00-.801-.232l-.83-.83A5.181 5.181 0 0013.19 8.5h1.177a.886.886 0 00.731.402.902.902 0 000-1.804zM5.962 8.887a.794.794 0 110-1.588.794.794 0 010 1.588zM8.294 5.7a.794.794 0 111.588 0 .794.794 0 01-1.588 0zm1.892 5.008a.958.958 0 110-1.916.958.958 0 010 1.916z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});