define("ember-svg-jar/inlined/l-milk-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 2h8M9 2v1.343M15 2v2.789a4 4 0 00.672 2.219l.656.984a4 4 0 01.672 2.22v1.131M7.8 7.8l-.128.192A4 4 0 007 10.212V20a2 2 0 002 2h6a2 2 0 002-2v-3\"/><path d=\"M7 15a6.47 6.47 0 015 0 6.47 6.47 0 003.435.435M2 2l20 20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-milk-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});