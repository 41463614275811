define("ember-svg-jar/inlined/pui_lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.132 4.629h-1.509C11.421 3.019 10.717 0 8 0S4.579 3.019 4.478 4.629h-1.61c-.604 0-1.107.503-1.107 1.107v9.157c0 .604.503 1.107 1.107 1.107h10.264c.604 0 1.107-.503 1.107-1.107V5.736c0-.604-.503-1.107-1.107-1.107zM8 1.006c1.811 0 2.314 2.214 2.516 3.623H5.484C5.686 3.321 6.189 1.006 8 1.006zm0 10.566c-.906 0-1.711-.805-1.711-1.711S7.094 8.151 8 8.151s1.711.805 1.711 1.711c0 1.006-.805 1.71-1.711 1.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});