define("ember-svg-jar/inlined/advertencia_de_riesgo_biologico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ADVERTENCIA_DE_RIESGO_BIOLOGICO</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M2 46.584L25.008 5l23.008 41.584H2zm4.418-2.898h37.201L25.136 9.878 6.418 43.686z\" fill=\"#020202\" fill-rule=\"nonzero\"/><path d=\"M32.78 31.852a4.998 4.998 0 00-2.879-.839 4.958 4.958 0 00.803-2.718 4.983 4.983 0 00-2.286-4.2l-.073-.046-.22.3.065.056a3.843 3.843 0 011.369 2.943c0 2.119-1.72 3.843-3.831 3.843a3.842 3.842 0 01-3.832-3.843 3.84 3.84 0 011.368-2.943l.067-.055-.221-.301-.073.047a4.982 4.982 0 00-2.287 4.2c0 .905.243 1.785.704 2.557a4.98 4.98 0 00-5.453 5.055v.088l.373.017.01-.086a3.836 3.836 0 013.803-3.423c1.285 0 2.477.64 3.19 1.71.568.854.772 1.878.571 2.886a3.818 3.818 0 01-1.63 2.444 3.8 3.8 0 01-3.2.493l-.083-.024-.126.352.08.035a4.98 4.98 0 001.993.415c.983 0 1.936-.291 2.757-.841a4.96 4.96 0 001.716-1.975c.379.878.997 1.621 1.798 2.158a4.975 4.975 0 004.751.425l.08-.036-.127-.35-.083.023a3.808 3.808 0 01-3.2-.492 3.825 3.825 0 01-1.63-2.445 3.827 3.827 0 01.572-2.885 3.823 3.823 0 015.314-1.063 3.838 3.838 0 011.68 2.777l.008.085.372-.017.002-.087a4.981 4.981 0 00-2.211-4.24zm-6.395 1.334a4.64 4.64 0 00-.676 1.224l-.193.521-.17-.53a4.574 4.574 0 00-.646-1.273l-.283-.39.473.087c.45.082.868.099 1.325.052l.444-.046-.274.355z\" stroke=\"#000\" fill-rule=\"nonzero\" fill=\"#000\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});