define("ember-svg-jar/inlined/pui_tower_crane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crane</title><path d=\"M15.7 3.8l-.7-.1L10.7.1c-.1 0-.1-.1-.2-.1H10.2L1.1 3.7l-.7.1c-.2.1-.4.3-.4.5v1.2c0 .3.2.5.5.5H2v5.8c0 .2-.1.4-.3.6-.4.3-.6.7-.6 1.1 0 .8.6 1.4 1.3 1.4.8 0 1.4-.7 1.3-1.4 0-.3-.1-.5-.4-.6-.3 0-.5.1-.6.4v.1c0 .2-.1.4-.3.4-.2 0-.3-.2-.3-.4 0-.1.1-.3.2-.3.4-.3.7-.8.7-1.3V6h5.7v9.5c0 .3.2.5.5.5s.5-.2.5-.5v-1L11 13v2.5c0 .3.2.5.5.5s.5-.2.5-.5V6h1.2v2.6c0 .3.2.5.5.5h1.8c.3 0 .5-.2.5-.5V4.4c0-.3-.1-.5-.3-.6zM11 8.5L9.7 10V8.5L11 7v1.5zM10.6 6l-.9 1V6h.9zm-.9 7v-1.5L11 10v1.5L9.7 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});