define("ember-svg-jar/inlined/l-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 21v-2a1 1 0 01-1-1v-1a2 2 0 012-2h2a2 2 0 012 2v1a1 1 0 01-1 1\"/><path d=\"M19 15V6.5a1 1 0 00-7 0v11a1 1 0 01-7 0V9m16 12v-2h-4M3 5h4V3\"/><path d=\"M7 5a1 1 0 011 1v1a2 2 0 01-2 2H4a2 2 0 01-2-2V6a1 1 0 011-1V3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-cable",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});