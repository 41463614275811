define("ember-svg-jar/inlined/pui_plastic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plastico</title><path d=\"M15.6 0h-.1c-.8 0-1.5.7-1.5 1.5v.9c0 .9-.716 1.8-1.4 1.8H8.4c-.72 0-1.5-.697-1.5-1.4V.3c0-.2 0-.3-.2-.3H5.1c-.2 0-.3.1-.3.3v.6c0 .1 0 .1.2.1.5 0 .8.4.8.9v.8c0 .3-.1.4-.3.5-.1 0-.199.148-.199.248 0 .182.065.307.299.352.834.06 1.7.7 1.8 1.6v9.2c-.1.8.6 1.4 1.3 1.4h6c.7 0 1.3-.6 1.3-1.3V.5c0-.4-.1-.5-.4-.5zM4.125 3.125h-2.35A.125.125 0 011.65 3v-.85c0-.069.056-.125.125-.125h2.35c.069 0 .125.056.125.125V3a.125.125 0 01-.125.125z\"/><path d=\"M4.7 4.825c-.5 0-.8-.3-.9-.9 0-.1 0-.2-.2-.2H2.3c-.2 0-.2.1-.2.2-.1.6-.4.9-.9.9-.6 0-1.2.5-1.2 1.2v8.4c0 .8.6 1.5 1.4 1.5.3 0 .6-.1.8-.2.25.167.5.247.75.275.25-.028.5-.108.75-.275.2.1.5.2.8.2.8 0 1.4-.7 1.4-1.5v-8.4c0-.7-.6-1.2-1.2-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});