define("ember-svg-jar/inlined/l-book-dashed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 17h2m-2 5h2M12 2h2m4 20h1a1 1 0 001-1M18 2h1a1 1 0 011 1v1m0 11v2h-2m2-9v3M4 11V9m0 10.5V15M4 5v-.5A2.5 2.5 0 016.5 2H8m0 20H6.5a1 1 0 010-5H8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-book-dashed",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});