define("ember-svg-jar/inlined/pui_valve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>valve</title><path d=\"M15.503 9.4h-1.59c-.099-.4-.497-.6-.894-.6-.298 0-.497.1-.696.3-.099.1-.199.2-.199.4h-.894c-.298-.3-.596-.5-.994-.6v-.5c.696-.3 1.093-1.1.795-1.8-.099-.5-.596-.9-1.193-.9h-.496V4.6c.596-.2.894-.7.894-1.3v-.5h2.286c.795 0 1.391-.6 1.391-1.4S13.317 0 12.522 0H3.28c-.796 0-1.392.6-1.392 1.4s.596 1.4 1.392 1.4h2.286v.5c0 .6.398 1.1.894 1.3v1.1h-.398c-.795 0-1.391.6-1.391 1.4 0 .6.398 1.2.994 1.4V9c-.398 0-.696.2-.994.4h-.994c0-.1-.099-.2-.199-.3-.099-.2-.397-.3-.596-.3-.497 0-.795.2-.894.6H.398C.199 9.4 0 9.6 0 9.8v5c0 .2.199.4.398.4h1.391c.099 0 .298.1.298.2.199.4.696.5 1.093.4.298-.1.497-.3.596-.6h.994c.895.5 1.988.8 3.18.8s2.286-.3 3.28-.9h.994c.099.3.298.5.596.6.398.2.894 0 1.093-.4.099-.1.199-.2.298-.2h1.391c.199 0 .398-.2.398-.4v-5a.546.546 0 00-.497-.3zM9.839 7.6H6.062c-.298 0-.497-.2-.497-.5s.199-.5.497-.5h3.677c.298 0 .497.2.497.5s-.199.5-.397.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});