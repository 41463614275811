define("ember-capacitor-events/utils/subscribe", ["exports", "@ember/debug", "@ember/utils", "@ember/object/events", "@ember/destroyable", "@ember-decorators/utils/decorator", "@ember/object"], function (_exports, _debug, _utils, _events, _destroyable, _decorator, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _decorator.decoratorWithRequiredParams)((target, key, desc, params) => {
    let _listener = null;
    let path = params[0];
    let [service, event, err] = path.split('.');
    let isValidPath = (0, _utils.isPresent)(service) && (0, _utils.isPresent)(event) && (0, _utils.isBlank)(err);
    let actualFn = desc.value;
    (false && !(isValidPath) && (0, _debug.assert)(`'subscribe()' expects a path with exactly one leaf, was given ${path}`, isValidPath));
    (false && !(actualFn && typeof actualFn === 'function') && (0, _debug.assert)('The @subscribe decorator must be applied to functions', actualFn && typeof actualFn === 'function'));
    (false && !(target instanceof _object.default) && (0, _debug.assert)('The @subscribe decorator can only be used on EmberObjects', target instanceof _object.default));
    let computedFn = function () {
      if ((0, _utils.isBlank)((0, _object.get)(this, service)) || (0, _utils.isPresent)(_listener)) {
        return;
      }
      (0, _destroyable.registerDestructor)(this, () => {
        (0, _object.get)(this, service).off(event, _listener);
        (0, _events.removeListener)(this, 'init', null, key);
        _listener = null;
        computedFn = null;
      });

      // proxy the event
      _listener = e => {
        actualFn.call(this, e);
      };

      // subscribe to the event
      (0, _object.get)(this, service).on(event, this, _listener);
    };
    desc.value = computedFn;

    //eslint-disable-next-line
    (0, _events.addListener)(target, 'init', null, key);
    return desc;
  }, 'subscribe');
});