define("ember-svg-jar/inlined/pui_electric_maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voltage</title><path d=\"M9.4 10.6c.1-.1 0-.2 0-.2L6.7 10c-.2 0-.2 0-.2-.1l1.8-2c.1-.1 0-.2-.1-.2l-3.1-.5s-.1 0-.1.1l-2.1 4c0 .1 0 .1.1.1l2.1.3c.1 0 .1.1.1.1l-1.5 3.6 5.7-4.8z\"/><path d=\"M15.607 6.416A.626.626 0 0015.4 6.3l-2.1-.5L14.5 4c.2-.3.1-.6-.1-.8l-1.5-1.5a.539.539 0 00-.181-.118.636.636 0 00-.662-.001l-1.87 1.149L9.8.6a.702.702 0 00-.182-.274A.633.633 0 009.141.1H6.956a.643.643 0 00-.625.493l-.364 1.522L5.8 2.7 4 1.5c-.025-.013-.061-.018-.092-.028a.639.639 0 00-.509.01.532.532 0 00-.199.118l-.405.431-1.12 1.12a.643.643 0 00-.093.791l1.149 1.87L.6 6.2a.702.702 0 00-.274.182.633.633 0 00-.226.477V9c0 .3.2.6.5.6l2 .5L5 6.2c.3-.3.6-.6 1-.8.6-.4 1.3-.7 2-.7 1.8 0 3.3 1.5 3.3 3.3 0 1-.4 1.9-1.1 2.5L6.1 14l.3 1.4c.099.298.298.496.594.499v.001h2.05a.643.643 0 00.625-.494l.493-2.064L11.9 14.5c.186.124.37.125.532.065a.64.64 0 00.348-.173l1.545-1.545a.643.643 0 00.093-.791l-1.157-1.883 2.116-.401a.642.642 0 00.523-.631V6.956a.643.643 0 00-.293-.54z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});