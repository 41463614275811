define("ember-svg-jar/inlined/l-headphone-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 14h-1.343M9.128 3.47A9 9 0 0121 12v3.343M2 2l20 20m-1.586-1.586A2 2 0 0119 21h-1a2 2 0 01-2-2v-3M3 14h3a2 2 0 012 2v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-7a9 9 0 012.636-6.364\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-headphone-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});