define("ember-svg-jar/inlined/l-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 7l4.41-4.41A2 2 0 017.83 2h8.34a2 2 0 011.42.59L22 7M4 12v8a2 2 0 002 2h12a2 2 0 002-2v-8\"/><path d=\"M15 22v-4a2 2 0 00-2-2h-2a2 2 0 00-2 2v4M2 7h20m0 0v3a2 2 0 01-2 2 2.7 2.7 0 01-1.59-.63.7.7 0 00-.82 0A2.7 2.7 0 0116 12a2.7 2.7 0 01-1.59-.63.7.7 0 00-.82 0A2.7 2.7 0 0112 12a2.7 2.7 0 01-1.59-.63.7.7 0 00-.82 0A2.7 2.7 0 018 12a2.7 2.7 0 01-1.59-.63.7.7 0 00-.82 0A2.7 2.7 0 014 12a2 2 0 01-2-2V7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-store",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});