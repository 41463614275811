define("ember-svg-jar/inlined/folder_closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 2H2v11h12V4H8.125A1.125 1.125 0 017 2.875V2zm.25-1a.75.75 0 01.75.75v1.125c0 .069.056.125.125.125H14a1 1 0 011 1v9a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h5.25z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});