define("ember-svg-jar/inlined/pui_cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cable</title><path d=\"M15.3 14.6h-.728v-1.314a.643.643 0 00.634-.643v-.875a.638.638 0 00-.571-.63.638.638 0 00.571-.63v-.875a.638.638 0 00-.571-.63.638.638 0 00.571-.63v-.876a.638.638 0 00-.571-.63.638.638 0 00.571-.63v-.875a.638.638 0 00-.571-.63.638.638 0 00.571-.63v-.875a.644.644 0 00-.634-.643V1.4h.728c.385 0 .7-.315.7-.7s-.315-.7-.7-.7H4c-.385 0-.7.315-.7.7s.315.7.7.7h.728v1.182h-.119a.645.645 0 00-.645.645v.875c0 .33.251.592.571.63a.638.638 0 00-.571.63v.875c0 .33.251.592.571.63a.638.638 0 00-.571.63v.875c0 .304.214.546.497.615H.645A.646.646 0 000 9.633v.875c0 .356.289.645.645.645h3.816a.636.636 0 00-.497.615v.875c0 .356.289.645.645.645h.259V14.6H4c-.385 0-.7.315-.7.7 0 .385.315.7.7.7h11.3c.385 0 .7-.315.7-.7 0-.385-.315-.7-.7-.7zM4.464 4.102v-.875c0-.08.065-.145.145-.145h9.952c.08 0 .145.065.145.145v.875c0 .08-.065.145-.145.145H4.609a.145.145 0 01-.145-.145zm0 2.136v-.876c0-.08.065-.145.145-.145h9.952c.08 0 .145.065.145.145v.875c0 .08-.065.145-.145.145H4.609a.145.145 0 01-.145-.144zm0 1.259c0-.08.065-.145.145-.145h9.952c.08 0 .145.065.145.145v.875c0 .08-.065.145-.145.145H4.609a.145.145 0 01-.145-.145v-.875zM.5 10.508v-.875c0-.08.065-.145.145-.145h13.916c.08 0 .145.065.145.145v.875c0 .08-.065.145-.145.145H.645a.145.145 0 01-.145-.145zm3.964 2.135v-.875c0-.08.065-.145.145-.145h9.952c.08 0 .145.065.145.145v.875c0 .08-.065.145-.145.145H4.609a.145.145 0 01-.145-.145z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});