define("ember-svg-jar/inlined/app_vulnerability_management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"euiIcon__fillSecondary\" d=\"M5.394 6.192L11.05.536 16 5.486l4.95-4.95 5.657 5.656L16 16.8 5.394 6.192zm2.828 0L16 13.971l7.778-7.779-2.828-2.828L16 8.314l-4.95-4.95-2.828 2.828z\"/><path d=\"M16 30.941L4.686 19.627A8.004 8.004 0 013.45 9.905a8.011 8.011 0 011.236-1.591L16 19.539 27.313 8.314l.045.045a8.003 8.003 0 01-.045 11.268L16 30.942zm0-2.828l9.899-9.9a6.002 6.002 0 001.169-6.838L16 22.357 4.932 11.375A6.003 6.003 0 006.1 18.213l9.9 9.9z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});