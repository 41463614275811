define("ember-svg-jar/inlined/pui_goggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.7 7v5.3l.7.5c.8.6 1.9 1.1 2.9 1.1.5 0 .9-.3 1.2-.7l.5-.8c.4-.6 1-.8 1.4-.4l.3.3.5.8c.3.4.7.6 1.2.7 1.1 0 2.1-.3 2.9-.8l.8-.4V7c-4-1-8.3-1-12.4 0zM0 10.7c0 .6.5 1.3 1.2 1.5V7H0v3.7zM14.8 7v5.4c.6-.1 1.2-.7 1.2-1.4V7h-1.2z\"/><path d=\"M14.583 6.684c-.026-.038-2.688-3.777-6.599-3.777-3.92 0-6.539 3.737-6.565 3.775l-.823-.568c.118-.172 2.948-4.208 7.388-4.208 4.439 0 7.3 4.034 7.419 4.206l-.82.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});