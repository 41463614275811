define("ember-svg-jar/inlined/l-briefcase-conveyor-belt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 20v2m4-2v2m4-2v2m3-2H3m3 0v2m2-6V4a2 2 0 012-2h4a2 2 0 012 2v12\"/><rect width=\"16\" height=\"10\" x=\"4\" y=\"6\" rx=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-briefcase-conveyor-belt",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});