define("ember-link/helpers/link", ["exports", "@ember/component/helper", "@ember/debug", "@ember/service", "ember-link/link"], function (_exports, _helper, _debug, _service, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LinkHelper = _exports.default = (_dec = (0, _service.inject)('link-manager'), _class = class LinkHelper extends _helper.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "linkManager", _descriptor, this);
    }
    /**
     * Normalizes the positional and named parameters passed to this helper.
     *
     * @param positional [route?, ...models?, query?]
     * @param named { route?, models?, model?, query? }
     */
    normalizeLinkParams(positional, named) {
      var _named$route, _named$query;
      (false && !(!('queryParams' in named)) && (0, _debug.assert)(`You provided 'queryParams', but the parameter you mean is just 'query'.`, !('queryParams' in named)));
      (false && !(!('routeName' in named)) && (0, _debug.assert)(`You provided 'routeName', but the parameter you mean is just 'route'.`, !('routeName' in named)));
      if (named.fromURL) {
        (false && !(!['route', 'models', 'model', 'query'].some(name => named[name])) && (0, _debug.assert)(`When specifying a serialized 'fromURL' ('${named.fromURL}'), you can't provide any further 'LinkParams'.`, !['route', 'models', 'model', 'query'].some(name => named[name])));
        return this.linkManager.getLinkParamsFromURL(named.fromURL);
      }
      (false && !(!(positional[0] && named.route)) && (0, _debug.assert)(`Either pass the target route name as a positional parameter ('${positional[0]}') or pass it as a named parameter ('${named.route}').`, !(positional[0] && named.route)));
      (false && !(Boolean(positional[0] || named.route)) && (0, _debug.assert)(`Neither specified the target route name as a positional or named parameter ('route').`, Boolean(positional[0] || named.route)));
      const positionalQueryParameters = positional.length > 0 && (0, _link.isQueryParams)(positional[positional.length - 1]) ? positional[positional.length - 1] : undefined;
      (false && !(!positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))) && (0, _debug.assert)(`Query parameters either need to be specified as the last positional parameter or as the named 'query' parameter.`, !positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))));
      (false && !(!(named.query && positionalQueryParameters)) && (0, _debug.assert)(`Either specify query parameters as the last positional parameter ('${positional[positional.length - 1]}') or as the named 'query' parameter ('${named.query}').`, !(named.query && positionalQueryParameters)));
      (false && !(!(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))) && (0, _debug.assert)(`Either specify models as positional parameters, as the named 'models' parameter, or as the named 'model' parameter as a short hand for a single model.`, !(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))));
      (false && !(!(named.models && named.model)) && (0, _debug.assert)(`Either pass multiple 'models' ('${named.models}') or pass a single 'model' ('${named.model}'). `, !(named.models && named.model)));
      return {
        route: (_named$route = named.route) !== null && _named$route !== void 0 ? _named$route : positional[0],
        models: Array.isArray(named.models) ? named.models : named.model ? [named.model] : positional.length > 1 ? positional.slice(1, positionalQueryParameters ? -1 : undefined) : undefined,
        query: (_named$query = named.query) !== null && _named$query !== void 0 ? _named$query : positionalQueryParameters,
        mode: named.mode,
        onTransitionTo: named.onTransitionTo,
        onReplaceWith: named.onReplaceWith
      };
    }

    /**
     * Normalizes and extracts the `UILinkParams` from the named params.
     *
     * @param named { preventDefault? }
     */
    normalizeUIParams(named) {
      var _named$preventDefault;
      return {
        preventDefault: (_named$preventDefault = named.preventDefault) !== null && _named$preventDefault !== void 0 ? _named$preventDefault : true
      };
    }
    compute(positional, named) {
      const linkParams = this.normalizeLinkParams(positional, named);
      const uiParams = this.normalizeUIParams(named);
      return this.linkManager.createUILink(linkParams, uiParams);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "linkManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});