define("ember-svg-jar/inlined/pui_safety_cone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.633 5.6h-5.27a.227.227 0 00-.215.154l-.944 2.745a.227.227 0 00.215.301h7.072a.227.227 0 00.217-.295L10.85 5.76a.228.228 0 00-.217-.16zM9.1.6C9 .2 8.6 0 8.2 0h-.6c-.4 0-.7.2-.8.6l-.795 2.298a.227.227 0 00.215.302h3.372a.227.227 0 00.217-.294L9.1.6zM13.55 14.24l-.9-2.881a.228.228 0 00-.217-.16h-9.06a.228.228 0 00-.219.166l-.807 2.868a.228.228 0 01-.219.167h-1.9a.228.228 0 00-.228.228v1.145c0 .125.102.227.228.227h15.545a.228.228 0 00.227-.228v-1.145a.228.228 0 00-.228-.228h-2.005a.226.226 0 01-.217-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});