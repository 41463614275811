define("ember-svg-jar/inlined/l-book-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 8.2A2.22 2.22 0 0013.8 6q-1.2 0-1.8.9-.6-.9-1.8-.9A2.22 2.22 0 008 8.2c0 .6.3 1.2.7 1.6A227 227 0 0012 13a404 404 0 003.3-3.1 2.41 2.41 0 00.7-1.7\"/><path d=\"M4 19.5v-15A2.5 2.5 0 016.5 2H19a1 1 0 011 1v18a1 1 0 01-1 1H6.5a1 1 0 010-5H20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-book-heart",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});