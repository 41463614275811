define("ember-svg-jar/inlined/tokenBoolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8 13A5 5 0 118 3a5 5 0 010 10zm-2.828-2.172a4 4 0 015.656-5.656c.004.013-5.645 5.674-5.656 5.656z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});