define("ember-svg-jar/inlined/pui_food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.4 2.6h-4.1V1.3h2c.4 0 .6-.3.6-.6s-.3-.7-.6-.7h-2.6c-.4 0-.7.3-.7.6v1.9H5.9c-.3 0-.6.3-.6.6v.7c0 .3.3.6.6.6h9.5c.3 0 .6-.3.6-.6v-.6c0-.3-.3-.6-.6-.6zM5.8 5.4L6 6.9c1.6.1 2.9.5 3.8 1.2 1.1.7 1.5 1.7 1.7 2.3.1.5 0 1-.3 1.4.1.3.2.5.2.8s-.1.6-.2.8c.3.5.4 1 .2 1.6-.1.2-.1.4-.2.5h1.3c.5 0 1-.4 1.1-.9l1.8-9.1-9.6-.1z\"/><path d=\"M5.3 7.8C1.6 7.8.4 9.4.1 10.4c-.1.4.2.8.6.8h9c.4 0 .7-.4.6-.8-.2-1-1.2-2.6-5-2.6zM5.2 16c3.7 0 4.9-.1 5.2-1.1.1-.4-.2-.8-.6-.8h-9c-.4 0-.7.4-.6.8.2.9 1.2 1.1 5 1.1zM9.4 13.6H.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h8.5c.5 0 .9.4.9.9s-.4.9-.9.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});