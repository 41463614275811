define("ember-svg-jar/inlined/pui_wood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>madera</title><path d=\"M14.7 8.5c-.1-.2-.3-.3-.4-.3-.2 0-.4 0-.5.1l-1.725 1.803a.276.276 0 01-.475-.191V2.528a.32.32 0 00-.461-.292c-.763.362-1.866.514-2.939.514-1.033 0-2.096-.141-2.855-.474a.32.32 0 00-.445.301V4.4L2.7 1.9c-.3-.3-.8-.2-1 .1-.2.2-.2.6 0 .8l2.7 3.8.554.831.046.152v7.802c0 .34.275.615.615.615h5.371a.615.615 0 00.615-.615v-1.993l.056-.166L14.7 9.2c.2-.2.2-.5 0-.7zm-4.8-.7v.9c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3v-1c0-.1 0-.1.1-.2.3-.4.5-.9.3-1.5-.1-.2-.2-.4-.3-.5-.1-.1-.1-.1-.1-.2V3.4c0-.2.1-.3.3-.3.2-.1.3.1.3.3v1.696c0 .067.029.131.073.181.153.176.24.361.327.622.3.601.2 1.401-.4 1.901z\"/><ellipse cx=\"8.2\" cy=\"1.164\" rx=\"3.18\" ry=\"1.164\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});