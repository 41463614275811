define("ember-svg-jar/inlined/pui_crash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vehicleAccident</title><circle cx=\"11.536\" cy=\"14.56\" r=\"1.121\"/><path d=\"M15.7 6.418h-.1l-5.3.5c-.5 0-.9.3-1.1.8l-1.1 2.4c-.1.4-.2.8-.2 1.2-.1-.1-.1-.2-.2-.3-.3-.4-2.3-1-2.3-1l-1.7-2.9c-.2-.3-.6-.5-1-.6l-2.7-.4v8l2.364.394a1.88 1.88 0 011.88-1.846c1.04 0 1.884.843 1.884 1.884 0 .165-.028.323-.068.475l.84.093c.4 0 .7-.3.8-.7l.3-1.6.1 1.5c0 .4.4.7.8.7l.789-.099a1.884 1.884 0 113.732-.359c0 .019-.005.037-.006.057L16 14.418v-8h-.3zm-14.2 3.1l.2-1.6 2 .4 1.1 1.8-3.3-.6zm8.7.6l.2-1.4c0-.1.1-.2.2-.2l3.8-.3.2 1.5-4.4.4z\"/><circle cx=\"4.244\" cy=\"14.549\" r=\"1.121\"/><path d=\"M10.927 4.891l-.783-.622.798-1.006-1.048.022c-.293-.005-.502-.211-.51-.486l-.022-.78-1.046 1.052c-.095.095-.249.119-.358.148a.497.497 0 01-.356-.153L6.538 1.964l-.067.849a.5.5 0 01-.493.46l-.687.009.659.594-.67.742-1.609-1.451A.5.5 0 014 2.296l1.51-.017.119-1.5a.5.5 0 01.859-.308l1.479 1.533L9.474.488a.499.499 0 01.854.339l.041 1.448 1.613-.034c.21.019.373.104.459.278a.501.501 0 01-.057.533l-1.457 1.839z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});