define("ember-svg-jar/inlined/l-backpack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 10a4 4 0 014-4h8a4 4 0 014 4v10a2 2 0 01-2 2H6a2 2 0 01-2-2zm4 0h8m-8 8h8\"/><path d=\"M8 22v-6a2 2 0 012-2h4a2 2 0 012 2v6M9 6V4a2 2 0 012-2h2a2 2 0 012 2v2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-backpack",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});