define("ember-m3/utils/caches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.recordToRecordArrayMap = _exports.recordDataToRecordMap = _exports.recordDataToQueryCache = void 0;
  const recordDataToRecordMap = _exports.recordDataToRecordMap = new WeakMap();
  const recordDataToQueryCache = _exports.recordDataToQueryCache = new WeakMap();
  const recordToRecordArrayMap = _exports.recordToRecordArrayMap = new WeakMap();
});