define("ember-svg-jar/inlined/pui_materials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.207 3.665l.939.939.075.075.099.038 1.124.415.277.475-1.795-.577-.824-1.305.105-.06m.193-.515h-.3l-.7.4 1.2 1.9 2.8.9.1-.1v-.3l-.624-1.07a.428.428 0 00-.216-.184L11.5 4.25l-1.1-1.1zM8.029 4.25l1.153 1.768-1.254 1.55-4.042-1.32.176-.387.676.084.323.04.168-.279.568-.946.006-.006.925-.504h1.301m.271-.5H6.6l-1.1.6-.1.1-.6 1-.8-.1c-.1 0-.2 0-.3.1l-.5 1.1 4.9 1.6 1.7-2.1-1.5-2.3zM10.871 7.142l3.754 1.341.02.02.02.02.022.017.762.61-.15.2-.6.8h.8v2.2H9.4V9.022l1.471-1.88M10.7 6.55l-1.8 2.3v4H16v-2.9c0-.1-.1-.3-.3-.3l.3-.4v-.3l-1-.8-.1-.1-4.2-1.5zM2.066 7.797L7.4 9.514v2.836H.5v-2.159c.219-.099.64-.246 1.037-.36l.325-.093.035-.336.169-1.605M2 7.25h-.2c-.1 0-.2.1-.2.2l-.2 1.9s-1.4.4-1.4.6v2.9h7.9v-3.7L2 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});