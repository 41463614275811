define("ember-svg-jar/inlined/l-message-square-dashed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 17H7l-4 4v-7m11 3h1M14 3h1m4 0a2 2 0 012 2m0 9v1a2 2 0 01-2 2m2-8v1M3 9v1m2-7a2 2 0 00-2 2m6-2h1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-message-square-dashed",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});