define("ember-svg-jar/inlined/pui_pedestrian", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.1 11.5l1.7 3H16l-3.2-3zM6.7 11.5l-.3 3h3.2l-.3-3zM0 14.5h3.2l1.7-3H3.2z\"/><path d=\"M11.42 12.737l-1.562-2.569-.359-1.885c-.027-.142-.659-3.198-.659-3.198l1.126.775.288 1.644a.499.499 0 10.984-.172l-.324-1.85a.49.49 0 00-.209-.325L8.434 3.592a.495.495 0 00-.437-.065l-.887.286a.498.498 0 00-.283.231L5.85 5.787l-1.731 1.12a.5.5 0 00.543.84l1.835-1.188a.491.491 0 00.164-.175l.131-.233.751 2.153-1.699 1.674a.497.497 0 00-.136.245l-.572 2.492a.5.5 0 10.974.223l.539-2.35 1.736-1.712h.208l.298 1.567a.516.516 0 00.064.166l1.609 2.646a.499.499 0 00.687.167.495.495 0 00.169-.685z\"/><circle cx=\"7.135\" cy=\"2.32\" r=\"1.102\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});