define("ember-svg-jar/inlined/l-volume-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 9a5 5 0 01.95 2.293m2.414-5.657a9 9 0 011.889 9.96M2 2l20 20M7 7l-.587.587A1.4 1.4 0 015.416 8H3a1 1 0 00-1 1v6a1 1 0 001 1h2.416a1.4 1.4 0 01.997.413l3.383 3.384A.705.705 0 0011 19.298V11M9.828 4.172A.686.686 0 0111 4.657v.686\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-volume-off",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});