define("ember-svg-jar/inlined/precaucion_materiales_con_riesgo_de_explosion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>PRECAUCION_MATERIALES_CON_RIESGO_DE_EXPLOSION</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFC107\" d=\"M0 0h50v50H0z\"/><path d=\"M3 47L25.132 7l22.131 40H3zm4.25-2.788h35.784l-17.78-32.52L7.25 44.212zm27.502-8.324l-4.089.089-1.47-1.966 4.391-.466 1.168 2.343zm-3.343-11.149l-3.03 3.424h-3.007l1.386-3.424h4.65zm-9.304-4.936l3.968 3.632-9.015 9.984 5.047-13.616zM15.34 42.31a3.247 3.247 0 01-1.492-1.579c-1.047-2.338.007-4.64 1.8-5.458.004-.002.986 1.459.986 1.459l2.03-.63.59 1.818-.83 2.031c1.066.056 2.121.12 2.11.154-.66 2.07-2.907 3.355-5.194 2.205zm17.334-14.483l.595 2.694-11.952 3.575 11.357-6.269zm-6.831 6.875l2.88 2.068-4.583 2.404-1.812-3.427 3.515-1.045zm9.636 7.255l-12.13-1.85s9.622-1.316 13.02-1.778l-.777 3.545a.103.103 0 01-.113.083z\" fill=\"#020202\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#ffc107"
    }
  };
});