define("ember-svg-jar/inlined/ubicacion_de_una_salida_de_emergencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_UNA_SALIDA_DE_EMERGENCIA</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#080\" d=\"M0 0h50v50H0z\"/><path d=\"M38.55 6.897c0-.666-.356-1.238-.842-1.593-.087-.043-.13-.087-.225-.13A1.975 1.975 0 0036.64 5H12.953C11.886 5 11 5.84 11 6.948v4.917h9.91l-1.024-1.021c-.269-.268-.225-.667 0-.935l.668-.666a.575.575 0 01.442-.173c.175 0 .356.043.487.173l3.288 3.331c.131.13.174.268.174.442s-.087.355-.174.485l-3.288 3.324a.646.646 0 01-.937 0l-.668-.666a.642.642 0 010-.935l1.068-1.02h-9.902v28.848c0 1.065.842 1.948 1.909 1.948h23.68c1.068 0 1.953-.84 1.953-1.948L38.55 6.897zm-8.225 10.464c1.336 0 2.446 1.108 2.446 2.44 0 1.332-1.11 2.39-2.446 2.39a2.381 2.381 0 01-2.396-2.39c0-1.325 1.017-2.44 2.396-2.44zM21.802 34.24c-.53.622-.885.84-1.597.796l-5.38-.087.182-2.52 4.93-.043 1.154-1.557 2.09 1.687-1.379 1.724zm12.879-4.034l-4.4-.97c-.312-.087-.53-.311-.667-.58l-1.024-2.258c-.087.086-2.352 3.808-2.352 3.808l2.577 1.948c.799.666.886 1.282.58 2.13l-2.664 6.118-2.352-.884 2.309-5.54-3.463-2.613c-.936-.58-1.285-1.687-.624-2.795l3.107-5.055-2.265-.796-4.087 2.042-1.067-1.774 4.268-2.216c.225-.13.443-.224.668-.224.225 0 .443.043.624.086l3.601 1.152a.73.73 0 01.312.174l1.953 1.462c.269.174.356.486.443.71l.98 3.056 3.956 1.02-.413 2z\" fill-rule=\"nonzero\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#080"
    }
  };
});