define("ember-svg-jar/inlined/pui_railroad_crossing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.3 2.1V.9c0-.2-.2-.4-.4-.4h-1.2c-.1 0-.2 0-.3.1L8 3.8 4.7.6c0-.1-.1-.2-.3-.2H3.2c-.2 0-.4.2-.4.5v1.2c0 .1 0 .2.1.3l3.3 3.3L2.9 9c-.1 0-.1.1-.1.3v1.2c0 .2.2.4.4.4h1.2c.1 0 .2 0 .3-.1L8 7.5l3.2 3.3c.1.1.2.1.3.1h1.2c.2 0 .4-.2.4-.4V9.3c0-.1 0-.2-.1-.3L9.9 5.7l3.2-3.2c.1-.2.2-.3.2-.4z\"/><path d=\"M9.2 13.4h-.8V8.3l-.8.9v4.2h-.8c-.2 0-.4.2-.4.4v1.3c0 .2.2.4.4.4h2.3c.2 0 .4-.2.4-.4v-1.3c.1-.2-.1-.4-.3-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});