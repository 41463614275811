define("ember-svg-jar/inlined/l-scan-qr-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 12v4a1 1 0 01-1 1h-4m5-14h2a2 2 0 012 2v2m-4 1V7m4 10v2a2 2 0 01-2 2h-2M3 7V5a2 2 0 012-2h2m0 14h.01M7 21H5a2 2 0 01-2-2v-2\"/><rect width=\"5\" height=\"5\" x=\"7\" y=\"7\" rx=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-scan-qr-code",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});