define("ember-svg-jar/inlined/l-letter-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 12h6m-6-6h6M3 13l3.553-7.724a.5.5 0 01.894 0L11 13m-8 5h18M4 11h6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-letter-text",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});