define("ember-svg-jar/inlined/ubicacion_de_un_extintor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>UBICACION_DE_UN_EXTINTOR</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#E11212\" d=\"M0 0h50v50H0z\"/><path d=\"M40.824 12.94a8.197 8.197 0 00-4.536-3.142l-2.854-.772c1.673-.54 3.139-1.578 4.158-3.005a.642.642 0 00.015-.727.657.657 0 00-.68-.28L28.621 6.68a.492.492 0 00-.398.481V9.27h-.773a.494.494 0 00-.496.49v.121c-5.136.132-9.274 4.291-9.274 9.397v7.302a1.94 1.94 0 00-.663 1.454v8.618c0 1.085.889 1.964 1.987 1.964a1.975 1.975 0 001.988-1.964v-8.618c0-.58-.26-1.095-.663-1.454v-7.302c0-3.661 2.951-6.633 6.624-6.766v.085c0 .27.223.49.497.49h.773v2.454c-3.17 1.013-5.465 3.95-5.465 7.42V42.04c0 1.062.872 1.923 1.946 1.923H36.6a1.934 1.934 0 001.946-1.923V22.961c0-3.47-2.294-6.407-5.465-7.42v-3.015l7.076 1.422a.66.66 0 00.68-.281.64.64 0 00-.013-.728zm-6.557 13.758v10.227c0 .648-.53 1.173-1.186 1.173h-4.858a1.18 1.18 0 01-1.187-1.173V26.698a1.18 1.18 0 011.187-1.173h4.858a1.18 1.18 0 011.186 1.173z\" stroke=\"#E11212\" stroke-width=\"2\" fill=\"#FFF\" fill-rule=\"nonzero\"/><path d=\"M9.667 41.385H7L11 46l4-4.615h-2.667V34H9.667v7.385z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#e11212"
    }
  };
});