define("ember-svg-jar/inlined/pui_wrench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.499 13.019L9.167 6.476c.633-1.689.316-3.588-1.055-5.066S4.629-.384 2.94.461L6 3.627l-2.215 2.11-3.06-3.06c-.844 1.689-.528 3.905.95 5.382 1.372 1.372 3.271 1.794 4.854 1.055l6.437 6.648c.317.317.739.317.95 0l1.583-1.689c.316-.316.316-.843 0-1.054z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});