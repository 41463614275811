define("ember-svg-jar/inlined/pui_metal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>metal</title><path d=\"M14.7 0H1.9C.8 0 0 .8 0 1.9v12.8c0 .7.6 1.3 1.3 1.3h6.4c.3 0 .5-.2.5-.5v-3.4L12 8.3h3.4c.4 0 .6-.3.6-.6V1.3c0-.7-.6-1.3-1.3-1.3zM2.9 14.4c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.5 1.3-1.3 1.3zm0-10.1c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.5 1.3-1.3 1.3zm4.8 11.2H4.9l2.8-2.8v2.8zm4-12.6c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3-.6 1.3-1.3 1.3-1.3-.5-1.3-1.3zm3.8 4.8h-2.8l2.8-2.8v2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});