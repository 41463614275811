define("ember-svg-jar/inlined/l-table-rows-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 10h2m-1 12v-8m0-12v4M2 10h2m16 0h2M3 19h18M3 22v-6a2 2 135 012-2h14a2 2 45 012 2v6M3 2v2a2 2 45 002 2h14a2 2 135 002-2V2M8 10h2M9 22v-8M9 2v4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "class": "lucide lucide-table-rows-split",
      "viewBox": "0 0 24 24",
      "data-lucide": "true"
    }
  };
});