define("ember-svg-jar/inlined/vis_map_region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 2.309v8.69a.499.499 0 01-.032.176L9.5 12.691V3.809l-3-1.5zm-1-.04L2 3.825v8.906l3.527-1.568a.5.5 0 01-.027-.164V2.27zm.274-1.216a.498.498 0 01.471.01l3.768 1.884 4.284-1.904A.5.5 0 0115 1.5v10a.5.5 0 01-.297.457l-4.5 2a.5.5 0 01-.427-.01l-3.789-1.894-4.283 1.904a.5.5 0 01-.703-.457v-10a.5.5 0 01.297-.457l4.476-1.99zM10.5 3.825v8.906l3.5-1.556V2.27l-3.5 1.556z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});